import React, { useState } from "react";
import { PropTypes } from "prop-types";
import styles from "../../css/facility/AssessmentNotificationList.module.scss";
import {
	getAssessmentRecipients,
	updateAssessmentEmailList,
	removeFromAssessmentsList, // ← might not be needed
	removeRecipientByListType,
} from "../../helpers/utils_notifications";
import FacilityNotificationsList from "./FacilityNotificationsList";
import AddAltEmail from "../user/AddAltEmail";

// ##TODOS:
// - Add updater request to 'addNewRecipient'

const customCSS = {
	email: {
		backgroundColor: "#eaecef",
	},
};

const addEmailMsg = `An additional email address will be used to receive notifications and alerts. All notifications and alerts will be sent to the below list of recipients.To add another email enter an email and save changes.`;

const AssessmentNotificationList = ({
	listType = "ASSESSMENTS",
	currentUser = {},
	currentFacility = {},
	notificationRecord = {},
	dispatchAlert,
	syncChangesHandler,
}) => {
	const [currentRecord, setCurrentRecord] = useState(notificationRecord);
	const [emailList, setEmailList] = useState(() => {
		return getAssessmentRecipients(notificationRecord);
	});
	const [newEmailRecipient, setNewEmailRecipient] = useState("");

	const handleNewEmail = (e) => {
		const { value } = e.target;
		setNewEmailRecipient(value);
	};

	const addNewRecipient = () => {
		setEmailList([newEmailRecipient, ...emailList]);
		setNewEmailRecipient("");
		setCurrentRecord({
			...currentRecord,
			EmailsTo: [newEmailRecipient, ...emailList].join("; "),
		});
		// fire off request here (eg 'saveAltEmail')
		return updateAndAlert({
			...currentRecord,
			EmailsTo: [newEmailRecipient, ...emailList].join("; "),
		});
	};

	// updates db & triggers alert UI
	const updateAndAlert = async (updatedRecord) => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const wasUpdated = await updateAssessmentEmailList(
			token,
			facilityID,
			updatedRecord
		);

		if (wasUpdated) {
			syncChangesHandler(listType, updatedRecord);
			return dispatchAlert("SUCCESS", {
				heading: `Email list was updated!`,
			});
		} else {
			// setEmailList([emailList.shift()]);
			return dispatchAlert("ERROR", {
				heading: `Email list was NOT updated.`,
				subheading: `Please try again!`,
			});
		}
	};

	const removeRecipient = (listType, emailToRemove) => {
		const updatedRecord = removeRecipientByListType(
			listType,
			emailToRemove,
			currentRecord
		);

		console.log("updatedRecord", updatedRecord);

		setEmailList(getAssessmentRecipients(updatedRecord));
		setCurrentRecord({ ...updatedRecord });
		// fire off removal request here...
		return updateAndAlert(updatedRecord);
	};

	return (
		<div className={styles.AssessmentNotificationList}>
			<div className={styles.AssessmentNotificationList_addRecipient}>
				<AddAltEmail
					key={"NEW_ASSESSMENT_EMAIL"}
					name="newAssessmentEmail"
					id="newAssessmentEmail"
					val={newEmailRecipient}
					btnText="Add Email Address"
					msg={addEmailMsg}
					customStyles={customCSS.email}
					handleChange={handleNewEmail}
					saveAddEmail={addNewRecipient}
				/>
			</div>

			<FacilityNotificationsList
				key={"ASSESSMENTS_LIST"}
				listType={listType}
				notificationsList={emailList}
				removeRecipient={removeRecipient}
			/>
		</div>
	);
};

export default AssessmentNotificationList;

AssessmentNotificationList.defaultProps = {
	listType: "ASSESSMENTS",
	currentUser: {},
	currentFacility: {},
	notificationRecord: {},
};

AssessmentNotificationList.propTypes = {
	listType: PropTypes.string,
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	notificationRecord: PropTypes.object,
	dispatchAlert: PropTypes.func,
	syncChangesHandler: PropTypes.func,
};
