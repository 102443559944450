import { isAfter, isBefore } from "date-fns";
import { messages } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { AppMessageModel } from "./utils_models";
import { params } from "./utils_params";
import { blueGrey, red, yellow } from "./utils_styles";

const msgPriorities = {
	1: "High Priority (red)",
	2: "Medium Priority (orange)",
	3: "Low Priority (grey)",
	0: "Low Priority (grey)",
	null: "No Priority (grey)",
	undefined: "No Priority (grey)",
};
const msgStatuses = {
	INACTIVE: false,
	ACTIVE: true,
};

const fetchMessages = async (token, customParams = {}) => {
	let url = currentEnv.base + messages.get.byApp2;
	url += "?" + new URLSearchParams({ ...params.messages });
	url += "&" + new URLSearchParams({ ...customParams });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const fetchLoginPageMessages = async (token) => {
	const messages = await fetchMessages(token, {
		ApplicationID: 3,
		IsEnable: true,
	});

	return messages;
};
const fetchMessagesByModule = async (token, moduleName) => {
	const moduleMessages = await fetchMessages(token, {
		MessageName: moduleName,
	});

	return moduleMessages;
};

const updateMessages = async (token, messageModel = {}) => {
	let url = currentEnv.base + messages.update.byApp;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(messageModel),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// disables ALL login page messages
const disableAllMessages = async (token) => {
	const wasDisabled = await updateMessages(token, {
		ApplicationID: 3,
		ApplicationMessageID: 1,
		MessageName: "LoginPage",
		Msg1IsActive: false,
		Msg2IsActive: false,
		Msg3IsActive: false,
		Msg4IsActive: false,
		Msg5IsActive: false,
	});

	if (wasDisabled) {
		return wasDisabled;
	} else {
		return wasDisabled;
	}
};

// MESSAGE PROCESSING UTILS //

const extractMsgFromRecord = (msgNumber = 1, msgRecord = {}) => {
	const prefix = `Msg`;
	const name = prefix + msgNumber;
	const priorityName = `${name}Priority`;
	const stateName = `${name}IsActive`;
	const expiryName = `${name}Expiry`;
	// msg values
	const msg = msgRecord?.[name];
	const priority = msgRecord?.[priorityName];
	const isActive = msgRecord?.[stateName];
	const expiry = msgRecord?.[expiryName];

	return {
		id: msgNumber,
		msg: msg,
		priority: priority,
		isActive: isActive,
		expiry: expiry,
	};
};

const processMessages = (msgRecord = {}) => {
	const msg1 = extractMsgFromRecord(1, msgRecord);
	const msg2 = extractMsgFromRecord(2, msgRecord);
	const msg3 = extractMsgFromRecord(3, msgRecord);
	const msg4 = extractMsgFromRecord(4, msgRecord);
	const msg5 = extractMsgFromRecord(5, msgRecord);

	const messages = [msg1, msg2, msg3, msg4, msg5];

	return {
		status: msgRecord?.IsActive ? "ACTIVE" : "INACTIVE",
		messages: messages,
	};
};

// processes data from server for client usage
const syncMessagesToState = (msgRecord = {}) => {
	const { status, messages } = processMessages(msgRecord);
	const [msg1, msg2, msg3, msg4, msg5] = messages;

	const msgState = {
		// global toggle
		disableAllMessages: msgStatuses?.[status],
		// msg1
		msg1: msg1?.msg,
		msg1Priority: msgPriorities?.[msg1?.priority] ?? "Low Priority (grey)",
		msg1Expiry: msg1?.expiry ?? "",
		enableMsg1: msg1?.isActive ?? false,
		// msg2
		msg2: msg2?.msg,
		msg2Priority: msgPriorities?.[msg2?.priority] ?? "Low Priority (grey)",
		msg2Expiry: msg2?.expiry ?? "",
		enableMsg2: msg2?.isActive ?? false,
		// msg3
		msg3: msg3?.msg,
		msg3Priority: msgPriorities?.[msg3?.priority] ?? "Low Priority (grey)",
		msg3Expiry: msg3?.expiry ?? "",
		enableMsg3: msg3?.isActive ?? false,
		// msg4
		msg4: msg4?.msg,
		msg4Priority: msgPriorities?.[msg4?.priority] ?? "Low Priority (grey)",
		msg4Expiry: msg4?.expiry ?? "",
		enableMsg4: msg4?.isActive ?? false,
		// msg5
		msg5: msg5?.msg,
		msg5Priority: msgPriorities?.[msg5?.priority] ?? "Low Priority (grey)",
		msg5Expiry: msg5?.expiry ?? "",
		enableMsg5: msg5?.isActive ?? false,
	};

	return msgState;
};

const updateMessagesModel = (vals = {}) => {
	const base = new AppMessageModel(vals);
	const model = base.getModel();

	return model;
};

// MESSAGE UTILS //

const matchPriorityIDFromName = (name) => {
	const priorities = {
		"High Priority (red)": 1,
		"Medium Priority (orange)": 2,
		"Low Priority (grey)": 3,
	};
	return priorities?.[name];
};

const getMsgPriorityColor = (priority) => {
	switch (priority) {
		case 1:
		case "HIGH": {
			return red[600];
		}
		case 2:
		case "MEDIUM": {
			return yellow[500];
		}
		case null:
		case undefined:
		case 0:
		case 3:
		case "LOW": {
			return blueGrey[500];
		}

		default:
			return blueGrey[500];
	}
};

const hasMsgExpired = (msgExpiry) => {
	// is 1st date *after* 2nd date (expiry)
	if (!msgExpiry || msgExpiry === null || msgExpiry === undefined) return false;
	const isExpired = isAfter(new Date(), new Date(msgExpiry));

	return isExpired;
};

/**
 * Checks if a 'message' is active & not expired.
 * @param {Object} msg - A client-formatted 'message' object
 * @returns {Boolean} - Returns whether the message should be active or not
 */
const isMsgActive = (msg = {}) => {
	const { enableMsg, msgExpiry } = msg;
	const isExpired = hasMsgExpired(msgExpiry);

	return enableMsg && !isExpired;
};

// ACTIVE MESSAGES (ALL) //

const MsgWithEmail = ({ msgStart, email, msgEnd }) => {
	return (
		<span>
			{msgStart} <a href={`mailto:${email}`}>{email}</a> {msgEnd}
		</span>
	);
};

const MESSAGES = [
	{
		// msg: `Advantage Care Tracker is now ready for use. Please contact support@aladvantage.com to start your 30 day free trial`,
		msg: (
			<MsgWithEmail
				msgStart="Advantage Care Tracker is now ready for use. Please contact"
				email="support@aladvantage.com"
				msgEnd="to start your 30 day free trial"
			/>
		),
		msgExpiry: new Date(2022, 10, 1),
		msgPriority: "MEDIUM",
		enableMsg: true,
	},
];

const ACTIVE_MESSAGES = {
	loginPage: [...MESSAGES],
	homePage: [...MESSAGES],
};

export {
	fetchMessages,
	fetchLoginPageMessages,
	fetchMessagesByModule,
	updateMessages,
	disableAllMessages,
};

export {
	getMsgPriorityColor,
	hasMsgExpired,
	isMsgActive,
	extractMsgFromRecord,
	processMessages,
	updateMessagesModel,
	// matchers
	matchPriorityIDFromName,
};

export { msgPriorities, msgStatuses, syncMessagesToState };

export { ACTIVE_MESSAGES };
