import React, { useState, useEffect, useContext, useCallback } from "react";
import styles from "../css/pages/Home.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { isEmptyArray, isEmptyVal } from "../helpers/utils_types";
// components
import PortalOptions from "../components/app/PortalOptions";
import HintsContainer from "../components/hints/HintsContainer";
import Hint from "../components/hints/Hint";
import DownloadFile from "../components/ui/DownloadFile";
// custom hints
import { legacyHint } from "../components/hints/CustomHints";
import {
	emarSummaryForUserFacilities,
	generateEmarErrorSummary,
	getEmarErrorSummary,
	getEmarErrors,
} from "../helpers/utils_emar";
import { subDays } from "date-fns";
import { AuthContext } from "../state/AuthContext";
import {
	acknowledgeBy,
	getBannerIDs,
	getFacilityServiceBanners,
	warningMsg,
} from "../helpers/utils_banners";
import { getNavigatedFrom } from "../helpers/utils_routes";
import { isLocalhost } from "../helpers/utils_env";
import { featureFlags } from "../helpers/utils_permissions";
import currentTrainingDoc from "../assets/docs/ALA Update Document Notice_5.24.22--LOC.docx";
// components
import RequireSecurityQuestions from "../components/firstlogin/RequireSecurityQuestions";
import HomePageMessages from "../components/messages/HomePageMessages";
import HomeAlertsBanner from "../components/alerts/HomeAlertsBanner";
import EmarErrorSummary from "../components/emar/EmarErrorSummary";
import UrgentBanner from "../components/banners/UrgentBanner";
import ServiceBanner from "../components/banners/ServiceBanner";

/**
 * Portal - Home Page: includes shortcuts for:
 * - 'Your Apps'
 * - 'Facility Access'
 * - 'User Access'
 * - 'Manage Settings'
 */

const showUrgentBanners = (bannerState = [], history = {}) => {
	const hasBanners = bannerState?.show;
	const fromPath = getNavigatedFrom(history);
	const justLoggedIn = fromPath === "/";
	// check if user is coming from the root path (eg. they just logged in)
	const shouldShow = justLoggedIn && hasBanners;
	// check if local ENV & if featureFlag enables/disables it for Localhost
	// const isLocalEnv = isLocalhost();
	const isLocalEnv = false;
	const isFeatureDisabledForLocalhost = false;

	// DISABLED FOR LOCAL TESTING
	// const isFeatureDisabledForLocalhost =
	// 	featureFlags?.banners?.disableServiceBannerOnLocal;
	// const enableAndShow =
	// 	!isLocalEnv && isFeatureDisabledForLocalhost && shouldShow;

	const enableAndShow = shouldShow && !isLocalEnv;

	// return justLoggedIn && hasBanners;
	return enableAndShow;
};

//  'Welcome <username>!'
const getWelcomeMsg = (currentUser = {}) => {
	const { username, firstName } = currentUser;
	if (isEmptyVal(username) && isEmptyVal(firstName)) return "";
	if (isEmptyVal(firstName)) {
		return `${username}!`;
	} else {
		return `${firstName}!`;
	}
};

const WelcomeHeader = ({ children }) => {
	return <header className={styles.Home_header}>{children}</header>;
};

const getUsername = (currentUser) => {
	const username = currentUser?.username ?? currentUser?.email;
	return !isEmptyVal(username) ? `(${username})` : "";
};

const Home = ({ history }) => {
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { authData } = useContext(AuthContext);
	const { currentUser } = globalState;

	// ONLY show banner if EMAR errors exist
	// const [showAlertsBanner, setShowAlertsBanner] = useState(true);
	const [emarSummary, setEmarSummary] = useState({
		showBanner: false,
		details: {
			raw: [],
			msg: "",
		},
	});
	// PAST-DUE ACCOUNT BALANCE SERVICE BANNER(S) //
	const [serviceBanners, setServiceBanners] = useState({
		show: false,
		banners: [],
		wasAcknowledged: false,
	});

	const fetchEmarIssues = async () => {
		const { facilities } = currentUser;
		const userID = currentUser?.userID;
		const token = currentUser?.token ?? authData?.token;
		const appID = 19;
		// range last 4 days
		const base = new Date();
		const rangeCount = 4;
		const start = subDays(base, rangeCount).toISOString();
		const end = base.toISOString();
		const rawSummary = await getEmarErrorSummary(token, appID, start, end); // returns array
		// errors for user's facilities - NOT NEEDED AS API HANDLES THIS LOGIC
		// const userEmarSummary = emarSummaryForUserFacilities(
		// 	facilities,
		// 	rawSummary
		// );

		if (!isEmptyArray(rawSummary)) {
			// const summary = generateEmarErrorSummary(userEmarSummary); // returns object
			const summary = generateEmarErrorSummary(rawSummary);

			return setEmarSummary({
				showBanner: true,
				details: { raw: rawSummary, msg: summary?.summary },
			});
		} else {
			return setEmarSummary({
				showBanner: false,
				details: { raw: [], msg: "" },
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};
	const fetchBannersAndIssues = useCallback(() => {
		const fetchAllBanners = async () => {
			const { facilities } = currentUser;
			const appID = 19;
			const userID = currentUser?.userID;
			const token = currentUser?.token ?? authData?.token;
			// range last 4 days
			const base = new Date();
			const rangeCount = 4;
			const start = subDays(base, rangeCount).toISOString();
			const end = base.toISOString();

			const [rawSummary, banners] = await Promise.all([
				getEmarErrorSummary(token, appID, start, end),
				getFacilityServiceBanners(token, userID),
			]);

			//
			//
			//
			//

			if (banners?.length >= 1 || rawSummary?.length >= 1) {
				// const summary = generateEmarErrorSummary(userEmarSummary); // returns object
				const summary = generateEmarErrorSummary(rawSummary);

				setServiceBanners({
					...serviceBanners,
					show: !isEmptyArray(banners),
					banners: banners,
				});
				return setEmarSummary({
					showBanner: true,
					details: { raw: rawSummary, msg: summary?.summary },
				});
			} else {
				setServiceBanners({
					...serviceBanners,
					show: !isEmptyArray(banners),
					banners: banners,
				});
				return setEmarSummary({
					showBanner: false,
					details: { raw: [], msg: "" },
				});
			}
		};

		fetchAllBanners();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fires off request to record acknowledgement & closes modal
	const confirmAcknowledgement = async (e) => {
		// setShowUrgentBanner(false);
		const { token, userID } = currentUser;
		const messageIDs = getBannerIDs(serviceBanners?.banners);

		const allParams = {
			userID: userID,
			name: "FacilityBanner",
			messageIDs: messageIDs, // list of 'FacilityBannerId's
		};

		const wasAcknowledged = await acknowledgeBy(token, allParams);
		// const wasAcknowledged = true;

		if (wasAcknowledged) {
			return setServiceBanners({
				...serviceBanners,
				show: false,
				wasAcknowledged: true,
			});
		} else {
			return setServiceBanners({
				...serviceBanners,
				wasAcknowledged: false,
			});
		}
	};

	// fetches emar error summary 'onMount'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (currentUser?.userID) {
			fetchBannersAndIssues();
		}

		return () => {
			isMounted = false;
		};
	}, [currentUser?.userID, fetchBannersAndIssues]);

	return (
		<div className={styles.Home}>
			{/* EMAR BANNER - PREVIOUS UI POSITION */}
			{/* {emarSummary.showBanner && (
				<HomeAlertsBanner icon="WARN2">
					<EmarErrorSummary
						key={`ERROR-SUMMARY-${emarSummary?.details?.raw?.length}`}
						errorSummaryData={emarSummary?.details ?? {}}
						currentUser={currentUser}
					/>
				</HomeAlertsBanner>
			)} */}

			{/* SERVICE BANNER - UPDATED */}
			{showUrgentBanners(serviceBanners, history) && (
				<UrgentBanner
					title={`Past Due Service Notice(s)`}
					subtitle={`You have ${
						serviceBanners?.banners?.length ?? 0
					} past due notice(s) to read.`}
					confirmAcknowledgement={confirmAcknowledgement}
					warningMsg={warningMsg}
				>
					{!isEmptyArray(serviceBanners?.banners) &&
						serviceBanners.banners.map((entry, idx) => (
							<ServiceBanner
								key={`USER-SB-${serviceBanners?.length}-${idx}`}
								bannerDetails={entry}
								confirmAcknowledgement={confirmAcknowledgement}
							/>
						))}
				</UrgentBanner>
			)}

			<WelcomeHeader>
				<h1 className={styles.Home_header_welcome}>
					Welcome <b>{getWelcomeMsg(currentUser)}</b>
				</h1>
				<p className={styles.Home_header_username}>
					{getUsername(currentUser)}
				</p>
				<HomePageMessages disableAll={false} />
			</WelcomeHeader>

			<div className={styles.Home_main}>
				<section className={styles.Home_main_options}>
					<PortalOptions currentUser={currentUser} history={history} />
				</section>

				<section className={styles.Home_main_emar}>
					{/* EMAR BANNER */}
					{emarSummary.showBanner &&
						featureFlags?.banners?.enableEmarBanners && (
							<HomeAlertsBanner icon="WARN2">
								<EmarErrorSummary
									key={`ERROR-SUMMARY-${emarSummary?.details?.raw?.length}`}
									errorSummaryData={emarSummary?.details ?? {}}
									currentUser={currentUser}
								/>
							</HomeAlertsBanner>
						)}
				</section>

				<section className={styles.Home_main_other}>
					{false && (
						<RequireSecurityQuestions
							currentUser={currentUser}
							globalState={globalState}
							dispatchToState={dispatchToState}
						/>
					)}
					{/* TRAINING DOCUMENT DOWNLOAD */}
					<DownloadFile
						src={currentTrainingDoc}
						enableFlash={true}
						flashText={`Updated ~ 5/24/22`}
					/>
				</section>
				<section className={styles.Home_hints}>
					<HintsContainer>
						<Hint hint={legacyHint} />
						{/* <HomePageMessages disableAll={true} /> */}
					</HintsContainer>
				</section>
			</div>
		</div>
	);
};

export default Home;

Home.defaultProps = {};

Home.propTypes = {
	history: PropTypes.object,
};
