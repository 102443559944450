import React, { useEffect, useState, useContext } from "react";
import styles from "../../css/views/PortalView.module.scss";
import { PropTypes } from "prop-types";
import { AuthContext } from "../../state/AuthContext";
import { GlobalStateContext } from "../../state/GlobalStateContext";
import {
	getInitialResource,
	populateState,
} from "../../helpers/utils_initialResource";
import { logout } from "../../helpers/utils_auth";
import { useWindowSize } from "../../utils/useWindowSize";
import { mobileSize } from "../../helpers/utils_styles";
import { setUserGA } from "../../helpers/utils_tracking";
// components
import SidebarWrapper from "../../components/app/SidebarWrapper";
import DashboardNav from "../../components/dashboard/DashboardNav";
// default landing page
import DashboardPage from "../../pages/DashboardPage";
import Spinner from "../../components/shared/Spinner";
import LogoutButton from "../../components/dashboard/LogoutButton";
import { setParamsMany } from "../../helpers/utils_params";
import { clearStorage } from "../../helpers/utils_caching";

// hide <DashboardNav/> on these pages via custom override
const hideOnThesePages = ["/portal/profile", "/portal/access", "/reset"];

const customCSS = {
	isCollapsed: {
		paddingLeft: "9rem",
		transition: "all .3s ease-in-out",
	},
	isNotCollapsed: {
		paddingLeft: "22rem",
		transition: "all .3s ease-in-out",
	},
	spinner: {
		marginTop: "5rem",
	},
};

const getViewSize = (isCollapsed, winSize = {}) => {
	const { width, height } = winSize;
	if (width < mobileSize.width || height < mobileSize.height) {
		return {
			width: "100%",
			height: "100%",
			paddingBottom: "7rem",
		};
	} else if (width > mobileSize.width || height > mobileSize) {
		if (isCollapsed) {
			return { ...customCSS.isCollapsed };
		} else {
			return { ...customCSS.isNotCollapsed };
		}
	}
};

const PortalView = ({ history }) => {
	const { authData } = useContext(AuthContext);
	const { state: globalState, dispatch: dispatchToState } =
		useContext(GlobalStateContext);
	const { currentUser } = globalState;
	const [initialMount, setInitialMount] = useState({
		isLoading: false,
		hasLoaded: false,
	});
	const { isLoading, hasLoaded } = initialMount;
	const [isCollapsed, setIsCollapsed] = useState(true);
	const winSize = useWindowSize();

	// loads facilities, userProfile, userAccess (ie apps)
	const handleInitialResource = async () => {
		setInitialMount({ ...initialMount, isLoading: true });
		const resource = await getInitialResource(authData);
		const updatedState = populateState(resource, globalState);

		if (updatedState) {
			setInitialMount({ isLoading: false, hasLoaded: true });
			// Uncomment below params handler for 'refresh support'
			// setParamsMany(window.location, { token: currentUser.token });
			return dispatchToState({
				type: "SYNC_INITIAL_RESOURCE",
				data: { newState: { ...updatedState } },
			});
		}
	};

	const handleLogout = async (e) => {
		e.preventDefault();
		const { token } = currentUser;
		const wasLoggedOut = await logout(token);

		console.log(`Was Logged out? `, wasLoggedOut);
		if (wasLoggedOut) {
			dispatchToState({ type: "MANUAL_LOGOUT" });
			// TEST SYSTEM_USER IS FAILING TO AUTHENTICATE
			// clearStorage("SYSTEM"); // PERHAPS ADD THIS TO CLEAN THE CACHE &
			return history.push({
				pathname: "/",
				state: {
					from: history?.location?.pathname,
					wasLoggedOut: true,
				},
			});
		} else {
			return alert(`❌ Oops! Logout failed. Please try again!`);
		}
	};

	// fetches apps available to user
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		handleInitialResource();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// sets userID for page tracking (ReactGA)
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setUserGA(currentUser);

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.PortalView}>
			<SidebarWrapper
				history={history}
				currentUser={currentUser}
				isCollapsed={isCollapsed}
				setIsCollapsed={setIsCollapsed}
			/>

			<LogoutButton handleLogout={handleLogout} />

			<section
				className={styles.PortalView_routes}
				style={getViewSize(isCollapsed, winSize)}
			>
				{isLoading && (
					<div className={styles.PortalView_loading}>
						<Spinner customStyles={customCSS.spinner} />
						<div className={styles.PortalView_loading_msg}>
							Loading app...please wait..
						</div>
					</div>
				)}
				{hasLoaded && (
					<>
						<DashboardNav
							history={history}
							winSize={winSize}
							isCollapsed={isCollapsed}
							hideOnThesePages={hideOnThesePages}
						/>
						<DashboardPage
							key={`DASHBOARD-${currentUser?.userID}`}
							globalState={globalState}
							dispatchToState={dispatchToState}
							history={history}
							winSize={winSize}
							isCollapsed={isCollapsed}
						/>
					</>
				)}
			</section>
		</div>
	);
};

export default PortalView;

PortalView.defaultProps = {};

PortalView.propTypes = {
	history: PropTypes.object,
};
