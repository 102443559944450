import React, { useState, useCallback, useEffect } from "react";
import styles from "../../css/user/ResetUsersPassword.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { blueGrey, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { changeUserPassword } from "../../helpers/utils_user";
import { createAndRegisterOtp } from "../../helpers/utils_otp";
// components
import PasswordValidator from "../forms/PasswordValidator";
import PasswordInput from "../shared/PasswordInput";
import DoNotMatchIndicator from "../forms/DoNotMatchIndicator";
import ConfirmedIndicator from "../forms/ConfirmedIndicator";
import ButtonSM from "../shared/ButtonSM";
import RequestOTP from "../otp/RequestOTP";
import ResetViaOTP from "./ResetViaOTP";

// Intended Purpose:
// - This is for admins & super-users to reset other user's passwords easily & quickly

// REQUIREMENTS:
// - Allow user to reset another user's password:
// 		- UI will generate & register a temp OTP to use as the 'targetUser's "old password"
// 		- Then fire off a request to set their password to the user's custom created one

const customCSS = {
	cancel: {
		padding: ".7rem 1.3rem",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: "1rem",
		fontSize: "1.5rem",
	},
	save: {
		padding: ".7rem 1.3rem",
		backgroundColor: blueGrey[700],
		color: "#ffffff",
		fontSize: "1.5rem",
	},
};

const hasPasswordVals = (vals = {}) => {
	const { newPassword, newPasswordConfirm } = vals;
	return !isEmptyVal(newPassword) && !isEmptyVal(newPasswordConfirm);
};

const ResetUsersPassword = ({
	currentUser = {},
	targetUser = {},
	isLockedOut = false,
	dispatchAlert,
}) => {
	const { formState, setFormState, handleChange, handleReset } = useForm({
		newPassword: "",
		newPasswordConfirmed: "",
	});
	const { values } = formState;
	const [passwordsMatch, setPasswordsMatch] = useState(false);
	const [wasChanged, setWasChanged] = useState(false);

	// watches for password changes to confirm the new passwords match
	const confirmNewPassword = useCallback(() => {
		const { newPassword, newPasswordConfirmed } = values;
		if (isEmptyVal(newPassword) || isEmptyVal(newPasswordConfirmed)) {
			return setPasswordsMatch(false);
		}

		if (newPassword === newPasswordConfirmed) {
			return setPasswordsMatch(true);
		} else {
			return setPasswordsMatch(false);
		}
	}, [values]);

	const handleNewPassword = (e) => {
		handleChange(e);
	};

	// solely handles applying changed new password
	const saveChangedPassword = async (tempOTP) => {
		const { token } = currentUser;
		const { newPasswordConfirmed } = values;
		const { userID } = targetUser;

		const wasChanged = await changeUserPassword(token, {
			userID: userID,
			oldPassword: tempOTP, // might need to be changed
			newPassword: newPasswordConfirmed,
		});

		console.log(`wasChanged? `, wasChanged);

		if (wasChanged) {
			return setWasChanged(true);
		} else {
			return setWasChanged(false);
		}
	};

	// creates an OTP & sets that as the user's "old" password
	const createAndSetUserOTP = async () => {
		const { token } = currentUser;
		const { userID } = targetUser;
		// generates & sets 'targetUser's password to an OTP in place of their actual password
		const createdOTP = await createAndRegisterOtp(token, {
			userLoginID: userID,
			expires: null,
		});
		if (createdOTP.wasRegistered) {
			const { tempOTP } = createdOTP;
			console.log(`OTP was registered...you may now change your password!`);
			return saveChangedPassword(tempOTP);
		} else {
			return setWasChanged(false);
		}
	};
	// cancel new password
	const cancelNewPassword = (e) => {
		// cancel reset
		handleReset(e);
	};

	// checks whether passwords entered match
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		confirmNewPassword();

		return () => {
			isMounted = false;
		};
	}, [confirmNewPassword]);

	return (
		<div className={styles.ResetUsersPassword}>
			{!wasChanged && (
				<>
					<div className={styles.ResetUsersPassword_info}>
						<span>Change/reset </span>
						<b>
							{targetUser?.firstName} {targetUser?.lastName}'s
						</b>{" "}
						<span> password by entering & re-entering a custom password</span>
						{isLockedOut && (
							<div className={styles.ResetUsersPassword_lockedOut}>
								This user is locked out. Please unlock this account before
								resetting their password.
							</div>
						)}
					</div>
					<div className={styles.ResetUsersPassword_reset}>
						<PasswordValidator
							name="newPassword"
							id="newPassword"
							label="Enter a new password"
							val={values.newPassword}
							handleChange={handleChange}
							autoComplete="new-password"
						/>
						<PasswordInput
							name="newPasswordConfirmed"
							id="newPasswordConfirmed"
							label="Re-enter new password"
							val={values.newPasswordConfirmed}
							handleChange={handleNewPassword}
							autoComplete="new-password"
							placeholder="Re-enter password..."
						/>
					</div>
					<div className={styles.ResetUsersPassword_results}>
						{!passwordsMatch && hasPasswordVals(values) && (
							<div
								className={styles.ChangePassword_matchingStatus_confirmation}
							>
								<DoNotMatchIndicator />
							</div>
						)}
						{passwordsMatch && (
							<div
								className={styles.ChangePassword_matchingStatus_confirmation}
							>
								<ConfirmedIndicator
									newPassword={values?.newPassword}
									newPasswordConfirm={values?.newPasswordConfirm}
								/>
							</div>
						)}
					</div>
					<div className={styles.ResetUsersPassword_actions}>
						<ButtonSM
							customStyles={customCSS.cancel}
							handleClick={cancelNewPassword}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							isDisabled={
								isEmptyVal(values.newPassword) ||
								isEmptyVal(values.newPasswordConfirmed)
							}
							customStyles={customCSS.save}
							handleClick={createAndSetUserOTP}
						>
							Save New Password
						</ButtonSM>
					</div>
				</>
			)}
			{wasChanged && (
				<div className={styles.ResetUsersPassword_success}>
					<svg className={styles.ResetUsersPassword_success_icon}>
						<use xlinkHref={`${sprite}#icon-check_circle1`} />
					</svg>

					<div className={styles.ResetUsersPassword_success_msg}>
						<b>
							{targetUser?.firstName} {targetUser?.lastName}'s
						</b>{" "}
						password has been changed!
					</div>
				</div>
			)}

			<div className={styles.ResetUsersPassword_msg}>
				OR Use a One-Time-Password (OTP)
			</div>

			{/* GENERATE OTP AS AN ALTERNATIVE */}
			<div className={styles.ResetUsersPassword_viaOTP}>
				<ResetViaOTP currentUser={currentUser} targetUser={targetUser} />
			</div>
		</div>
	);
};

export default ResetUsersPassword;

ResetUsersPassword.defaultProps = {};

ResetUsersPassword.propTypes = {};
