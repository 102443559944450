import React, { useState } from "react";
import styles from "../../css/facility/CustomFacilitySearchWindow.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	formatAndSortUserFacilities,
	searchForFacilityBy,
} from "../../helpers/utils_facility";
import { isEmptyVal } from "../../helpers/utils_types";
import { featureFlags } from "../../helpers/utils_permissions";
import { blueGrey, red } from "../../helpers/utils_styles";
// components
import FormSection from "../forms/FormSection";
import CustomCheckbox from "../shared/CustomCheckbox";
import TextInput from "../shared/TextInput";
import CustomDropdown from "../shared/CustomDropdown";
import ButtonSM from "../shared/ButtonSM";

const customCSS = {
	checkbox: {
		marginBottom: ".6rem",
	},
	searchBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
	},
	cancelBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "1rem",
	},
	clearBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "auto",
	},
	dropdown: {
		width: "35rem",
	},
	searchInput: {
		backgroundColor: "#eaecef",
	},
	userIDSearch: {
		backgroundColor: "#eaecef",
	},
};

// checks if required selections have been made
const noTypeSelected = (vals = {}) => {
	const {
		// bools
		byEmail,
		byFirstName,
		byLastName,
		byFacility,
		byUserID,
		// user type bools
		byALAAdmin,
		byMedTechRestricted,
		bySuperUser,
		byFacilityAdmin,
		byRegionalAdmin,
		// fields
		emailSearch,
		firstNameSearch,
		lastNameSearch,
		facilitySearch,
		userIDSearch,
	} = vals;

	// no search type or search value supplied
	const hasNoType =
		!byEmail &&
		!byFirstName &&
		!byLastName &&
		!byFacility &&
		!byUserID &&
		// user types
		!byALAAdmin &&
		!byMedTechRestricted &&
		!bySuperUser &&
		!byFacilityAdmin &&
		!byRegionalAdmin;
	const hasNoSearch =
		isEmptyVal(emailSearch) &&
		isEmptyVal(firstNameSearch) &&
		isEmptyVal(lastNameSearch) &&
		isEmptyVal(facilitySearch) &&
		isEmptyVal(userIDSearch);

	return hasNoSearch && hasNoType;
};

const CustomSearch = ({
	vals,
	handleChange,
	handleCheckbox,
	handleSettings,
	allFacilities = [],
}) => {
	// handles which to render
	const renderSearch = (vals) => {
		switch (true) {
			case vals?.byEmail: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_EMAIL`}
							label="Search By Email Address"
							name="emailSearch"
							id="emailSearch"
							placeholder="Enter an email..."
							val={vals.emailSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byCommunityName: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_COMMUNITY_NAME`}
							label="Search By Community Name"
							name="communitySearch"
							id="communitySearch"
							placeholder="Enter User's Community Name..."
							val={vals.communitySearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFacilityID: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_FACILITY_ID`}
							label="Search By Facility ID"
							name="facilityIDSearch"
							id="facilityIDSearch"
							placeholder="Enter Facility ID..."
							val={vals.facilityIDSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFacility: {
				return (
					<div className={styles.CustomSearch}>
						<CustomDropdown
							name="communityNameSearch"
							id="communityNameSearch"
							label="Search for Facility Name"
							selection={vals.communityNameSearch}
							setSelection={handleSettings}
							options={allFacilities}
							customStyles={customCSS.dropdown}
						/>
					</div>
				);
			}
			case vals?.byParentID: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_PARENT_ID`}
							label="Search By Parent ID"
							name="parentIDSearch"
							id="parentIDSearch"
							placeholder="Enter Parent ID..."
							val={vals.parentIDSearch}
							handleChange={handleChange}
							customStyles={customCSS.userIDSearch}
						/>
					</div>
				);
			}
			case vals?.bySuspendedStatus: {
				return (
					<div className={styles.CustomSearch}>
						<CustomCheckbox
							label="Is Suspended"
							name="suspendedStatus"
							id="suspendedStatus"
							val={vals?.suspendedStatus}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
					</div>
				);
			}
			default:
				return null;
		}
	};

	return <>{renderSearch(vals)}</>;
};

const CustomFacilitySearchWindow = ({ currentUser = {}, closeWindow }) => {
	const { formState, setFormState, handleCheckbox, handleChange, handleReset } =
		useForm({
			// by user field
			byCommunityName: false,
			byFacilityID: false,
			byParentID: false,
			byEmail: false,
			byAddress: false,
			byState: false,
			byCity: false,
			byPhoneNumber: false,
			byExecDirector: false,
			byALADirector: false,
			bySuspendedStatus: false,
			// searches
			facilityIDSearch: "",
			parentIDSearch: "",
			communitySearch: "",
			emailSearch: "",
			suspendedStatus: false,
			// not enabled/active yet
			addressSearch: "",
			citySearch: "",
			stateSearch: "",
			phoneNumberSearch: "",
			execDirectorSearch: "",
			alaDirectorSearch: "",
		});
	const { values } = formState;
	const [searchResults, setSearchResults] = useState(null);
	const [isLoadingResults, setIsLoadingResults] = useState(false);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// fires off request w/ search values
	const runSearch = async (e) => {
		const { token } = currentUser;
		setIsLoadingResults(true);
		const results = await searchForFacilityBy(token, values);
		console.log("results", results);

		if (results) {
			setIsLoadingResults(false);
			return setSearchResults(results);
		} else {
			return setIsLoadingResults(false);
		}
	};

	// clear form & close search window
	const cancelSearch = (e) => {
		handleReset(e);
		closeWindow();
	};
	// clear our form fields
	const clearSearch = (e) => {
		handleReset(e);
		setSearchResults([]);
	};

	return (
		<div className={styles.CustomFacilitySearchWindow}>
			<section className={styles.CustomFacilitySearchWindow_main}>
				{/* SEARCH BY: EMAIL, NAME, FACILITY */}
				<FormSection
					title="Search By:"
					icon="checkbox"
					hoverText="Search by a user-related value."
				>
					<CustomCheckbox
						isDisabled={
							values?.byCommunityName ||
							values?.byParentID ||
							values?.byFacility ||
							values?.byFacilityID
						}
						label="By Email"
						name="byEmail"
						id="byEmail"
						val={values?.byEmail}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byEmail ||
							values?.byParentID ||
							values?.bySuspendedStatus ||
							values?.byFacilityID
						}
						label="By Community Name"
						name="byCommunityName"
						id="byCommunityName"
						val={values?.byCommunityName}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byCommunityName ||
							values?.byEmail ||
							values?.bySuspendedStatus ||
							values?.byFacilityID
						}
						label="By Parent ID"
						name="byParentID"
						id="byParentID"
						val={values?.byParentID}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byCommunityName ||
							values?.byEmail ||
							values?.bySuspendedStatus
						}
						label="By Facility ID"
						name="byFacilityID"
						id="byFacilityID"
						val={values?.byFacilityID}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					<CustomCheckbox
						isDisabled={
							values?.byEmail ||
							values?.byParentID ||
							values?.byCommunityName ||
							values?.byFacilityID
						}
						label="By Suspended Status"
						name="bySuspendedStatus"
						id="bySuspendedStatus"
						val={values?.bySuspendedStatus}
						handleCheckbox={handleCheckbox}
						customStyles={customCSS.checkbox}
					/>
					{/* CUSTOM SEARCH INPUT RENDERER */}
					<CustomSearch
						vals={values}
						handleChange={handleChange}
						handleSettings={handleSettings}
						allFacilities={[
							...formatAndSortUserFacilities(currentUser?.facilities),
						]}
					/>
				</FormSection>

				{/* ACTIONS */}
				<div className={styles.CustomFacilitySearchWindow_main_actions}>
					<ButtonSM customStyles={customCSS.clearBtn} handleClick={clearSearch}>
						<span>Clear Search</span>
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.cancelBtn}
						handleClick={cancelSearch}
					>
						<span>Cancel Search</span>
					</ButtonSM>
					<ButtonSM
						isDisabled={noTypeSelected(values)}
						customStyles={customCSS.searchBtn}
						handleClick={runSearch}
					>
						<svg
							className={styles.CustomFacilitySearchWindow_main_actions_icon}
						>
							<use xlinkHref={`${sprite}#icon-search2`}></use>
						</svg>
						<span>Run Search</span>
					</ButtonSM>
				</div>
			</section>
			{/*  */}
		</div>
	);
};

export default CustomFacilitySearchWindow;

CustomFacilitySearchWindow.defaultProps = {};

CustomFacilitySearchWindow.propTypes = {};
