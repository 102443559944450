import React, { useState, useEffect } from "react";
import styles from "../../css/settings/ManageUserTypes.module.scss";
import { PropTypes } from "prop-types";
import {
	getAllUserTypes,
	processAndSortAllUserTypes,
	saveNewUserType,
	saveUserType,
	sortAllTypesByID,
	updateUserType,
	updateUserTypeModel,
	updateUserTypeRecord,
} from "../../helpers/utils_userTypes";
import { isEmptyArray } from "../../helpers/utils_types";
import UserTypesList from "../userTypes/UserTypesList";
import { useForm } from "../../utils/useForm";
import AddUserType from "../userTypes/AddUserType";
import Spinner from "../shared/Spinner";
// components

const ManageUserTypes = ({
	globalState,
	currentUser,
	currentFacility,
	dispatchAlert,
	dispatchToState,
}) => {
	const [userTypes, setUserTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { formState, setFormState, handleChange, handleReset } = useForm({
		// create new type
		newUserTypeName: "",
		newUserTypeDesc: "",
		// edit existing type
		editUserTypeName: "",
		editUserTypeDesc: "",
	});
	const { values } = formState;
	// for editing
	const [selectedUserType, setSelectedUserType] = useState({});

	const removeUserType = (userType) => {
		const { typeID } = userType;
		const newList = userTypes.filter((type) => type.typeID !== typeID);

		setUserTypes([...newList]);
	};

	// sets selected type to state (edit client handler)
	const editUserType = (userType) => {
		setSelectedUserType(userType);
		setFormState({
			...formState,
			values: {
				...values,
				editUserTypeName: userType?.name,
				editUserTypeDesc: userType?.desc,
			},
		});
	};

	// saves updates to database (edit request handler)
	const applyUpdatesToUserType = async (e) => {
		const { token } = currentUser;
		const { editUserTypeName, editUserTypeDesc } = values;
		// populate model
		const updatedModel = updateUserTypeModel({
			typeID: selectedUserType?.typeID,
			userTypeName: editUserTypeName,
			userTypeDesc: editUserTypeDesc,
			isActive: true,
		});
		const wasSaved = await saveUserType(token, updatedModel);

		if (wasSaved) {
			const updatedType = {
				...selectedUserType,
				name: editUserTypeName,
				desc: editUserTypeDesc,
			};
			const without = userTypes.filter(
				(x) => x.typeID !== selectedUserType.typeID
			);
			const newList = [...sortAllTypesByID(without, updatedType)];

			handleReset(e);
			setUserTypes([...newList]);
			return dispatchAlert("SUCCESS", {
				heading: `New User Type Added!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! There was an error.`,
				subheading: `Please try again!`,
			});
		}
	};

	// accepts updated model & fires off request
	const saveUpdates = async (updatedType) => {
		const { token } = currentUser;
		const wasSaved = await updateUserTypeRecord(token, updatedType);

		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `User type was updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! An error occurred`,
				subheading: `Please try again!`,
			});
		}
	};

	// creates & saves new user type record
	const saveNewType = async (e) => {
		const { token } = currentUser;
		const { newUserTypeName, newUserTypeDesc } = values;
		// populate model
		const typeModel = updateUserTypeModel({
			typeID: 0,
			userTypeName: newUserTypeName,
			userTypeDesc: newUserTypeDesc,
			isActive: true,
		});
		// should return new user type ID
		const wasSaved = await saveNewUserType(token, typeModel);

		if (wasSaved) {
			const newType = {
				typeID: userTypes?.length - 1 + 1,
				name: values.newUserTypeName,
				desc: values?.newUserTypeDesc,
				isActive: true,
			};
			const newList = [...userTypes, newType];

			handleReset(e);
			setUserTypes([...newList]);
			return dispatchAlert("SUCCESS", {
				heading: `New User Type Added!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops! There was an error.`,
				subheading: `Please try again!`,
			});
		}
	};
	const cancelNewType = (e) => {
		handleReset(e);
	};

	const enableUserType = async (userType) => {
		const updatedModel = updateUserTypeModel({
			typeID: userType.typeID,
			userTypeName: userType.name,
			userTypeDesc: userType.desc,
			isUserTypeActive: true,
		});

		console.log(`Updated:`, updatedModel);

		// return saveUpdates(updatedModel)
	};
	const disableUserType = async (userType) => {
		console.log(`UserType:`, userType);
		const updatedModel = updateUserTypeModel({
			typeID: userType.typeID,
			userTypeName: userType.name,
			userTypeDesc: userType.desc,
			isUserTypeActive: false,
		});

		console.log(`Updated:`, updatedModel);

		// return saveUpdates(updatedModel);
	};

	const fetchUserTypes = async () => {
		const { token } = currentUser;
		const rawTypes = await getAllUserTypes(token);
		const sorted = processAndSortAllUserTypes(rawTypes);

		if (!isEmptyArray(sorted)) {
			setIsLoading(false);
			return setUserTypes(sorted);
		} else {
			setIsLoading(false);
			return setUserTypes([]);
		}
	};

	// fetch user types 'onMount'
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchUserTypes();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ManageUserTypes}>
			<header className={styles.ManageUserTypes_header}>
				<h2 className={styles.ManageUserTypes_header_title}>
					Manage User Types
				</h2>
				<h6 className={styles.ManageUserTypes_header_desc}>
					Edit, update, add or remove existing ALA User Types such as: 'Super
					User', 'Facility Admin', 'Standard User' etc.
				</h6>
			</header>
			<div className={styles.ManageUserTypes_main}>
				<div className={styles.ManageUserTypes_main_row}>
					<AddUserType
						vals={values}
						handleChange={handleChange}
						handleReset={handleReset}
						saveNewType={saveNewType}
						cancelNewType={cancelNewType}
					/>
				</div>
				<div className={styles.ManageUserTypes_main_row}>
					{isLoading && <Spinner />}
					<UserTypesList
						editUserType={editUserType}
						removeUserType={removeUserType}
						userTypesList={userTypes}
						disableUserType={disableUserType}
						enableUserType={enableUserType}
					/>
				</div>
			</div>
		</div>
	);
};

export default ManageUserTypes;

ManageUserTypes.defaultProps = {};

ManageUserTypes.propTypes = {};
