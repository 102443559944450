import { extractRawParams } from "./utils_params";
import styles from "../css/pages/LoginPage.module.scss";

//////////////////////////////////////////////////
/////////// ERROR CODES & FAILURE MAPS ///////////
/////////////////////////////////////////////////

/**
 * Map of 'Login Failure Reasons' (ie. 'ERROR CODES')
 * @property {String} name - Human-readble name.
 * @property {String} desc - Description of the status/reason.
 * @property {String} systemDesc - Generic description provided by the ALAServices system.
 */
const LOGIN_FAILURES = {
	AccountException: {
		id: 0,
		errorCode: `E000`,
		name: "Account Exception",
		desc: "There was an exception with this account.",
		systemDesc: "",
	},
	AccountExpired: {
		id: 1,
		errorCode: `E100`,
		name: "Account Expired", // add verbiage for accounts w/ invalid OR duplciate usernames
		desc: "This account was only enabled for a specified amount of time. That time has expired.",
		systemDesc: "",
	},
	AccountLocked: {
		id: 2,
		errorCode: `E200`,
		name: "Account Locked",
		desc: "This account is locked. This is due to multiple failed logins. Please reset your password.",
		systemDesc:
			"Login account access expired for the login information provided.",
	},
	AccountSuspended: {
		id: 3,
		errorCode: `E300`,
		name: "Account Suspended",
		desc: "This account is temporarily suspended. Contact your admin to release the account.",
		systemDesc: "",
	},
	InvalidCredential: {
		id: 4,
		errorCode: `E400`,
		name: "Invalid Credentials",
		desc: "The login info entered is incorrect.",
		systemDesc: "Credential failure due to the login information provided.",
	},
	PermissionDenied: {
		id: 5,
		errorCode: `E500`,
		name: "Permission Denied",
		desc: "You are not authorized for this application. Contact your administrator",
		systemDesc:
			"No permission granted to appplication for login information provided.",
	},
	UnknownError: {
		id: 6,
		errorCode: `E600`,
		name: "Unknown Error",
		desc: "There was an unknown error. Please try again.",
		systemDesc: "",
	},
	UserDoesNotExists: {
		id: 7,
		errorCode: `E700`,
		name: "User Does Not Exist",
		desc: "This is not a valid user. Or this user does not exist.",
		systemDesc: "",
	},
};
/**
 * Map of 'Login Failure Reasons' (ie. 'ERROR CODES')
 * @property {String} name - Human-readble name.
 * @property {String} desc - Description of the status/reason.
 * @property {String} systemDesc - Generic description provided by the ALAServices system.
 */
const ERROR_CODES = {
	E000: {
		id: 0,
		errorCode: `E000`,
		name: "Account Exception",
		desc: "There was an exception with this account.",
		systemDesc: "",
	},
	E100: {
		id: 1,
		errorCode: `E100`,
		name: "Account Expired",
		desc: "This account was only enabled for a specified amount of time. That time has expired.",
		systemDesc: "",
	},
	E200: {
		id: 2,
		errorCode: `E200`,
		name: "Account Locked",
		desc: "This account is locked. This is due to multiple failed logins. Please reset your password.",
		systemDesc:
			"Login account access expired for the login information provided.",
	},
	E300: {
		id: 3,
		errorCode: `E300`,
		name: "Account Suspended",
		desc: "This account is temporarily suspended. Contact your admin to release the account.",
		systemDesc: "",
	},
	E400: {
		id: 4,
		errorCode: `E400`,
		name: "Invalid Credentials",
		desc: "The login info entered is incorrect.",
		systemDesc: "Credential failure due to the login information provided.",
	},
	E500: {
		id: 5,
		errorCode: `E500`,
		name: "Permission Denied",
		desc: "You are not authorized for this application. Contact your administrator",
		systemDesc:
			"No permission granted to appplication for login information provided.",
	},
	E600: {
		id: 6,
		errorCode: `E600`,
		name: "Unknown Error",
		desc: "There was an unknown error. Please try again.",
		systemDesc: "",
	},
	E700: {
		id: 7,
		errorCode: `E700`,
		name: "User Does Not Exist",
		desc: "This is not a valid user. Or this user does not exist.",
		systemDesc: "",
	},
};

// OUTAGE ERROR ON LOGIN PAGE
const ERROR_OUTAGE = {
	enableErrorMsg: false,
	msg: `ALAdvantage will be performing maintenance from 8pm - 12am PT on the following days:\n\nFriday, January 20th\n\nFriday, January 27th\n\nFriday, February 4th\n\nServices may be unavailable during these time frames. Thank you.`,
};

const OutageUI = () => {
	return (
		<div className={styles.OutageUI}>
			<div className={styles.OutageUI_main}>
				AL Advantage will be performing maintenance from 8pm - 4am PT on the
				following days:
			</div>
			{/* <div className={styles.OutageUI_item}>Friday, January 20th</div>
			<div className={styles.OutageUI_item}>Friday, January 27th</div> */}
			<div className={styles.OutageUI_item}>Friday, February 3rd</div>
			<div className={styles.OutageUI_main}>
				Services may be unavailable during these time frames. Thank you.
			</div>
		</div>
	);
};

//////////////////////////////////////////////////
////////// ERROR CODES & FAILURE UTILS //////////
/////////////////////////////////////////////////

/**
 * Returns error code info based off query params' error code (ie. 'E100')
 * @param {URL} url - Url string from 'window.location' typically
 * @typedef {Object} - Returns entry from 'ERROR_CODES' map
 * @returns {Object} - Returns entry from 'ERROR_CODES' map
 * @property {Number} id - 'ID' from 'ERROR_CODES' map
 * @property {String} desc - 'Description' from 'ERROR_CODES' map
 * @property {String} name - 'Name' from 'ERROR_CODES' map
 * @property {String} systemDesc - 'System Description' from 'ERROR_CODES' map
 * @property {String} errorCode - 'Error Code' from 'ERROR_CODES' map
 */
const getErrorFromUrl = (url = window.location) => {
	const params = extractRawParams(url, [`errorCode`]);
	const code = params?.errorCode;
	const errorInfo = getErrorFromCode(code);
	return errorInfo;
};

/**
 * Extracts error code info from 'ERROR_CODES' map based off code string. (util)
 * @param {String} code - Error code from query param (ie. 'E500', 'E100' etc)
 * @returns {Object} - Returns object of error codes' info.
 */
const getErrorFromCode = (code) => {
	const record = ERROR_CODES?.[code];
	const { id, name, desc, errorCode } = record;

	return {
		id: id,
		name: name,
		desc: desc,
		code: errorCode,
	};
};

/**
 * Determines error code type.
 * @param {String} code - Error code from query param (ie. 'E400' etc)
 * @returns {String} - Returns error type based off code.
 */
const getErrorType = (code) => {
	console.log("code", code);
	const hasErrorPrefix = code.startsWith("E");
	if (hasErrorPrefix) return `LOGIN_ERROR`;
	if (!hasErrorPrefix) return `SERVER_ERROR`;
	return `UNKNOWN_ERROR`;
};

export { LOGIN_FAILURES, ERROR_CODES, ERROR_OUTAGE, OutageUI };
export {
	getErrorFromCode,
	getErrorType,
	// primary 'public' util (main usage)
	getErrorFromUrl,
};
