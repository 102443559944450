import React, { useRef, useState, useEffect } from "react";
import styles from "../../css/user/JobTitleHandler.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { formatAndSortUserTitles } from "../../helpers/utils_user";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
// components
import TextInput from "../shared/TextInput";
import JobTitleDropdown from "./JobTitleDropdown";
import { createNewJobTitle } from "../../helpers/utils_titles";

// REQUIREMENTS:
// - Supports:
//    - Searching for job title
//    - Creating a new job title, if no matches are found

const customCSS = {
	jobTitle: {
		width: "100%",
	},
};

const NoMatches = ({ showCustomInput }) => {
	const customDisabled = `Don't see your job title?`;
	const customEnabled = `Want to find an existing match?`;

	return (
		<div className={styles.NoMatches}>
			<button
				type="button"
				onClick={showCustomInput}
				className={styles.NoMatches_btn}
			>
				{customDisabled}
			</button>
		</div>
	);
};

const searchTitlesForMatch = (userEntry, userTitles = []) => {
	// search all 'userTitles' for a match based off the user's input
	// - if there's no match; create a new entry
	// - if there's a match; do nothing
	let normalEntry = userEntry?.toLowerCase();
	const matches = userTitles.filter((title) => {
		if (
			normalEntry === title?.desc ||
			normalEntry === title?.desc?.toLowerCase() ||
			normalEntry === title?.name ||
			normalEntry === title?.name?.toLowerCase() ||
			title?.desc.includes(normalEntry) ||
			title?.desc?.toLowerCase()?.includes(normalEntry) ||
			title?.name.includes(normalEntry) ||
			title?.name?.toLowerCase()?.includes(normalEntry)
		) {
			// match found
			return title;
		} else {
			// no match
			return null;
		}
	});

	return matches;
};

const getMatchResults = (userEntry, userTitles = []) => {
	const matches = searchTitlesForMatch(userEntry, userTitles);

	const hasExactMatch = matches?.length === 1 && matches?.[0] === userEntry;
	const hasRelativeMatches = matches?.length > 1 && matches?.[0] !== userEntry;

	return {
		hasExactMatch: hasExactMatch,
		hasRelativeMatches: hasRelativeMatches,
		relativeMatches: matches,
	};
};

const showOptions = (val, results) => {
	const { hasExactMatch, hasRelativeMatches, relativeMatches } = results;
	if (hasExactMatch) return false;
	if (!isEmptyArray(relativeMatches) && !isEmptyVal(val)) return true;
	if (hasRelativeMatches) return true;
};

const JobTitleHandler = ({
	label = "Enter Job Title (select from matches or enter custom)",
	currentUser,
	vals = {},
	setFormState,
	userTitles = [],
	handleJobTitle,
	handleChange,
	handleCustomJobTitle,
	readOnly = false,
	customCSS = {},
}) => {
	const [results, setResults] = useState({
		hasExactMatch: false,
		hasRelativeMatches: false,
		relativeMatches: [],
	});
	const [showResults, setShowResults] = useState(false);

	const handleSearch = (e) => {
		handleChange(e);
		const { value } = e.target;
		const newResults = getMatchResults(value, userTitles);
		setResults(newResults);
		setShowResults(showOptions(vals.jobTitle, newResults));
	};

	const selectTitleMatch = (match) => {
		console.log("match", match);
		handleJobTitle("jobTitle", match?.desc);
		setShowResults(false);
	};

	const saveNewTitle = async (e) => {
		const { token } = currentUser;
		const { jobTitle } = vals;
		const matches = searchTitlesForMatch(jobTitle, userTitles);

		if (isEmptyArray(matches) && !isEmptyVal(jobTitle)) {
			// fire off request
			const jobTitleID = await createNewJobTitle(token, jobTitle);
			return handleCustomJobTitle(jobTitleID);
		} else {
			return;
			// has match, then do nothing
			// or make sure jobTitle is populated w/ correct value
		}
	};

	return (
		<div className={styles.JobTitleHandler}>
			<div className={styles.JobTitleHandler_search}>
				<TextInput
					name="jobTitle"
					id="jobTitle"
					label={label}
					val={vals?.jobTitle}
					handleChange={handleSearch}
					handleBlur={saveNewTitle}
					customStyles={customCSS}
					readOnly={readOnly}
				/>
			</div>
			<ul className={styles.JobTitleHandler_results}>
				{showResults &&
					results.relativeMatches.map((match, idx) => (
						<li
							onClick={() => selectTitleMatch(match)}
							key={`Title:${match}-${idx}`}
							className={styles.JobTitleHandler_results_match}
						>
							{match?.desc}
						</li>
					))}
			</ul>
		</div>
	);
};

export default JobTitleHandler;

JobTitleHandler.defaultProps = {};

JobTitleHandler.propTypes = {};
