import React, { useCallback, useEffect, useState } from "react";
import styles from "../../css/user/ChangePassword.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { COLORS_MAP } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";
import { changeUserPassword } from "../../helpers/utils_user";
// components
import PasswordInput from "../shared/PasswordInput";
import ButtonSM from "../shared/ButtonSM";
import PasswordValidator from "../forms/PasswordValidator";
import DoNotMatchIndicator from "../forms/DoNotMatchIndicator";
import ConfirmedIndicator from "../forms/ConfirmedIndicator";

const customCSS = {
	current: {
		backgroundColor: "#ffffff",
		opacity: "1",
	},
	new: {
		backgroundColor: "#ffffff",
		borderRadius: ".5rem",
		opacity: "1",
	},
	confirm: {
		backgroundColor: "#ffffff",
		opacity: "1",
	},
	hr: {
		width: "100%",
		margin: "5rem 0",
		opacity: ".5",
	},
	change: {
		backgroundColor: COLORS_MAP.purple[600],
		color: "#ffffff",
		padding: ".7rem 1.5rem",
		fontSize: "1.5rem",
		fontWeight: "600",
	},
};

const hasPasswordVals = (vals = {}) => {
	const { newPassword, newPasswordConfirm } = vals;
	return !isEmptyVal(newPassword) && !isEmptyVal(newPasswordConfirm);
};

// REQUIREMENTS:
// - MUST enter & confirm current password
// - MUST enter password that meets strength requirements
// - MUST re-enter new password and MUST match

const ChangePassword = ({ currentUser = {}, dispatchAlert }) => {
	const { formState, handleChange } = useForm({
		currentPasswordConfirm: "",
		newPassword: "",
		newPasswordConfirm: "",
	});
	const { values } = formState;
	const [newPasswordsMatch, setNewPasswordsMatch] = useState(false);
	const [confirmedCurrentPassword, setConfirmedCurrentPassword] =
		useState(false);

	const saveNewPassword = async (e) => {
		const { token, userID } = currentUser;
		const { currentPasswordConfirm: oldPassword, newPassword } = values;

		const success = await changeUserPassword(token, {
			userID: userID,
			oldPassword: oldPassword,
			newPassword: newPassword,
		});

		if (!isEmptyVal(success)) {
			console.log(`✅ Success: `, success);

			return dispatchAlert("SUCCESS", {
				heading: "Password Changed!",
				subheading: "Your password changes have been saved.",
			});
		} else {
			console.log(`❌ Ooops! Password changes failed:`, success);

			return dispatchAlert("ERROR", {
				heading: "Ooops!",
				subheading: "Your changes were not saved!",
			});
		}
	};

	const confirmCurrentPassword = (e) => {
		const { value } = e.target;
		handleChange(e);
		if (value === currentUser?.password) {
			return setConfirmedCurrentPassword(true);
		} else {
			return setConfirmedCurrentPassword(false);
		}
	};

	// watches for password changes to confirm the new passwords match
	const confirmNewPassword = useCallback(() => {
		const { newPassword, newPasswordConfirm } = values;
		if (isEmptyVal(newPassword) || isEmptyVal(newPasswordConfirm)) return;
		if (newPassword === newPasswordConfirm) {
			return setNewPasswordsMatch(true);
		} else {
			return setNewPasswordsMatch(false);
		}
	}, [values]);

	const handleNewPassword = (e) => {
		handleChange(e);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		confirmNewPassword();

		return () => {
			isMounted = false;
		};
	}, [confirmNewPassword]);

	return (
		<div className={styles.ChangePassword}>
			<section className={styles.ChangePassword_header}>
				<h2 className={styles.ChangePassword_header_title}>
					Current Password:
				</h2>
			</section>
			<section className={styles.ChangePassword_current}>
				<PasswordInput
					label="Enter Current Password"
					name="currentPasswordConfirm"
					id="currentPasswordConfirm"
					val={values.currentPasswordConfirm}
					handleChange={confirmCurrentPassword}
					customStyles={customCSS.current}
				/>
			</section>
			<section className={styles.ChangePassword_confirmed}>
				{confirmedCurrentPassword && (
					<ConfirmedIndicator confirmedMsg="Password is Confirmed!" />
				)}
				{!confirmedCurrentPassword &&
					!isEmptyVal(values?.currentPasswordConfirm) && (
						<DoNotMatchIndicator failedMsg="Incorrect Password!" />
					)}
			</section>
			<hr style={customCSS.hr} />
			<section className={styles.ChangePassword_header}>
				<h2 className={styles.ChangePassword_header_title}>New Password:</h2>
			</section>
			<fieldset
				disabled={!confirmedCurrentPassword}
				className={styles.ChangePassword_new}
			>
				<PasswordValidator
					label="Enter New Password"
					name="newPassword"
					id="newPassword"
					handleChange={handleChange}
					minLength={6}
					customStyles={customCSS.new}
					isDisabled={!confirmedCurrentPassword}
				/>
				<br style={{ margin: "4rem 0" }} />
				<PasswordInput
					label="Re-enter New Password"
					name="newPasswordConfirm"
					id="newPasswordConfirm"
					val={values.newPasswordConfirm}
					handleChange={handleNewPassword}
					customStyles={customCSS.current}
					isDisabled={!confirmedCurrentPassword}
				/>
			</fieldset>
			<section className={styles.ChangePassword_matchingStatus}>
				{!newPasswordsMatch && hasPasswordVals(values) && (
					<div className={styles.ChangePassword_matchingStatus_confirmation}>
						<DoNotMatchIndicator />
					</div>
				)}
				{newPasswordsMatch && (
					<div className={styles.ChangePassword_matchingStatus_confirmation}>
						<ConfirmedIndicator
							newPassword={values?.newPassword}
							newPasswordConfirm={values?.newPasswordConfirm}
						/>
					</div>
				)}
			</section>
			<section className={styles.ChangePassword_actions}>
				<ButtonSM
					isDisabled={!newPasswordsMatch}
					handleClick={saveNewPassword}
					customStyles={customCSS.change}
				>
					Change Password
				</ButtonSM>
			</section>
		</div>
	);
};

export default ChangePassword;

ChangePassword.defaultProps = {
	currentUser: {},
};

ChangePassword.propTypes = {
	currentUser: PropTypes.object,
	dispatchAlert: PropTypes.func,
};
