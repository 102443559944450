import React, { useState } from "react";
import styles from "../../css/residents/ResidentSearchResults.module.scss";
import { PropTypes } from "prop-types";
import {
	getResidentName,
	getResidentAge,
	getResidentID,
} from "../../helpers/utils_residents";
import { isEmptyArray } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
// components
import Spinner from "../shared/Spinner";
import Empty from "../shared/Empty";

const customCSS = {
	empty: {
		icon: {
			fill: red[600],
		},
		heading: {
			// color: red[600],
		},
		subheading: {
			color: red[600],
		},
	},
};

const ResidentEntry = ({ resident = {}, viewResident }) => {
	const [showMore, setShowMore] = useState(false);

	return (
		<li className={styles.ResidentEntry}>
			<div className={styles.ResidentEntry_inner}>
				<div className={styles.ResidentEntry_inner_name}>
					<span className={styles.ResidentEntry_inner_name_msg}>
						{getResidentName(resident)}
					</span>

					<button
						onClick={viewResident}
						className={styles.ResidentEntry_inner_name_editBtn}
					>
						View resident
					</button>
				</div>
				<button
					type="button"
					onClick={() => setShowMore(!showMore)}
					className={styles.ResidentEntry_inner_showMoreBtn}
				>
					{showMore ? "Hide" : "Show"}
				</button>
			</div>
			{showMore && (
				<div className={styles.ResidentEntry_showMore}>
					<div className={styles.ResidentEntry_showMore_section}>
						<div className={styles.ResidentEntry_showMore_section_desc}>
							Resident Details:
						</div>
						<div className={styles.ResidentEntry_showMore_section_details}>
							Age: <b>{getResidentAge(resident)}</b>
						</div>
						<div className={styles.ResidentEntry_showMore_section_details}>
							Unit Type: <b>{resident?.FloorUnit}</b>
						</div>
						<div className={styles.ResidentEntry_showMore_section_details}>
							Room Number: <b>{resident?.RoomNum}</b>
						</div>
						<div className={styles.ResidentEntry_showMore_section_details}>
							ResidentID: <b>{getResidentID(resident)}</b>
						</div>
					</div>
				</div>
			)}
		</li>
	);
};

const ResidentSearchResults = ({
	isLoading = false,
	searchResults = [],
	setSelectedResident,
}) => {
	console.log("searchResults", searchResults);
	if (isLoading) {
		return (
			<div className={styles.LoadingSearchResults}>
				<Spinner />
				<div className={styles.LoadingSearchResults_msg}>
					Loading search results...
				</div>
			</div>
		);
	}
	return (
		<div className={styles.ResidentSearchResults}>
			<div className={styles.ResidentSearchResults_header}>
				<div className={styles.ResidentSearchResults_header_title}>
					Showing <b>{searchResults?.length ?? ""}</b> residents
				</div>
			</div>
			<ul className={styles.ResidentSearchResults_list}>
				{(!searchResults || isEmptyArray(searchResults)) && (
					<div className={styles.ResidentSearchResults_noResults}>
						<Empty
							customStyles={customCSS.empty}
							size="MD"
							heading="No matching results."
							icon="archive"
						/>
					</div>
				)}
				{!isEmptyArray(searchResults) &&
					searchResults.map((resident, idx) => (
						<ResidentEntry
							key={`${resident?.residentID}_${idx}`}
							resident={resident}
							viewResident={() => setSelectedResident(resident)}
						/>
					))}
			</ul>
		</div>
	);
};

export default ResidentSearchResults;

ResidentSearchResults.defaultProps = {};

ResidentSearchResults.propTypes = {
	searchResults: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.instanceOf(null),
	]),
};
