import React from "react";
import styles from "../css/pages/ContactUsPage.module.scss";
import { PropTypes } from "prop-types";
import ContactUs from "../components/app/ContactUs";

const ContactUsPage = ({ history }) => {
	console.log("history", history);
	return (
		<div className={styles.ContactUsPage}>
			<header className={styles.ContactUsPage_header}>
				<h2 className={styles.ContactUsPage_header_title}>Have Questions?</h2>
				<h2 className={styles.ContactUsPage_header_altTitle}>Contact Us</h2>
				<a
					href="https://www.aladvantage.com"
					target="_blank"
					rel="noreferrer noopener"
					className={styles.ContactUsPage_header_subtitle}
				>
					AL Advantage LLC.
				</a>
			</header>
			<div className={styles.ContactUsPage_main}>
				<ContactUs />
			</div>
		</div>
	);
};

export default ContactUsPage;

ContactUsPage.defaultProps = {};

ContactUsPage.propTypes = {
	history: PropTypes.object,
};
