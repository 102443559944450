import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/loc/LOCController.module.scss";
import sprite from "../../assets/icons/alerts.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import {
	defaultSchema,
	saveFacilityLevelsOfCare,
	resetFacilityLevelsOfCare,
	prepareLOCSettings,
	saveFacilityLOCFlagSettings,
	mergeLOCIntoArray,
	fetchAndProcessLOCApiValidation,
	prepareAllCareLevels,
	lockAllChildLOCByParent,
	unlockAllChildLOCByParent,
} from "../../helpers/utils_loc";
import {
	getLevelsAndTemplates,
	matchLOCTemplateByName,
} from "../../helpers/utils_loctemplate";
import { blue, green, orange, red } from "../../helpers/utils_styles";
import {
	getFacilityType,
	isParentFacility,
} from "../../helpers/utils_facility";
import { sortByAlphaAsc } from "../../helpers/utils_processing";
// components
import ButtonSM from "../shared/ButtonSM";
import LOCTable from "./LOCTable";
import LOCModal from "./LOCModal";
import LOCReset from "./LOCReset";
import LOCOptions from "./LOCOptions";
import LOCTemplateSelector from "../loctemplate/LOCTemplateSelector";
import LOCValidation from "./LOCValidation";

// REQUIREMENTS:
// - 'Level Name' field CANNOT BE BLANK!
// - 'Level' field should be locked?? or at least only accept numbers
// - Starting level's 'min' MUST ALWAYS BE 0
// - Ending level's 'max' MUST ALWAYS BE 99999
// - ALL levels MUST COVER THE ENTIRE 0-99999 RANGE, WITH NO GAPS
// 		- 0-100, 101-200 etc etc 800-99999
// ADDITIONAL REQUIREMENTS:
// - ONLY show 'Personal Care' table for communities in AZ
// - Implement READ-ONLY mode for facility admins, and users NOT registered as 'ADMINS' or 'SUPER-USERS'

// RULES FOR 'LOC FLAGS' & 'LOCK/UNLOCK ALL CHILD' FEATURES:
// - "LOCAreAdminLocked":
// 		- Controlled per facility (each facility controls their own, unless locked by the parent)
// 		- Parents can choose to 'Lock/Unlock All' which then locks all children and ONLY EAs can unlock
// 		- This flag can ONLY be controlled by ExecutiveAdmins, even for children
// - "Lock/Unlock All Childs":
// 		- ONLY available to parent communities and user MUST be an ExecutiveAdmin or SuperUser to access it
// 		- Locking ALL children will mean facility admins can no longer change the lock status of any facility. EAs must do it.

const customCSS = {
	reset: {
		padding: "1.2rem 2rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	options: {
		padding: "1.2rem 2rem",
		backgroundColor: blue[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "2rem",
	},
	loadBtn: {
		padding: ".9rem 1.7rem",
		fontSize: "1.6rem",
		fontWeight: "600",
		color: "#ffffff",
		backgroundColor: blue[600],
		marginLeft: "1rem",
	},
	selector: {
		width: "35rem",
		display: "flex",
		justifyContent: "center",

		// height: "4.5rem",
		// marginLeft: "1rem",
	},
	saveBtn: {
		padding: "1.4rem 2rem",
		fontSize: "1.7rem",
		fontWeight: "600",
		color: "#ffffff",
		backgroundColor: green[500],
	},
	lock: {
		padding: "1.2rem 2rem",
		backgroundColor: orange[500],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	unlock: {
		padding: "1.2rem 2rem",
		backgroundColor: green[600],
		color: "#ffffff",
		fontSize: "1.6rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
};

const FacilityIndicator = ({ currentFacility }) => {
	if (isEmptyVal(currentFacility.facilityID)) {
		return null;
	}

	return (
		<div className={styles.FacilityIndicator}>
			({currentFacility?.communityName})
		</div>
	);
};

const isReadOnlyLOC = (currentUser, currentFacility, settings = {}) => {
	const { isAdmin, isSuperUser, isFacilityAdmin } = currentUser;
	const { facilityID } = currentFacility;
	const { Editable, LOCAreAdminLocked } = settings;

	const hasSuper = isAdmin || isSuperUser;
	const hasAdmin = isAdmin || isSuperUser || isFacilityAdmin;

	// no facility loaded yet
	if (isEmptyVal(facilityID)) return false;
	// non-admins
	if (!hasAdmin) return true;
	// not editable via db setting
	if (!Editable) return true;
	// user is NOT an admin & LOC are admin locked
	if (!hasSuper && LOCAreAdminLocked) return true;

	return false;
};

const getLockedMsg = (currentUser, currentFacility, settings = {}) => {
	const { isAdmin, isSuperUser, isFacilityAdmin } = currentUser;
	// flag settings
	const { Editable, LOCAreAdminLocked } = settings;
	const isNonAdmin = !isAdmin && !isSuperUser && !isFacilityAdmin;
	const isReadOnly = isReadOnlyLOC(currentUser, currentFacility, settings);
	// return early, if not applicable
	if (!isReadOnly || isEmptyVal(currentFacility.facilityID)) return "";

	switch (true) {
		case !Editable: {
			return `Levels are NOT editable!`;
		}
		case LOCAreAdminLocked || isNonAdmin: {
			return !isAdmin
				? `Levels are ONLY editable for Admins. Contact your administrator.`
				: ``;
		}
		default:
			return ``;
	}
};

const disableSaveBtn = (currentUser, locFlags = {}) => {
	const { isAdmin, isSuperUser, isRegionalAdmin, isFacilityAdmin } =
		currentUser;
	const { Editable, LOCAreAdminLocked } = locFlags;

	// 'SuperUsers' & 'ExecutiveAdmins'
	const hasSuper = isAdmin || isSuperUser;
	// 'FacilityAdmins'
	const hasFacOnly = !hasSuper && isFacilityAdmin;
	// 'Non-Admins'
	const isNonAdmin = !hasSuper && !isFacilityAdmin && !isRegionalAdmin;

	if (!Editable) return true;
	if (isNonAdmin) return true;
	if (hasFacOnly && LOCAreAdminLocked) return true;

	return false;
};

const noLevelsLoaded = (levels = {}, currentFacility = {}) => {
	const { facilityID } = currentFacility;
	const types = Object.keys(levels);
	const isAllEmpty = types.every((type) => {
		const level = levels[type];
		return isEmptyArray(level);
	});

	if (isEmptyVal(facilityID)) return true;
	return isAllEmpty;
};

const showChildLockControls = (currentUser, currentFacility, parentsMap) => {
	const { facilityID } = currentFacility;
	const { isAdmin, isSuperUser, isRegionalAdmin, isFacilityAdmin } =
		currentUser;
	const isParent = isParentFacility(facilityID, parentsMap);

	// super/executive-admin types
	const isEA = isAdmin || isSuperUser;

	// Rules:
	// - ONLY show for parent communities
	// - ONLY show for SuperUsers and ExecutiveAdmins
	if (!isParent) return false;
	if (!isEA) return false;

	return true;
};

const showTables = (selectedTemplate, currentTemplate) => {
	return (
		!isEmptyVal(selectedTemplate) && !isEmptyVal(currentTemplate?.templateID)
	);
};

const showLevels = (loc = {}) => {
	const al = loc?.["Assisted Living"];
	if (!isEmptyArray(al)) return true;
	return false;
};

const showLOC = (currentUser, currentFacility, loc = {}) => {
	// implement logic for user types: 'Admin/SuperUser' vs. 'Facility Admin'
	const al = loc?.["Assisted Living"];
	const hasLevels = !isEmptyArray(al);
	const hasFac = !isEmptyVal(currentFacility.facilityID);

	return hasFac && hasLevels;
};

const showLOCSelector = (currentFacility) => {
	if (isEmptyVal(currentFacility?.facilityID)) return false;
	return true;
};

const showPersonalCare = (currentFacility) => {
	const { state } = currentFacility?.address;
	const upperState = state.toUpperCase();
	const lowerState = state.toLowerCase();
	const statesList = ["AZ"];

	return statesList.includes(upperState) || statesList?.includes(lowerState);
};

// Rules:
// - ONLY show flag settings for super users/admins, NOT facility admins
// - ONLY show flag settings for child & independent
const showFlagSettings = (currentUser, currentFacility, parentsMap) => {
	const { isAdmin, isFacilityAdmin, isSuperUser } = currentUser;
	if (isFacilityAdmin && !isAdmin && !isSuperUser) return false;

	const type = getFacilityType(currentFacility, parentsMap);

	const showForFacility = type === "CHILD" || type === "INDEPENDENT";
	const showForUser = isAdmin || isSuperUser;
	const shouldShow = showForFacility && showForUser;

	return shouldShow;
};

// initial 'locValidation' state
const baseValidation = {
	Independent: {},
	"Assisted Living": {},
	"Memory Care": {},
	"Personal Care": {},
};
// initial 'careLevels' state
const baseLevels = {
	Independent: [],
	"Assisted Living": [],
	"Memory Care": [],
	"Personal Care": [],
};

const ReadOnlyBadge = ({ msg = `Levels Of Care Are Locked!` }) => {
	return (
		<div className={styles.ReadOnlyBadge}>
			<svg className={styles.ReadOnlyBadge_icon}>
				<use xlinkHref={`${sprite}#icon-warning`}></use>
			</svg>
			<span className={styles.ReadOnlyBadge_text}>{msg}</span>
		</div>
	);
};

const LOCNotice = ({ template, isShowingTemplate = false }) => {
	return (
		<div className={styles.LOCNotice}>
			{isShowingTemplate && <TemplateMsg templateName={template} />}
			{!isShowingTemplate && <NewMsg />}
		</div>
	);
};
const TemplateMsg = ({ templateName }) => {
	return (
		<div className={styles.TemplateMsg}>
			Editing "{templateName}" care levels...
		</div>
	);
};
const NewMsg = () => {
	return <div className={styles.NewMsg}>Editing current care levels...</div>;
};

const LOCController = ({
	globalState,
	currentUser,
	currentFacility,
	dispatchToState,
	dispatchAlert,
	handlePendingChanges,
}) => {
	const { parentsMap } = globalState;
	// care levels state that the LOC tables read from directly
	const [careLevels, setCareLevels] = useState({ ...baseLevels });
	// stores a facility's current care levels (eg. what's currently in the database)
	// this should NEVER be mutated, it'll read from a fresh fetch from the api
	const [currentCareLevels, setCurrentCareLevels] = useState({ ...baseLevels });
	const [isLoading, setIsLoading] = useState(() => {
		if (!isEmptyVal(currentFacility.facilityID)) {
			const notLoaded = noLevelsLoaded(careLevels, currentFacility);
			return notLoaded;
		} else {
			return false;
		}
	});
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showResetModal, setShowResetModal] = useState(false);
	const [showLOCSettings, setShowLOCSettings] = useState(false);
	// currently updated LOC table
	const [showingTemplate, setShowingTemplate] = useState(false);

	// global LOC settings (flags, etc.)
	const { formState, setFormState, handleCheckbox } = useForm({
		ApplyLOCToChildren: false, // disregard/ignore
		DoNotAllowChildLOCOverride: false, // disregard/ignore
		Editable: false,
		LOCAreAdminLocked: false,
	});
	const { values } = formState;
	// template state
	const [facilityTemplates, setFacilityTemplates] = useState({
		names: [],
		templates: [],
	});
	const [selectedTemplate, setSelectedTemplate] = useState("");
	const [currentTemplate, setCurrentTemplate] = useState({});
	// validation
	const [locValidation, setLOCValidation] = useState({
		...baseValidation,
	});

	const handleOptions = (e) => {
		const { name, checked } = e.target;
		if (name === "ApplyLOCToChildren") {
			return setFormState({
				...formState,
				values: {
					...values,
					ApplyLOCToChildren: checked,
					DoNotAllowChildLOCOverride: checked,
				},
			});
		} else {
			return handleCheckbox(e);
		}
	};

	const selectTemplate = (name, templateName) => {
		if (isEmptyVal(templateName)) {
			setSelectedTemplate("");
			setShowingTemplate(false);
			setCareLevels({});
			return setCurrentTemplate({});
		}
		setShowingTemplate(false);
		return setSelectedTemplate(templateName);
	};

	const loadTemplate = () => {
		const matchingTemplate = matchLOCTemplateByName(
			selectedTemplate,
			facilityTemplates.templates
		);

		setCurrentTemplate(matchingTemplate);
		setCareLevels({ ...matchingTemplate?.template });
		setShowingTemplate(true);
		dispatchAlert("SUCCESS", {
			heading: `"${selectedTemplate}" template loaded!`,
		});
	};

	// re-loads currently set care levels
	const loadCurrentLevels = () => {
		setSelectedTemplate("");
		setCurrentTemplate({});
		setShowingTemplate(false);
		setCareLevels({
			...currentCareLevels,
		});
	};

	// gets table data & table name upon changes
	// this is redundant syncing, not necessary
	// ...might remove this handler!!!
	const syncTableValues = (tableName, tableData = {}) => {
		setCareLevels({
			...careLevels,
			[tableName]: tableData.data,
		});
	};

	// inits 'SAVE' LOC action, runs validation & opens 'CONFIRM' modal
	const saveLOCChanges = async () => {
		const { token } = currentUser;
		setShowConfirmModal(true);

		// original client validation code //
		// const validation = validateAllLOC(careLevels);
		// setLOCValidation({ ...validation });

		// new api validation code
		const merged = mergeLOCIntoArray(careLevels);
		console.log("merged", merged);
		const validation = await fetchAndProcessLOCApiValidation(token, merged);

		if (!isEmptyObj(validation)) {
			console.log("validation", validation);
			return setLOCValidation({ ...validation });
		} else {
			return alert("OOOPS!");
		}
	};

	// confirm care levels
	const confirmSave = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;

		// fixed to index each unit type's levels separately for correct 'LevelNumber' value
		const preparedLevels = prepareAllCareLevels(facilityID, careLevels);
		const newLOC = {
			...values,
			CareLevels: preparedLevels,
		};

		console.log("preparedLevels", preparedLevels);

		// const wasSaved = false;
		const wasSaved = await saveFacilityLevelsOfCare(token, facilityID, newLOC);

		if (wasSaved) {
			setShowConfirmModal(false);
			setCurrentCareLevels({ ...careLevels });
			return dispatchAlert("SUCCESS", {
				heading: `Changes Were Saved!!`,
			});
		} else {
			// setShowConfirmModal(false);
			return dispatchAlert("ERROR", {
				heading: `Error Occurred:`,
				subheading: `Changes were NOT saved!!`,
			});
		}
	};
	const cancelSave = () => {
		setShowConfirmModal(false);
	};

	// WARNING: THIS RESETS ALL TABLES LOC
	const resetLevelsToDefault = async (tableName) => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const wasReset = await resetFacilityLevelsOfCare(token, facilityID);

		console.log("wasReset", wasReset);

		if (wasReset) {
			return dispatchAlert("SUCCESS", {
				heading: `Care Levels Were Reset!!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Reset Failed!`,
				subheading: `Could NOT reset care levels!!`,
			});
		}
	};

	const confirmReset = () => {
		resetLevelsToDefault();
		setShowResetModal(false);
	};
	const cancelReset = () => {
		setShowResetModal(false);
	};

	// handles LOC flags (parent facilities ONLY)
	const saveLOCSettings = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const newRecord = prepareLOCSettings(facilityID, values);
		const wasUpdated = await saveFacilityLOCFlagSettings(token, newRecord);

		if (wasUpdated) {
			setShowLOCSettings(false);
			return dispatchAlert("SUCCESS", {
				heading: `LOC Settings Were Updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Update Failed!!`,
				subheading: `Please try again!`,
			});
		}
	};
	// cancels LOC flags
	const cancelLOCSettings = async () => {
		setShowLOCSettings(false);
	};

	// opens LOC settings modal
	const initLOCSettings = () => {
		setShowLOCSettings(true);
	};

	// lock all children LOC
	const lockAllChildLOC = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const wasLocked = await lockAllChildLOCByParent(token, facilityID);

		console.log(`All LOC Children have been "Locked":`, wasLocked);

		if (wasLocked) {
			return dispatchAlert("SUCCESS", {
				heading: `Locked!`,
				subheading: `All Child LOC Have Been Locked!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops!`,
				subheading: `Your changes were not saved.`,
			});
		}
	};
	// unlock all children LOC
	const unlockAllChildLOC = async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;
		const wasUnlocked = await unlockAllChildLOCByParent(token, facilityID);

		console.log(`All LOC Children have been "Un-Locked":`, wasUnlocked);

		if (wasUnlocked) {
			return dispatchAlert("SUCCESS", {
				heading: `Un-Locked!`,
				subheading: `All Child LOC Have Been Un-Locked!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Ooops!`,
				subheading: `Your changes were not saved.`,
			});
		}
	};

	const handleTableReset = (tableName) => {
		setCareLevels({
			...careLevels,
			[tableName]: [...currentCareLevels?.[tableName]],
		});
	};

	// fetch ALL care levels onMount and/or facility load
	const fetchLOCLevelsAndTemplates = useCallback(async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const { facilityLevels, facilityTemplates } = await getLevelsAndTemplates(
			token,
			facilityID
		);
		const sortedTemplates = {
			templates: facilityTemplates?.templates,
			names: sortByAlphaAsc(facilityTemplates?.names),
		};

		// response handling
		if (!isEmptyObj(facilityLevels)) {
			setCareLevels({ ...facilityLevels.levels });
			setCurrentCareLevels({ ...facilityLevels.levels });
			// setFacilityTemplates({ ...facilityTemplates });
			setFacilityTemplates({ ...sortedTemplates });
			setIsLoading(false);
			return setFormState({
				...formState,
				values: {
					...values,
					ApplyLOCToChildren: false,
					DoNotAllowChildLOCOverride: false,
					Editable: facilityLevels?.Editable ?? true,
					// Editable: false, // READ-ONLY TEST VALUE
					LOCAreAdminLocked: facilityLevels?.LOCAreAdminLocked ?? false,
					HasStates: facilityLevels?.HasStates,
				},
			});
		} else {
			setFormState({
				...formState,
				values: {
					...values,
					ApplyLOCToChildren: false,
					DoNotAllowChildLOCOverride: false,
					Editable: false,
					LOCAreAdminLocked: false,
				},
			});
			setIsLoading(false);
			setCurrentCareLevels({});
			setFacilityTemplates({ names: [], templates: [] });
			return setCareLevels({});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch care levels, if facility is loaded
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(currentFacility.facilityID)) {
			fetchLOCLevelsAndTemplates();
			setIsLoading(true);
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility.facilityID, fetchLOCLevelsAndTemplates]);

	return (
		<>
			<div className={styles.LOCController}>
				<div className={styles.LOCController_notice}>
					{!isLoading &&
						isReadOnlyLOC(currentUser, currentFacility, values) && (
							<ReadOnlyBadge
								msg={getLockedMsg(currentUser, currentFacility, values)}
							/>
						)}
				</div>
				<div className={styles.LOCController_header}>
					<div className={styles.LOCController_header_title}>
						Set Facility's Levels Of Care{" "}
						<FacilityIndicator
							key={`LOC-${currentFacility?.facilityID}`}
							currentFacility={currentFacility}
						/>
					</div>
					<div className={styles.LOCController_header_about}>
						Change this community's Levels of Care by editing existing levels or
						loading a template and making changes as needed.
					</div>
				</div>
				{showLOCSelector(currentFacility) && (
					<>
						<div className={styles.LOCController_reset}>
							<ButtonSM
								handleClick={loadCurrentLevels}
								customStyles={customCSS.reset}
							>
								Show Current Care Levels
							</ButtonSM>
							{/* SHOW LOC FLAG(S) UI FOR: 
								// - INDEPENDENT & CHILD COMMUNITIES ONLY, NOT PARENT COMMUNITIES
								// - SUPER-USER/EXECUTIVE-ADMINS ONLY, NOT FACILITY ADMINS OR BELOW
							*/}
							{showFlagSettings(currentUser, currentFacility, parentsMap) && (
								<ButtonSM
									handleClick={initLOCSettings}
									customStyles={customCSS.options}
								>
									Open Care Level Settings
								</ButtonSM>
							)}
							{/* SHOW "LOCK ALL CHILD LOC" UI FOR:
								// - PARENT COMMUNITIES ONLY
							*/}
							{/* {isParentFacility(currentFacility?.facilityID, parentsMap) && ( */}
							{showChildLockControls(
								currentUser,
								currentFacility,
								parentsMap
							) && (
								<div className={styles.LOCController_reset_buttons}>
									<ButtonSM
										handleClick={lockAllChildLOC}
										customStyles={customCSS.lock}
									>
										Lock All Children
									</ButtonSM>
									<ButtonSM
										handleClick={unlockAllChildLOC}
										customStyles={customCSS.unlock}
									>
										Unlock All Children
									</ButtonSM>
								</div>
							)}
						</div>

						<div className={styles.LOCController_selector}>
							<div className={styles.LOCController_selector_label}>
								Select a template
							</div>
							<div className={styles.LOCController_selector_container}>
								<LOCTemplateSelector
									key={`SELECTOR-LOC-${facilityTemplates?.names?.length}`}
									selectedTemplate={selectedTemplate}
									handleTemplate={selectTemplate}
									templateNames={facilityTemplates?.names}
									currentFacility={currentFacility}
									customStyles={customCSS.selector}
								/>
								<ButtonSM
									isDisabled={isEmptyVal(selectedTemplate)}
									handleClick={loadTemplate}
									customStyles={customCSS.loadBtn}
								>
									Load Template
								</ButtonSM>
							</div>
						</div>

						{/* LOC TABLES */}
						{(showLevels(careLevels) ||
							showTables(selectedTemplate, currentTemplate)) && (
							<>
								<div className={styles.LOCController_notice}>
									<LOCNotice
										template={selectedTemplate}
										isShowingTemplate={showingTemplate}
									/>
								</div>
								<div className={styles.LOCController_tables}>
									<LOCTable
										key={`ASSISTED-LIVING-${
											careLevels?.["Assisted Living"]?.length
										}-${showingTemplate}-${
											JSON.stringify(careLevels) !==
											JSON.stringify(currentCareLevels)
										}`}
										isEditable={values?.Editable}
										btnText="Save Assisted Living"
										title="Assisted Living"
										subtitle="Edit or add new care levels to be applied to 'Assisted Living' unit types at your community(s)."
										schema={{
											cols: defaultSchema?.cols,
											data: careLevels?.["Assisted Living"],
										}}
										syncTableData={syncTableValues}
										handleTableReset={handleTableReset}
									/>
									<LOCTable
										key={`MEMORY-CARE-${
											careLevels?.["Memory Care"]?.length
										}-${showingTemplate}-${
											JSON.stringify(careLevels) !==
											JSON.stringify(currentCareLevels)
										}`}
										isEditable={values?.Editable}
										btnText="Save Memory Care"
										title="Memory Care"
										subtitle="Edit or add new care levels to be applied to 'Memory Care' unit types at your community(s)."
										schema={{
											cols: defaultSchema?.cols,
											data: careLevels?.["Memory Care"],
										}}
										syncTableData={syncTableValues}
										handleTableReset={handleTableReset}
									/>
									<LOCTable
										key={`INDEPENDENT-${
											careLevels?.["Independent"]?.length
										}-${showingTemplate}-${
											JSON.stringify(careLevels) !==
											JSON.stringify(currentCareLevels)
										}`}
										isEditable={values?.Editable}
										btnText="Save Independent"
										title="Independent"
										subtitle="Edit or add new care levels to be applied to 'Independent Living' unit types at your community(s)."
										schema={{
											cols: defaultSchema?.cols,
											data: careLevels?.["Independent"],
										}}
										syncTableData={syncTableValues}
										handleTableReset={handleTableReset}
									/>
									{showPersonalCare(currentFacility) && (
										<LOCTable
											key={`PERSONAL-CARE-${
												careLevels?.["Personal Care"]?.length
											}-${showingTemplate}-${
												JSON.stringify(careLevels) !==
												JSON.stringify(currentCareLevels)
											}`}
											isEditable={values?.Editable}
											btnText="Save Personal Care"
											title="Personal Care"
											subtitle="Edit or add new care levels to be applied to 'Personal Care' unit types at your community(s)."
											schema={{
												cols: defaultSchema?.cols,
												data: careLevels?.["Personal Care"],
											}}
											syncTableData={syncTableValues}
											handleTableReset={handleTableReset}
										/>
									)}
								</div>

								<div className={styles.LOCController_actions}>
									<ButtonSM
										isDisabled={disableSaveBtn(currentUser, values)}
										customStyles={customCSS.saveBtn}
										handleClick={saveLOCChanges}
									>
										Save Levels of Care
									</ButtonSM>
								</div>
							</>
						)}
					</>
				)}
			</div>

			{/* MODAL: CONFIRM LOC CHANGES */}
			{showConfirmModal && (
				<LOCModal
					key={`VALIDATE-LOC`}
					title={`Confirm Levels of Care Changes?`}
					closeModal={() => setShowConfirmModal(false)}
				>
					<LOCValidation
						vals={values}
						currentFacility={currentFacility}
						confirmSave={confirmSave}
						cancelSave={cancelSave}
						locDetails={locValidation}
					/>
				</LOCModal>
			)}

			{/* MODAL: RESET LEVELS TO DEFAULT */}
			{showResetModal && (
				<LOCModal
					key={`RESET-ALL-LOC`}
					title={`Confirm Reset of All Care Levels?`}
					closeModal={() => setShowResetModal(false)}
				>
					<LOCReset
						key={`RESET--${careLevels?.length}`}
						careLevels={careLevels}
						cancelResetLevels={cancelReset}
						confirmResetLevels={confirmReset}
					/>
				</LOCModal>
			)}

			{/* MODAL: UPDATE LOC FLAGS/SETTINGS */}
			{showLOCSettings && (
				<LOCModal
					key={`LOC-SETTINGS`}
					title={`Update LOC Settings`}
					closeModal={() => setShowLOCSettings(false)}
				>
					<LOCOptions
						key={`${currentFacility?.facilityID}`}
						vals={values}
						handleCheckbox={handleOptions}
						currentUser={currentUser}
						currentFacility={currentFacility}
						parentsMap={parentsMap}
						saveSettings={saveLOCSettings}
						cancelSettings={cancelLOCSettings}
						lockAllChildren={lockAllChildLOC}
						unlockAllChildren={unlockAllChildLOC}
					/>
				</LOCModal>
			)}
		</>
	);
};

export default LOCController;

LOCController.defaultProps = {};

LOCController.propTypes = {
	globalState: PropTypes.object,
	currentUser: PropTypes.object,
	currentFacility: PropTypes.object,
	dispatchToState: PropTypes.func,
	dispatchAlert: PropTypes.func,
};
