import { security, user } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { sortUsersBy } from "./utils_user";
import { red, blue, green, pink, yellow, blueGrey } from "./utils_styles";
import { UserTypeModel } from "./utils_models";
import { defaultParams } from "./utils_params";
import { isEmptyArray, isEmptyVal } from "./utils_types";
import {
	sortAlphaAscByKey,
	sortByNumAsc,
	sortNumAscByKey,
} from "./utils_processing";
import { featuresWhiteList } from "./utils_permissions";

////////////////////////////////////////////////////////////
//////////////////// USER-TYPES UTILS /////////////////////
////////////////////////////////////////////////////////////

/**
 * "UserTypes":
 * - List of string user types for updating a user's type.
 */
const USER_TYPES = [
	// `Administrator`, // OLD NAMING
	`Executive Administrator`, // NEW NAMING
	`Facility Administrator`,
	`Super User`,
	`Contractor`,
	`Manager`,
	`Staff`,
	`MedTech`,
	`Read-Only User`, // enable after jose's changes
];

/**
 * "UserTypes" (formatted):
 * - List of 'User-Types' objects formatted for the client.
 * 		- 'name': user-type name
 * 		- 'desc': user-type desc in human-readable form
 * 		- 'typeID': user-type numeric id.
 */
const USER_TYPES_FORMATTED = [
	{ name: "SuperUser", desc: "Internal acount user type", typeID: 1 },
	{ name: "Staff", desc: "Staff user type", typeID: 2 },
	{ name: "Manager", desc: "Manager user type", typeID: 3 },
	{ name: "Contractor", desc: "Contractor user type", typeID: 4 },
	// OLD NAMING
	// { name: "Administrator", desc: "Administrator user type", typeID: 5 },
	// NEW NAMING //
	{
		name: "Executive Administrator",
		desc: "Executive Administrator user type",
		typeID: 5,
	},
	{
		name: "FacilityAdministrator",
		desc: "Facility Administrator user type",
		typeID: 6,
	},
	{
		name: "MedTech",
		desc: "Med Tech user type",
		typeID: 7,
	},
	{
		name: "ReadOnlyUser",
		desc: "Read only allow user type",
		typeID: 8,
	},
];

const USER_TYPES_RAW = [
	{
		UserTypeID: 1,
		Name: "SuperUser",
		Description: "Super user type",
		IsActive: true,
		CreatedDate: "2020-12-26T22:19:15.2Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-12-26T22:19:15.2Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVPEGGY",
	},
	{
		UserTypeID: 2,
		Name: "Staff",
		Description: "Staff user type",
		IsActive: true,
		CreatedDate: "2020-12-26T22:19:15.22Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-12-26T22:19:15.22Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVPEGGY",
	},
	{
		UserTypeID: 3,
		Name: "Manager",
		Description: "Manager user type",
		IsActive: true,
		CreatedDate: "2020-12-26T22:19:15.223Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-12-26T22:19:15.223Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVPEGGY",
	},
	{
		UserTypeID: 4,
		Name: "Contractor",
		Description: "Contractor user type",
		IsActive: true,
		CreatedDate: "2020-12-26T22:19:15.227Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2020-12-26T22:19:15.227Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVPEGGY",
	},
	{
		UserTypeID: 5,
		Name: "ExecutiveAdministrator",
		Description: "Executive Administrator user type",
		IsActive: true,
		CreatedDate: "2021-04-02T18:33:02.333Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVPEGGY",
		ModifiedDate: "2021-04-02T18:33:02.333Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVPEGGY",
	},
	{
		UserTypeID: 6,
		Name: "FacilityAdministrator",
		Description: "Facility Administrator user type",
		IsActive: true,
		CreatedDate: "2021-10-13T18:30:54.687Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2021-10-13T18:30:54.687Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVCHARITY",
	},
	{
		UserTypeID: 7,
		Name: "MedTech",
		Description: "Med Tech user type",
		IsActive: true,
		CreatedDate: "2021-11-17T01:26:01.117Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2021-11-17T01:26:01.117Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVCHARITY",
	},
	{
		UserTypeID: 8,
		Name: "ReadOnlyUser",
		Description: "Read only allow user type",
		IsActive: true,
		CreatedDate: "2021-11-17T01:26:01.117Z",
		CreatedBy: null,
		CreatedLoginBy: "AdvantageAdmin",
		CreatedStation: "DEVCHARITY",
		ModifiedDate: "2021-11-17T01:26:01.117Z",
		ModifiedBy: null,
		ModifiedLoginBy: "AdvantageAdmin",
		ModifiedStation: "DEVCHARITY",
	},
];

const USER_TYPE_DESC = {
	Staff: {
		name: `Staff User Type`,
		desc: `Staff user type is a non-admin user account w/ normal access.`,
	},
	SuperUser: {
		name: `Super User Type`,
		desc: `Super User is an admin-level user account w/ access to features & functions that admins and non-admins don't have access to.`,
	},
	Manager: {
		name: `Manager Type`,
		desc: `Manager user type is a non-admin user account w/ normal access for managers.`,
	},
	Contractor: {
		name: `Contractor Type`,
		desc: `Contractor user type is a non-admin user account w/ normal access for contractors.`,
	},
	ExecutiveAdministrator: {
		name: `Executive Administrator Type`,
		desc: `Executive Administrator user type is an admin user account w/ administrative access & features enabled.`,
	},
	Administrator: {
		name: `Administrator Type`,
		desc: `Administrator user type is an admin user account w/ administrative access & features enabled.`,
	},
	FacilityAdministrator: {
		name: `Facility Administrator Type`,
		desc: `FacilityAdministrator user type is an admin user account w/ administrative access & features enabled for one or more facilities.`,
	},
	MedTech: {
		name: `Med Tech Restricted Type`,
		desc: `Med Tech user type is a non-admin user account w/ restricted access within certain apps such as the Senior Care EHR platform`,
	},
	ReadOnly: {
		name: `READ-ONLY User Type`,
		desc: `READ-ONLY user type will disable ALL edit functionality within EVERY available application. They may ONLY read/view content, but not edit/update/change/delete.`,
	},
};

// UPDATE & SAVE USER-TYPE UTILS //

/**
 * Fetches ALL user type categories: 'Admin', 'Manager', 'Staff', 'Contractor' etc
 */
const getAllUserTypes = async (token, params = { ...defaultParams }) => {
	let url = currentEnv.base + security.userType.get2;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		return err.message;
	}
};

/**
 * Updates an existing 'UserType' record.
 * @param {String} token - Auth token
 * @param {Object} userType - User type object with new user type definition.
 * @returns {Boolean} - Returns whether new user type was saved.
 */
const saveUserType = async (token, userType = {}) => {
	let url = currentEnv.base + security.userType.save;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(userType),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Saves/inserts a NEW 'UserType' record.
 * @param {String} token - Auth token
 * @param {Object} newUserType - User type object with new user type definition.
 * @returns {Boolean} - Returns whether new user type was saved.
 */
const saveNewUserType = async (token, newUserType = {}) => {
	let url = currentEnv.base + security.userType.save;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(newUserType),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// applies update to an existing user type record
const updateUserTypeRecord = async (token, updatedUserType = {}) => {
	const wasSaved = await saveUserType(token, updatedUserType);

	return wasSaved;
};

// SET USER TYPE REQUEST UTILS //

// updates a user's 'UserType' via userTypeID (supports legacy & new infra user types)
/**
 * Updates a user's UserType field to a specific 'UserTypeID'
 * @param {String} token - Auth token
 * @param {String} userID - User guid and/or user login ID.
 * @param {Number} userTypeID - Numeric user type ID.
 * @returns {Boolean} - Returns whether update was successful
 */
const updateUsersUserType = async (token, userID, userTypeID) => {
	let url = currentEnv.base + user.update.userType.setUserType;
	url += "?" + new URLSearchParams({ userLoginId: userID });
	url += "&" + new URLSearchParams({ userType: userTypeID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// updates a user's 'UserType' (eg. now 'Executive Admin') (supports legacy & new infra user types)
const updateUserAsAdmin = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asAdmin;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' to 'Executive Admin' formerly known as 'Admin' (supports legacy & new infra user types)
const updateUserAsExecutiveAdmin = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asExecutiveAdmin;
	url += "?" + new URLSearchParams({ userLoginId: userID });
	// url += "&" + new URLSearchParams({ userType: userTypeID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsContractor = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asContractor;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsFacilityAdmin = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asFacilityAdmin;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsManager = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asManager;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsStaff = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asStaff;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsSuperUser = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asSuperUser;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsMedTechRestricted = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asMedTech;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
// updates a user's 'UserType' (supports legacy & new infra user types)
const updateUserAsReadOnly = async (token, userID) => {
	let url = currentEnv.base + user.update.userType.asReadOnly;
	url += "?" + new URLSearchParams({ userLoginId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Wraps all user-type request handlers in a switch statement by 'userType' name
 * @param {String} token - Auth token
 * @param {String} userID - User guid (ie. 'UserLoginID')
 * @param {String} userType - A string-form user type (eg. 'Admin', 'Facility Admin' etc)
 * @returns {Boolean} - Returns whether user type was successfuly updated
 */
const updateUserType = async (token, userID, userType) => {
	switch (userType) {
		case "Executive Admin":
		case "Executive Administrator":
		case "ExecutiveAdministrator": {
			// const wasUpdated = await updateUsersUserType(token, userID, 4);
			const wasUpdated = await updateUserAsExecutiveAdmin(token, userID);
			return wasUpdated;
		}
		case "Admin":
		case "Administrator": {
			const wasUpdated = await updateUserAsAdmin(token, userID);
			return wasUpdated;
		}
		case "FacilityAdmin":
		case "Facility Admin":
		case "FacilityAdministrator":
		case "Facility Administrator": {
			const wasUpdated = await updateUserAsFacilityAdmin(token, userID);
			return wasUpdated;
		}
		// case "Executive Admin":
		// case "Executive Administrator":
		case "SuperUser":
		case "Super User":
		case "Super-User": {
			const wasUpdated = await updateUserAsSuperUser(token, userID);
			return wasUpdated;
		}
		case "Staff": {
			const wasUpdated = await updateUserAsStaff(token, userID);
			return wasUpdated;
		}
		case "Contactor":
		case "Contractor": {
			const wasUpdated = await updateUserAsContractor(token, userID);
			return wasUpdated;
		}
		case "Manager": {
			const wasUpdated = await updateUserAsManager(token, userID);
			return wasUpdated;
		}
		case "MedTech":
		case "MedTechRestricted":
		case "Med-Tech-Restricted":
		case "Med-Tech Restricted": {
			const wasUpdated = await updateUserAsMedTechRestricted(token, userID);
			return wasUpdated;
		}
		case "ReadOnly":
		case "Read-Only":
		case "Read-Only User":
		case "READ-ONLY":
		case "READ-ONLY User": {
			const wasUpdated = await updateUserAsReadOnly(token, userID);
			return wasUpdated;
		}
		default:
			throw new Error(`❌ Ooops! Unrecognized 'userType' provided:`, userType);
	}
};

/**
 * Handles applying multiple user type updates to a single user at once.
 * @param {String} token - Auth token
 * @param {String} userID - Target user guid string
 * @param {Array} userTypes - Array of user type string indicators
 * @returns {Array} - Returns array of HTTP responses.
 */
const updateUserTypesMany = async (token, userID, userTypes = []) => {
	// checks values & adds relevant requests, as needed
	const responses = await Promise.allSettled([
		userTypes.includes("Administrator") &&
			updateUserType(token, userID, "Admin"),
		userTypes.includes("Facility Administrator") &&
			updateUserType(token, userID, "Facility Admin"),
		userTypes.includes("Super User") &&
			updateUserType(token, userID, "Super User"),
	]);

	console.log(`Response(s):`, responses);

	return responses;
};

// checks userType selections(multiple) & creates list of selected types
// ...from boolean values
const getTypeNamesFromSelections = (vals = {}) => {
	const { enableAdminType, enableFacilityAdminType, enableSuperUserType } =
		vals;
	let typesList = [];
	if (enableAdminType) {
		typesList.push("Admin");
	}
	if (enableFacilityAdminType) {
		typesList.push("Facility Admin");
	}
	if (enableSuperUserType) {
		typesList.push("Super User");
	}

	return typesList;
};

// removes 'Super User' type, if user is NOT a 'isSuperUser'
const getAllowedUserTypes = (currentUser, allUserTypes = []) => {
	const { userID } = currentUser;
	const isWhiteListed = featuresWhiteList.includes(userID);

	if (isWhiteListed) {
		return allUserTypes;
	} else {
		const allowedUserTypes = getAllowedUserTypesByUserRole(
			currentUser,
			allUserTypes
		);

		return allowedUserTypes;
	}
};

// removes more than on user type from the list
/**
 * Removes one or more user type strings from the list of all user types.
 * @param {String[]} typesToRemove - An array of string-form user types to be removed.
 * @param {String[]} allUserTypes - An array of all string-form user types.
 * @returns {String[]} - Returns an array of strings
 */
const removeUserTypesFromList = (typesToRemove = [], allUserTypes = []) => {
	const without = [...allUserTypes].filter((type) => {
		const shouldRemove = typesToRemove.includes(type);
		if (!shouldRemove) {
			return type;
		} else {
			return;
		}
	});

	return without;
};

const getAllowedUserTypesByUserRole = (currentUser, allUserTypes = []) => {
	const {
		isAdmin, // renamed to 'isExecutiveAdmin'
		isSuperUser,
		isFacilityAdmin,
	} = currentUser;

	// user types available to everyone (technically)
	const baseTypes = [`Contractor`, `Manager`, `Staff`, `Read-Only User`];

	switch (true) {
		// SUPER-USERS
		case isSuperUser: {
			return allUserTypes;
		}
		// EXECUTIVE-ADMINS
		case isAdmin && !isSuperUser: {
			const typesForEAs = removeUserTypesFromList(
				[`Super User`, `SuperUser`],
				allUserTypes
			);

			return typesForEAs;
		}
		// FACILITY-ADMINS
		case isFacilityAdmin && !isAdmin && !isSuperUser: {
			const typesForFAs = removeUserTypesFromList(
				[
					`Super User`,
					`SuperUser`,
					`Executive Administrator`,
					`ExecutiveAdministrator`,
				],
				allUserTypes
			);

			return typesForFAs;
		}

		default:
			return baseTypes;
	}
};

// USER-TYPE STATE UPDATERS //

const updateUserTypeInUserList = (usersList = [], userVals = {}) => {
	const { userID, userType } = userVals;
	// target user object
	const targetUser = [...usersList].filter(
		(user) => user.userID === userID
	)?.[0];
	// usersList WITHOUT target user
	const without = [...usersList].filter((user) => user.userID !== userID);

	switch (userType) {
		case "Admin":
		case "Administrator": {
			const newUser = {
				...targetUser,
				isAdmin: true,
			};
			const newUsersList = [
				...sortUsersBy("lastNameASC", [...without, newUser]),
			];

			return newUsersList;
		}
		case "SuperUser":
		case "Super-User":
		case "Super User": {
			const newUser = {
				...targetUser,
				isSuperUser: true,
			};
			const newUsersList = [
				...sortUsersBy("lastNameASC", [...without, newUser]),
			];

			return newUsersList;
		}
		case "FacilityAdministrator":
		case "Facility Administrator":
		case "Facility Admin": {
			const newUser = {
				...targetUser,
				isFacilityAdmin: true,
			};
			const newUsersList = [
				...sortUsersBy("lastNameASC", [...without, newUser]),
			];

			return newUsersList;
		}
		// DO NOTHING???
		case "Manager":
		case "Staff":
		case "Contactor": {
			const newUser = {
				...targetUser,
			};
			const newUsersList = [
				...sortUsersBy("lastNameASC", [...without, newUser]),
			];

			return newUsersList;
		}

		default:
			return [...usersList];
	}
};

// USER-TYPE UTILS //

/**
 * Determines & returns a given user's user type definition.
 * @param {Object} user - Client-formatted 'user' object.
 * @returns {String} - Returns string-form user type (eg. 'Super', 'Admin' etc)
 */
const getUserTypeLabel = (user = {}) => {
	const {
		isSuperUser,
		isFacilityAdmin,
		isRegionalAdmin,
		isAdmin,
		isALAAdmin,
		isMedTechRestricted,
		isReadOnly,
		userTypeID,
	} = user;

	// SuperUser(s): 'isAdmin', 'isALAAdmin', 'isSuperUser'
	// Standard Admin (non super): 'isFacilityAdmin', 'isRegionalAdmin'
	// not 'Executive Administrator'
	const isEA =
		((isAdmin || isALAAdmin) && !isSuperUser) ||
		(!isEmptyVal(userTypeID) && userTypeID === 5);
	// const isEA = (isAdmin || isALAAdmin) && !isSuperUser;
	const isNonAdmin = !isEA && !isFacilityAdmin && !isRegionalAdmin;
	const isMedTech = !isEA && isNonAdmin && isMedTechRestricted;

	switch (true) {
		// 'READ-ONLY' User
		case isReadOnly: {
			return `READ-ONLY User`;
		}
		// 'Super User' (Super type & internal ONLY)
		case isSuperUser: {
			return `Super User`;
		}
		// 'ALA Admin' (Super type & external)
		// case (isAdmin || isALAAdmin) && !isSuperUser:
		// case (isALAAdmin || isAdmin) && !isSuperUser: {
		// 	// return `Super (ALA-Admin)`;
		// 	return `Executive Administrator`;
		// }
		// 'ALA Admin'/'Executive Admin'
		case isEA: {
			// return `Super (ALA-Admin)`;
			return `Executive Administrator`;
		}
		// 'Facility Administrator'
		// case isFacilityAdmin && !isSuperUser && !isAdmin && !isALAAdmin: {
		case isFacilityAdmin && !isEA: {
			return `Facility Administrator`;
		}
		// 'Regional Administrator'
		case isRegionalAdmin && !isSuperUser && !isAdmin && !isALAAdmin: {
			return `Regional Administrator`;
		}
		// RECENTLY UPDATED - 4/27/2022 AT 7:35 AM
		// // 'Admin' or 'Administrator'
		// case isAdmin && !isSuperUser && !isRegionalAdmin: {
		// 	return `Admin`;
		// }
		// 'Med-Tech Restricted'
		case isMedTech: {
			return `Med-Tech Restricted`;
		}
		//  'Standard User' or 'Standard'
		case !isAdmin &&
			!isSuperUser &&
			!isFacilityAdmin &&
			!isRegionalAdmin &&
			!isMedTechRestricted: {
			// RECENT UPDATE - 4/28/2022 AT 2:31 PM
			// case isNonAdmin && !isMedTech: {
			return `Standard User (default)`;
		}

		default:
			return `Standard User (default)`;
	}
};

/**
 * Returns a user type's color code.
 * @param {String} userType - String user type (eg. 'Super User', 'Facility Admin' etc)
 * @returns {String} - Returns the color code for a given user type.
 */
const getUserTypeColor = (userType) => {
	switch (userType) {
		case "ReadOnlyUser":
		case "Read only user":
		case "Read-Only User":
		case "READ-ONLY User": {
			return blueGrey[300];
		}
		case "SuperUser":
		case "Super User": {
			return blue[600];
		}
		case "ExecutiveAdmin":
		case "Executive Admin":
		case "ExecutiveAdministrator":
		case "Executive Administrator":
		case "Super (ALA-Admin)":
		case "ALA Admin":
		case "ALAAdmin": {
			return green[500];
		}
		case "Facility Administrator":
		case "Facility Admin": {
			return pink[500];
		}
		case "Regional Administrator":
		case "Regional Admin": {
			return yellow[300];
		}
		case "Admin":
		case "Administrator": {
			return yellow[300];
		}
		case "Standard":
		case "Standard User": {
			return blueGrey[700];
		}
		// 'Med-Tech Restricted'
		case "Med-Tech":
		case "Med-Tech Restricted": {
			return red[600];
		}

		default:
			return blueGrey[700];
	}
};

// USER-TYPE & PERMS UTILS //

/**
 * Checks for 'Super-User' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Super-User' user type.
 */
const isSuperUser = (currentUser) => {
	const hasStatus = currentUser?.isSuperUser ?? currentUser?.superUser ?? false;
	return hasStatus;
};
/**
 * Checks for 'Facility-Admin' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Facility-Admin' user type.
 */
const isExecutiveAdmin = (currentUser) => {
	const hasStatus =
		currentUser?.userTypeID === 5 || currentUser?.isExecutiveAdmin;
	return hasStatus;
};
/**
 * Checks for 'Facility-Admin' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Facility-Admin' user type.
 */
const isFacilityAdmin = (currentUser) => {
	const hasStatus = currentUser?.isFacilityAdmin ?? false;
	return hasStatus;
};
/**
 * Checks for 'Regional-Admin' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Regional-Admin' user type.
 */
const isRegionalAdmin = (currentUser) => {
	const hasStatus = currentUser?.isRegionalAdmin ?? false;
	return hasStatus;
};
/**
 * Checks for 'Administrator' user type/permissions. (includes: 'Regional', 'Facility' and generic 'Admin' user types.)
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Administrator' user type.
 */
const isAdmin = (currentUser) => {
	const hasStatus =
		isFacilityAdmin(currentUser) || isRegionalAdmin(currentUser);
	return hasStatus;
};
/**
 * Checks for 'Standard' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Standard' user type.
 */
const isStandardUser = (currentUser) => {
	const hasStandardStatus =
		!isSuperUser(currentUser) &&
		!isAdmin(currentUser) &&
		!isMedTechRestricted(currentUser);

	return hasStandardStatus;
};
/**
 * Checks for 'Med-Tech-Restricted' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'Med-Tech-Restricted' user type.
 */
const isMedTechRestricted = (currentUser) => {
	const hasStatus = currentUser?.isMedTechRestricted ?? false;
	return hasStatus;
};
/**
 * Checks for 'READ-ONLY' user type/permissions.
 * @param {Object} currentUser - User object from global store
 * @returns {Boolean} - Returns whether user is a 'READ-ONLY' user type.
 */
const isReadOnly = (currentUser) => {
	return currentUser?.isReadOnly ?? false;
};
// checks for ANY ADMIN type (ie. Facility, Executive, Regional, ALAAdmin etc)
const isAdminType = (currentUser) => {
	const admin = isAdmin(currentUser);
	const isEA = currentUser?.isExecutiveAdmin || currentUser?.userTypeID === 5;
	const hasStatus = admin || isEA;

	return hasStatus;
};

// USER TYPE CHECKERS

// USER-TYPE CHECKER UTILS //

/**
 * Checks ALL relevant user records for 'Super-User' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @param {Object} userProfile - 'UserProfile' record, if available.
 * @param {Object} userMerge - 'UserMerges' record, if available.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 * ##TODOS:
 * - Deprecate use of 'ADVUSER' at a later date
 */
const hasSuperUserPerms = (advUser, userProfile, userMerge) => {
	const isSuperViaAdv = advUser?.superUser ? true : false;
	const isSuperViaProfile =
		userProfile?.userTypeID === 1 || userProfile?.UserTypeID === 1
			? true
			: false;
	const isSuperViaMerge =
		userMerge?.isSuperUser || userMerge?.IsSuperUser || advUser?.superUser
			? true
			: false;
	// checks ALL record types
	const isSuperUser = isSuperViaAdv || isSuperViaProfile || isSuperViaMerge;

	return isSuperUser;
};
/**
 * Determines if a user has 'Regional Administrator' permissions.
 * - Defined by: 'Facility Administrator' status and access to multiple facilities.
 * @param {Object} advUser - 'ADVUSER' record
 * @param {Object} userProfile - 'UserProfile' record
 * @param {Object[]} userFacilities - User's facility records.
 */
const hasRegionalAdminPerms = (advUser, userProfile, userFacilities = []) => {
	const isRegionalProfile =
		(userProfile?.userTypeID === 6 || userProfile?.UserTypeID === 6) &&
		userFacilities?.length > 1;

	return isRegionalProfile;
};
/**
 * Determines if a user has 'Regional Administrator' permissions.
 * - Defined by: 'Facility Administrator' status and access to multiple facilities.
 * @param {Object} advUser - 'ADVUSER' record
 * @param {Object} userProfile - 'UserProfile' record
 * @param {Object[]} userFacilities - User's facility records.
 */
const hasExecutiveAdminPerms = (advUser, userProfile, userFacilities = []) => {
	const hasExecutivePerms =
		userProfile?.userTypeID === 5 || userProfile?.UserTypeID === 5;

	return hasExecutivePerms;
};
/**
 * Checks ALL relevant user records for 'FacilityAdmin' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @param {Object} userProfile - 'UserProfile' record, if available.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 * ##TODOS:
 * - Deprecate use of 'ADVUSER' at a later date
 *    - Consider replacing 'ADVUSER' usage w/ 'UserTypeID' in 'UserProfile'
 */
const hasFacilityAdminPerms = (advUser, userProfile) => {
	const isAdminViaAdv = advUser?.bitFacilityAdministrator ?? false;
	const isAdminViaProfile =
		userProfile?.userTypeID === 6 || userProfile?.UserTypeID === 6
			? true
			: false;
	// checks ALL record types
	const isFacilityAdmin = isAdminViaAdv || isAdminViaProfile;

	return isFacilityAdmin;
};
/**
 * Checks ALL relevant user records for 'ALA-Admin' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 * ##TODOS:
 * - Deprecate use of 'ADVUSER' at a later date
 *    - Consider replacing 'ADVUSER' usage w/ 'UserTypeID' in 'UserProfile'
 */
const hasAlaAdminPerms = (advUser, userProfile) => {
	const isAlaViaAdv = advUser?.alaAdmin;
	const isAdminViaInfra = userProfile?.UserTypeID === 5;

	const isALA = isAlaViaAdv || isAdminViaInfra;

	return isALA;
};
/**
 * Checks ALL relevant user records for 'ALA-Admin' permissions.
 * @param {Object} advUser - 'ADVUSER' record, if available.
 * @param {Object} userProfile - 'UserProfile' record.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 */
const hasMedTechPerms = (advUser, userProfile) => {
	const isMedProfile =
		userProfile?.userTypeID === 7 || userProfile?.UserTypeID === 7
			? true
			: false;
	const isMedAdv = advUser?.MedTechRestrictedAccess ? true : false;

	return isMedProfile || isMedAdv;
};
/**
 * Checks ALL relevant user records for 'READ-ONLY' permissions.
 * @param {Object} userProfile - 'UserProfile' record.
 * @returns {Boolean} - Returns true|false for super user permissions.
 *
 */
const hasReadOnlyPerms = (userProfile) => {
	const typeID = userProfile?.UserTypeID ?? userProfile?.userTypeID;
	const isReadOnlyType = typeID === 8;
	return isReadOnlyType;
};

/**
 * Determines if user has standard permissions
 * @param {Object} advUser - 'ADVUSER' record, if available
 * @param {Object} userProfile - 'UserProfile' record.
 */
const hasStandardPerms = (advUser, userProfile) => {
	// super, admin, med-tech
	const definedTypes = [1, 6, 7];
	const nonType = !definedTypes.includes(
		userProfile?.userTypeID ?? userProfile?.UserTypeID
	)
		? true
		: false;

	return nonType;
};

// USER TYPE PROCESSING UTILS //

// processes/formats a single user type record
const processUserType = (userType = {}) => {
	const { Name, Description, UserTypeID, IsActive = true } = userType;
	const newObj = {
		name: Name,
		desc: Description,
		typeID: UserTypeID,
		isActive: IsActive,
	};

	return newObj;
};

/**
 * Processes & inits client-formatted user types.
 * @returns {Array} - Returns an array of formatted objects.
 */
const processUserTypes = (allTypes = []) => {
	if (isEmptyArray(allTypes)) return [];
	return allTypes.reduce((userTypes, typeRecord) => {
		const { Name, Description, UserTypeID, IsActive } = typeRecord;
		const newObj = {
			name: Name,
			desc: Description,
			typeID: UserTypeID,
			isActive: IsActive,
		};
		userTypes = [...userTypes, newObj];
		return userTypes;
	}, []);
};

// format user types - client format
const processAndSortAllUserTypes = (userTypes = []) => {
	if (isEmptyArray(userTypes)) return [];
	const processed = processUserTypes(userTypes);
	// sorted alpha
	return processed.sort((a, b) => {
		return a.typeID - b.typeID;
	});
	// return processed.sort((a, b) => {
	// 	const { name: nameA } = a;
	// 	const { name: nameB } = b;
	// 	return nameA.localeCompare(nameB);
	// });
};

// extracts ONLY the userType 'names' into an array
const formatAllUserTypes = (userTypes) => {
	if (isEmptyArray(userTypes)) return [];

	return userTypes.map(({ name }) => name);
};

// sorts by 'typeID'
const sortAllTypesByID = (userTypes = []) => {
	if (isEmptyArray(userTypes)) return [];
	const sorted = sortNumAscByKey("typeID", userTypes);

	return sorted;
};
const sortAllTypesByName = (userTypes = []) => {
	if (isEmptyArray(userTypes)) return [];
	const sorted = sortAlphaAscByKey("name", userTypes);

	return sorted;
};

// USER-TYPE MODEL UTILS //

/**
 * Creates, generates and populates a new 'UserTypeModel' w/ the supplied values.
 * @param {Object} vals - An object of new user type values.
 * @returns {Object:UserTypeModel} - Returns a populated 'UserTypeModel' instance.
 */
const updateUserTypeModel = (vals = {}) => {
	const base = new UserTypeModel(vals);
	const model = base.getModel();

	return model;
};

export {
	USER_TYPES,
	USER_TYPES_FORMATTED,
	USER_TYPES_RAW,
	USER_TYPE_DESC,
	getUserTypeColor,
	getUserTypeLabel,
};

export { updateUserTypeModel };

// user type checkers
export {
	isSuperUser,
	isExecutiveAdmin,
	isRegionalAdmin,
	isFacilityAdmin,
	isAdmin,
	isStandardUser,
	isMedTechRestricted,
	isReadOnly,
	isAdminType,
};

// user type checking utils
export {
	hasSuperUserPerms,
	hasFacilityAdminPerms,
	hasAlaAdminPerms,
	hasExecutiveAdminPerms,
	hasRegionalAdminPerms,
	hasMedTechPerms,
	hasStandardPerms,
	hasReadOnlyPerms,
};

export { getTypeNamesFromSelections, getAllowedUserTypes };

export { updateUserTypeInUserList };

export {
	processUserTypes,
	processUserType,
	formatAllUserTypes,
	sortAllTypesByID,
	sortAllTypesByName,
	processAndSortAllUserTypes,
};

// user type generic utils
export {
	getAllUserTypes,
	// update & save utils
	saveNewUserType,
	saveUserType,
	updateUserTypeRecord,
};

// user type request handlers
export {
	updateUserAsExecutiveAdmin,
	updateUserAsAdmin,
	updateUserAsContractor,
	updateUserAsFacilityAdmin,
	updateUserAsManager,
	updateUserAsStaff,
	updateUserAsSuperUser,
	updateUserAsMedTechRestricted,
	updateUserAsReadOnly,
	updateUserType,
	updateUserTypesMany,
	updateUsersUserType,
};
