import React, { useState } from "react";
import styles from "../css/pages/ResetPasswordPage.module.scss";
import fallbackLogo from "../assets/brand/logo.png";
import { PropTypes } from "prop-types";
import { useForm } from "../utils/useForm";
import { isEmptyVal } from "../helpers/utils_types";
// components
import { DesignUI } from "../components/errors/LoginError";
import ViewContainer from "../components/app/ViewContainer";
import ValidateOTP from "../components/otp/ValidateOTP";

// REQUIREMENTS:
// - Check lockout status
// - Confirm available reset methods:
//    - By Email
//    - By Questions
//    - By Admin

const mergeChars = (vals) => {
	const keys = Object.keys(vals);
	let merged;
	keys.reduce((joined, key) => {
		const val = vals[key];
		if (!val) return;
		merged = joined + val;
		return merged;
	}, "");

	// console.log("merged", merged);
	return merged;
};

const ActionButton = ({ handleAction, children }) => {
	return (
		<button onClick={handleAction} className={styles.ActionButton}>
			{children}
		</button>
	);
};

const ResetPasswordPage = ({ history }) => {
	const [showOTP, setShowOTP] = useState(false);
	const [otp, setOtp] = useState("8cd05568");

	// fetch lockout status & otp
	const fetchResetStatus = async () => {
		// fetch user lockout
		// fetch user OTP, if applicable
	};

	const goToLogin = () => {
		history.replace("/");
	};

	return (
		<>
			<div className={styles.ResetPasswordPage}>
				<div className={styles.ResetPasswordPage_logo}>
					<div className={styles.ResetPasswordPage_logo_logoContainer}>
						<img
							src={fallbackLogo}
							alt="AL Advantage Brand Logo"
							className={styles.ResetPasswordPage_logo_logoContainer_logo}
						/>
					</div>
				</div>
				<div className={styles.ResetPasswordPage_header}>
					<h2 className={styles.ResetPasswordPage_header_title}>
						Reset Password
					</h2>
					<p className={styles.ResetPasswordPage_header_desc}>
						Change your password by verifying your account and updating your
						login details.
					</p>
				</div>
				<div className={styles.ResetPasswordPage_ui}>
					<DesignUI errorCode="E400" />
				</div>

				<footer className={styles.ResetPasswordPage_footer}>
					<ActionButton handleAction={goToLogin}>Go to login</ActionButton>
				</footer>
			</div>

			{showOTP && (
				<ValidateOTP otp={otp} closeModal={() => setShowOTP(false)} />
			)}
		</>
	);
};

export default ResetPasswordPage;

ResetPasswordPage.defaultProps = {};

ResetPasswordPage.propTypes = {};
