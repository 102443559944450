import { currentEnv, getEnvName, isDevOrTest } from "./utils_env";
import { apps, facility, generic, security, user } from "./utils_endpoints";
import { generateURL, params } from "./utils_params";
import { isEmptyArray, isEmptyVal } from "./utils_types";
import { getAllUsers, processUserList } from "./utils_user";
import { AppByUserAccessModel } from "./utils_models";

// ##TODOS:
// - Create 'GetUserAccessList' wrapper around request & processing util
// - Map out ALL ALA apps and their IDs

/**
 * Application IDs: unique identifiers in the 'Application' table.
 */
const appIDs = {
	CareManager: 1, // SeniorCareVB
	SeniorCareVB: 1, // SeniorCareVB
	SeniorCareEHR: 1, // SeniorCareVB/SeniorCareEHR
	CareTracker: 2, // AdvantageTracker
	AdvantageTracker: 2, // AdvantageTracker
	AdminPortal: 3, // AdminPortal
	CarePortal: 3,
	ALAServices: 4,
	AccufloInterface: 5,
	// internal-portal
	InternalPortal: `INTERNAL-PORTAL`,
	Emar: "Chart Meds (EMAR)",
	ePay: 22,
};
const appNames = {
	1: "SeniorCareVB",
	// 1: "SeniorCareEHR",
	2: "AdvantageTracker",
	3: "AdminPortal",
	4: "ALAServices",
	5: "AccufloInterface",
	// internal
	22: "StaxInterface",
	InternalPortal: "InternalPortal",
};

const appAliases = {
	1: "Senior Care EHR",
	2: "Care Tracker",
	3: "Admin Portal",
	4: "ALA Services",
	5: "Accuflo Interface",
	22: "ePay (Stax)",
	// internal
	InternalPortal: "Internal Portal",
};

// ##TODOS:
// - Might need to update 'SeniorCareVB' url value according to Jon????

const SERVICES = {
	SeniorCareEHR: {
		appID: 1,
		primary: "Care Manager",
		alias: "SeniorCareEHR",
		name: "Senior Care EHR",
		desc: "EHR Care Platform",
		url: {
			// test: "http://local.aladvantage.com/Account/Login.aspx", // JON'S LOCAL MACHINE
			test: "https://test.aladvantage.com/Account/Login.aspx",
			prod: "https://app.aladvantage.com/Account/Login.aspx",
		},
	},
	SeniorCareVB: {
		appID: 1,
		primary: "Care Manager",
		alias: "SeniorCareVB",
		name: "Senior Care VB",
		desc: "EHR Care Platform",
		url: {
			// test: "http://local.aladvantage.com/Account/Login.aspx", // JON'S LOCAL MACHINE
			test: "https://test.aladvantage.com/Account/Login.aspx",
			prod: "https://app.aladvantage.com/Account/Login.aspx",
		},
	},
	AdvantageTracker: {
		appID: 2,
		primary: "Care Tracker",
		alias: "AdvantageTracker",
		name: "Care Tracker",
		desc: "Care Tracking & Reporting Platform",
		url: {
			local: "http://localhost:3001",
			test: "https://trackertest.aladvantage.com",
			prod: "https://tracker.aladvantage.com",
		},
	},
	AdminPortal: {
		appID: 3,
		alias: "AdminPortal",
		name: "ALA Admin Portal",
		desc: "Portal for ALA app settings, user management and single-sign-on (SSO).",
		url: {
			local: "http://localhost:3000",
			test: "https://portaltest.aladvantage.com",
			prod: "https://portal.aladvantage.com",
		},
	},
	ALAServices: {
		appID: 4,
		alias: "ALAServices",
		name: "ALA Services",
		desc: "Web services for connecting to ALA's backend infrastructure.",
		url: {
			prod: "https://api.aladvantage.com/alaservices/v1",
			test: "https://apitest.aladvantage.com/alaservices/v1",
		},
	},
	InternalPortal: {
		appID: "InternalPortal",
		alias: "Internal Portal",
		name: "Internal Portal",
		desc: "Internal use settings & management portal.",
		url: {
			prod: "https://internal-portal-app.herokuapp.com",
			test: "https://internal-portal-app.herokuapp.com",
		},
	},
	AccufloInterface: {
		appID: 5,
		alias: "AccufloInterface",
		name: "Accu-Flow Interface",
		desc: "Third-party interface for transmitting resident data.",
		url: null,
	},
};

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// APP REQUEST UTILS /////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

/**
 * Provides a facility access to a given application.
 * @param {String} token - Auth token
 * @param {String} facilityId - facility guid
 * @param {Number} applicationId - Target application id.
 * @returns {Object} - Returns object w/ boolean for success|failure
 */
const assignFacilityAppAccess = async (token, facilityId, applicationId) => {
	let url = currentEnv.base + facility?.apps?.assignAppToFacility;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Provides a facility access to a given application.
 * @param {String} token - Auth token
 * @param {String} facilityId - facility guid
 * @param {Number} applicationId - Target application id.
 * @returns {Object} - Returns object w/ boolean for success|failure
 */
const unassignFacilityAppAccess = async (token, facilityId, applicationId) => {
	let url = currentEnv.base + facility?.apps?.assignAppToFacility;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Provides a facility access to a given application.
 * @param {String} token - Auth token
 * @param {String} facilityId - facility guid
 * @param {Number} applicationId - Target application id.
 * @returns {Object} - Returns object w/ boolean for success|failure
 */
const assignFacilityUsersAppAccess = async (
	token,
	facilityId,
	applicationId
) => {
	let url = currentEnv.base + facility?.apps?.assignAppToFacilityUsers;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Provides a facility access to a given application.
 * @param {String} token - Auth token
 * @param {String} facilityId - facility guid
 * @param {Number} applicationId - Target application id.
 * @returns {Object} - Returns object w/ boolean for success|failure
 */
const unassignFacilityUsersAppAccess = async (
	token,
	facilityId,
	applicationId
) => {
	let url = currentEnv.base + facility?.apps?.unassignAppToFacilityUsers;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Checks if ALL users for a given facility have access to a given application.
 * @param {String} token - Auth token
 * @param {String} facilityId - Target facility guid
 * @param {Number} applicationId - Numeric app ID.
 * @returns {Boolean} - Returns true|false
 */
const checkFacilityUsersAppAccess = async (
	token,
	facilityId,
	applicationId
) => {
	let url = currentEnv.base + facility?.apps?.checkFacilityUsersAppAccess;
	url += "?" + new URLSearchParams({ facilityId, applicationId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Wrapper around assigning/un-assigning app access for a facility
 * @param {String} token - Auth token
 * @param {Boolean} enableAccess - Boolean for enabling app access
 * @param {String} facilityId - Target facility guid
 * @param {Number} applicationId - Numeric app ID.
 * @returns {Boolean} - Returns true|false
 */
const saveFacilityAppAssignment = async (
	token,
	enableAccess = false,
	facilityId,
	applicationId
) => {
	if (enableAccess) {
		// assign
		const wasEnabled = await assignFacilityAppAccess(
			token,
			facilityId,
			applicationId
		);

		return wasEnabled;
	} else {
		// un-assign
		const wasDisabled = await unassignFacilityAppAccess(
			token,
			facilityId,
			applicationId
		);

		return wasDisabled;
	}
};

/**
 * Custom wrapper around enabling/disabling facility user's app access requests. Used for enabling/disabling all users at a given facility for an app.
 * @param {String} token - Auth token
 * @param {Boolean} enableAllUsers - Boolean for enabling all users app access
 * @param {String} facilityId - Target facility guid
 * @param {Number} applicationId - Numeric application ID.
 * @returns {Boolean} - Returns true|false
 */
const saveFacilityUsersAppAccess = async (
	token,
	enableAllUsers = false,
	facilityId,
	applicationId
) => {
	if (enableAllUsers) {
		// assign
		const wasAllUsersEnabled = await assignFacilityUsersAppAccess(
			token,
			facilityId,
			applicationId
		);

		return wasAllUsersEnabled;
	} else {
		// un-assign
		const wasAllUsersDisabled = await unassignFacilityUsersAppAccess(
			token,
			facilityId,
			applicationId
		);

		return wasAllUsersDisabled;
	}
};

/**
 * Checks if a user has access to a given app.
 * @param {String} token - Auth token
 * @param {String} userId - String user guid
 * @param {Number} appId - Numeric user ID.
 * @returns {Boolean} - returns whether user has access to target app.
 */
const checkUserAppAccess = async (token, userID, appID) => {
	let url = currentEnv.base + security.appAccess.checkUser;
	url += "?" + new URLSearchParams({ userID });
	url += "&" + new URLSearchParams({ applicationId: appID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Fetches a user's ApplicationByUser record, for a given app.
 * @param {String} token - Auth token
 * @param {String} userID - String user guid.
 * @param {Number} appID - Numeric application ID.
 * @returns {Array} - Returns an array with ApplicationByUser record contained inside
 */
const getUserAccessRecord = async (token, appID, userID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByUser });
	url += "&" + new URLSearchParams({ ApplicationID: appID });
	url += "&" + new URLSearchParams({ UserID: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Returns a list of 'ApplicationByUser' records that are enabled for the 'ApplicationID' provided.
 * @returns {Array|Null} - Returns an array of records OR null if NO matching records found.
 */
const getUserAccessByApp = async (token, appID = 2) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByUser });
	url += "&" + new URLSearchParams({ IsAccessible: true });
	url += "&" + new URLSearchParams({ ApplicationID: appID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};
/**
 * Returns a list of 'ApplicationByUser' records that are enabled for the 'ApplicationID' provided.
 * @returns {Array|Null} - Returns an array of records OR null if NO matching records found.
 */
const getAppAccessByUserID = async (token, appID = 2, userID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByUser });
	url += "&" + new URLSearchParams({ UserID: userID });
	url += "&" + new URLSearchParams({ ApplicationID: appID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

// returns all app access records for a given user
const getAppAccessByUser = async (token, userID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByUser });
	url += "&" + new URLSearchParams({ UserID: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

// currently fetches AppsByUser (however AppsByFacility should be added in future)
const getUserApps = async (token, userID) => {
	const [legacy, tracker, emar, ePay] = await Promise.all([
		checkUserAppAccess(token, userID, 1),
		checkUserAppAccess(token, userID, 2),
		checkUserAppAccess(token, userID, 19),
		checkUserAppAccess(token, userID, 22),
	]);

	console.log("ePay", ePay);

	// generate 'AppByUser' pseudo records
	const { trackerAccess, legacyAccess, ePayAccess } = processUserAppAccess({
		isTrackerEnabled: tracker,
		isLegacyEnabled: legacy,
		isEPayEnabled: ePay,
	});
	const emarAccess = {
		ApplicationID: 19,
		ApplicationName: "ChartMedsInterface",
		IsAccessible: emar,
		Alias: "Chart Meds (EMAR)",
	};
	const ePayAccessRecord = {
		ApplicationID: 22,
		ApplicationName: "StaxInterface",
		IsAccessible: ePay,
		Alias: "ePay (Stax)",
	};

	// console.group(`Get User Apps`);
	// console.log("legacy", legacy);
	// console.log("tracker", tracker);
	// console.log("trackerAccess", trackerAccess);
	// console.log("legacyAccess", legacyAccess);
	// console.log("emarAccess", emarAccess);
	// console.groupEnd();

	return {
		trackerAccess: { ...trackerAccess, Alias: "Care Tracker" },
		legacyAccess: { ...legacyAccess, Alias: "Senior Care EHR" },
		emarAccess: { ...emarAccess, Alias: "Chart Meds (EMAR)" },
		ePayAccess: { ...ePayAccessRecord, Alias: "ePay (Stax)" },
	};
};

const processUserAppAccess = (appAccess = {}) => {
	const tracker = {
		ApplicationId: appIDs["AdvantageTracker"],
		ApplicationName: "AdvantageTracker",
		IsAccessible: appAccess?.isTrackerEnabled ?? false,
	};
	const legacy = {
		ApplicationId: appIDs["SeniorCareVB"],
		ApplicationName: "SeniorCareVB",
		IsAccessible: appAccess?.isLegacyEnabled ?? true,
	};
	const emar = {
		ApplicationId: appIDs["Emar"],
		ApplicationName: "ChartMedsInterface",
		IsAccessible: appAccess?.isEmarEnabled ?? false,
	};
	const ePay = {
		ApplicationId: appIDs["ePay"],
		ApplicationName: "StaxInterface",
		IsAccessible: appAccess?.isEPayEnabled ?? false,
	};

	return {
		trackerAccess: tracker,
		legacyAccess: legacy,
		emarAccess: emar,
	};
};

/**
 * Returns a list of 'ApplicationByFacility' records that are enabled for the 'ApplicationID' provided.
 * @returns {Array|Null} - Returns an array of records OR null if NO matching records found.
 */
const getFacilityAccessByApp = async (token, appID = 2) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByFacility });
	url += "&" + new URLSearchParams({ IsAccessible: true });
	url += "&" + new URLSearchParams({ ApplicationID: appID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};
/**
 * Returns list of facility access records.
 * @returns {Array|Null} - Returns an array of records OR null if NO matching records found.
 */
const getFacilityAppAccessList = async (token, appID) => {
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByFacility });
	url += "&" + new URLSearchParams({ IsAccessible: true });
	url += "&" + new URLSearchParams({ ApplicationID: appID });
	url += "&" + new URLSearchParams({ index: 0 });
	url += "&" + new URLSearchParams({ rows: 100 });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

/**
 * Fetches a list of ALL facilities w/ access to a given app.
 * @param {String} token - Auth token
 * @param {Number} appId - Numeric app ID.
 * @returns {String[]} - Returns an array of facilityIDs w/ access to a given app.
 */
const getAppAccessForAllFacilities = async (token, appId) => {
	let url = currentEnv.base + apps.get.accessForAllFacilities;
	url += "?" + new URLSearchParams({ applicationId: appId });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Fetches app access for ALL facilities & ALL apps.
 * @param {String} token - Auth token
 * @returns {Object} - Returns object of access lists for each app by property
 */
const getAllAppsAccessForAllFacilities = async (token) => {
	const [legacy, tracker, portal] = await Promise.all([
		getAppAccessForAllFacilities(token, 1),
		getAppAccessForAllFacilities(token, 2),
		getAppAccessForAllFacilities(token, 3),
	]);

	return {
		legacyAccess: legacy,
		trackerAccess: tracker,
		portalAccess: portal,
	};
};

/**
 * Fetches a facility's 'ApplicationByFacility' access record.
 * - This is the 'GET' version, but does support query params such as: 'ApplicationID' and 'FacilityID'
 * @returns {Object|Array|Null} - Returns the object record (destructured from its array), if available OR an empty array.
 */
const getFacilityAccessRecord = async (token, appID = 2, facilityID) => {
	let url = currentEnv.base + apps.get.byFacility;
	url += "?" + new URLSearchParams({ ApplicationID: appID });
	url += "&" + new URLSearchParams({ FacilityID: facilityID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const { Data } = await request.json();
		if (!isEmptyArray(Data)) return Data?.[0];
		return Data;
	} catch (err) {
		console.log(`❌ Oops! Error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches a facility's 'ApplicationByFacility' access record.
 * - This is the "POST" v2 of 'getFacilityAccessRecord'
 * - This API allows a little bit more control over query parameters & request criteria
 * @returns {Object|Array|Null} - Returns the object record (destructured from its array), if available OR an empty array.
 */
const getFacilityAccessRecord2 = async (token, appID = 2, facilityID) => {
	let url = currentEnv.base + apps.get.byFacility2;
	url += "?" + new URLSearchParams({ ApplicationID: appID });
	url += "&" + new URLSearchParams({ FacilityID: facilityID });
	url += "&" + new URLSearchParams({ index: 0, rows: 10 });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const { Data } = await request.json();
		if (!isEmptyArray(Data)) return Data[0];
		return Data;
	} catch (err) {
		console.log(`❌ Oops! Error occurred:`, err);
		return err.message;
	}
};

/**
 * Fetches a given facility's ApplicationByFacility record (for a single appID)
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid
 * @param {Number} appID - Numeric 'ApplicationID' value
 * @returns {Array} - Returns array of ApplicationByFacility records matching criteria
 */
const getFacilityAppAccessByApp = async (token, facilityID, appID) => {
	if (isEmptyVal(facilityID) || isEmptyVal(appID)) return {};
	let url = currentEnv.base + generic.get2;
	url += "?" + new URLSearchParams({ ...params.appsByFacility });
	url += "&" + new URLSearchParams({ ApplicationID: appID });
	url += "&" + new URLSearchParams({ FacilityID: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log("AppAccessByApp", response?.Data);
		return response?.Data?.[0] ?? {};
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

/**
 * Saves changes to an 'ApplicationByFacility' record that changes a facility's app access.
 * - Updates whether a facility has access to a specific app.
 * @param {Object} accessRecord - an 'ApplicationByFacility' record that covers a facility's access to an ALA App.
 */
const saveFacilityAccessRecord = async (token, accessRecord = {}) => {
	let url = currentEnv.base + apps.save.byFacility;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(accessRecord),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log("An error happened", err);
		return err.message;
	}
};

/**
 * Saves a single 'ApplicationByUser' access record to the database.
 */
const saveUserAccessByApp = async (token, accessRecord = {}) => {
	let url = currentEnv.base + apps.save.byUser;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(accessRecord),
		});
		const response = await request.json();
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// get disabled app access reason
// ##TODOS:
// - Might need to remove 'getFacilityAccessRecord()':
// 		- Since we're not using the 'AppsByFacility' records as override
const getAppAccessDeniedReason = async (token, userVals = {}) => {
	const { userID, facilityID, appID } = userVals;
	const [facilityAccess, userAccess] = await Promise.all([
		getFacilityAccessRecord(token, appID, facilityID),
		getUserAccessRecord(token, appID, userID),
	]);
	const facilityApp = facilityAccess; // object
	const userApp = userAccess?.[0]; // object

	console.group("App Access Denied Reason");
	console.log("facilityAccess:", facilityAccess);
	console.log("userAccess:", userAccess);
	console.groupEnd();

	const isUserEnabled = userApp?.IsAccessible;
	const isFacilityEnabled = facilityApp?.IsAccessible;

	switch (true) {
		// Facility: DISABLED
		case !isFacilityEnabled: {
			return {
				reason: `Your facility does NOT have access to this app.`,
				cta: `Please contact ALA Support.`,
			};
		}
		// Facility: ENABLED/UNKNOWN, User: DISABLED
		case !isUserEnabled: {
			return {
				reason: `Your account does NOT have access to this app.`,
				cta: `Please contact your administrator.`,
			};
		}

		default:
			return {
				reason: `Access to this app is disabled`,
				cta: `Contact support w/ questions`,
			};
	}
};

////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// APP ACCESS BY USER ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

/**
 * Extracts every 'UserID' that has access to a specific app, when given a list of 'access records'.
 * @returns {Array} - Returns an array of 'UserIDs'
 */
const getUserAccessList = (accessRecords = []) => {
	return accessRecords.reduce((accessIDs, record) => {
		const { UserID } = record;
		accessIDs.push(UserID);
		return accessIDs;
	}, []);
};
/**
 * Fetches x number of users and user access records for a given app.
 * @returns {Object} - Returns an object w/ the users & access records.
 */
const getUserAccess = async (token, appID = 2, index = 0, rows = 1000) => {
	const { AdvantageTracker, SeniorCareVB } = appIDs;
	const [trackerAccess, legacyAccess, usersList] = await Promise.all([
		getUserAccessByApp(token, AdvantageTracker),
		getUserAccessByApp(token, SeniorCareVB),
		getAllUsers(token, {
			index,
			rows,
		}),
	]);

	return {
		trackerAccessList: [...getUserAccessList(trackerAccess)],
		legacyAccessList: [...getUserAccessList(legacyAccess)],
		usersList: [...processUserList(usersList)],
	};
};

////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// APP ACCESS UTILS /////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

// finds target app record or provides default fallback record
const findAppInFacilityAccess = (appID, appByFacility = []) => {
	const appMatch = appByFacility.filter((x) => x?.ApplicationID === appID);
	const appRecord = appMatch?.[0] ?? {
		ApplicationByFacilityID: 0,
		ApplicationID: appID,
		FacilityID: null,
		IsAccessible: false,
	};

	return appRecord;
};

/**
 * Fetches a list of facilityIDs w/ access to a given app.
 * @param {String} token - Auth token
 * @returns {String[]} - Returns an array of facilityIDs w/ access to the given app.
 */
const getFacilityAccess = async (token) => {
	const accessRecords = await getAppAccessForAllFacilities(token, 2);
	if (!isEmptyArray(accessRecords)) {
		return accessRecords;
	} else {
		return [];
	}
};

// fetches all facilities' app access (ie list of facilities with app access)
const getAllFacilitiesAccessToApps = async (token) => {
	const [trackerAccess, emarAccess, ePayAccess] = await Promise.all([
		getAppAccessForAllFacilities(token, 2),
		getAppAccessForAllFacilities(token, 19),
		getAppAccessForAllFacilities(token, 22),
	]);
	if (!isEmptyArray(trackerAccess)) {
		return {
			trackerAccess,
			emarAccess,
			ePayAccess,
		};
	} else {
		return {
			trackerAccess: [],
			emarAccess: [],
			ePayAccess: [],
		};
	}
};

/**
 * Iterate thru app-access records & create a list of facilities that have access to a specific app.
 * @param {Array} accessRecords - An array of records that indicate a facility's access to a specific app.
 */
const getFacilityAccessList = (accessRecords = []) => {
	return accessRecords.reduce((accessIDs, record) => {
		const { FacilityID } = record;
		accessIDs.push(FacilityID);
		return accessIDs;
	}, []);
};

/**
 * Model updater utils:
 * - User-Access updater
 * - Facility-Access updater
 * - App-Settings updater
 */

/**
 * Util for init & applying values to the 'AppByUserAccessModel'
 * @returns {Object} - Returns populated model.
 */
const updateUserAccessModel = (userAccessVals = {}) => {
	const base = new AppByUserAccessModel({
		appID: userAccessVals?.appID,
		userID: userAccessVals?.userID,
		isAccessible: userAccessVals?.isAccessible,
		modifiedBy: userAccessVals?.modifiedBy,
	});
	return base.getModel();
};

/**
 * Checks if a specific user's userID is listed in an application's access list.
 * @param {String} targetUserID - A user's 'UserID' and/or 'UserLoginID'
 * @param {Array} accessList - An array of 'UserID's and/or 'UserLoginID's that have access to an application.
 */
const doesUserHaveAppAccess = (targetUserID, accessList = []) => {
	const lowerList = accessList.map((x) => x.toLowerCase());
	const lowerUserID = targetUserID.toLowerCase();
	const existsInList =
		accessList.includes(targetUserID) || lowerList.includes(lowerUserID);

	return existsInList;
};

// fetches app access for botyh facility and user & returns the results
const getUserAndFacilityAppAccess = async (
	token,
	userID,
	facilityID,
	appID
) => {
	const [facilityList, userHasAccess] = await Promise.all([
		getAppAccessForAllFacilities(token, appID),
		checkUserAppAccess(token, userID, appID),
	]);

	const facilityHasAccess = facilityList.includes(facilityID);

	const bothHaveAccess = facilityHasAccess && userHasAccess;

	return {
		userHasAccess,
		facilityHasAccess,
		bothHaveAccess,
	};
};

// REDIRECT TO APP UTILS //

// determines target app's URL based of 'targetAppName' and the 'currentEnv'
const getBaseUrl = (targetAppName) => {
	switch (targetAppName) {
		case "CareTracker":
		case "Care Tracker":
		case "AdvantageTracker":
		case "Advantage Tracker": {
			const urls = SERVICES["AdvantageTracker"].url;
			const base = urls[getEnvName()];
			return base;
		}
		case "CareManager":
		case "Care Manager":
		case "EHR: Care Manager":
		case "SeniorCareEHR":
		case "Senior Care EHR":
		case "SeniorCareVB":
		case "Senior Care VB": {
			const urls = SERVICES["SeniorCareVB"].url;
			const base = urls[isDevOrTest() ? "test" : "prod"];
			return base;
		}

		default:
			return;
	}
};

/**
 * @param {String} targetAppName - The target application's base url.
 * @param {Object} data - Contains various data to be forwarded to the target such as:
 * @property {String} data.username - Target user's username
 * @property {String} data.password - Target user's password that's been base64 encoded.
 * @property {String} data.userID - Target user's userID string.
 * @property {String} data.token - Target user's security token
 *
 * - Updated 8/6/2021 at 10:40 AM:
 * 		- Now supports different environments and redirects to a different URL based off the environment and requested service
 */
const generateRedirectUrl = (targetAppName, data = {}) => {
	const { username, password, userID, token } = data;

	switch (targetAppName) {
		case "CareTracker":
		case "Care Tracker":
		case "AdvantageTracker":
		case "Advantage Tracker": {
			const base = getBaseUrl(targetAppName) + "/dashboard/daily";
			const url = generateURL(base, {
				username: username,
				password: password,
				token: token,
			});

			console.log("url", url);
			return url;
		}
		case "CareManager":
		case "Care Manager":
		case "EHR: Care Manager":
		case "SeniorCareEHR":
		case "Senior Care EHR":
		case "SeniorCareVB":
		case "Senior Care VB": {
			// ##TODOS:
			// - Possibly update 'generateURL()' to use requested key names for 'username' 'token' etc.????
			// used for Jon for testing purposes, remove later
			const customRedirects = [
				// "dandemo@aladvantage.com",
				// "sgore99@aladvantage.com",
			];

			// if 'customRedirects' (testing purposes) redirect to localHost
			if (customRedirects.includes(data.username)) {
				const base = `http://local.aladvantage.com/Account/Login.aspx`;
				const url = generateURL(base, {
					userID: userID,
					token: token,
				});
				return url;
			}

			const base = getBaseUrl(targetAppName);
			const url = generateURL(base, {
				userID: userID,
				token: token,
			});
			return url;
		}
		default:
			throw new Error(`❌ Ooops! Invalid application name:`, targetAppName);
	}
};

// MATCHER UTILS //

const matchAppAccessByAppID = (id, allApps = []) => {
	const match = allApps.filter((x) => x.ApplicationID === id);
	return match?.[0];
};
const matchAppAccessByUserEntryID = (entryID, allApps = []) => {
	const match = allApps.filter((x) => x.ApplicationByUserID === entryID);
	return match?.[0];
};

// application IDs
export { appIDs, appNames, appAliases, SERVICES };

// facility access requests
export {
	getFacilityAccessByApp,
	getFacilityAppAccessList,
	getFacilityAccessRecord,
	getFacilityAccessRecord2,
	saveFacilityAccessRecord,
	// fetches all facilities with access to an app
	getAppAccessForAllFacilities,
	getAllAppsAccessForAllFacilities,
	// both user & facility app access
	getUserAndFacilityAppAccess,
};

// facility app access NEW requests
export {
	assignFacilityAppAccess,
	unassignFacilityAppAccess,
	assignFacilityUsersAppAccess,
	unassignFacilityUsersAppAccess,
	checkFacilityUsersAppAccess,
	// custom wrapper handler
	saveFacilityAppAssignment,
	saveFacilityUsersAppAccess,
};

export {
	getFacilityAccess,
	getFacilityAccessList,
	getFacilityAppAccessByApp,
	getAllFacilitiesAccessToApps,
};

// user access requests
export {
	getUserAccessRecord,
	getUserAccessByApp,
	getUserAccess,
	getAppAccessByUser,
	saveUserAccessByApp,
	getAppAccessByUserID,
	// wrapper around XXXByUser and XXXByFacility
	getUserApps,
	checkUserAppAccess,
};

// user access utils
export { getUserAccessList, doesUserHaveAppAccess, getAppAccessDeniedReason };

// model-updater utils
export { updateUserAccessModel };

// redirect utils
export { generateRedirectUrl };

export {
	matchAppAccessByAppID,
	matchAppAccessByUserEntryID,
	findAppInFacilityAccess,
};
