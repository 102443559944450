import React, { useEffect, useState, useCallback } from "react";
import styles from "../../css/migration/MigrationPanel.module.scss";
import sprite from "../../assets/icons/data.svg";
import { PropTypes } from "prop-types";
import {
	matchUserFacilityByName,
	migrateFacilityUsers,
} from "../../helpers/utils_facility";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import {
	getUsersByFacility,
	processUserList,
	getAllUserIDsFromStr,
} from "../../helpers/utils_user";
import { migrationLogger } from "../../helpers/utils_processing";
import { red } from "../../helpers/utils_styles";
import {
	MIGRATION_STATES as migrationStates,
	getProcessStatus,
} from "../../helpers/utils_migration";
import { isToday } from "date-fns";
// components
import Divider from "../forms/Divider";
import Dialog from "../shared/Dialog";
import ButtonSM from "../shared/ButtonSM";
import MigrationPanelHeader from "./MigrationPanelHeader";
import MigrationPanelSelector from "./MigrationPanelSelector";
import MigrationPanelSummary from "./MigrationPanelSummary";
import MigrationPanelActions from "./MigrationPanelActions";
import MigrationScheduler from "./MigrationScheduler";
import MigrationStatus from "./MigrationStatus";

// ##TODOS: - 8/23/2021
// - Improve 'MIGRATION STATUS' handling
// 		- Use newly added handlers in 'utils_migration.js'

const customCSS = {
	cancelBtn: {
		padding: ".5rem 1.4rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.5rem",
		fontWeight: "600",
		marginRight: "1rem",
	},
	confirmBtn: {
		padding: ".5rem 1.4rem",
		backgroundColor: red[600],
		color: red[50],
		fontSize: "1.5rem",
		fontWeight: "600",
	},
};

const headerDetails = {
	title: `Migrate Facility & Users`,
	desc: `Easily migrate an entire facility's users to our new application
          called the`,
	app: `Advantage Tracker`,
	appsList: [
		`Advantage Tracker (Care Tracker)`,
		`Admin Portal (Care Portal - SSO)`,
		`Senior Care Manager (Care Manager)`,
	],
	about: `Once migration is completed every one of your users will have access to the complete ALA Apps Suite including:`,
};

const getConfirmMsg = (facilityName, selectedUsers = [], totalUsers = []) => {
	let msg = `You're about to migrate `;
	if (isEmptyArray(selectedUsers)) {
		msg += `${totalUsers?.length} users from `;
		msg += `${facilityName}`;
		return msg;
	} else {
		msg += `${selectedUsers?.length} out of ${totalUsers?.length} users from `;
		msg += `${facilityName}`;
		return msg;
	}
};

const MigrateIcon = () => {
	return (
		<div className={styles.MigrateIcon}>
			<svg className={styles.MigrateIcon_icon}>
				<use xlinkHref={`${sprite}#icon-cloud-storage-3`}></use>
			</svg>
			<svg className={styles.MigrateIcon_icon}>
				<use xlinkHref={`${sprite}#icon-data-in-both-directions-2`}></use>
			</svg>
			<svg className={styles.MigrateIcon_icon}>
				<use xlinkHref={`${sprite}#icon-cloud-storage-2`}></use>
			</svg>
		</div>
	);
};

const MigrationPanel = ({
	currentUser,
	currentFacility,
	dispatchToState,
	dispatchAlert,
}) => {
	const [selectedFacility, setSelectedFacility] = useState(() => {
		return currentFacility?.communityName;
	});
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [migrateAll, setMigrateAll] = useState(true);
	// not currently supported - date MUST be NULL (for immediate migration)
	const [scheduleFor, setScheduleFor] = useState(
		// format(new Date(), "MM/DD/YYYY")
		null
	);
	const [migrationData, setMigrationData] = useState({});
	const [migrationStatus, setMigrationStatus] = useState("NOT_MIGRATED");
	// migration info

	// handles facility dropdown selector & syncs to global-store
	const handleFacility = (name, val) => {
		if (isEmptyVal(val)) {
			setSelectedFacility(val);
			return dispatchToState({ type: "CLEAR_FACILITY" });
		}
		return setSelectedFacility(val);
	};

	const handleUsers = (selections) => {
		if (!isEmptyArray(selections)) {
			return setSelectedUsers(selections);
		} else {
			return setSelectedUsers(selections);
		}
	};

	const handleMigrateAll = (e) => {
		const { checked } = e.target;
		setMigrateAll(checked);
	};

	const handleScheduleFor = (name, val) => {
		setScheduleFor(val);
	};

	const startMigration = () => {
		setShowConfirmModal(true);
		setMigrationStatus("PROCESSING");
	};

	// dispatch alert & update STATUS
	const updateMigrationStatus = (wasSuccessFul = false) => {
		if (!wasSuccessFul) {
			// failed
			setMigrationStatus("FAILED");
			return dispatchAlert("ERROR", {
				heading: "There was an error!",
				subheading: "Migration Failed.",
			});
		} else if (isEmptyVal(scheduleFor)) {
			// completed, for today
			setMigrationStatus("COMPLETED");
			return dispatchAlert("SUCCESS", {
				heading: "Migration Completed!",
				subheading: "Facility migration has finished.",
			});
		} else if (!isToday(scheduleFor)) {
			// scheduled
			setMigrationStatus("SCHEDULED");
			return dispatchAlert("INFO", {
				heading: "Migration Scheduled!",
				subheading: "Facility migration has been scheduled.",
			});
		}
	};

	const confirmMigration = async () => {
		const { token } = currentUser;
		const { facilityID, communityName, users } = currentFacility;
		const scheduledDate = !scheduleFor
			? null
			: new Date(scheduleFor).toISOString();

		// if 'migrateAll' pass []
		// otherwise extract selected userIDs
		if (migrateAll) {
			const userIDs = [];
			const wasSuccessFul = await migrateFacilityUsers(
				token,
				facilityID,
				scheduledDate,
				userIDs
			);
			migrationLogger(communityName, users?.length ?? 0);
			console.log(`Migration Response(all):`, wasSuccessFul);

			setShowConfirmModal(false);
			return updateMigrationStatus(wasSuccessFul);
		} else {
			const userIDs = getAllUserIDsFromStr(selectedUsers);
			const wasSuccessFul = await migrateFacilityUsers(
				token,
				facilityID,
				scheduledDate,
				userIDs
			);

			migrationLogger(communityName, userIDs?.length ?? 0);
			console.log(`Migration Response(some):`, wasSuccessFul);

			setShowConfirmModal(false);
			return updateMigrationStatus(wasSuccessFul);
		}
	};

	const cancelMigration = (e) => {
		setSelectedUsers([]);
		setSelectedFacility("");
		setShowConfirmModal(false);
		setMigrateAll(false);
	};

	const changeFacility = useCallback(async (selection) => {
		const { token } = currentUser;
		const record = matchUserFacilityByName(selection, currentUser?.facilities);
		const facilityUsers = await getUsersByFacility(token, record?.facilityID);

		dispatchToState({
			type: "SYNC_FACILITY_FOR_MIGRATION",
			data: {
				facilityRecord: record,
				facilityUsers: [...processUserList(facilityUsers)],
			},
		});

		// fetch facility details here
		dispatchAlert("SUCCESS", {
			heading: `Loaded Facility`,
			subheading: `Loaded ${selection}`,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetches migration status & info

	// syncs 'facility' to global state upon selection-change
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(selectedFacility)) {
			changeFacility(selectedFacility);
		}

		return () => {
			isMounted = false;
		};
	}, [changeFacility, selectedFacility]);

	// fetch facility migration status
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		const getMigrationInfo = async () => {
			const { token } = currentUser;
			const { facilityID } = currentFacility;
			// get info
			const status = await getProcessStatus(token, facilityID);
			console.log("status.status", status.status);

			if (!isEmptyObj(status)) {
				setMigrationStatus(migrationStates?.[status?.status] ?? "Not-Migrated");
				return setMigrationData(status);
			} else {
				return setMigrationData({});
			}
		};

		if (currentFacility?.facilityID || !isEmptyVal(selectedFacility)) {
			getMigrationInfo();
		}

		return () => {
			isMounted = false;
		};
	}, [currentFacility, currentUser, selectedFacility]);

	return (
		<>
			<div className={styles.MigrationPanel}>
				<div className={styles.MigrationPanel_left}>
					<MigrationPanelHeader
						app={headerDetails?.app}
						appsList={headerDetails.appsList}
						title={headerDetails?.title}
						desc={headerDetails?.desc}
						about={headerDetails?.about}
					/>
					<Divider />
					<div className={styles.MigrationPanel_left_main}>
						<MigrationPanelSelector
							key={`--${currentFacility?.facilityID}--`}
							currentFacility={currentFacility}
							currentUser={currentUser}
							selectedUsers={selectedUsers}
							selectedFacility={selectedFacility}
							handleFacility={handleFacility}
							handleUsers={handleUsers}
							handleCheckbox={handleMigrateAll}
							migrateAll={migrateAll}
						/>
						<MigrationScheduler
							scheduleFor={scheduleFor}
							handleDate={handleScheduleFor}
						/>

						{!isEmptyVal(currentFacility?.facilityID) && (
							<MigrationPanelSummary
								currentFacility={currentFacility}
								currentUser={currentUser}
							/>
						)}
						<MigrationPanelActions
							currentFacility={currentFacility}
							cancelMigration={cancelMigration}
							startMigration={startMigration}
						/>
					</div>
				</div>
				<div className={styles.MigrationPanel_right}>
					<div className={styles.MigrationPanel_right_icon}>
						<MigrateIcon />
						<MigrationStatus
							key={`Migration:${migrationData?.status || migrationStatus}`}
							status={migrationStatus}
						/>
					</div>
				</div>
			</div>

			{showConfirmModal && (
				<Dialog
					type="ERROR"
					icon="ERROR"
					title="Confirm Migration?"
					subheading={getConfirmMsg(
						currentFacility?.communityName,
						selectedUsers,
						currentFacility?.users
					)}
					closeModal={() => setShowConfirmModal(false)}
				>
					<div className={styles.MigrationPanel_confirm}>
						<ButtonSM
							type="button"
							customStyles={customCSS.cancelBtn}
							handleClick={cancelMigration}
						>
							Cancel
						</ButtonSM>
						<ButtonSM
							type="button"
							customStyles={customCSS.confirmBtn}
							handleClick={confirmMigration}
						>
							Yes
						</ButtonSM>
					</div>
				</Dialog>
			)}
		</>
	);
};

export default MigrationPanel;

MigrationPanel.defaultProps = {};

MigrationPanel.propTypes = {};
