import React, { useEffect, useState } from "react";
import styles from "../../css/user/ChangeQuestions.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { blueGrey } from "../../helpers/utils_styles";
import { useForm } from "../../utils/useForm";
import {
	createSecurityQAModels,
	formatSecurityQuestions,
	saveUserSecurityQuestionAndAnswers,
	getRemainingQuestions,
	saveUserResetPreference,
} from "../../helpers/utils_security";
// components
import Divider from "../forms/Divider";
import ButtonSM from "../shared/ButtonSM";
import ChangeQuestionAndAnswer from "./ChangeQuestionAndAnswer";

const alertMsgs = {
	SUCCESS: {
		heading: `Success!`,
		subheading: `Your changes have been saved.`,
	},
	ERROR: {
		heading: `Error!`,
		subheading: `There was an error. Please, try again.`,
	},
};

const customCSS = {
	dropdown: {
		width: "100%",
	},
	divider: {
		opacity: ".4",
		margin: "2rem 0",
	},
	saveBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: "5rem",
		fontSize: "1.5rem",
		fontWeight: "500",
		backgroundColor: blueGrey[700],
		color: blueGrey[100],
	},
	cancelBtn: {
		padding: ".7rem 1.3rem",
		borderRadius: "5rem",
		fontSize: "1.5rem",
		fontWeight: "500",
		backgroundColor: "transparent",
		color: blueGrey[700],
		marginRight: "1rem",
	},
};

// checks if any questions or answers don't match the original q & a's
const changesWereMade = (vals = {}, userQAs = {}) => {
	const {
		// original user questions
		securityQuestion1: userQ1,
		securityQuestion2: userQ2,
		securityQuestion3: userQ3,
		// original user answers
		securityAnswer1: userA1,
		securityAnswer2: userA2,
		securityAnswer3: userA3,
	} = userQAs;
	const {
		// new changes user questions
		securityQuestion1: q1,
		securityQuestion2: q2,
		securityQuestion3: q3,
		// new changes user answers
		securityAnswer1: a1,
		securityAnswer2: a2,
		securityAnswer3: a3,
	} = vals;

	const hasQuestionChange = q1 !== userQ1 || q2 !== userQ2 || q3 !== userQ3;
	const hasAnswerChange = a1 !== userA1 || a2 !== userA2 || a3 !== userA3;

	console.group("Were changes made? ", hasQuestionChange || hasAnswerChange);
	console.log("Question was changed:", hasQuestionChange);
	console.log("Answer was changed:", hasAnswerChange);
	console.groupEnd();

	return hasQuestionChange || hasAnswerChange;
};

const hasExistingQAs = (userQAs = {}) => {
	const {
		// original user questions
		securityQuestion1: userQ1,
		securityQuestion2: userQ2,
		securityQuestion3: userQ3,
		// original user answers
		securityAnswer1: userA1,
		securityAnswer2: userA2,
		securityAnswer3: userA3,
	} = userQAs;

	const hasQuestions =
		!isEmptyVal(userQ1) && !isEmptyVal(userQ2) && !isEmptyVal(userQ3);

	const hasAnswers =
		!isEmptyVal(userA1) && !isEmptyVal(userA2) && !isEmptyVal(userA3);

	return hasQuestions && hasAnswers;
};

const ChangeQuestions = ({
	securityQuestions = [],
	userQAs = {},
	currentUser = {},
	dispatchAlert,
	dispatchToState,
}) => {
	const { formState, setFormState, handleChange, handleReset } = useForm({
		// qa #1
		securityQuestion1: userQAs?.securityQuestion1 ?? "",
		securityAnswer1: userQAs?.securityAnswer1 ?? "",
		// qa #2
		securityQuestion2: userQAs?.securityQuestion2 ?? "",
		securityAnswer2: userQAs?.securityAnswer2 ?? "",
		// qa #3
		securityQuestion3: userQAs?.securityQuestion3 ?? "",
		securityAnswer3: userQAs?.securityAnswer3 ?? "",
	});
	const { values } = formState;
	// formatted security questions as string
	const [strQuestions, setStrQuestions] = useState([
		...formatSecurityQuestions(securityQuestions),
	]);
	const [remainingQuestions, setRemainingQuestions] = useState([
		...formatSecurityQuestions(securityQuestions),
	]);
	// tracks changes to questions & answers
	const [hasChanges, setHasChanges] = useState(false);

	// handles dropdowns
	const handleSecurityQuestion = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	const saveSecurityChanges = async (e) => {
		const { token, userID } = currentUser;
		const securityQAModel = createSecurityQAModels(securityQuestions, values);
		const resetPreferenceID = 3;

		// added reset preference designation on 1/3/2022
		const [savedQAs, savedPreference] = await Promise.all([
			saveUserSecurityQuestionAndAnswers(token, userID, securityQAModel),
			saveUserResetPreference(token, userID, resetPreferenceID),
		]);

		// otherwise create entry
		if (savedQAs) {
			console.log(`✅ Response:`, savedQAs);
			dispatchToState({
				type: "UPDATE_USER_QUESTIONS",
				data: {
					userQAVals: values,
				},
			});
			return handleAlert("SUCCESS");
		} else {
			console.log(`❌ Ooops!:`, savedQAs);
			return handleAlert("ERROR");
		}
	};

	const cancelSecurityChanges = (e) => {
		handleReset(e);
	};

	// dispatch alert!!
	const handleAlert = (type = "SUCCESS") => {
		return dispatchAlert(type, alertMsgs[type]);
	};

	// updates 'remainingQuestions' each time a selection is made
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		setRemainingQuestions([...getRemainingQuestions(values, strQuestions)]);

		return () => {
			isMounted = false;
		};
	}, [strQuestions, values]);

	// listens for changes & enables/disables 'Save' button accordingly
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		setHasChanges(false);

		if (changesWereMade(values, userQAs)) {
			setHasChanges(true);
		}

		return () => {
			isMounted = false;
		};
	}, [userQAs, values]);

	return (
		<div className={styles.ChangeQuestions}>
			<div className={styles.ChangeQuestions_header}>
				<p className={styles.ChangeQuestions_header_desc}>
					Security Questions are used in case of lock-out and password resets
					when a user does NOT have a valid email address to receive resets.
					Last resort resets require admin intervention.
				</p>
				<p className={styles.ChangeQuestions_header_notice}>
					NOTE: Please select one of the existing security question options. You
					cannot create your own custom questions.
				</p>
				<p className={styles.ChangeQuestions_header_hint}>
					Click <span role="img">✏️</span> button to change or update questions
					and answers.
				</p>
			</div>
			<div className={styles.ChangeQuestions_questions}>
				{/* #1: QUESTION & ANSWER */}
				<div className={styles.ChangeQuestions_questions_instance}>
					<ChangeQuestionAndAnswer
						questionNum={1}
						vals={values}
						handleSecurityQuestion={handleSecurityQuestion}
						handleAnswer={handleChange}
						allQuestions={strQuestions}
						questions={strQuestions}
					/>
				</div>
				<Divider customStyles={customCSS.divider} />

				{/* #2: QUESTION & ANSWER */}
				<div className={styles.ChangeQuestions_questions_instance}>
					<ChangeQuestionAndAnswer
						questionNum={2}
						vals={values}
						handleSecurityQuestion={handleSecurityQuestion}
						handleAnswer={handleChange}
						allQuestions={strQuestions}
						questions={remainingQuestions}
					/>
				</div>
				<Divider customStyles={customCSS.divider} />

				{/* #3: QUESTION & ANSWER */}
				<div className={styles.ChangeQuestions_questions_instance}>
					<ChangeQuestionAndAnswer
						questionNum={3}
						vals={values}
						handleSecurityQuestion={handleSecurityQuestion}
						handleAnswer={handleChange}
						allQuestions={strQuestions}
						questions={remainingQuestions}
					/>
				</div>
			</div>
			{/* ACTION BUTTONS */}
			<div className={styles.ChangeQuestions_actions}>
				<ButtonSM
					handleClick={cancelSecurityChanges}
					customStyles={customCSS.cancelBtn}
				>
					<span>Cancel</span>
				</ButtonSM>
				<ButtonSM
					isDisabled={!hasChanges}
					customStyles={customCSS.saveBtn}
					handleClick={saveSecurityChanges}
				>
					<span>Save Changes</span>
				</ButtonSM>
			</div>
		</div>
	);
};

export default ChangeQuestions;

ChangeQuestions.defaultProps = {};

ChangeQuestions.propTypes = {
	securityQuestions: PropTypes.arrayOf(PropTypes.object),
	userQAs: PropTypes.object, // 'values' for a user security questions & answers
	currentUser: PropTypes.object,
};
