import React, { useState, useEffect } from "react";
import styles from "../../css/residents/ResidentSearchDetails.module.scss";
import { PropTypes } from "prop-types";
import {
	getResidentAdmissionDate,
	getResidentDetails,
	getResidentDetails2,
	getResidentName,
	getResidentUnitType,
	transferResidentToFacility,
} from "../../helpers/utils_residents";
import { format, isToday } from "date-fns";
import {
	matchFacilityByID,
	matchFacilityByName,
} from "../../helpers/utils_facility";
import {
	isEmptyArray,
	isEmptyObj,
	isEmptyVal,
} from "../../helpers/utils_types";
import { useForm } from "../../utils/useForm";
import { red } from "../../helpers/utils_styles";
// components
import CopyText from "../forms/CopyText";
import ResidentStatus from "./ResidentStatus";
import UnitTypeBadge from "./UnitTypeBadge";
import TransferResident from "./TransferResident";
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";

const getResidentFacility = (facilityID, facilities = []) => {
	const record = matchFacilityByID(facilityID, facilities);
	console.log("record(fac):", record);

	return !isEmptyObj(record) ? record : {};
};

const getFacilityName = (record = {}) => {
	const name =
		record?.communityName ?? record?.CommunityName ?? record?.name ?? "";

	return name;
};

const getResidentCommunityDetails = (facilityID, facilities = []) => {
	const facility = getResidentFacility(facilityID, facilities);
	const { communityName = "" } = facility;

	return communityName;
};

const ArchiveBadge = ({ resident }) => {
	if (!resident?.isArchived) {
		return null;
	}

	return (
		<div className={styles.ArchiveBadge}>
			<div className={styles.ArchiveBadge_text}>Archived</div>
		</div>
	);
};

const SectionDesc = ({ children }) => {
	return <div className={styles.SectionDesc}>{children}</div>;
};

const ArchiveButton = ({ handleArchive, isDisabled = false }) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			onClick={handleArchive}
			className={styles.ArchiveButton}
		>
			Archive
		</button>
	);
};
const UnarchiveButton = ({ handleUnarchive, isDisabled = false }) => {
	return (
		<button
			type="button"
			disabled={isDisabled}
			onClick={handleUnarchive}
			className={styles.UnarchiveButton}
		>
			Un-Archive
		</button>
	);
};

const ResidentSearchDetails = ({
	resident = {},
	currentUser,
	currentFacility,
	facilities = [],
	parentsMap = {},
	globalState = {},
	dispatchAlert,
}) => {
	const [residentFacility, setResidentFacility] = useState(
		getResidentFacility(resident?.facilityID, facilities)
	);
	const [residentDetails, setResidentDetails] = useState({});
	const { formState, setFormState, handleChange, handleReset } = useForm({
		residentID: "",
		residentName: getResidentName(resident),
		targetFacilityID: "",
		targetFacilityName: "",
		parentFacilityName: "",
		targetDate: format(new Date(), "MM/DD/YYYY"),
	});
	const { values } = formState;
	const [isArchived, setIsArchived] = useState(resident?.Archive ?? false);
	// other resident info
	// const [resident]
	// target facility & it's parent, if applicable
	const [target, setTarget] = useState({
		child: {},
		parent: {},
	});

	const handleSelection = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	const handleFacilitySelect = (name, val) => {
		if (isEmptyVal(val)) {
			setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					targetFacilityID: "",
				},
			});
		} else {
			const record = matchFacilityByName(val, currentUser.facilities);
			setFormState({
				...formState,
				values: {
					...values,
					[name]: val,
					targetFacilityID: record?.facilityID,
				},
			});
		}
	};

	const archiveResident = async () => {
		//
		//
	};
	const unarchiveResident = async () => {
		//
		//
	};

	// inits 'resident-transfer' to new facility
	const moveResidentToFacility = async () => {
		const { token } = currentUser;
		const { residentID, targetFacilityID, targetDate } = values;
		const date =
			isToday(targetDate) || isEmptyVal(targetDate)
				? null
				: new Date(targetDate).toISOString();

		const wasTransferred = await transferResidentToFacility(
			token,
			residentID,
			targetFacilityID,
			date
		);

		if (wasTransferred) {
			return dispatchAlert("SUCCESS", {
				heading: `Success!`,
				subheading: `Resident was transferred!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `There was an error. Try again later, please.`,
			});
		}
	};

	const getDetails = async () => {
		const { token } = currentUser;
		const { residentID } = resident;
		// resident info
		const info = await getResidentDetails2(token, residentID);
		console.log("info", info);

		if (!isEmptyObj(info)) {
			const residentCommunity = getResidentFacility(
				resident?.facilityID,
				facilities
			);
			setResidentFacility(residentCommunity);
			return setResidentDetails({
				...info,
				contacts: info?.contacts ?? [],
				loa: info?.loa ?? [],
			});
		} else {
			setResidentDetails({});
			return setResidentFacility({});
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		// if (!isEmptyObj(resident) && !isEmptyVal(resident?.facilityID)) {
		// setResidentFacility(getResidentFacility(resident?.facilityID, facilities));
		// }

		getDetails();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.ResidentSearchDetails}>
			<header className={styles.ResidentSearchDetails_header}>
				<h2 className={styles.ResidentSearchDetails_header_title}>
					Resident: <b>{getResidentName(resident)}</b>{" "}
					<ArchiveBadge resident={resident} />
				</h2>
				<div className={styles.ResidentSearchDetails_header_id}>
					<span>Resident ID:</span>
					<CopyText text={resident?.residentID} />
				</div>

				<div className={styles.ResidentSearchDetails_header_dateCreated}>
					Admission Date: <b>{getResidentAdmissionDate(resident)}</b>
				</div>
				{resident?.isArchived && (
					<div className={styles.ResidentSearchDetails_header_archiveDate}>
						Archive Date:{" "}
						<b>{format(resident?.archiveDate, "M/DD/YYYY h:mm A")}</b>
					</div>
				)}
				<div className={styles.ResidentSearchDetails_header_status}>
					<span>Status:</span>
					<ResidentStatus resident={resident} />
				</div>
				<div className={styles.ResidentSearchDetails_header_unitType}>
					{/* UNIT TYPE */}
					<span className={styles.ResidentSearchDetails_header_unitType_label}>
						Unit Type:
					</span>
					<span>
						<UnitTypeBadge unitType={getResidentUnitType(resident)} />
					</span>
				</div>
			</header>
			<div className={styles.ResidentSearchDetails_main}>
				<div className={styles.ResidentSearchDetails_main_title}>
					Other Details
				</div>
				<div className={styles.ResidentSearchDetails_main_data}>
					<span>Facility Name:</span>
					<CopyText
						text={getResidentCommunityDetails(resident?.facilityID, facilities)}
					/>
				</div>
				<div className={styles.ResidentSearchDetails_main_data}>
					<span>Facility ID:</span>
					<CopyText text={resident?.facilityID} />
				</div>
				<div className={styles.ResidentSearchDetails_main_data}>
					<span>Room Number:</span>
					<CopyText text={resident?.roomNum} />
				</div>
				<div className={styles.ResidentSearchDetails_main_data}>
					<span>Date of Birth:</span>
					<CopyText text={resident?.dateOfBirth} />
				</div>
				<div className={styles.ResidentSearchDetails_main_data}>
					<span>Medicare Number:</span>
					<CopyText text={resident?.medicareNumber ?? "N/A"} />
				</div>
				<div className={styles.ResidentSearchDetails_main_data}>
					<span>Yardi Number:</span>
					<CopyText text={resident?.yardiNumber ?? "N/A"} />
				</div>
			</div>
			<Divider />
			{/* CONTACTS */}
			<div className={styles.ResidentSearchDetails_other}>
				<div className={styles.ResidentSearchDetails_other_title}>Contacts</div>
				<div className={styles.ResidentSearchDetails_other_contacts}>
					{!isEmptyArray(residentDetails?.contacts) &&
						residentDetails?.contacts.map((entry, index) => (
							<div
								key={`${entry.ID}-${index}`}
								className={styles.ResidentSearchDetails_other_contacts_entry}
							>
								<span>
									<b>Name:</b> {entry?.FirstName} {entry?.LastName}
								</span>
								<span>
									<b>Email:</b> {entry?.Email ?? "Unknown"}
								</span>
								<span>
									<b>Relationship:</b> {entry.Relationship}
								</span>
								<span>
									<b>Primary #:</b> {entry.PhonePrimary}
								</span>
								<span>
									<b>Contact Type:</b> {entry.EmergencyContactType}
								</span>
							</div>
						))}
				</div>
			</div>
			<Divider />

			{/* EDIT RESIDENT DETAILS */}
			<div className={styles.ResidentSearchDetails_form}>
				<FormSection
					icon="building"
					title="Transfer Resident"
					hoverText="Transfer resident to different community"
				>
					<SectionDesc>
						Transfer a resident & all their data from community to another
						community.
					</SectionDesc>
					<div className={styles.ResidentDetails_form_label}>
						<TransferResident
							vals={values}
							currentUser={currentUser}
							currentResident={resident}
							currentFacility={currentFacility}
							handleSelection={handleSelection}
							handleFacilitySelect={handleFacilitySelect}
							moveResidentToFacility={moveResidentToFacility}
						/>
					</div>
				</FormSection>
				<Divider />
				<FormSection
					icon="flag"
					title="Archive Resident"
					hoverText="Archive resident's active status"
					iconColor={red[600]}
					titleColor={red[600]}
				>
					<SectionDesc>
						Archiving a resident is meant for residents no longer active with
						your community. No data will be lost or deleted. You may un-archive
						this resident at any time.
					</SectionDesc>
					{isArchived && (
						<UnarchiveButton
							isDisabled={!isArchived}
							handleUnarchive={unarchiveResident}
						/>
					)}
					{!isArchived && (
						<ArchiveButton
							isDisabled={isArchived}
							handleArchive={archiveResident}
						/>
					)}
				</FormSection>
			</div>
		</div>
	);
};

export default ResidentSearchDetails;

ResidentSearchDetails.defaultProps = {};

ResidentSearchDetails.propTypes = {};
