import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/otp/ValidateOTP.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { isCorrectChar, isCorrectOTP } from "../../helpers/utils_otp";
import { isEmptyVal } from "../../helpers/utils_types";
import { SYSTEM_USER } from "../../helpers/utils_systemUser";
// components
import OTPChar from "./OTPChar";

const allCharsFilled = (values) => {
	const keys = Object.keys(values);
	const allFilled = keys.every((key) => !isEmptyVal(values[key]));
	console.log(`allFilled:`, allFilled);

	return allFilled;
};

const VerifyButton = ({ initVerifyOTP, isDisabled = true }) => {
	return (
		<button
			type="button"
			className={styles.VerifyButton}
			onClick={initVerifyOTP}
			disabled={isDisabled}
		>
			Verify
		</button>
	);
};

const ResendAction = ({ initResendOTP }) => {
	return (
		<div className={styles.ResendAction}>
			<p className={styles.ResendAction_msg}>Didn't receive a code?</p>
			<button className={styles.ResendAction_btn} onClick={initResendOTP}>
				Resend
			</button>
		</div>
	);
};

const ValidateOTP = ({
	otp = "8cd05568",
	initVerifyOTP,
	initResendOTP,
	isDisabled = false,
	closeModal,
}) => {
	const [isOtpConfirmed, setIsOtpConfirmed] = useState(false);
	const { formState, setFormState, handleBlur } = useForm({
		char1: "",
		char2: "",
		char3: "",
		char4: "",
		char5: "",
		char6: "",
		char7: "",
		char8: "",
	});
	const { values, touched } = formState;
	const [focusedIdx, setFocusedIdx] = useState(0);

	// prevents more than 1 char per input
	const handleChar = (e) => {
		const { value, name } = e.target;
		const { charindex: idx } = e.target.dataset;
		// const idx = e.target.dataset.charindex;

		console.group("Handle Char");
		console.log("name:", name);
		console.log("value:", value);
		console.log("idx", idx);
		console.groupEnd();

		setFormState({
			...formState,
			values: {
				...values,
				[name]: value,
			},
		});
		setFocusedIdx(+idx + 1);
	};

	// runs otp validator & sets state
	const handleLastBlur = (e) => {
		handleBlur(e);
		setIsOtpConfirmed(() => {
			return isCorrectOTP(values, otp);
		});
	};

	const verifyOTP = useCallback(() => {
		console.log(`Verifying OTP...`);

		setIsOtpConfirmed(() => {
			return isCorrectOTP(values, otp);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// when all chars are filled, validator is run
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (allCharsFilled(values)) {
			verifyOTP();
		}

		return () => {
			isMounted = false;
		};
	}, [values, verifyOTP]);

	return (
		<div className={styles.ValidateOTP}>
			<div className={styles.ValidateOTP_top}>
				<div className={styles.ValidateOTP_top_title}>
					Verification Code (OTP)
				</div>
				<p className={styles.ValidateOTP_top_desc}>
					Enter the OTP code provided to you. (via email, phone, admin etc)
				</p>
				<div className={styles.ValidateOTP_top_close}>
					<svg
						className={styles.ValidateOTP_top_close_icon}
						onClick={closeModal}
					>
						<use xlinkHref={`${sprite}#icon-clearclose`}></use>
					</svg>
				</div>
			</div>
			<div className={styles.ValidateOTP_fields}>
				{/* CHAR #1 */}
				<OTPChar
					key={`Char-0`}
					name="char1"
					id="char1"
					val={values.char1}
					isValid={isCorrectChar(0, values.char1, otp)}
					wasTouched={touched?.char1 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 0}
					charIdx={0}
				/>
				{/* CHAR #2 */}
				<OTPChar
					name="char2"
					id="char2"
					val={values.char2}
					isValid={isCorrectChar(1, values.char2, otp)}
					wasTouched={touched?.char2 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 1}
					charIdx={1}
				/>
				{/* CHAR #3 */}
				<OTPChar
					name="char3"
					id="char3"
					val={values.char3}
					isValid={isCorrectChar(2, values.char3, otp)}
					wasTouched={touched?.char3 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 2}
					charIdx={2}
				/>
				{/* CHAR #4 */}
				<OTPChar
					name="char4"
					id="char4"
					val={values.char4}
					isValid={isCorrectChar(3, values.char4, otp)}
					wasTouched={touched?.char4 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 3}
					charIdx={3}
				/>
				{/* CHAR #5 */}
				<OTPChar
					name="char5"
					id="char5"
					val={values.char5}
					isValid={isCorrectChar(4, values.char5, otp)}
					wasTouched={touched?.char5 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 4}
					charIdx={4}
				/>
				{/* CHAR #6 */}
				<OTPChar
					name="char6"
					id="char6"
					val={values.char6}
					isValid={isCorrectChar(5, values.char6, otp)}
					wasTouched={touched?.char6 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 5}
					charIdx={5}
				/>
				{/* CHAR #7 */}
				<OTPChar
					name="char7"
					id="char7"
					val={values.char7}
					isValid={isCorrectChar(6, values.char7, otp)}
					wasTouched={touched?.char7 ?? false}
					handleChar={handleChar}
					handleBlur={handleBlur}
					focusInput={focusedIdx === 6}
					charIdx={6}
				/>
				{/* CHAR #8 */}
				<OTPChar
					name="char8"
					id="char8"
					val={values.char8}
					isValid={isCorrectChar(7, values.char8, otp)}
					wasTouched={touched?.char8 ?? false}
					handleChar={handleChar}
					handleBlur={handleLastBlur}
					focusInput={focusedIdx === 7}
					charIdx={7}
				/>
			</div>
			<div className={styles.ValidateOTP_actions}>
				<VerifyButton
					initVerifyOTP={initVerifyOTP}
					isDisabled={!allCharsFilled(values)}
				/>
				<ResendAction initResendOTP={initResendOTP} />
			</div>
		</div>
	);
};

export default ValidateOTP;

ValidateOTP.defaultProps = {};

ValidateOTP.propTypes = {};
