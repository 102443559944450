import React, { useState, useReducer } from "react";
import styles from "../../css/admin/UserAccessTable.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray, isEmptyVal } from "../../helpers/utils_types";
import { sortUsersBy, sortUsersByAccess } from "../../helpers/utils_user";
import { doesUserHaveAppAccess } from "../../helpers/utils_apps";
import { red } from "../../helpers/utils_styles";
import { updateUserTypeInUserList } from "../../helpers/utils_userTypes";
// components
import Empty from "../shared/Empty";
import ModalLG from "../shared/ModalLG";
import UserAccessHeader from "./UserAccessHeader";
import UserAccessActions from "./UserAccessActions";
import UserAccessColumns from "./UserAccessColumns";
import UserAccessTableBody from "./UserAccessTableBody";
import CustomSearchWindow from "./CustomSearchWindow";
import UserDetails from "../user/UserDetails";
import Spinner from "../shared/Spinner";
import { numInRange } from "../../helpers/utils_processing";

const customCSS = {
	spinner: {
		display: "block",
		margin: "1rem auto",
	},
};

const emptyCss = {
	heading: {
		color: red[600],
	},
};

// ##TODOS:
// - Consider adding 'AdminPortal' as column w/ app access
// - Update row styles to accomodate newly-added column
// const columns = [`SeniorCareEHR`, `CareTracker`, `AdminPortal`]; // NEW COLUMNS
// const columns = [`SeniorCareEHR`, `CareTracker`, `AdminPortal`, `EMAR`]; // NEWEST COLUMNS
const columns = [`SeniorCareEHR`, `CareTracker`, `AdminPortal`, `EMAR`, `ePay`]; // NEWEST COLUMNS

const searchUsers = (val, allUsers = []) => {
	val = val?.toLowerCase();

	return allUsers.filter((user) => {
		if (
			user?.firstName?.toLowerCase().startsWith(val) ||
			user?.lastName?.toLowerCase().startsWith(val) ||
			user?.email?.toLowerCase().startsWith(val) ||
			user?.title?.toLowerCase().startsWith(val) ||
			user?.userID?.toLowerCase() === val
		) {
			return user;
		} else {
			return null;
		}
	});
};

const initialState = {
	isSorted: false,
	usersList: [],
	trackerAccess: [],
	legacyAccess: [],
	portalAccess: [],
	ePayAccess: [],
};

const tableReducer = (state, action) => {
	switch (action.type) {
		case "SEARCH": {
			const { searchVal, rawUsers } = action.data;
			const { usersList } = state;

			if (isEmptyVal(searchVal)) {
				return {
					...state,
					usersList: [...rawUsers],
				};
			} else {
				const newList = [...searchUsers(searchVal, usersList)];

				return {
					...state,
					usersList: [...newList],
				};
			}
		}
		case "SORT": {
			const { sortType } = action.data;
			const { usersList, isSorted } = state;

			switch (sortType) {
				case "User": {
					if (isSorted) {
						return {
							...state,
							isSorted: !isSorted,
							sortType: sortType,
							usersList: [...sortUsersBy("lastNameDESC", usersList)],
						};
					} else {
						return {
							...state,
							isSorted: true,
							sortType: sortType,
							usersList: [...sortUsersBy("lastNameASC", usersList)],
						};
					}
				}
				case "Care Tracker":
				case "CareTracker": {
					const { trackerAccess } = state;
					const { rawUsers } = action.data;

					if (isSorted) {
						return {
							...state,
							isSorted: !isSorted,
							sortType: null,
							usersList: [...sortUsersBy("lastNameASC", rawUsers)],
						};
					} else {
						return {
							...state,
							sortType: sortType,
							isSorted: !isSorted,
							usersList: [...sortUsersByAccess("CareTracker", usersList)],
						};
					}
				}
				case "SeniorCareEHR":
				case "SeniorCareVB": {
					const { legacyAccess } = state;
					const { rawUsers } = action.data;

					if (isSorted) {
						return {
							...state,
							sortType: null,
							isSorted: !isSorted,
							usersList: [...sortUsersBy("lastNameASC", rawUsers)],
						};
					} else {
						return {
							...state,
							sortType: sortType,
							isSorted: !isSorted,
							usersList: [...sortUsersByAccess(legacyAccess, usersList)],
						};
					}
				}
				case "Suspend": {
					const { rawUsers } = action.data;
					// check if random user in list is suspended (indicating list is sorted currently)
					const randomNum = numInRange(0, usersList?.length);
					const testUser = usersList?.[randomNum];
					const isCurrentlySorted = testUser?.isSuspended;
					if (isCurrentlySorted) {
						return {
							...state,
							sortType: null,
							usersList: [...sortUsersBy("lastNameASC", rawUsers)],
						};
					} else {
						return {
							...state,
							sortType: "Suspend",
							usersList: [...sortUsersBy("Suspend", usersList)],
						};
					}
				}

				default:
					return { ...state };
			}
		}
		case "DELETE_USER": {
			const { userID } = action.data;
			const newList = state.usersList.filter((x) => x.userID !== userID);

			return {
				...state,
				usersList: newList,
			};
		}
		case "USER_NAME_CHANGE": {
			const { userID, newFirstName, newLastName } = action.data;
			const { usersList } = state;

			// get usersList without targetUser & get target user record
			const withoutTargetUser = [...usersList].filter(
				(user) => user.userID !== userID
			);
			const targetUser = [...usersList].filter(
				(user) => user.userID === userID
			)?.[0];

			const updatedUser = {
				...targetUser,
				firstName: newFirstName,
				lastName: newLastName,
			};

			const newUsersList = [...withoutTargetUser, updatedUser];

			return {
				...state,
				usersList: [...sortUsersBy("lastNameASC", newUsersList)],
			};
		}
		case "USER_TYPE_CHANGE": {
			const { targetUserID, newUserType } = action.data;
			const { usersList } = state;
			const updatedUsersList = updateUserTypeInUserList(usersList, {
				userID: targetUserID,
				userType: newUserType,
			});

			return {
				...state,
				usersList: updatedUsersList,
			};
		}

		default:
			return { ...state };
	}
};

const UserAccessTable = ({
	isLoading = false,
	isLegacyOnly = false,
	currentUser = {},
	currentFacility = {},
	usersList = [],
	trackerAccessList = [],
	legacyAccessList = [],
	portalAccessList = [],
	emarAccessList = [],
	ePayAccessList = [],
	updateAccessList,
	allFacilities = [],
	syncDeletedUser,
	dispatchToState,
	dispatchAlert,
}) => {
	const [showCustomSearchWindow, setShowCustomSearchWindow] = useState(false);
	const [showSearchUserDetails, setShowSearchUserDetails] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [searchVal, setSearchVal] = useState("");
	const [tableState, dispatchAction] = useReducer(tableReducer, {
		...initialState,
		isSorted: true,
		sortType: "User",
		usersList: sortUsersBy("lastNameASC", usersList),
		trackerAccess: trackerAccessList,
		legacyAccess: legacyAccessList,
		portalAccess: portalAccessList,
		emarAccess: emarAccessList,
		ePayAccess: ePayAccessList,
	});
	const { trackerAccess, legacyAccess, portalAccess, emarAccess, ePayAccess } =
		tableState;

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);
		dispatchAction({
			type: "SEARCH",
			data: {
				searchVal: value,
				rawUsers: [...usersList],
			},
		});
	};
	const handleUserSorting = (sortType) => {
		dispatchAction({
			type: "SORT",
			data: {
				sortType: sortType,
				rawUsers: [...tableState?.usersList],
			},
		});
	};
	const handleAppSorting = (app) => {
		dispatchAction({
			type: "SORT",
			data: {
				sortType: app,
				portalAccess: portalAccess,
				trackerAccess: trackerAccess,
				legacyAccess: legacyAccess,
				emarAccess: emarAccess,
				ePayAccess: ePayAccess,
				rawUsers: [...usersList],
			},
		});
	};
	const handleSuspendSorting = () => {
		dispatchAction({
			type: "SORT",
			data: {
				sortType: "Suspend",
				rawUsers: [...usersList],
			},
		});
	};

	const clearSearch = () => {
		setSearchVal("");
		dispatchAction({
			type: "SEARCH",
			data: {
				searchVal: "",
				rawUsers: [...usersList],
			},
		});
	};

	// open custom search window
	const initCustomSearch = () => {
		setShowCustomSearchWindow(true);
	};

	// selects search result & opens user in 'UserDetails' modal
	const viewCustomSearchUser = (user) => {
		setShowCustomSearchWindow(false);
		setShowSearchUserDetails(true);
		setSelectedUser(user);
	};

	// remove user upon deletion
	const deleteUser = (userID) => {
		dispatchAction({
			type: "DELETE_USER",
			data: { userID: userID },
		});
		syncDeletedUser(userID);
	};

	return (
		<>
			<div className={styles.UserAccessTable}>
				<div className={styles.UserAccessTable_header}>
					<UserAccessHeader
						title={`Community Users & App Access (${currentFacility?.communityName})`}
						subtitle="Click a User Name to view or update"
						usersList={usersList}
						isLegacyOnly={isLegacyOnly}
					/>
					<UserAccessActions
						searchVal={searchVal}
						currentUser={currentUser}
						handleSearch={handleSearch}
						clearSearch={clearSearch}
						initCustomSearch={initCustomSearch}
					/>
				</div>
				<div className={styles.UserAccessTable_main}>
					{isEmptyArray(usersList) && !isLoading && (
						<div className={styles.UserAccessTable_main_empty}>
							<>
								<Empty
									icon="box"
									size="XLG"
									heading="No user data available."
								/>
								<Empty
									hideIcon={true}
									size="XLG"
									heading="Users may not have been migrated yet."
									customStyles={emptyCss}
								/>
							</>
						</div>
					)}
					{!isEmptyArray(usersList) && (
						<UserAccessColumns
							columns={columns}
							isSorted={tableState.isSorted}
							sortType={tableState.sortType}
							handleUserSorting={handleUserSorting}
							handleAppSorting={handleAppSorting}
							handleSuspendSorting={handleSuspendSorting}
						/>
					)}
					{isLoading && (
						<div className={styles.UserAccessTable_loading}>
							<Spinner customStyles={customCSS.spinner} />
							<div className={styles.UserAccessTable_loading_msg}>
								Loading user data...please wait..
							</div>
						</div>
					)}
					{!isLoading && !isEmptyArray(tableState?.usersList) && (
						<UserAccessTableBody
							key={`TABLESTATE:${tableState?.usersList?.length}-${currentFacility?.users?.length}`}
							currentUser={currentUser}
							currentFacility={currentFacility}
							usersList={tableState?.usersList}
							trackerAccess={tableState?.trackerAccess}
							legacyAccess={tableState?.legacyAccess}
							portalAccess={tableState?.portalAccess}
							emarAccess={tableState?.emarAccess}
							ePayAccess={tableState?.ePayAccess}
							isLoading={isLoading}
							allFacilities={allFacilities}
							updateAccessList={updateAccessList}
							dispatchAlert={dispatchAlert}
							dispatchToState={dispatchToState}
							tableDispatch={dispatchAction}
							deleteUser={deleteUser}
							isLegacyOnly={isLegacyOnly}
						/>
					)}
				</div>
			</div>

			{/* CUSTOM SEARCH WINDOW */}
			{showCustomSearchWindow && (
				<ModalLG
					key={`SEARCH-WINDOW-2`}
					title="Custom User Search"
					closeModal={() => setShowCustomSearchWindow(false)}
				>
					<CustomSearchWindow
						allFacilities={allFacilities}
						currentUser={currentUser}
						usersList={usersList}
						trackerAccess={trackerAccess}
						legacyAccess={legacyAccess}
						closeWindow={() => setShowCustomSearchWindow(false)}
						dispatchAlert={dispatchAlert}
						setSelectedUser={viewCustomSearchUser}
					/>
				</ModalLG>
			)}

			{/* CUSTOM SEARCH RESULTS - VIEW USER */}
			{showSearchUserDetails && (
				<ModalLG
					key={`MODAL-LG--CUSTOM-SEARCH`}
					title="User Details: Custom Search"
					closeModal={() => setShowSearchUserDetails(false)}
				>
					<UserDetails
						key={`USER-DETAILS--CUSTOM-SEARCH`}
						allFacilities={allFacilities}
						currentUser={currentUser}
						user={selectedUser}
						hasTrackerAccess={doesUserHaveAppAccess(
							selectedUser?.userID,
							trackerAccessList
						)}
						hasLegacyAccess={doesUserHaveAppAccess(
							selectedUser?.userID,
							legacyAccessList
						)}
						closeWindow={() => setShowSearchUserDetails(false)}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default UserAccessTable;

UserAccessTable.defaultProps = {
	isLoading: false,
	currentUser: {},
	usersList: [],
	userAccessList: [],
};

UserAccessTable.propTypes = {
	isLoading: PropTypes.bool,
	currentUser: PropTypes.object,
	updateAccessList: PropTypes.func.isRequired,
	usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
	userAccessList: PropTypes.arrayOf(PropTypes.string).isRequired,
};
