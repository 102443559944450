import React, { useState, useEffect } from "react";
import styles from "../../css/facility/FacilitySearchDetails.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { red } from "../../helpers/utils_styles";
import { useForm } from "../../utils/useForm";
// components
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";
import ProtectedFeature from "../permissions/ProtectedFeature";
import ToggleSwitch from "../shared/ToggleSwitch";
import CopyText from "../forms/CopyText";
import FacilityAccessSwitch from "./FacilityAccessSwitch";
import FacilityAppAccessController from "./FacilityAppAccessController";
import { featuresWhiteList } from "../../helpers/utils_permissions";
import { saveFacilityAppAssignment } from "../../helpers/utils_apps";

const isTrackerLocked = (facility, accessRecord, currentUser) => {
	const { userID } = currentUser;
	const isWhiteListed = featuresWhiteList.includes(userID);
	const hasAccess = facility?.hasAccess ?? false;
	const isEnabled = !isEmptyObj(accessRecord)
		? accessRecord?.IsAccessible
		: false;

	const lockAccess = !hasAccess && !isEnabled && !isWhiteListed;

	return lockAccess;
};

const isEmarLocked = (facility, accessRecord, currentUser) => {
	const { userID } = currentUser;
	const isWhiteListed = featuresWhiteList.includes(userID);
	const hasAccess = facility?.hasAccess ?? false;
	const isEnabled = !isEmptyObj(accessRecord)
		? accessRecord?.IsAccessible
		: false;

	const lockAccess = !hasAccess && !isEnabled && !isWhiteListed;

	return lockAccess;
};

const FacilitySuspendButton = ({ suspendFacilityVal, handleSuspend }) => {
	return (
		<div className={styles.FacilitySuspendButton}>
			<div className={styles.FacilitySuspendButton_label}>
				{suspendFacilityVal ? "Suspended" : "Suspend"}
			</div>
			<ToggleSwitch
				size="MD"
				name="suspendUser"
				id="suspendUser"
				val={suspendFacilityVal}
				handleToggle={handleSuspend}
				customColor={red[500]}
			/>
			{suspendFacilityVal && (
				<div className={styles.FacilitySuspendButton_isSuspended}>
					This user is suspended!
				</div>
			)}
		</div>
	);
};

const AddressUI = ({
	street = "Unknown",
	suite = "",
	city = "Unknown",
	state = "XX",
	zip = "XXXXX",
}) => {
	const addy = isEmptyVal(street) ? "Unknown" : street;
	const suiteNum = isEmptyVal(suite) ? "Unknown" : suite;
	const cityName = isEmptyVal(city) ? "Unknown" : city;
	const stateName = isEmptyVal(state) ? "XX" : state;
	const zipCode = isEmptyVal(zip) ? "XXXXX" : zip;

	return (
		<div className={styles.AddressUI}>
			<div className={styles.AddressUI_text}>
				{addy} {suiteNum}
			</div>
			<div className={styles.AddressUI_text}>
				{cityName}, {stateName} {zipCode}
			</div>
		</div>
	);
};

const FacilityAddress = ({ facility = {} }) => {
	const {
		CommunityName: name,
		Address1: street,
		City: city,
		State: state,
		ZIP: zipCode,
	} = facility;

	return (
		<div className={styles.FacilityAddress}>
			<div className={styles.FacilityAddress_name}>{name}</div>
			<div className={styles.FacilityAddress_address}>
				<AddressUI street={street} city={city} state={state} zip={zipCode} />
			</div>
		</div>
	);
};

const FacilitySearchDetails = ({
	currentUser = {},
	facility = {},
	globalState = {},
	dispatchAlert,
	closeModal,
}) => {
	const { formState, setFormState, handleChange } = useForm({
		isSuspended: facility?.bitSuspendSubscription ?? false,
		facilityID: facility?.guidFacility,
		parentID: facility?.guidParentFacility,
	});
	const { values, touched } = formState;
	// tracker's app access record
	const [accessRecord, setAccessRecord] = useState({});
	const [trackerAccess, setTrackerAccess] = useState({
		isEnabled: facility?.hasAccess ?? false,
		enableAllUsers: false,
	});
	const [emarAccess, setEmarAccess] = useState({
		isEnabled: false,
		enableAllUsers: false,
	});

	const handleSuspension = (e) => {
		console.log(`Suspend facility...`);
	};

	// facility app access toggle handler
	const handleEnableAccess = (e) => {
		const { name, checked } = e.target;
		// setIsEnabled(checked);

		// UPDATE FACILITY ACCESS
		return saveChanges(name, checked);
	};

	// handles enable user's app access checkbox(s)
	const handleEnableAllUsersAccess = (e) => {
		const { name, checked } = e.target;

		// console.group("Enable All Users");
		// console.log("name", name);
		// console.log("checked", checked);
		// console.groupEnd();

		return saveAllUsersAccess(name, checked);
	};

	// wrapper around app access state updaters:
	// ...to enable supporting multiple app access changes via a single handler
	const handleAppAccessState = (appName, isEnabled) => {
		switch (appName) {
			case "AdvantageTracker": {
				return setTrackerAccess({
					...trackerAccess,
					isEnabled: isEnabled,
				});
			}

			case "Chart-Meds (EMAR)": {
				return setEmarAccess({
					...emarAccess,
					isEnabled: isEnabled,
				});
			}

			default:
				return;
		}
	};

	const handleUsersAppAccessState = (appName, isEnabled) => {
		console.log("appName", appName);
		switch (appName) {
			case "AdvantageTracker": {
				return setTrackerAccess({
					...trackerAccess,
					enableAllUsers: isEnabled,
				});
			}

			case "Chart-Meds (EMAR)": {
				return setEmarAccess({
					...emarAccess,
					enableAllUsers: isEnabled,
				});
			}

			default:
				return;
		}
	};

	// fetches facility's record invokes updater fn()
	const saveChanges = async (name, checked) => {
		// e.preventDefault();
		const { token } = currentUser;
		const { facilityID } = facility;
		// determine appID by checkbox input's "name" property
		const appID = name === `AdvantageTracker` ? 2 : 19;

		// ↓ UPDATED REQUEST HANDLER ↓ //
		const wasSaved = await saveFacilityAppAssignment(
			token,
			checked,
			facilityID,
			appID
		);

		console.log("wasSaved", wasSaved);

		if (wasSaved) {
			// updateAccessList({
			// 	isEnabled: checked,
			// 	facilityID: facilityID,
			// });
			// setIsEnabled(checked);
			handleAppAccessState(name, checked);
			return closeModal();
		} else {
			closeModal();
			// return setIsEnabled(!accessRecord?.IsAccessible);
			return handleAppAccessState(name, checked);
		}
	};

	// handles saving all user's app access checkbox changes
	const saveAllUsersAccess = async (name, checked) => {
		const { token } = currentUser;
		const { facilityID } = facility;
		// determine appID by checkbox input's "name" property
		const appID = name === `AdvantageTracker` ? 2 : 19;

		// use "!checked" since state has NOT been updated yet
		// const wasSaved = await saveFacilityUsersAppAccess(
		// 	token,
		// 	!checked, // use opposite value of 'checked' since state has NOT been updated yet!
		// 	facilityID,
		// 	appID
		// );
		const wasSaved = true;

		console.group(`Save All Users Access: ${name}`);
		console.log("wasSaved", wasSaved);
		console.log("checked", checked);
		console.log("appID", appID);
		console.groupEnd();

		if (wasSaved) {
			handleUsersAppAccessState(name, checked);
			return dispatchAlert("SUCCESS", {
				heading: `App access was updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Whoops! Something went wrong.`,
				subheading: `Please try again.`,
			});
		}
	};

	console.log("facility", facility);
	return (
		<div className={styles.FacilitySearchDetails}>
			<div className={styles.FacilitySearchDetails_about}>
				{/* ADDRESS & NAME */}
				<FormSection
					icon="building"
					title="Location & Address"
					hoverText="Displays a facility's address, if available."
				>
					<FacilityAddress facility={facility} />
				</FormSection>
				<Divider />
				{/* FACILITY IDs */}
				<FormSection
					icon="news"
					title="Other Details"
					hoverText="Click to copy any of the below fields."
				>
					<div className={styles.FacilitySearchDetails_id}>
						Facility ID:
						<b>
							<CopyText text={`${values?.facilityID}`} />
						</b>
					</div>
					<div className={styles.FacilitySearchDetails_id}>
						Parent Facility ID:
						<b>
							<CopyText text={`${values?.parentID ?? "N/A"}`} />
						</b>
					</div>
				</FormSection>
			</div>
			<Divider />

			<div className={styles.FacilitySearchDetails_main}>
				{/* APPLICATION ACCESS - V2 */}
				<FormSection
					icon="settings"
					title="Application Access - v2"
					hoverText="Change/edit a facility's access to ALA Applications."
				>
					<div className={styles.FacilitySearchDetails_apps_warning}>
						Please note enabling some applications may incur an additional cost.
						Please contact <b>support@aladvantage.com</b> with any questions or
						concerns.
					</div>
					{/* LEGACY - SENIOR CARE EHR */}
					<FacilityAccessSwitch
						key="EHR-TOGGLE-1"
						appName="SeniorCareVB"
						label="Senior Care EHR"
						isEnabled={true}
						isLocked={true}
						handleAccess={handleEnableAccess}
					/>
					<br style={{ margin: "2rem 0" }} />
					{/* CARE TRACKER */}
					<FacilityAppAccessController
						key="TRACKER-TOGGLE-1"
						label="Advantage Tracker"
						appName="AdvantageTracker"
						isEnabled={trackerAccess?.isEnabled}
						enableAllUsers={trackerAccess?.enableAllUsers}
						isLocked={isTrackerLocked(facility, accessRecord, currentUser)}
						handleAccess={handleEnableAccess}
						handleAllUsersAccess={handleEnableAllUsersAccess}
					/>
					<br style={{ margin: "2rem 0" }} />
					{/* EMAR - CHART-MEDS */}
					<FacilityAppAccessController
						key="CHART-MEDS-EMAR-TOGGLE-1"
						label="EMAR Access"
						appName="Chart-Meds (EMAR)"
						isEnabled={emarAccess?.isEnabled ?? false}
						enableAllUsers={emarAccess?.enableAllUsers}
						isLocked={isEmarLocked(facility, accessRecord, currentUser)}
						handleAccess={handleEnableAccess}
						handleAllUsersAccess={handleEnableAllUsersAccess}
					/>
				</FormSection>
				<Divider />
				{/* SUSPEND/UN-SUSPEND ACCOUNT */}
				<ProtectedFeature isEnabled={true}>
					<FormSection
						icon="flag"
						title="Suspend/Unsuspend Community"
						hoverText="Suspend/un-suspend a community account"
						iconColor={red[600]}
						titleColor={red[600]}
					>
						<FacilitySuspendButton
							suspendFacilityVal={values?.isSuspended}
							handleSuspend={handleSuspension}
						/>
					</FormSection>
				</ProtectedFeature>
			</div>
		</div>
	);
};

export default FacilitySearchDetails;

FacilitySearchDetails.defaultProps = {};

FacilitySearchDetails.propTypes = {};
