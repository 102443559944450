import React, { useState, useRef, useEffect } from "react";
import styles from "../../css/loctemplate/LOCTemplateTableCell.module.scss";
import { PropTypes } from "prop-types";
import { useOutsideClick } from "../../utils/useOutsideClick";
import { isEmptyVal } from "../../helpers/utils_types";
import { matchesPattern } from "../../helpers/utils_validation";
import { addEllipsis } from "../../helpers/utils_processing";

const isEditableCell = (cellIdx, disableEdit) => {
	if (disableEdit) return false;
	if (cellIdx === 1) return false;
	return true;
};

const showCellVal = (cellKey, cellVal) => {
	if (cellKey === "LevelNumber") {
		return cellVal;
	} else {
		return cellVal;
	}
};

const getTitle = (cellIdx, disableEdit) => {
	if (cellIdx === 1) return `Level Number CANNOT be changed!`;
	if (disableEdit) return `Care Levels are locked!`;
	return `Click to edit!`;
};

const hasError = (cellVal) => {
	const isEmpty = isEmptyVal(cellVal);
	return isEmpty;
};

const errCSS = {
	boxShadow: `0 0 0 2px hsla(352, 70%, 50%, 0.6)`,
	border: `none`,
};

const lockedCell = {
	opacity: ".7",
	cursor: "not-allowed",
};

const getCustomCss = (customStyles, isLocked = false) => {
	if (isLocked) {
		return {
			...customStyles,
			...lockedCell,
		};
	} else {
		return { ...customStyles };
	}
};

const isAllowedVal = (val, pattern) => {
	if (isEmptyVal(val)) return true;
	// if (val?.length <= 50) return true;
	const matches = matchesPattern(val, pattern);
	console.log("matches", matches);
	return matches;
};

// enforces data type & formatting
const formatCellValue = (val = "", cellKey) => {
	switch (cellKey) {
		case "LevelDescription": {
			return val;
		}
		case "LevelNumber": {
			return val;
		}
		case "PointsMin": {
			return Number(val);
		}
		case "PointsMax": {
			return Number(val);
		}
		case "Pricing": {
			const num = Number(val);
			const money = num.toFixed(2);
			return money;
		}
		default:
			return;
	}
};

const renderCellVal = (val, cellKey) => {
	switch (cellKey) {
		case "LevelDescription": {
			return val;
		}
		case "LevelNumber": {
			return val;
		}
		case "PointsMin": {
			return Number(val);
		}
		case "PointsMax": {
			return Number(val);
		}
		case "Pricing": {
			const num = Number(val);
			const money = num.toFixed(2);
			return money;
		}
		default:
			return;
	}
};

const Notice = ({ msg }) => {
	return (
		<div className={styles.Notice}>
			<div className={styles.Notice_msg}>{msg}</div>
		</div>
	);
};

const LOCCellInput = ({
	inputRef,
	handleChange,
	handleBlur,
	handleEnter,
	cellVal,
	hideInput,
	inputMode = "numeric",
}) => {
	const { isOutside } = useOutsideClick(inputRef);

	const handleMount = () => {
		inputRef?.current?.focus();
		inputRef?.current?.select();
	};

	// focus input & select/highlight text
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		handleMount();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// isoutside, then disable input
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (isOutside) {
			hideInput();
		}

		return () => {
			isMounted = false;
		};
	}, [hideInput, isOutside]);

	return (
		<input
			type="text"
			inputMode={inputMode}
			value={cellVal}
			onChange={handleChange}
			onBlur={handleBlur}
			onKeyDown={handleEnter}
			className={styles.LOCCellInput}
			ref={inputRef}
			style={hasError(cellVal) ? errCSS : {}}
		/>
	);
};

const LOCTemplateTableCell = ({
	rowIdx,
	cellKey,
	cellData,
	cellIdx,
	updateTableData,
	disableEdit = false,
	allowedChars = null,
	customStyles = {},
	inputMode = "numeric",
}) => {
	const inputRef = useRef();
	const [cell, setCell] = useState(() => {
		const val = renderCellVal(cellData, cellKey);
		return val;
	});
	const [isEditing, setIsEditing] = useState(false);
	// locks 'Level' cell(s) by 'cellIdx'
	const isEditable = useRef(() => {
		const canEdit = isEditableCell(cellIdx, disableEdit);
		return canEdit;
	});

	const handleClick = (e) => {
		if (disableEdit || !isEditable.current) return;
		setIsEditing(true);
	};
	const handleFocus = (e) => {
		if (disableEdit || !isEditable.current) return;
		setIsEditing(true);
	};

	// edit change handler
	const handleChange = (e) => {
		const { value } = e.target;
		if (!isEditable.current) return;
		if (value?.length >= 50) return;

		// runs reg check for 'allowedChars'
		const isAllowed = isAllowedVal(value, allowedChars);

		if (isAllowed) {
			return setCell(value);
		}
		return;
	};

	// when input's blur, sync to parent component (ie. 'table')
	const handleBlur = (e) => {
		// checks for empty non-zero value
		const cellIsEmpty =
			(isEmptyVal(cell) && cell !== 0) ||
			(isEmptyVal(e?.target?.value) && cell !== 0);

		if (cellIsEmpty) {
			const newVal = formatCellValue(cell, cellKey);

			updateTableData(rowIdx, { [cellKey]: newVal });
			return setCell(newVal);
			// setIsEditing(false);
		}

		// formats cell data in proper data type, then sets state here & in parent
		const newVal = formatCellValue(cell, cellKey);

		updateTableData(rowIdx, { [cellKey]: newVal });
		setCell(newVal);
		return setIsEditing(false);
	};

	const handleEnter = (e) => {
		if (e.key === "Enter") {
			return handleBlur(e);
		} else if (e.key === "Tab") {
			// prevent tabbing, if cell is empty
			if (isEmptyVal(cell)) {
				return e.preventDefault();
			}
			return handleBlur(e);
		} else {
			return;
		}
	};

	return (
		<div
			title={getTitle(cellIdx, disableEdit)}
			className={styles.LOCTemplateTableCell}
			data-cellidx={cellIdx}
			onClick={handleClick}
			onFocus={handleFocus}
			tabIndex={isEditable.current ? 0 : -1}
			style={getCustomCss(customStyles, cellIdx === 1 || !isEditable.current)}
		>
			{!isEditing && (
				<span
					className={styles.LOCTemplateTableCell_value}
					style={getCustomCss(
						customStyles,
						cellIdx === 1 || !isEditable.current
					)}
				>
					{cell}
				</span>
			)}
			{isEditing && (
				<LOCCellInput
					cellVal={cell}
					inputRef={inputRef}
					handleBlur={handleBlur}
					handleChange={handleChange}
					handleEnter={handleEnter}
					hideInput={handleBlur}
					inputMode={inputMode}
				/>
			)}

			{cellIdx === 0 && cell?.length === 50 && (
				<Notice key="LIMIT-NOTICE" msg="50 character limit" />
			)}
			{isEmptyVal(cell) && cell !== 0 && (
				<Notice key="EMPTY-NOTICE" msg="Cell cannot be empty" />
			)}
		</div>
	);
};

export default LOCTemplateTableCell;

LOCTemplateTableCell.defaultProps = {};

LOCTemplateTableCell.propTypes = {
	cellData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
