import React, { useState } from "react";
import styles from "../../css/user/UserFacilityAccess.module.scss";
import { PropTypes } from "prop-types";
import {
	formatAndSortUserFacilities,
	createUserFacilityAccessRecords,
	removeAccessRecord,
	getGrantAccessRecordsFromSelections,
	denyFacilityAccess,
	sortFacilityAccess,
} from "../../helpers/utils_facility";
import { grantFacilityAccess } from "../../helpers/utils_security";
// components
import FacilityAccessList from "../facility/FacilityAccessList";
import { isEmptyVal } from "../../helpers/utils_types";

const searchAccessRecords = (val, records = []) => {
	if (isEmptyVal(val) || !val) return records;
	val = val.toLowerCase();

	return records.filter((record) => {
		const { facilityName } = record;
		if (facilityName.toLowerCase().startsWith(val)) {
			return record;
		} else {
			return null;
		}
	});
};

const UserFacilityAccess = ({
	currentUser = {},
	currentFacility = {},
	facilityAccessList = [],
	dispatchAlert,
}) => {
	const { facilities } = currentUser;
	// facility access selections
	const [showAccessList, setShowAccessList] = useState(false);
	const [accessSelections, setAccessSelections] = useState([
		...createUserFacilityAccessRecords(facilities, currentUser),
	]);
	const [searchVal, setSearchVal] = useState("");
	const [isSearching, setIsSearching] = useState(false);

	const toggleShowList = () => {
		setShowAccessList(!showAccessList);
	};

	const handleSearch = (e) => {
		const { value } = e.target;
		setSearchVal(value);

		if (isEmptyVal(value) || !value) {
			setIsSearching(false);
			return setAccessSelections([
				...createUserFacilityAccessRecords(facilities, currentUser),
			]);
		} else {
			setIsSearching(true);
			return setAccessSelections([
				...searchAccessRecords(value, accessSelections),
			]);
		}
	};

	const removeFacilityEntry = async (entry) => {
		const { token, userID } = currentUser;
		const { facilityID } = entry;
		console.log(`Entry:`, entry);
		const newList = removeAccessRecord(entry, accessSelections);
		setAccessSelections(newList);

		const wasSaved = await denyFacilityAccess(token, userID, [facilityID]);
		// - fire off request to remove record
		if (wasSaved) {
			dispatchAlert("SUCCESS", {
				heading: `Facility Access was updated!`,
			});
		} else {
			dispatchAlert("ERROR", {
				heading: `There was an error!`,
				subheading: `Your changes were NOT saved.`,
			});
		}
	};

	const saveAccessChanges = async () => {
		console.log(`Creating access records...`);
		// wire up facility changes
		const { token, userID } = currentUser;
		// extracts list of facilityIDs
		const listOfIDs = getGrantAccessRecordsFromSelections(
			accessSelections,
			facilities
		);
		const accessWasUpdated = await grantFacilityAccess(
			token,
			userID,
			listOfIDs
		);

		if (accessWasUpdated) {
			return dispatchAlert("SUCCESS", {
				heading: `Changes Were Saved!`,
				subheading: `Facility Access was updated.`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `ERROR!`,
				subheading: `Something went wrong. Please try again.`,
			});
		}
	};
	const cancelAccessChanges = () => {
		console.log(`Cancelling changes...`);
		setAccessSelections([
			...createUserFacilityAccessRecords(facilities, currentUser),
		]);
	};

	return (
		<div className={styles.UserFacilityAccess}>
			<div className={styles.UserFacilityAccess_count}>
				{!isSearching && (
					<div className={styles.UserFacilityAccess_count_value}>
						This user has access to <b>{accessSelections?.length ?? 0}</b>{" "}
						facilities.
					</div>
				)}
				{isSearching && (
					<div className={styles.UserFacilityAccess_count_value}>
						Found <b>{accessSelections?.length ?? 0}</b> facilities matching
						your search.
					</div>
				)}
			</div>
			<div className={styles.UserFacilityAccess_main}>
				<button
					type="button"
					onClick={toggleShowList}
					className={styles.UserFacilityAccess_main_toggleBtn}
				>
					{showAccessList ? "Hide" : "Show"} Granted Facilities
				</button>
			</div>
			{showAccessList && (
				<div className={styles.UserFacilityAccess_list}>
					<div className={styles.UserFacilityAccess_list_search}>
						<input
							type="text"
							name="searchFacilities"
							id="searchFacilities"
							value={searchVal}
							onChange={handleSearch}
							placeholder="Search by facility name..."
							className={styles.UserFacilityAccess_list_search_input}
						/>
					</div>
					<FacilityAccessList
						currentUser={currentUser}
						isSearching={false}
						removeEntry={removeFacilityEntry}
						isLoading={false}
						accessList={sortFacilityAccess(accessSelections)}
					/>
				</div>
			)}
		</div>
	);
};

export default UserFacilityAccess;

UserFacilityAccess.defaultProps = {};

UserFacilityAccess.propTypes = {
	facilityAccessList: PropTypes.arrayOf(PropTypes.object),
};
