import React, { useState, useEffect } from "react";
import styles from "../../css/admin/UserAccessRow.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { getUserName, getUserEmail } from "../../helpers/utils_user";
import {
	checkForUserLockout,
	suspendUserLogin,
	unsuspendUserLogin,
} from "../../helpers/utils_lockouts";
import { debounce } from "../../helpers/utils_processing";
import { red } from "../../helpers/utils_styles";
// components
import UserBadge from "./UserBadge";
import UserDetails from "../user/UserDetails";
import ModalLG from "../shared/ModalLG";
import EnabledBadge from "../facility/EnabledBadge";
import DisabledBadge from "../facility/DisabledBadge";

const suspendCSS = {
	backgroundColor: red[600],
};

const customCSS = {
	name: {
		color: "#ffffff",
	},
	email: {
		color: "#ffffff",
	},
};

const LockoutIcon = () => {
	return (
		<svg className={styles.LockoutIcon}>
			<use xlinkHref={`${sprite}#icon-lock11`}></use>
		</svg>
	);
};

const SuspendButton = ({ suspendUser }) => {
	return (
		<button onClick={suspendUser} className={styles.SuspendButton}>
			Suspend
		</button>
	);
};
const UnsuspendButton = ({ unsuspendUser }) => {
	return (
		<button onClick={unsuspendUser} className={styles.UnsuspendButton}>
			Unsuspend
		</button>
	);
};

const UserAccessRow = ({
	user = {},
	currentUser = {},
	currentFacility = {},
	legacyAccess = false,
	trackerAccess = false,
	portalAccess = false,
	emarAccess = false,
	ePayAccess = false,
	updateAccessList,
	allFacilities = [],
	dispatchAlert,
	dispatchToState,
	tableDispatch,
	deleteUser,
	isLegacyOnly,
}) => {
	// local state
	const [isSuspended, setIsSuspended] = useState(user?.isSuspended);
	const [showUserDetails, setShowUserDetails] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [justUpdated, setJustUpdated] = useState(false);
	const [isLockedOut, setIsLockedOut] = useState(() => {
		const isLocked = checkForUserLockout(
			user?.userID,
			currentFacility?.lockouts
		);
		return isLocked || user?.isLockedOut;
	});

	// only used to sync lock status to UI from '<UserDetails/>'
	const lockAccount = (lockStatus) => {
		setIsLockedOut(lockStatus);
	};

	const syncSuspendStatus = (isSuspended) => {
		setIsSuspended(isSuspended);
	};

	// suspend user login
	const suspendUser = async () => {
		const { token } = currentUser;
		const { userID } = user;
		setIsSuspended(!isSuspended);
		const wasSuspended = await suspendUserLogin(token, userID);

		console.log(`Suspending ${user.email}`);
		console.log(`Was suspended? `, wasSuspended);
	};
	// unsuspend user login
	const unsuspendUser = async () => {
		const { token } = currentUser;
		const { userID } = user;
		setIsSuspended(!isSuspended);
		const wasSuspended = await unsuspendUserLogin(token, userID);

		console.log(`Un-suspending ${user.email}`);
		console.log(`Was un-suspended? `, wasSuspended);
	};

	// resets 'justUpdated' state
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		let timerID;
		if (justUpdated) {
			timerID = setTimeout(() => {
				setJustUpdated(false);
			}, 3000);
		}

		return () => {
			isMounted = false;
			clearTimeout(timerID);
		};
	}, [justUpdated]);

	return (
		<>
			<div
				className={styles.UserAccessRow}
				style={isSuspended ? suspendCSS : null}
			>
				{/* USER COLUMN */}
				<div
					className={styles.UserAccessRow_userCol}
					onClick={() => setShowUserDetails(true)}
					onMouseOver={() => debounce(setShowEmail(true), 800)}
					onMouseLeave={() => debounce(setShowEmail(false), 600)}
				>
					<UserBadge size="SM" user={user}>
						{isLockedOut && <LockoutIcon />}
					</UserBadge>
					<div
						className={styles.UserAccessRow_userCol_name}
						style={isSuspended ? customCSS.name : null}
					>
						{getUserName(user)}
					</div>

					{showEmail && (
						<div
							className={styles.UserAccessRow_userCol_email}
							style={isSuspended ? customCSS.email : null}
						>
							{getUserEmail(user)}
						</div>
					)}
				</div>
				{/* SENIOR CARE ACCESS COLUMN */}
				<div className={styles.UserAccessRow_legacy}>
					<div className={styles.UserAccessRow_legacy_access}>
						{legacyAccess ? (
							<EnabledBadge key="LEGACY-ACCESS--ON" />
						) : (
							<DisabledBadge key="LEGACY-ACCESS--OFF" />
						)}
					</div>
				</div>
				{/* TRACKER ACCESS COLUMN */}
				<div className={styles.UserAccessRow_tracker}>
					<div className={styles.UserAccessRow_tracker_access}>
						{trackerAccess ? (
							<EnabledBadge key="TRACKER-ACCESS--ON" />
						) : (
							<DisabledBadge key="TRACKER-ACCESS--OFF" />
						)}
					</div>
				</div>
				{/* PORTAL ACCESS COLUMN */}
				<div className={styles.UserAccessRow_portal}>
					<div className={styles.UserAccessRow_portal_access}>
						{portalAccess ? (
							<EnabledBadge key="PORTAL-ACCESS--ON" />
						) : (
							<DisabledBadge key="PORTAL-ACCESS--OFF" />
						)}
					</div>
				</div>
				{/* EMAR ACCESS COLUMN */}
				<div className={styles.UserAccessRow_emar}>
					<div className={styles.UserAccessRow_emar_access}>
						{emarAccess ? (
							<EnabledBadge key="EMAR-ACCESS--ON" />
						) : (
							<DisabledBadge key="EMAR-ACCESS--OFF" />
						)}
					</div>
				</div>
				{/* EPAY ACCESS COLUMN */}
				<div className={styles.UserAccessRow_ePay}>
					<div className={styles.UserAccessRow_ePay_access}>
						{ePayAccess ? (
							<EnabledBadge key="EPAY-ACCESS--ON" />
						) : (
							<DisabledBadge key="EPAY-ACCESS--OFF" />
						)}
					</div>
				</div>
				{/* DEACTIVATE USER COLUMN */}
				<div className={styles.UserAccessRow_deactivate}>
					<div className={styles.UserAccessRow_deactivate_col}>
						{!isSuspended && <SuspendButton suspendUser={suspendUser} />}
						{isSuspended && <UnsuspendButton unsuspendUser={unsuspendUser} />}
					</div>
				</div>
			</div>

			{showUserDetails && (
				<ModalLG
					title={`User Details: ${getUserName(user)}`}
					closeModal={() => setShowUserDetails(false)}
				>
					<UserDetails
						key={`USER-DETAILS-MODAL`}
						user={user}
						currentUser={currentUser}
						currentFacility={currentFacility}
						hasTrackerAccess={trackerAccess}
						hasLegacyAccess={legacyAccess}
						hasPortalAccess={portalAccess}
						hasEmarAccess={emarAccess}
						hasEPayAccess={ePayAccess}
						updateAccessList={updateAccessList}
						isSuspended={isSuspended}
						isLockedOut={isLockedOut}
						allFacilities={allFacilities}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						tableDispatch={tableDispatch}
						syncLockStatus={lockAccount}
						deleteUser={deleteUser}
						closeModal={() => setShowUserDetails(false)}
						syncSuspendStatus={syncSuspendStatus}
						isLegacyOnly={isLegacyOnly}
					/>
				</ModalLG>
			)}
		</>
	);
};

export default UserAccessRow;
export { LockoutIcon };

UserAccessRow.defaultProps = {};

UserAccessRow.propTypes = {
	user: PropTypes.object.isRequired,
	hasAccess: PropTypes.bool,
	updateAccessList: PropTypes.func,
};
