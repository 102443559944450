import React, { useState } from "react";
import styles from "../../css/admin/BadgesLegend.module.scss";
import UserBadge from "./UserBadge";
import { LockoutIcon } from "./UserAccessRow";

const sampleUsers = {
	superUser: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: true,
		isFacilityAdmin: false,
		isRegionalAdmin: false,
		isALAAdmin: false,
		isAdmin: false,
		isMedTechRestricted: false,
		isReadOnly: false,
	},
	regionalAdmin: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isFacilityAdmin: false,
		isRegionalAdmin: true,
		isALAAdmin: false,
		isAdmin: false,
		isMedTechRestricted: false,
		isReadOnly: false,
	},
	facilityAdmin: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isRegionalAdmin: false,
		isFacilityAdmin: true,
		isALAAdmin: false,
		isAdmin: false,
		isMedTechRestricted: false,
		isReadOnly: false,
	},
	admin: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isRegionalAdmin: false,
		isFacilityAdmin: false,
		isALAAdmin: false,
		isAdmin: true,
		isMedTechRestricted: false,
		isReadOnly: false,
	},
	alaAdmin: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isRegionalAdmin: false,
		isFacilityAdmin: false,
		isALAAdmin: true,
		isAdmin: false,
		isMedTechRestricted: false,
		isReadOnly: false,
	},
	medTech: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isRegionalAdmin: false,
		isFacilityAdmin: false,
		isALAAdmin: false,
		isAdmin: false,
		isMedTechRestricted: true,
		isReadOnly: false,
	},
	standard: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isRegionalAdmin: false,
		isFacilityAdmin: false,
		isALAAdmin: false,
		isAdmin: false,
		isMedTechRestricted: false,
		isReadOnly: false,
	},
	readOnly: {
		firstName: `First`,
		lastName: `Last`,
		isSuperUser: false,
		isRegionalAdmin: false,
		isFacilityAdmin: false,
		isALAAdmin: false,
		isAdmin: false,
		isMedTechRestricted: false,
		isReadOnly: true,
	},
};

const BadgesLegend = () => {
	const {
		superUser,
		facilityAdmin,
		admin,
		alaAdmin,
		medTech,
		standard,
		regionalAdmin,
		readOnly,
	} = sampleUsers;
	const [showLegend, setShowLegend] = useState(false);

	return (
		<div className={styles.BadgesLegend}>
			<div className={styles.BadgesLegend_toggle}>
				<button
					type="button"
					onClick={() => setShowLegend(!showLegend)}
					className={styles.BadgesLegend_toggle_btn}
				>
					{showLegend ? "Hide" : "Show"} Badges Legend
				</button>
			</div>
			{showLegend && (
				<div className={styles.BadgesLegend_details}>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge
							key={`SUPER-USER-INTERNAL-EXAMPLE`}
							size="XSM"
							user={superUser}
						/>
						<div
							className={styles.BadgesLegend_details_example_label}
							id="superUser"
						>
							Super User Badge
						</div>
					</div>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge
							key={`EXECUTIVE-ADMIN-EXAMPLE`}
							size="XSM"
							user={alaAdmin}
						/>
						<div
							className={styles.BadgesLegend_details_example_label}
							id="alaAdmin"
						>
							Executive Admin (ALA Admin)
						</div>
					</div>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge
							key={`REGIONAL-ADMIN-EXAMPLE`}
							size="XSM"
							user={regionalAdmin}
						/>
						<div
							className={styles.BadgesLegend_details_example_label}
							id="facilityAdmin"
						>
							Regional Admin Badge
						</div>
					</div>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge
							key={`FACILITY-ADMIN-EXAMPLE`}
							size="XSM"
							user={facilityAdmin}
						/>
						<div
							className={styles.BadgesLegend_details_example_label}
							id="facilityAdmin"
						>
							Facility Admin Badge
						</div>
					</div>

					<div className={styles.BadgesLegend_details_example}>
						<UserBadge key={`MED-TECH-EXAMPLE`} size="XSM" user={medTech} />
						<div
							className={styles.BadgesLegend_details_example_label}
							id="standard"
						>
							Med-Tech Restricted Badge
						</div>
					</div>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge key={`STANDARD-EXAMPLE`} size="XSM" user={standard} />
						<div
							className={styles.BadgesLegend_details_example_label}
							id="standard"
						>
							Standard User Badge
						</div>
					</div>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge key={`LOCKOUT-EXAMPLE`} size="XSM" user={standard}>
							<LockoutIcon />
						</UserBadge>
						<div
							className={styles.BadgesLegend_details_example_label}
							id="standard"
						>
							Lockout Badge
						</div>
					</div>
					<div className={styles.BadgesLegend_details_example}>
						<UserBadge key={`READ-ONLY-EXAMPLE`} size="XSM" user={readOnly} />
						<div
							className={styles.BadgesLegend_details_example_label}
							id="readOnly"
						>
							READ-ONLY Badge
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default BadgesLegend;

BadgesLegend.defaultProps = {};

BadgesLegend.propTypes = {};
