import React, { useState } from "react";
import styles from "../../css/summary/LockoutEntry.module.scss";
import sprite from "../../assets/icons/multistep.svg";
import { PropTypes } from "prop-types";
import { isEmptyVal } from "../../helpers/utils_types";
import { format } from "date-fns";
import { addEllipsis } from "../../helpers/utils_processing";
import { useForm } from "../../utils/useForm";
import { generateOTP, unlockUserLogin } from "../../helpers/utils_lockouts";
// components
import UserBadge from "../user/UserBadge";
import AccountUnlockDialog from "./AccountUnlockDialog";
import AccountUnlockSwitch from "./AccountUnlockSwitch";
import AccountUnlockNotice from "./AccountUnlockNotice";
import RequestOTP from "../otp/RequestOTP";
import { determineLockoutUnit } from "../../helpers/utils_dates";

// REQUIREMENTS:
// - Display lock-outs in order of urgency:
//    - Who's been locked out the longest

// get username/email limited to 35 chars
const getAbbrUsername = (entry) => {
	const username = isEmptyVal(entry?.username) ? entry?.email : entry?.username;
	return addEllipsis(username, 35);
};

const getUsersInitials = (entry) => {
	const { firstName, lastName } = entry;
	let first = firstName?.split("")[0];
	let last = lastName?.split("")[0];

	first = first?.toUpperCase();
	last = last?.toUpperCase();

	return `${first}${last}`;
};

// returns => '10/15/2021 at 1:09 PM'
const getLockoutTimestamp = (timestamp) => {
	const time = format(new Date(timestamp), "h:mm A");
	const date = format(new Date(timestamp), "M/DD/YYYY");
	return `${date} at ${time}`;
};
// gets lockout time in most logical units (eg days, hours or mins)
const getLockoutAmount = (lockoutEntry = {}) => {
	const { timeElapsedSinceLockout } = lockoutEntry;
	const time = Math.abs(timeElapsedSinceLockout);
	return determineLockoutUnit(time);
};

const LockoutBadge = ({ isLockedOut = true }) => {
	if (!isLockedOut) {
		return null;
	}
	return (
		<div className={styles.LockoutBadge}>
			<div className={styles.LockoutBadge_label}>Account Locked</div>
		</div>
	);
};

const UnlockButton = ({ initUnlockAccount }) => {
	return (
		<button
			type="button"
			onClick={initUnlockAccount}
			className={styles.UnlockButton}
		>
			Unlock
		</button>
	);
};

const UserLockoutBadge = ({ entry }) => {
	return (
		<div className={styles.UserLockoutBadge}>
			<div className={styles.UserLockoutBadge_initials}>
				{getUsersInitials(entry)}
			</div>
			<div className={styles.UserLockoutBadge_iconWrapper}>
				<svg className={styles.UserLockoutBadge_iconWrapper_icon}>
					<use xlinkHref={`${sprite}#icon-lock`}></use>
				</svg>
			</div>
		</div>
	);
};

const LockoutEntry = ({
	currentFacility = {},
	lockoutEntry = {},
	currentUser = {},
}) => {
	const [showUnlockDialog, setShowUnlockDialog] = useState(false);
	const [isLockedOut, setIsLockedOut] = useState(true);
	const [otp, setOtp] = useState({});

	const handleUnlock = () => {
		setIsLockedOut(!isLockedOut);
		saveUnlock();
	};

	const saveUnlock = async () => {
		const { token } = currentUser;
		const { userID } = lockoutEntry;
		const wasUnlocked = await unlockUserLogin(token, userID);

		if (wasUnlocked) {
			console.log(`Was Unlocked?`, wasUnlocked);
			return wasUnlocked;
		} else {
			console.log(`❌ Ooops! Unlock failed:`, wasUnlocked);
			return wasUnlocked;
		}
	};

	const requestOTP = async () => {
		const { token } = currentUser;
		const { userID } = lockoutEntry;
		const newOTP = await generateOTP(token, userID);

		if (!isEmptyVal(newOTP?.otp)) {
			return setOtp(newOTP);
		} else {
			return setOtp({});
		}
	};

	return (
		<>
			<div className={styles.LockoutEntry}>
				<div className={styles.LockoutEntry_username}>
					<UserLockoutBadge entry={lockoutEntry} />
					<div className={styles.LockoutEntry_username_details}>
						<div className={styles.LockoutEntry_username_details_name}>
							{lockoutEntry?.firstName} {lockoutEntry?.lastName}
						</div>
						<div className={styles.LockoutEntry_username_details_login}>
							{getAbbrUsername(lockoutEntry)}
						</div>
						<div className={styles.LockoutEntry_username_status}>
							<LockoutBadge isLockedOut={isLockedOut} />
						</div>
					</div>
				</div>
				<div className={styles.LockoutEntry_info}>
					<div className={styles.LockoutEntry_info_mins}>
						Locked for <b>{getLockoutAmount(lockoutEntry)}</b>
					</div>
					<div className={styles.LockoutEntry_info_timestamp}>
						Timestamp:{" "}
						<b>{getLockoutTimestamp(lockoutEntry?.lockoutTimestamp)}</b>
					</div>
					<UnlockButton initUnlockAccount={() => setShowUnlockDialog(true)} />
				</div>
			</div>

			{showUnlockDialog && (
				<AccountUnlockDialog
					entry={lockoutEntry}
					closeDialog={() => setShowUnlockDialog(false)}
				>
					<AccountUnlockSwitch
						handleUnlockSwitch={handleUnlock}
						isLocked={isLockedOut}
					/>
					<AccountUnlockNotice entry={lockoutEntry} />
					<RequestOTP
						otp={otp?.otp}
						entry={lockoutEntry}
						generateOTP={requestOTP}
					/>
				</AccountUnlockDialog>
			)}
		</>
	);
};

export default LockoutEntry;

LockoutEntry.defaultProps = {
	lockoutEntry: {},
};

LockoutEntry.propTypes = {
	lockoutEntry: PropTypes.object,
};
