import { format } from "date-fns";
import { facility } from "./utils_endpoints";
import { currentEnv } from "./utils_env";
import { isEmptyVal } from "./utils_types";

// REASON TYPES:
const SUSPEND_REASONS = {
	Cancelled: 1,
	"Non-Payment": 2,
	"No Contact": 3,
	"Left Parent": 4,
	"Switched Software": 5,
	"Went to Paper": 6,
	Other: 7,
};

// fetches 'SuspendReasonTypes'
const getSuspendReasons = async (token, params = {}) => {
	let url = currentEnv.base + facility.suspend.getReasons;
	url += "?" + new URLSearchParams({ ...params });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Suspends a facility's account.
 * @param {String} token - Auth token
 * @param {String} facilityId - Target facility guid to be suspended.
 * @param {Object} suspendVals - An object of suspend data values: 'suspendReasonId', 'suspendComment', 'scheduleFor'
 * @property {Number} suspendVals.suspendReasonId - ID that represents a specific suspension reason record.
 * @property {String} suspendVals.suspendComment - Additional info regarding the suspension, such as which software they switched to, why they left etc.
 * @property {Date} suspendVals.scheduleFor - Date that the suspension should be executed. If null, then run immediately.
 * @returns {Boolean} - Returns whether suspension was successful.
 */
const suspendFacilityAccount = async (token, facilityId, suspendVals = {}) => {
	const {
		suspendReasonId = 7,
		suspendComment = "Deactivated by Admin",
		scheduleFor = null,
	} = suspendVals;
	let url = currentEnv.base + facility.suspend.facilityAccount;
	url += "?" + new URLSearchParams({ facilityId });
	url += "&" + new URLSearchParams({ suspendReasonId });
	url += "&" + new URLSearchParams({ suspendComment });
	url += "&" + new URLSearchParams({ scheduleFor });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * Unsuspends a facility account (ie re-activates).
 * @param {String} token - Auth token
 * @param {String} facilityId - Facility guid string
 * @returns {Boolean} - Return whether 'unsuspend' was true.
 */
const unsuspendFacilityAccount = async (token, facilityId) => {
	let url = currentEnv.base + facility.unsuspend.facilityAccount;
	url += "?" + new URLSearchParams({ facilityId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// formatts 'suspend' reasons for the client
const processSuspendReasons = (reasons = []) => {
	const formatted = reasons.map((reason) => {
		const {
			Name: name,
			Description: desc,
			SuspendReasonTypeId: suspendReasonID,
			IsActive: isActive,
			CreatedDate: dateCreated,
		} = reason;

		const clientReason = {
			name,
			desc,
			suspendReasonID,
			isActive,
			dateCreated,
		};
		return clientReason;
	});
	return formatted;
};

// find matching 'suspend' type from a text description/name value
const matchSuspendReasonByDesc = (desc, reasons = []) => {
	const match = reasons.filter((type) => {
		const { Name, Description } = type;
		if (desc === Name || desc === Description) {
			return type;
		} else {
			return null;
		}
	});

	return match?.[0];
};
// find matching 'suspend' type from a text description/name value
// uses client-formatted suspend reasons
const matchSuspendReasonByClientDesc = (desc, reasons = []) => {
	const match = reasons.filter((type) => {
		const { name, desc: typeDesc } = type;
		if (desc === typeDesc || desc === name) {
			return type;
		} else {
			return null;
		}
	});
	return match?.[0];
};

// finds user from userID
const matchUserByID = (userID, usersList) => {
	const lowerID = userID?.toLowerCase();
	const match = usersList.filter((user) => {
		if (user?.userID?.toLowerCase() === lowerID) {
			return user;
		} else {
			return;
		}
	});

	return match?.[0];
};

// USER SUSPEND FIELD GETTERS
const getSuspendedBy = (suspendedBy) => {
	if (isEmptyVal(suspendedBy)) return "";

	return suspendedBy;
};

const getSuspendedDate = (date) => {
	const newDate = format(date, "MM/DD/YYYY");
	const newTime = format(date, "h:mm A");
	return `${newDate} at ${newTime}`;
};

export { getSuspendReasons, suspendFacilityAccount, unsuspendFacilityAccount };

export {
	SUSPEND_REASONS,
	processSuspendReasons,
	matchSuspendReasonByDesc,
	matchSuspendReasonByClientDesc,
};
export { matchUserByID };

export { getSuspendedBy, getSuspendedDate };
