import React, { useEffect, useContext, useState } from "react";
import styles from "../../css/facility/FacilityDetails.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { AuthContext } from "../../state/AuthContext";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { blue, main, orange, red } from "../../helpers/utils_styles";
import {
	saveFacilityAccessRecord,
	saveFacilityAppAssignment,
	saveFacilityUsersAppAccess,
} from "../../helpers/utils_apps";
import {
	enableFeatureViaWhiteList,
	featureFlags,
	featuresWhiteList,
} from "../../helpers/utils_permissions";
import {
	facilityTypeColors,
	getFacilityDetails,
	getFacilityInfoDetails,
} from "../../helpers/utils_facility";
import { getUserTypeColor } from "../../helpers/utils_userTypes";
// components
import FormSection from "../forms/FormSection";
import Divider from "../forms/Divider";
import FacilityAccessSwitch from "./FacilityAccessSwitch";
import CopyText from "../forms/CopyText";
import ButtonSM from "../shared/ButtonSM";
import AccordionContainer from "../shared/AccordionContainer";
import AccordionSection from "../shared/AccordionSection";
import FacilityNotificationsPanel from "./FacilityNotificationsPanel";
import ProtectedFeature from "../permissions/ProtectedFeature";
import FacilityAppAccessController from "./FacilityAppAccessController";

// ## TODOS:
// - Fix 'Update to facility access' handlers
// 		- Need to check for existing record first

const customCSS = {
	save: {
		padding: ".7rem 1.2rem",
		backgroundColor: main.red,
		color: "#ffffff",
	},
	cancel: {
		backgroundColor: "transparent",
		color: main.red,
		marginRight: "1rem",
	},
};

const isTrackerLocked = (facility, accessRecord, currentUser) => {
	const { userID } = currentUser;
	const isWhiteListed = featuresWhiteList.includes(userID);
	const hasAccess = facility?.hasAccess ?? false;
	const isEnabled = !isEmptyObj(accessRecord)
		? accessRecord?.IsAccessible
		: false;

	const lockAccess = !hasAccess && !isEnabled && !isWhiteListed;

	return lockAccess;
};

const isEmarLocked = (facility, accessRecord, currentUser) => {
	const { userID } = currentUser;
	const isWhiteListed = featuresWhiteList.includes(userID);
	const hasAccess = facility?.hasAccess ?? false;
	const isEnabled = !isEmptyObj(accessRecord)
		? accessRecord?.IsAccessible
		: false;

	const lockAccess = !hasAccess && !isEnabled && !isWhiteListed;

	return lockAccess;
};

const getTypeColor = (facilityType) => {
	const color = facilityTypeColors[facilityType];

	return {
		color,
	};
};

const getEmarHref = (facilityName) => {
	const name = facilityName ?? "";
	const baseHref = `mailto: support@aladvantage.com`;
	const subject = `subject=${name}'s Emar Access`;
	const href = `${baseHref}?${subject}`;

	return href;
};

/**
 * - ONLY 'AdvantageTracker' is accepting access changes.
 * - Legacy (ie 'SeniorCareVB') is not currently enabled in ALA Services.
 */
const appID = 2;

const EmarStatus = ({ facilityName, hasEmarAccess = false }) => {
	if (!hasEmarAccess) {
		return (
			<>
				<div className={styles.EmarStatus_disabled}>
					<div className={styles.EmarStatus_disabled_check}>
						<svg className={styles.EmarStatus_disabled_check_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</div>
					<div className={styles.EmarStatus_disabled_badge}>Disabled</div>
				</div>
				<div className={styles.EmarSupport}>
					<div className={styles.EmarSupport_text}>
						Have questions or need to change your EMAR access?
					</div>
					<div className={styles.EmarSupport_text}>
						Contact our support staff at{" "}
						<a
							href={getEmarHref(facilityName)}
							target="_blank"
							rel="noreferrer"
							className={styles.EmarSupport_text_link}
						>
							support@aladvantage.com
						</a>
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			<div className={styles.EmarStatus_enabled}>
				<div className={styles.EmarStatus_enabled_check}>
					<svg className={styles.EmarStatus_enabled_check_icon}>
						<use xlinkHref={`${sprite}#icon-check_circle`}></use>
					</svg>
				</div>
				<div className={styles.EmarStatus_enabled_badge}>Enabled</div>
			</div>
			<div className={styles.EmarSupport}>
				<div className={styles.EmarSupport_text}>
					Have questions or need to change your EMAR access?
				</div>
				<div className={styles.EmarSupport_text}>
					Contact our support staff at{" "}
					<a
						href={getEmarHref(facilityName)}
						target="_blank"
						rel="noreferrer"
						className={styles.EmarSupport_text_link}
					>
						support@aladvantage.com
					</a>{" "}
					or give us a call at{" "}
					<a
						href="tel: 877-727-2640"
						target="_blank"
						rel="noreferrer"
						className={styles.EmarSupport_text_link}
					>
						(877) 727-2640
					</a>
				</div>
			</div>
		</>
	);
};

const EPayStatus = ({ facilityName, hasEPayAccess }) => {
	if (!hasEPayAccess) {
		return (
			<>
				<div className={styles.EPay_disabled}>
					<div className={styles.EPay_disabled_check}>
						<svg className={styles.EPay_disabled_check_icon}>
							<use xlinkHref={`${sprite}#icon-clearclose`}></use>
						</svg>
					</div>
					<div className={styles.EPay_disabled_badge}>Disabled</div>
				</div>
				<div className={styles.EPaySupport}>
					<div className={styles.EPaySupport_text}>
						Have questions or need to change your ePay access?
					</div>
					<div className={styles.EPaySupport_text}>
						Contact our support staff at{" "}
						<a
							href={getEmarHref(facilityName)}
							target="_blank"
							rel="noreferrer"
							className={styles.EPaySupport_text_link}
						>
							support@aladvantage.com
						</a>
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			<div className={styles.EPay_enabled}>
				<div className={styles.EPay_enabled_check}>
					<svg className={styles.EPay_enabled_check_icon}>
						<use xlinkHref={`${sprite}#icon-check_circle`}></use>
					</svg>
				</div>
				<div className={styles.EPay_enabled_badge}>Enabled</div>
			</div>
			<div className={styles.EPaySupport}>
				<div className={styles.EPaySupport_text}>
					Have questions or need to change your ePay access?
				</div>
				<div className={styles.EPaySupport_text}>
					Contact our support staff at{" "}
					<a
						href={getEmarHref(facilityName)}
						target="_blank"
						rel="noreferrer"
						className={styles.EPaySupport_text_link}
					>
						support@aladvantage.com
					</a>{" "}
					or give us a call at{" "}
					<a
						href="tel: 877-727-2640"
						target="_blank"
						rel="noreferrer"
						className={styles.EPaySupport_text_link}
					>
						(877) 727-2640
					</a>
				</div>
			</div>
		</>
	);
};

const FacilityDetails = ({
	facility = {},
	currentUser = {},
	facilityType,
	closeModal,
	updateAccessList,
	triggerJustUpdated,
	dispatchAlert,
	enableFacilityType,
}) => {
	const { authData } = useContext(AuthContext);
	// has access to 'Tracker' ONLY - legacy is enabled by default
	// const [isEnabled, setIsEnabled] = useState(facility?.hasAccess ?? false); // ← TO BE DEPRECATED
	// const [enableAllUsers, setEnableAllUsers] = useState(false); // ← TO BE DEPRECATED
	// access record: 'ApplicationByFacility'
	const [accessRecord, setAccessRecord] = useState({});
	// new data: facility record
	const [facilityDetails, setFacilityDetails] = useState({});
	// APP ACCESS STATES - NEW!! (as of 5/15/2023)
	const [trackerAccess, setTrackerAccess] = useState({
		isEnabled: facility?.hasAccess ?? false,
		enableAllUsers: false,
	});
	const [emarAccess, setEmarAccess] = useState({
		isEnabled: false,
		enableAllUsers: false,
	});
	const [ePayAccess, setEPayAccess] = useState({
		isEnabled: facility?.hasEPayAccess ?? false,
		enableAllUsers: false,
	});
	// fetch notifications lists onMount - NOT WIRED UP!!
	const [notificationLists, setNotificationLists] = useState({
		incidents: {},
		assessments: {},
		lockouts: {},
	});

	const { communityName: name } = facility;

	// facility app access toggle handler
	const handleEnableAccess = (e) => {
		const { name, checked } = e.target;
		// setIsEnabled(checked);

		// UPDATE FACILITY ACCESS
		return saveChanges(name, checked);
	};

	// handles enable user's app access checkbox(s)
	const handleEnableAllUsersAccess = (e) => {
		const { name, checked } = e.target;

		// console.group("Enable All Users");
		// console.log("name", name);
		// console.log("checked", checked);
		// console.groupEnd();

		return saveAllUsersAccess(name, checked);
	};

	const syncNotificationsChanges = (listType, updatedRecord) => {
		const type = listType?.toLowerCase();
		setNotificationLists({
			...notificationLists,
			[type]: updatedRecord,
		});
	};

	// fetches facility's record invokes updater fn()
	const saveChanges_OLD = async (checked) => {
		// e.preventDefault();
		const { token } = authData;
		const { facilityID } = facility;

		// ↓ PREVIOUS CODE HANDLING ↓ //
		const updatedRecord = {
			FacilityID: facilityID,
			ApplicationByFacilityID: accessRecord?.ApplicationByFacilityID ?? 0,
			ApplicationID: 2,
			IsAccessible: checked,
			IsActive: true,
		};
		// send 'SAVE' request to save changes
		const accessID = await saveFacilityAccessRecord(token, updatedRecord);

		// const accessID = true;

		if (!isEmptyVal(accessID)) {
			updateAccessList({
				isEnabled: updatedRecord?.IsAccessible,
				facilityID: facilityID,
			});
			// setIsEnabled(updatedRecord?.IsAccessible);
			closeModal();
			return triggerJustUpdated();
		} else {
			closeModal();
			// return setIsEnabled(!accessRecord?.IsAccessible);
			return;
		}
	};
	// fetches facility's record invokes updater fn()
	const saveChanges = async (name, checked) => {
		// e.preventDefault();
		const { token } = authData;
		const { facilityID } = facility;
		// determine appID by checkbox input's "name" property
		const appID = name === `AdvantageTracker` ? 2 : 19;

		// ↓ UPDATED REQUEST HANDLER ↓ //
		const wasSaved = await saveFacilityAppAssignment(
			token,
			checked,
			facilityID,
			appID
		);

		console.log("wasSaved", wasSaved);

		if (wasSaved) {
			updateAccessList({
				isEnabled: checked,
				facilityID: facilityID,
			});
			// setIsEnabled(checked);
			handleAppAccessState(name, checked);
			closeModal();
			return triggerJustUpdated();
		} else {
			closeModal();
			// return setIsEnabled(!accessRecord?.IsAccessible);
			return handleAppAccessState(name, checked);
		}
	};

	const cancelChanges = (e) => {
		e.preventDefault();
		closeModal();
	};

	// handles saving all user's app access checkbox changes
	const saveAllUsersAccess = async (name, checked) => {
		const { token } = authData;
		const { facilityID } = facility;
		// determine appID by checkbox input's "name" property
		const appID = name === `AdvantageTracker` ? 2 : 19;

		// use "!checked" since state has NOT been updated yet
		// const wasSaved = await saveFacilityUsersAppAccess(
		// 	token,
		// 	!checked, // use opposite value of 'checked' since state has NOT been updated yet!
		// 	facilityID,
		// 	appID
		// );
		const wasSaved = true;

		console.group(`Save All Users Access: ${name}`);
		console.log("wasSaved", wasSaved);
		console.log("checked", checked);
		console.log("appID", appID);
		console.groupEnd();

		if (wasSaved) {
			handleUsersAppAccessState(name, checked);
			return dispatchAlert("SUCCESS", {
				heading: `App access was updated!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Whoops! Something went wrong.`,
				subheading: `Please try again.`,
			});
		}
	};

	// wrapper around app access state updaters:
	// ...to enable supporting multiple app access changes via a single handler
	const handleAppAccessState = (appName, isEnabled) => {
		switch (appName) {
			case "AdvantageTracker": {
				return setTrackerAccess({
					...trackerAccess,
					isEnabled: isEnabled,
				});
			}

			case "Chart-Meds (EMAR)": {
				return setEmarAccess({
					...emarAccess,
					isEnabled: isEnabled,
				});
			}

			default:
				return;
		}
	};

	const handleUsersAppAccessState = (appName, isEnabled) => {
		console.log("appName", appName);
		switch (appName) {
			case "AdvantageTracker": {
				return setTrackerAccess({
					...trackerAccess,
					enableAllUsers: isEnabled,
				});
			}

			case "Chart-Meds (EMAR)": {
				return setEmarAccess({
					...emarAccess,
					enableAllUsers: isEnabled,
				});
			}

			default:
				return;
		}
	};

	// fetches facility details:
	// - facility record
	// - tracker access
	// - emar access
	// - are all users enabled for tracker
	// - are all users enabled for emar
	// - all email notification lists
	const fetchFacilityInfo = async () => {
		const { token } = authData;
		const { facilityID } = facility;
		// fetch target facility's AppByFacility record for AdvantageTracker ONLY!!
		const {
			facilityRecord,
			trackerAccess: accessRecord,
			emarAccess: emarAccessRecord,
			enableAllTracker,
			enableAllEmar,
			notifications,
		} = await getFacilityInfoDetails(token, facilityID);

		const currentAccess =
			isEmptyObj(accessRecord) || !accessRecord ? {} : accessRecord;
		const currentEmarAccess =
			isEmptyObj(emarAccessRecord) || !emarAccessRecord ? {} : emarAccessRecord;

		setFacilityDetails(facilityRecord);
		setAccessRecord(currentAccess);
		setTrackerAccess({
			isEnabled: accessRecord?.IsAccessible ?? false,
			enableAllUsers: enableAllTracker,
		});
		setEmarAccess({
			isEnabled: currentEmarAccess?.IsAccessible ?? false,
			enableAllUsers: enableAllEmar,
		});
		return setNotificationLists({ ...notifications });
	};

	// fetch current facility app access (tracker only)
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchFacilityInfo();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.log("facility", facility);

	return (
		<div className={styles.FacilityDetails}>
			<header className={styles.FacilityDetails_header}>
				<h2 className={styles.FacilityDetails_header_title}>Viewing {name}</h2>
				{enableFacilityType && (
					<p
						className={styles.FacilityDetails_header_type}
						style={getTypeColor(facilityType)}
					>
						({facilityType})
					</p>
				)}
			</header>
			{/* FACILITY DETAILS & ADDRESS */}
			<FormSection
				icon="building"
				title="Location & Address"
				hoverText="Displays a facility's address, if available."
			>
				<FacilityAddress facility={facility} />
			</FormSection>
			<Divider />
			{/* FACILITY ID(S) */}
			<FormSection
				icon="switchAccount"
				title="Other Details"
				hoverText="Click to copy any of the below fields."
			>
				<div className={styles.FacilityDetails_id}>
					Facility ID:
					<CopyText text={`${facility?.facilityID?.toUpperCase()}`} />
				</div>
				<div className={styles.FacilityDetails_id}>
					Parent Facility ID:
					<CopyText text={`${facility?.parentID?.toUpperCase() ?? "N/A"}`} />
				</div>
			</FormSection>
			<Divider />

			{/* APPLICATION ACCESS - V2 */}
			<FormSection
				icon="settings"
				title="Application Access - v2"
				hoverText="Change/edit a facility's access to ALA Applications."
			>
				<div className={styles.FacilityDetails_apps_warning}>
					Please note enabling some applications may incur an additional cost.
					Please contact <b>support@aladvantage.com</b> with any questions or
					concerns.
				</div>
				{/* LEGACY - SENIOR CARE EHR */}
				<FacilityAccessSwitch
					appName="SeniorCareVB"
					label="Senior Care EHR"
					isEnabled={true}
					isLocked={true}
					handleAccess={handleEnableAccess}
				/>
				<br style={{ margin: "2rem 0" }} />
				{/* CARE TRACKER */}
				<FacilityAppAccessController
					key="TRACKER-TOGGLE"
					label="Advantage Tracker"
					appName="AdvantageTracker"
					isEnabled={trackerAccess?.isEnabled}
					enableAllUsers={trackerAccess?.enableAllUsers}
					isLocked={isTrackerLocked(facility, accessRecord, currentUser)}
					handleAccess={handleEnableAccess}
					handleAllUsersAccess={handleEnableAllUsersAccess}
				/>

				{/* EMAR - CHART-MEDS */}
				{featureFlags?.facility?.enableEmarToggles && emarAccess?.isEnabled && (
					<>
						<br style={{ margin: "2rem 0" }} />
						<FacilityAppAccessController
							key="CHART-MEDS-EMAR-TOGGLE"
							label="EMAR Access"
							appName="Chart-Meds (EMAR)"
							isEnabled={emarAccess?.isEnabled ?? false}
							enableAllUsers={emarAccess?.enableAllUsers}
							isLocked={isEmarLocked(facility, accessRecord, currentUser)}
							handleAccess={handleEnableAccess}
							handleAllUsersAccess={handleEnableAllUsersAccess}
						/>
					</>
				)}
			</FormSection>
			<Divider />

			{/* EMAR ACCESS STATUS - READ-ONLY */}
			<FormSection
				title="EMAR Access"
				icon="emar"
				hoverText="Check this facility's EMAR access status."
				isHidden={false}
				titleColor={blue[600]}
				iconColor={orange[500]}
			>
				<EmarStatus
					key={`EMAR-STATUS-${facility?.communityName}`}
					hasEmarAccess={emarAccess?.isEnabled}
					facilityName={facility?.communityName ?? ""}
				/>
			</FormSection>
			<Divider />

			{/* EPAY ACCESS STATUS - READ-ONLY */}
			<FormSection
				title="ePay Access"
				icon="ePay"
				hoverText="Check this facility's ePay access status."
				isHidden={false}
				titleColor={blue[600]}
				iconColor={orange[500]}
			>
				<EPayStatus
					key={`EPAY-STATUS-${facility?.communityName}`}
					hasEPayAccess={ePayAccess?.isEnabled}
					facilityName={facility?.communityName ?? ""}
				/>
			</FormSection>
			<Divider />

			{/* FACILITY NOTIFICATIONS - DISABLED BEHIND FEATURE-FLAG */}
			<FormSection
				title="Facility Notifications"
				icon="alert"
				hoverText="Update email notifications & preferences for this facility."
				isHidden={false}
			>
				<AccordionContainer>
					{/* INCIDENT NOTIFICATIONS */}
					<AccordionSection label="Incident Notifications List">
						<FacilityNotificationsPanel
							listType="INCIDENTS"
							title="Incident Notifications List"
							currentFacility={facility}
							currentUser={currentUser}
							notificationsList={notificationLists?.incidents}
							syncChangesHandler={syncNotificationsChanges}
							dispatchAlert={dispatchAlert}
						/>
					</AccordionSection>
					{/* ASSESSMENT CHANGE NOTIFICATIONS */}
					<AccordionSection label="Assessment Change Notifications List">
						<FacilityNotificationsPanel
							listType="ASSESSMENTS"
							title="Assessment Change Notifications List"
							currentFacility={facility}
							currentUser={currentUser}
							notificationsList={notificationLists?.assessments}
							syncChangesHandler={syncNotificationsChanges}
							dispatchAlert={dispatchAlert}
						/>
					</AccordionSection>
					{/* ADMIN LOCKOUT NOTIFICATIONS */}
					<AccordionSection label="Administrator Lockout Notifications List">
						<FacilityNotificationsPanel
							listType="LOCKOUTS"
							title="Administrator Lockout Notifications List"
							currentFacility={facility}
							currentUser={currentUser}
							notificationsList={notificationLists?.lockouts}
							syncChangesHandler={syncNotificationsChanges}
							dispatchAlert={dispatchAlert}
						/>
					</AccordionSection>
				</AccordionContainer>
			</FormSection>
			<Divider />

			{/* SUSPEND/UN-SUSPEND ACCOUNT */}
			<ProtectedFeature
				// isEnabled={enableFeatureViaWhiteList(currentUser?.userID)}
				isEnabled={false}
			>
				<FormSection
					icon="flag"
					title="Suspend/Unsuspend Community"
					hoverText="Suspend/un-suspend a community account"
					iconColor={red[600]}
					titleColor={red[600]}
				>
					{/*  */}
					{/*  */}
					{/*  */}
				</FormSection>
			</ProtectedFeature>

			{/* ACTION BUTTONS FOR FUTURE FEATURES */}
			{featureFlags?.facility?.enableActionButtons && (
				<div className={styles.FacilityDetails_actions}>
					<ButtonSM customStyles={customCSS.cancel} handleClick={cancelChanges}>
						<span>Cancel</span>
					</ButtonSM>
					<ButtonSM customStyles={customCSS.save} handleClick={saveChanges}>
						<span>Save Changes</span>
					</ButtonSM>
				</div>
			)}
		</div>
	);
};

export default FacilityDetails;

FacilityDetails.defaultProps = {};

FacilityDetails.propTypes = {
	facility: PropTypes.object,
	closeModal: PropTypes.func,
	updateAccessList: PropTypes.func,
	triggerJustUpdated: PropTypes.func,
};

const fallbackValue = (val, fallback = "") => {
	if (isEmptyVal(val)) return fallback;
	return val;
};

const AddressUI = ({ address = {} }) => {
	let { street, city, state, zip } = address;
	street = fallbackValue(street, "Unknown");
	city = fallbackValue(city, "Unknown");
	state = fallbackValue(state, "USA");
	zip = fallbackValue(zip, "");

	return (
		<div className={styles.AddressUI}>
			<div className={styles.AddressUI_text}>{street}</div>
			<div className={styles.AddressUI_text}>
				{city}, {state} {zip}
			</div>
		</div>
	);
};

const FacilityAddress = ({ facility = {} }) => {
	const { communityName: name, address } = facility;

	return (
		<div className={styles.FacilityAddress}>
			<div className={styles.FacilityAddress_name}>{name}</div>
			<div className={styles.FacilityAddress_address}>
				<AddressUI address={address} />
			</div>
		</div>
	);
};
