import React, { useState, useContext, useEffect } from "react";
import styles from "../css/pages/YourAppsPage.module.scss";
import { PropTypes } from "prop-types";
import { GlobalStateContext } from "../state/GlobalStateContext";
import { red, purple } from "../helpers/utils_styles";
import {
	generateRedirectUrl,
	getAppAccessForAllFacilities,
	getUserApps,
} from "../helpers/utils_apps";
import { openInNewTab, openInSameTab } from "../helpers/utils_params";
import { sendPageTracking } from "../helpers/utils_tracking";
import { emarSSO } from "../helpers/utils_emar";
import { isEmptyArray, isEmptyVal } from "../helpers/utils_types";
// components
import Dialog from "../components/shared/Dialog";
import ButtonSM from "../components/shared/ButtonSM";
import ViewContainer from "../components/app/ViewContainer";
import AvailableApps from "../components/dashboard/AvailableApps";
import EmarAppLogin from "../components/emar/EmarAppLogin";
import EPayAppLogin from "../components/epay/EPayAppLogin";

// ##TODOS:
// - Wire up requests to fetch user's current app access 'onMount'

/**
 * ViewContainer details:
 * - title
 * - description
 */
const view = {
	title: `Your Apps`,
	desc: `All available apps in one location.`,
};

const customCSS = {
	confirm: {
		padding: ".6rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		marginLeft: ".5rem",
		backgroundColor: purple[600],
		color: purple[100],
	},
	cancel: {
		padding: ".6rem 1.4rem",
		fontSize: "1.4rem",
		fontWeight: "600",
		backgroundColor: "transparent",
		color: red[600],
	},
};

const appsMap = {
	AdvantageTracker: "Care Tracker",
	SeniorCareVB: "EHR: Care Manager",
	AdminPortal: "ALA Portal",
	ChartMedsInterface: "Chart Meds (EMAR)",
};

const defaultEmar = {
	ApplicationId: 19,
	ApplicationName: "ChartMedsInterface",
	Alias: "ChartMeds EMAR",
	IsAccessible: false,
};

const getEmarFacilityList = (emarFacilities = [], allFacilities = []) => {
	if (isEmptyArray(emarFacilities)) {
		return [];
	} else {
		const list = allFacilities.filter((x) =>
			emarFacilities?.includes(x?.facilityID ?? x?.FacilityId)
		);
		const names = list.map((x) => x?.communityName ?? x?.CommunityName);

		console.group(`EMAR FACILITY LIST (USER-SPECIFIC)`);
		console.log("emarFacilities(raw)", emarFacilities);
		console.log("allFacilities(raw)", allFacilities);
		console.log("list", list);
		console.log("names", names);
		console.groupEnd();

		return names;
	}
};
const getEPayFacilityList = (ePayFacilities = [], allFacilities = []) => {
	if (isEmptyArray(ePayFacilities)) {
		return [];
	} else {
		const list = allFacilities.filter((x) =>
			ePayFacilities?.includes(x?.facilityID ?? x?.FacilityId)
		);
		const names = list.map((x) => x?.communityName ?? x?.CommunityName);

		console.group(`EPAY FACILITY LIST (USER-SPECIFIC)`);
		console.log("ePayFacilities(raw)", ePayFacilities);
		console.log("allFacilities(raw)", allFacilities);
		console.log("list", list);
		console.log("names", names);
		console.groupEnd();

		return names;
	}
};

const getTargetFacilityForEmar = (emarFacilities) => {
	//
	//
};

const YourAppsPage = ({ history }) => {
	const { state: globalState } = useContext(GlobalStateContext);
	const { currentUser, currentFacility, apps } = globalState; // remove apps here & instead fetch them
	const { facilities } = currentUser;
	const [selectedApp, setSelectedApp] = useState("");
	const [userApps, setUserApps] = useState({});
	const [emarFacilities, setEmarFacilities] = useState([]);
	const [ePayFacilities, setEPayFacilities] = useState([]);
	const [showRedirectModal, setShowRedirectModal] = useState(false);

	const goToApp = (app) => {
		const { ApplicationName: appName } = app;
		setSelectedApp(appsMap[appName]);
		setShowRedirectModal(true);
		console.log(`Re-directing to...`, appName);
	};

	// ##TODOS:
	// - Update query params name when redirecting to 'Legacy' app
	// 		- userID should be renamed 'u'
	// 		- token should be renamed 's'
	const confirmRedirect = () => {
		setShowRedirectModal(false);
		// generate auth token & append target route
		const url = generateRedirectUrl(selectedApp, {
			userID: currentUser?.userID,
			token: currentUser?.token,
			username: currentUser?.username,
			password: btoa(currentUser?.password),
		});
		console.log(`Generated Url:\n\n`, url);
		// window.open(url, "_self");
		openInSameTab(url);
	};
	const cancelRedirect = () => {
		setSelectedApp("");
		setShowRedirectModal(false);
	};

	// redirects to emar and handles SSO
	const handleEmarRedirect = async (app) => {
		const residentID = 0;
		const { token, facilityID, userID } = currentUser;
		const { selectedFacility } = app;
		const facID = "E7E97361-E602-4A61-B7E1-E2BE440699CC";
		// ##TODOS:
		// - FIGURE OUT WHICH FACILITY ID TO USE FOR A GIVEN USER
		// const emarURL = await emarSSO(token, facilityID, userID, residentID);
		const emarURL = await emarSSO(token, facID, userID);
		console.log("EMAR URL:", emarURL);

		if (!isEmptyVal(emarURL)) {
			return openInSameTab(emarURL);
			// return openInNewTab(emarURL);
		} else {
			return alert("Whoops! There was an issue.");
		}
	};

	const getUserAppDetails = async () => {
		const { token, userID, facilityID } = currentUser;
		const [userAppsAccess, userEmarFacilities, epayFacilities] =
			await Promise.all([
				getUserApps(token, userID, facilityID),
				getAppAccessForAllFacilities(token, 19),
				getAppAccessForAllFacilities(token, 22),
			]);

		return {
			userAppsAccess,
			userEmarFacilities,
			ePayFacilities: epayFacilities,
		};
	};

	const fetchUserApps = async () => {
		// const { token, userID, facilityID } = currentUser;
		// fetches from new ALAServices API: 2 requests, 1 for Legacy & 1 for Tracker
		// const userAppsAccess = await getUserApps(token, userID, facilityID);
		const { userAppsAccess, userEmarFacilities, ePayFacilities } =
			await getUserAppDetails();

		console.group(`FETCH USER-APPS`);
		console.log("userAppsAccess", userAppsAccess);
		console.log("userEmarFacilities", userEmarFacilities);
		console.log("ePayFacilities", ePayFacilities);
		console.groupEnd();

		setUserApps(userAppsAccess);
		setEmarFacilities(userEmarFacilities);
		setEPayFacilities(ePayFacilities);
	};

	// fetch up-to-date user app's access
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchUserApps();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// page tracker
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		sendPageTracking(currentUser?.username, {
			pagename: `YourApps`,
			path: `/portal/apps`,
		});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ViewContainer title={view.title} desc={view.desc}>
				<header className={styles.YourAppsPage_header}>
					<div className={styles.YourAppsPage_header_title}>
						Click an App Icon to Login
					</div>
				</header>
				<div className={styles.YourAppsPage_apps}>
					<AvailableApps
						currentUser={currentUser}
						availableApps={apps}
						goToApp={goToApp}
					/>
					{/* CHART-MEDS EMAR */}
					{!isEmptyArray(emarFacilities) && (
						<EmarAppLogin
							key={`CM-EMAR-SSO-${userApps?.emarAccess?.isAccessible}-${emarFacilities?.length}`}
							currentUser={currentUser}
							currentFacility={currentFacility}
							emarFacilities={getEmarFacilityList(emarFacilities, facilities)}
							app={userApps?.["emarAccess"] ?? defaultEmar}
							availableApps={apps}
							// goToApp={handleEmarRedirect}
						/>
					)}
					{/* ELDERMARK EMAR */}
					{/* {!isEmptyArray(emarFacilities) && (
						<EmarAppLogin
							key={`EM-EMAR-SSO-${userApps?.emarAccess?.isAccessible}-${emarFacilities?.length}`}
							currentUser={currentUser}
							currentFacility={currentFacility}
							emarFacilities={getEmarFacilityList(emarFacilities, facilities)}
							app={userApps?.["emarAccess"] ?? defaultEmar}
							availableApps={apps}
							// goToApp={handleEmarRedirect}
						/>
					)} */}
					{/* EPAY APP LOGIN */}
					{!isEmptyArray(ePayFacilities) && (
						<EPayAppLogin
							key={`EPAY-SSO-${userApps?.ePayAccess?.isAccessible}-${ePayFacilities?.length}`}
							currentUser={currentUser}
							currentFacility={currentFacility}
							ePayFacilities={getEPayFacilityList(ePayFacilities, facilities)}
							app={userApps?.["ePayAccess"] ?? defaultEmar}
							availableApps={apps}
							// goToApp={handleEmarRedirect}
						/>
					)}
				</div>
			</ViewContainer>

			{showRedirectModal && (
				<Dialog
					icon="INFO"
					title={`Redirect to ${selectedApp}?`}
					closeModal={cancelRedirect}
				>
					<ButtonSM
						customStyles={customCSS.cancel}
						handleClick={cancelRedirect}
					>
						Stay Here
					</ButtonSM>
					<ButtonSM
						customStyles={customCSS.confirm}
						handleClick={confirmRedirect}
					>
						Open App
					</ButtonSM>
				</Dialog>
			)}
		</>
	);
};

export default YourAppsPage;

YourAppsPage.defaultProps = {};

YourAppsPage.propTypes = {
	history: PropTypes.object,
};
