import React from "react";
import styles from "../../css/dashboard/AvailableApps.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyArray } from "../../helpers/utils_types";
import AppLogin from "../app/AppLogin";

const appIcons = {
	AdvantageTracker: "chartDark",
	AdminPortal: "inbox",
	SeniorCareVB: "calendarPerson",
	SeniorCareEHR: "calendarPerson",
};

const AvailableApps = ({ currentUser, availableApps = [], goToApp }) => {
	console.log("availableApps", availableApps);
	return (
		<div className={styles.AvailableApps}>
			{!isEmptyArray(availableApps) &&
				availableApps.map((app, idx) => {
					return (
						<AppLogin
							app={app}
							goToApp={() => goToApp(app)}
							key={`${app?.ApplicationName}--${idx}`}
							icon={appIcons[app?.ApplicationName]}
							currentUser={currentUser}
						/>
					);
				})}
		</div>
	);
};

export default AvailableApps;

AvailableApps.defaultProps = {};

AvailableApps.propTypes = {
	availableApps: PropTypes.array.isRequired,
	goToApp: PropTypes.func.isRequired,
};
