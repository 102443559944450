import { stax } from "./utils_endpoints";
import { currentEnv } from "./utils_env";

/**
 * FACILITY-RELATED APIs
 */

/**
 * Adds a new facility record into the Stax System to be used.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Stax & facility params
 * @returns {Object}
 */
const addStaxFacility = async (token, staxParams = {}) => {
	const { facilityID, staxPlatformID, staxApiKey } = staxParams;

	let url = currentEnv.base + stax.facility.addFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ staxApiKey: staxApiKey });
	url += "&" + new URLSearchParams({ staxPlatformId: staxPlatformID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Removes an existing facility record in the Stax System.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const removeStaxFacility = async (token, facilityID) => {
	let url = currentEnv.base + stax.facility.removeFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Updates facility record into the Stax System.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const updateStaxFacility = async (token, facilityID) => {
	let url = currentEnv.base + stax.facility.updateFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Enables a facility in the Stax System.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const enableStaxFacility = async (token, facilityID) => {
	let url = currentEnv.base + stax.facility.enableFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Disables a facility in the Stax System.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const disableStaxFacility = async (token, facilityID) => {
	let url = currentEnv.base + stax.facility.disableFacility;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Fetches a facility's Stax ID (app id.)
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const getStaxAppIDForFacility = async (token, facilityID) => {
	let url = currentEnv.base + stax.facility.getAppID;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Checks is a given facility has Stax access enabled or not.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const checkFacilityStaxAccess = async (token, facilityID) => {
	let url = currentEnv.base + stax.facility.isAccessible;
	url += "?" + new URLSearchParams({ facilityId: facilityID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * RESIDENT-RELATED APIs
 */

/**
 * Adds a new resident record into the Stax System.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.residentID - Resident id for target resident
 * @returns {Object}
 */
const addStaxResident = async (token, staxParams = {}) => {
	const { facilityID, residentID } = staxParams;

	let url = currentEnv.base + stax.resident.addResident;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Removes a resident record in the Stax System.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.residentID - Resident id for target resident
 * @returns {Object}
 */
const removeStaxResident = async (token, staxParams = {}) => {
	const { facilityID, residentID } = staxParams;
	let url = currentEnv.base + stax.resident.removeResident;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Updates a resident record in the Stax System.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.residentID - Resident id for target resident
 * @returns {Object}
 */
const updateStaxResident = async (token, staxParams = {}) => {
	const { facilityID, residentID } = staxParams;
	let url = currentEnv.base + stax.resident.updateResident;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Enables resident record into the Stax System.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.residentID - Resident id for target resident
 * @returns {Object}
 */
const enableStaxResident = async (token, staxParams = {}) => {
	const { facilityID, residentID } = staxParams;
	let url = currentEnv.base + stax.resident.enableResident;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Disables a resident record in the Stax System.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.residentID - Resident id for target resident
 * @returns {Object}
 */
const disableStaxResident = async (token, staxParams = {}) => {
	const { facilityID, residentID } = staxParams;
	let url = currentEnv.base + stax.resident.disableResident;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Transfers an existing Stax resident from one facility to another.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query params object
 * @param {String} staxParams.originFacilityID - Facility guid of origin (ie. 'from') facility
 * @param {String} staxParams.targetFacilityID - Facility guid to target (ie. 'to') community
 * @returns {Object}
 */
const transferStaxResident = async (token, staxParams = {}) => {
	const { originFacilityID, targetFacilityID, residentID } = staxParams;
	let url = currentEnv.base + stax.resident.transferResident;
	url += "?" + new URLSearchParams({ facilityIdFrom: originFacilityID });
	url += "&" + new URLSearchParams({ facilityIdTo: targetFacilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Checks if a given resident has Stax access enabled or not.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const checkResidentStaxAccess = async (token, staxParams = {}) => {
	const { facilityID, residentID } = staxParams;
	let url = currentEnv.base + stax.resident.isAccessible;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

/**
 * USER-RELATED APIs
 */

/**
 * Adds a new user record in the Stax System. This is a pre-requisite to enabling Stax access to the target user.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.userID - User id for target resident
 * @returns {Object}
 */
const addStaxUser = async (token, staxParams = {}) => {
	const { facilityID, userID } = staxParams;

	let url = currentEnv.base + stax.user.addUser;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Removes a user record in the Stax System.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.userID - User id for target resident
 * @returns {Object}
 */
const removeStaxUser = async (token, staxParams = {}) => {
	const { facilityID, userID } = staxParams;

	let url = currentEnv.base + stax.user.removeUser;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Updates a user in the Stax system.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.userID - User id for target resident
 * @returns {Object}
 */
const updateStaxUser = async (token, staxParams = {}) => {
	const { facilityID, userID } = staxParams;

	let url = currentEnv.base + stax.user.updateUser;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Enables a user in the Stax system. Enables access for this user.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.userID - User id for target resident
 * @returns {Object}
 */
const enableStaxUser = async (token, staxParams = {}) => {
	const { facilityID, userID } = staxParams;

	let url = currentEnv.base + stax.user.enableUser;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Disables a user in the Stax system. Disables access for this user.
 * @param {String} token - Auth token
 * @param {Object} staxParams - Query Params object
 * @param {String} staxParams.facilityID - Facility guid to target community
 * @param {String} staxParams.userID - User id for target resident
 * @returns {Object}
 */
const disableStaxUser = async (token, staxParams = {}) => {
	const { facilityID, userID } = staxParams;

	let url = currentEnv.base + stax.user.disableUser;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Checks if a given user has Stax access enabled or not.
 * @param {String} token - Auth token
 * @param {String} facilityID - Facility guid to target community
 * @returns {Object}
 */
const checkUserStaxAccess = async (token, staxParams = {}) => {
	const { facilityID, userID } = staxParams;
	let url = currentEnv.base + stax.user.isAccessible;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};
/**
 * Initiates Single-Sign-On (SSO) from ALA to the Stax platform.
 * @param {String} token - Auth token (from ALAServices )
 * @param {Object} staxParams - Query params object for authentication
 * @param {String} staxParams.facilityID - Facility guid
 * @param {String} staxParams.userID - User guid for user attempting to login to Stax
 * @param {String} staxParams.residentID - Resident ID
 * @returns {Object}
 */
const staxSSO = async (token, staxParams = {}) => {
	const { facilityID, userID, residentID = 0 } = staxParams;
	let url = currentEnv.base + stax.sso.authSSO;
	url += "?" + new URLSearchParams({ facilityId: facilityID });
	url += "&" + new URLSearchParams({ userId: userID });
	url += "&" + new URLSearchParams({ residentId: residentID });

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// facility-related apis
export {
	addStaxFacility,
	removeStaxFacility,
	updateStaxFacility,
	enableStaxFacility,
	disableStaxFacility,
	getStaxAppIDForFacility,
	checkFacilityStaxAccess,
};
// resident-related apis
export {
	addStaxResident,
	removeStaxResident,
	updateStaxResident,
	enableStaxResident,
	disableStaxResident,
	transferStaxResident,
	checkResidentStaxAccess,
};
// user-related apis
export {
	addStaxUser,
	removeStaxUser,
	updateStaxUser,
	enableStaxUser,
	disableStaxUser,
	checkUserStaxAccess,
	// Auth SSO
	staxSSO,
};
