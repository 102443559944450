import React, { useEffect, useState } from "react";
import styles from "../../css/messages/AppMessagesPanel.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import { purple, red } from "../../helpers/utils_styles";
import {
	disableAllMessages,
	fetchLoginPageMessages,
	syncMessagesToState,
	updateMessages,
	updateMessagesModel,
} from "../../helpers/utils_messages";
import { isEmptyArray } from "../../helpers/utils_types";
// components
import AppMessage from "./AppMessage";
import AccordionContainer from "../shared/AccordionContainer";
import AccordionSection from "../shared/AccordionSection";
import ButtonSM from "../shared/ButtonSM";
import Spinner from "../shared/Spinner";

// REQUIREMENTS:
// - View messages, by app, by module within app, by enabled/disabled, by priority
// - Edit messages:
//    - Update message text
//    - Remove message
//    - Change message priority
//    - Delete message
//    - Disable/enable message
// Each Message Should Have:
// - Text message
// - Enable/disable toggle
// - Expiry option
// - Priority option (high, medium, low, no-priority)

const customCSS = {
	cancel: {
		padding: ".8rem 1.6rem",
		fontSize: "1.6rem",
		backgroundColor: "transparent",
		color: red[600],
		marginRight: "1rem",
	},
	save: {
		padding: ".8rem 1.6rem",
		fontSize: "1.6rem",
		backgroundColor: purple[700],
		color: "#ffffff",
	},
};

const AppMessagesPanel = ({ currentUser, dispatchAlert }) => {
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			// global disabler
			disableAllMessages: false,
			// msg1
			msg1: "",
			msg1Priority: null,
			msg1Expiry: null,
			enableMsg1: false,
			// msg2
			msg2: "",
			msg2Priority: null,
			msg2Expiry: null,
			enableMsg2: false,
			// msg3
			msg3: "",
			msg3Priority: null,
			msg3Expiry: null,
			enableMsg3: false,
			// msg4
			msg4: "",
			msg4Priority: null,
			msg4Expiry: null,
			enableMsg4: false,
			// msg5
			msg5: "",
			msg5Priority: null,
			msg5Expiry: null,
			enableMsg5: false,
		});
	const { values } = formState;
	const [rawMessages, setRawMessages] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	const saveMsgChanges = async () => {
		const { token } = currentUser;
		const model = updateMessagesModel(values);

		const wasSaved = await updateMessages(token, model);
		// const wasSaved = false;

		console.log("model", model);

		if (wasSaved) {
			return dispatchAlert("SUCCESS", {
				heading: `Your changes were saved!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error Occurred!`,
				subheading: `${wasSaved}`,
			});
		}
	};

	const cancelMsgChanges = (e) => {
		handleReset(e);
	};

	const disableAll = async () => {
		const { token } = currentUser;
		const wasDisabled = false;
		// const wasDisabled = await disableAllMessages(token);

		if (wasDisabled) {
			setFormState({
				...formState,
				values: {
					...values,
					disableAllMessages: true,
					enableMsg1: false,
					enableMsg2: false,
					enableMsg3: false,
					enableMsg4: false,
					enableMsg5: false,
				},
			});
			return dispatchAlert("SUCCESS", {
				heading: `All Messages Were Disabled!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `There was an error!`,
			});
		}
	};

	const fetchMessageInfo = async () => {
		const { token } = currentUser;
		setIsLoading(true);
		const msgInfo = await fetchLoginPageMessages(token);

		if (!isEmptyArray(msgInfo)) {
			setIsLoading(false);
			setFormState({
				...formState,
				values: {
					...syncMessagesToState(msgInfo),
				},
			});
			return setRawMessages(msgInfo);
		} else {
			setIsLoading(false);
			return setRawMessages({});
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		// fetchMessageInfo();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.AppMessagesPanel}>
			<div className={styles.AppMessagesPanel_header}>
				<div className={styles.AppMessagesPanel_header_title}>
					Login Page Message(s)
				</div>
				<p className={styles.AppMessagesPanel_header_desc}>
					You may add, edit and remove up to 5 separate messages that display on
					the login page of the Admin Portal below.
				</p>
			</div>
			<div className={styles.AppMessagesPanel_editor}>
				<div className={styles.AppMessagesPanel_editor_action}>
					<button
						type="button"
						onClick={disableAll}
						className={styles.AppMessagesPanel_editor_action_btn}
					>
						Disable All Messages
					</button>
				</div>
				{isLoading && <Spinner />}
				<AccordionContainer>
					<AccordionSection label="Message 1">
						<AppMessage
							key={`MESSAGE-1`}
							messageName="Message 1"
							name="msg1"
							id="msg1"
							vals={values}
							handleChange={handleChange}
							handlePriority={handleSettings}
							handleExpiry={handleSettings}
							handleCheckbox={handleCheckbox}
						/>
					</AccordionSection>
					<AccordionSection label="Message 2">
						<AppMessage
							key={`MESSAGE-2`}
							messageName="Message 2"
							name="msg2"
							id="msg2"
							vals={values}
							handleChange={handleChange}
							handlePriority={handleSettings}
							handleExpiry={handleSettings}
							handleCheckbox={handleCheckbox}
						/>
					</AccordionSection>
					<AccordionSection label="Message 3">
						<AppMessage
							key={`MESSAGE-3`}
							messageName="Message 3"
							name="msg3"
							id="msg3"
							vals={values}
							handleChange={handleChange}
							handlePriority={handleSettings}
							handleExpiry={handleSettings}
							handleCheckbox={handleCheckbox}
						/>
					</AccordionSection>
					<AccordionSection label="Message 4">
						<AppMessage
							key={`MESSAGE-4`}
							messageName="Message 4"
							name="msg4"
							id="msg4"
							vals={values}
							handleChange={handleChange}
							handlePriority={handleSettings}
							handleExpiry={handleSettings}
							handleCheckbox={handleCheckbox}
						/>
					</AccordionSection>
					<AccordionSection label="Message 5">
						<AppMessage
							key={`MESSAGE-5`}
							messageName="Message 5"
							name="msg5"
							id="msg5"
							vals={values}
							handleChange={handleChange}
							handlePriority={handleSettings}
							handleExpiry={handleSettings}
							handleCheckbox={handleCheckbox}
						/>
					</AccordionSection>
				</AccordionContainer>
			</div>
			<div className={styles.AppMessagesPanel_actions}>
				<ButtonSM
					customStyles={customCSS.cancel}
					handleClick={cancelMsgChanges}
				>
					Cancel
				</ButtonSM>
				<ButtonSM customStyles={customCSS.save} handleClick={saveMsgChanges}>
					Save
				</ButtonSM>
			</div>
		</div>
	);
};

export default AppMessagesPanel;

AppMessagesPanel.defaultProps = {};

AppMessagesPanel.propTypes = {};
