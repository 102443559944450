import { currentEnv, isDevOrTest, isLocalhost } from "./utils_env";
import { permissions } from "./utils_endpoints";
import { WhiteListedUserModel } from "./utils_models";

////////////////////////////////////////////////////////////
///////////////////// USER PERMISSIONS /////////////////////
////////////////////////////////////////////////////////////

/**
 * Creates/sets user permissions for various ALA modules.
 * @param {String} token - Auth token
 * @param {String} userLoginId - UserLoginID string
 * @param {Object} permissionsModel - Custom 'permissions' object.
 * @returns {Boolean} - Returns whether user's permissions were saved.
 */
const createUserPermissions = async (token, userLoginId, permissionsModel) => {
	let url = currentEnv.base + permissions.create.byUser;
	url += "?" + new URLSearchParams({ userLoginId });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(permissionsModel),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// List of UserIDs that should have access to super-features
const featuresWhiteList = [
	`f61244bd-f3c3-4b2f-a073-2a58f850c046`, // trainingsb@aladvantage.com - Seth's #1 (TEST & PROD)
	`7801cc7e-4462-4442-b214-bcdff70b3f95`, // trainingsb2@aladvantage.com - Seth's #2 (TEST & PROD)
	`edc28929-2c5d-4fc6-8188-f786c1d598d4`, // dandemo@aladvantage.com - Seth's super-user account
	`2807d3cc-c791-4eae-810d-5749a2347a68`, // testingme334@gmail... (PROD)
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@ala... (PROD)
	`86a67ebe-38e6-4b66-bc5a-8b14940ddf68`, // crule@ala... (PROD)
	// `30e9000c-0b38-4645-bde2-824bdbfed572`, // granitecollission@gmail.com
];

/**
 * Internal Feature's WhiteList:
 * - Restricts/enables feature access for INTERNAL-USE-ONLY to select users ONLY!
 */
const internalWhiteList = [
	`e99c5c77-68b7-4ccf-aa5f-5dd5601aff16`, // sgore99@aladvantage.com - main
	`2807d3cc-c791-4eae-810d-5749a2347a68`, // sgore99@ala/testingme334@gmail.com... (PROD)
	`86a67ebe-38e6-4b66-bc5a-8b14940ddf68`, // crule@ala... (PROD)
	`f61244bd-f3c3-4b2f-a073-2a58f850c046`, // trainingsb@ala... (TEST/PROD)
	`7801cc7e-4462-4442-b214-bcdff70b3f95`, // trainingsb2@ala... (TEST/PROD)
	`edc28929-2c5d-4fc6-8188-f786c1d598d4`, // dandemo@ala... (TEST/PROD)
];

/**
 * Concise map of 'Migration' states.
 */
const migrationStates = {
	COMPLETED: "COMPLETED",
	FAILED: "FAILED",
	PROCESSING: "PROCESSING",
	NOT_MIGRATED: "NOT-MIGRATED",
	SCHEDULED: "SCHEDULED",
};

/**
 * Detailed 'Migration' states w/ descriptions of each state.
 */
const MIGRATION_STATES = {
	COMPLETED: {
		state: "COMPLETED",
		desc: "Migration has completed.",
	},
	FAILED: {
		state: "FAILED",
		desc: "There was an error. Migration attempt failed.",
	},
	PROCESSING: {
		state: "PROCESSING",
		desc: "Migration is still processing. Check back later.",
	},
	NOT_MIGRATED: {
		state: "NOT-MIGRATED",
		desc: "Migration has not started. There are no current migrations scheduled.",
	},
	SCHEDULED: {
		state: "SCHEDULED",
		desc: "Migration has been scheduled for a future date. Check back later.",
	},
};

/**
 * 'Feature Flags': temporary feature's map for toggling features on/off.
 * - Should be replaced via table-driven records in the future.
 */
const featureFlags = {
	user: {
		changeResetMethods: true,
		changeAppAccess: true,
		changeUserType: true,
		suspendUser: true,
		suspendMyUser: false, // internal only: allows a logged in user to suspend their account, should force log them out
		unlockUser: true,
		deleteUser: true,
		deleteMyUser: false, // internal only: allows a logged in user to delete their account, should force log them out
		addCustomTitle: false,
		resetUsersPassword: true,
		editAvatar: false,
		enableNameChanges: true,
		newUserButton: false,
		editOwnFacilityAccess: false,
		enableResetPreference: false,
		enableEmar: true,
	},
	facility: {
		deleteFacility: false,
		grantAccess: true,
		enableNotifications: true,
		enableActionButtons: false,
		enableYardiNumber: true,
		enableCustomSearch: false,
		enableDeactivations: false, // '<FacilityAccessRow/>' feature
		enableLockoutSearch: false,
		enableEmar: true,
		enableEmarToggles: false,
	},
	search: {
		byUserType: true,
	},
	views: {},
	banners: {
		disableServiceBannerOnLocal: false,
		enableEmarBanners: !isLocalhost(),
	},
};

// TEMPORARY MOCK EXAMPLE(S)
// organizes 'featureFlags' map by Application
const permissionsByApp = {
	AdvantageTracker: {
		pageViews: [
			{
				name: "enableDailyView",
				id: "enableDailyView",
				label: "Daily View",
			},
			{
				name: "enableCalendarView",
				id: "enableCalendarView",
				label: "Calendar View",
			},
			// reports
			{
				name: "enableCompletionReport",
				id: "enableCompletionReport",
				label: "Completion Report",
			},
			{
				name: "enableExceptionReport",
				id: "enableExceptionReport",
				label: "Exception Report",
			},
			{
				name: "enablePastDueReport",
				id: "enablePastDueReport",
				label: "Past Due Report",
			},
			{
				name: "enableReassessReport",
				id: "enableReassessReport",
				label: "Reassess Report",
			},
			{
				name: "enableTaskCreatedReport",
				id: "enableTaskCreatedReport",
				label: "Task Created Report",
			},
			{
				name: "enableTaskStatus",
				id: "enableTaskStatus",
				label: "Task Status Report",
			},
		],
		tasks: {
			deleteTask: false,
			createTask: false,
			editTask: false,
		},
	},
	SeniorCareVB: {
		pageViews: [
			{
				name: "enableResidentsPage",
				id: "enableResidentsPage",
				label: "Residents Page", // 'Residents' page
			},
			{
				name: "enableReportsPage",
				id: "enableReportsPage",
				label: "Reports Page", // 'Reports' page
			},
			{
				name: "enableChartingPage",
				id: "enableChartingPage",
				label: "Charting Page", // 'Charting' page
			},
			{
				name: "enableCommunityInfo",
				id: "enableCommunityInfo",
				label: "Community Info Page", // 'Community Info' page
			},
			{
				name: "enableArchivedResidentsPage",
				id: "enableArchivedResidentsPage",
				label: "Archived Residents Page", // 'Archived Residents' page
			},
			// tabs within a 'Community Info' page
			{
				name: "enableCommunityMTC", // community maintenance
				id: "enableCommunityMTC", // community maintenance
				label: "Community Maintenance",
			},
			{
				name: "enableUserMTC", // user maintenance
				id: "enableUserMTC", // user maintenance
				label: "User Maintenance",
			},
		],
	},
	AdminPortal: {
		userAccess: { ...featureFlags?.user },
		facilityAccess: { ...featureFlags?.facility },
	},
};

/**
 * Determines whether to enable/disable a feature, via permissions and/or user-type.
 * @param {Object} currentUser - A client-formatted user object.
 * @param {Array} permissions - An array of permissions records.
 * @returns {Boolean} - Returns true|false
 */
const enableAccess = (currentUser = {}) => {
	const { userID, isAdmin, isRegionalAdmin, isSuperUser } = currentUser;
	const normalWhitelist = featuresWhiteList.map((x) => x?.toLowerCase());
	// const whiteListOverride = featuresWhiteList.includes(userID);
	// normalized userIDs to match casing
	const whiteListOverride = normalWhitelist.includes(userID?.toLowerCase());
	// override feature access via 'whitelist'
	if (whiteListOverride) {
		return true;
	} else {
		return isAdmin || isRegionalAdmin || isSuperUser;
	}
};

/**
 * Determines whether a feature should be enabled/disabled.
 * @param {String} featureName - A feature's key name.
 * @param {Object} featureFlags - An object map of feature flags
 * @returns {Boolean} - Returns true|false
 */
const enableFeature = (featureName, featureFlags = {}) => {
	const isEnabled = featureFlags[featureName];
	return isEnabled;
};

/**
 * Determines a user's access, along with the feature's state (eg 'on/off').
 * @param {String} featureName - A string feature name.
 * @param {Object} featureFlags - An object map of feature flags.
 * @param {Object} currentUser - A user for whom to check access for.
 * @returns {Boolean} - Returns true|false
 */
const isAccessEnabled = (featureName, featureFlags = {}, currentUser = {}) => {
	// feature override for whitelist

	if (!enableFeature(featureName, featureFlags)) return false;
	if (featuresWhiteList.includes(currentUser.userID)) return true;
	if (!enableAccess(currentUser)) return false;
	// if (currentUser?.isReadOnly) return false;
	return true;
};

/**
 * Enables/disables a feature via whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaWhiteList = (userID) => {
	const hasOverride = featuresWhiteList.includes(userID);

	return hasOverride;
};

/**
 * Enables/disables a feature via internal whitelist override
 * @param {String} userID - User ID
 * @returns {Boolean} - Returns whether a feature can be enabled (even if turned off) via whitelist
 */
const enableFeatureViaInternalList = (userID) => {
	const normalList = internalWhiteList.map((x) => x?.toLowerCase());
	const hasOverride = normalList.includes(userID?.toLowerCase());
	// const hasOverride = internalWhiteList.includes(userID);

	return hasOverride;
};

// USER-TYPES UPDATER UTILS //

// WHITELISTS UPDATER UTILS //

const updateWhiteListModel = (userVals = {}) => {
	const base = new WhiteListedUserModel(userVals);
	const model = base.getModel();

	return model;
};

// WHITELISTS REQUEST UTILS //

const getUserLoginFromWhiteList = async (token, userID) => {
	let url = currentEnv.base + permissions.whitelist.getInfo;
	url += "?" + new URLSearchParams({ UserLoginID: userID });

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data?.[0] ?? {};
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const getUserLoginsFromWhiteList = async (token, whitelist = []) => {
	//
	//
};

/**
 * Fetches list of whitelisted user records.
 * @param {String} token - Auth token
 * @returns {Array} - Returns an array of whitelisted user objects.
 */
const getUserWhiteList = async (token) => {
	let url = currentEnv.base + permissions.whitelist.get;

	try {
		const request = await fetch(url, {
			method: "GET",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

const addUserToWhiteList = async (token, whiteListModel) => {
	let url = currentEnv.base + permissions.whitelist.save;

	try {
		const request = await fetch(url, {
			method: "POST",
			headers: {
				Authorization:
					"Basic " + btoa(currentEnv.user + ":" + currentEnv.password),
				SecurityToken: token,
				"Content-Type": "application/json",
			},
			body: JSON.stringify(whiteListModel),
		});
		const response = await request.json();
		console.log(`Response:`, response.Data);
		return response.Data;
	} catch (err) {
		console.log(`❌ Oops! An error occurred:`, err);
		return err.message;
	}
};

// permissions request utils
export { createUserPermissions };

export { permissionsByApp };

export { migrationStates, MIGRATION_STATES };

// feature flags & utils
export {
	featuresWhiteList,
	internalWhiteList,
	featureFlags,
	enableAccess,
	enableFeature,
	isAccessEnabled,
	enableFeatureViaWhiteList,
	enableFeatureViaInternalList,
};

export {
	// whitelist model updaters
	updateWhiteListModel,
	// whitelist request utils
	getUserWhiteList,
	addUserToWhiteList,
	getUserLoginFromWhiteList,
};
