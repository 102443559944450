import React, { useState, useEffect } from "react";
import styles from "../../css/firstlogin/AddQuestions.module.scss";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	createSecurityQAModels,
	formatSecurityQuestions,
	getAllSecurityQuestions,
	saveUserSecurityQuestionAndAnswers,
} from "../../helpers/utils_security";
// components
import ModalLG from "../shared/ModalLG";
import CreateNewQuestions from "./CreateNewQuestions";
import ButtonSM from "../shared/ButtonSM";
import { purple, red } from "../../helpers/utils_styles";
import { isEmptyVal } from "../../helpers/utils_types";

const customCSS = {
	cancel: {
		padding: ".7rem 1.6rem",
		fontSize: "1.6rem",
		backgroundColor: "transparent",
		color: red[600],
		border: "none",
		outline: "none",
	},
	save: {
		padding: ".7rem 1.6rem",
		fontSize: "1.6rem",
		backgroundColor: purple[700],
		color: "#ffffff",
	},
};

const readyToSubmit = (vals) => {
	const {
		securityQuestion1: q1,
		securityQuestion2: q2,
		securityQuestion3: q3,
		securityAnswer1: a1,
		securityAnswer2: a2,
		securityAnswer3: a3,
	} = vals;
	const hasQs = !isEmptyVal(q1) && !isEmptyVal(q2) && !isEmptyVal(q3);
	const hasAs = !isEmptyVal(a1) && !isEmptyVal(a2) && !isEmptyVal(a3);

	return hasQs && hasAs;
};

const AddQuestions = ({
	currentUser,
	globalState,
	showModal,
	closeModal,
	dispatchToState,
}) => {
	const [securityQuestions, setSecurityQuestions] = useState([]);
	const { formState, setFormState, handleChange, handleReset } = useForm({
		// security questions
		securityQuestion1: "",
		securityAnswer1: "",
		securityQuestion2: "",
		securityAnswer2: "",
		securityQuestion3: "",
		securityAnswer3: "",
	});
	const { values } = formState;

	// handles form dropdowns
	const handleSelection = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// ##TODOS:
	// - Update API used for updating security answers and questions (wait for Jose to create API)
	// - Update request handlers to support:
	// 		- Creating new security questions/answers
	// 		- Updating existing security questions & answers
	const saveNewQuestions = async (e) => {
		const { token, userID } = currentUser;
		const securityQAModel = createSecurityQAModels(securityQuestions, values);
		console.log(`SecurityQA Models:\n\n`, securityQAModel);

		const wasSaved = await saveUserSecurityQuestionAndAnswers(
			token,
			userID,
			securityQAModel
		);
		// otherwise create entry
		if (wasSaved) {
			console.log(`✅ Response:`, wasSaved);
			return dispatchToState({
				type: "UPDATE_USER_QUESTIONS",
				data: {
					userQAVals: values,
				},
			});
		} else {
			console.log(`❌ Ooops!:`, wasSaved);
			return wasSaved;
		}
	};

	const cancelSecurityChanges = (e) => {
		handleReset(e);
	};

	const fetchQuestions = async () => {
		// fetch all available questions
		const { token } = currentUser;
		const allQuestions = await getAllSecurityQuestions(token);

		setSecurityQuestions(allQuestions);
	};

	// fetch all questions onMount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		fetchQuestions();

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.AddQuestions}>
			<CreateNewQuestions
				key={`REQUIRED-ACTION: SECURITY QUESTIONS`}
				vals={values}
				handleChange={handleChange}
				handleQuestion={handleSelection}
				securityQuestions={[...formatSecurityQuestions(securityQuestions)]}
			/>
			<div className={styles.AddQuestions_actions}>
				<ButtonSM customStyles={customCSS.cancel}>Cancel</ButtonSM>
				<ButtonSM
					isDisabled={!readyToSubmit(values)}
					customStyles={customCSS.save}
				>
					Save
				</ButtonSM>
			</div>
		</div>
	);
};

export default AddQuestions;

AddQuestions.defaultProps = {};

AddQuestions.propTypes = {};
