import React, { useState, useEffect, useCallback } from "react";
import styles from "../../css/facility/FacilityInfo.module.scss";
import { PropTypes } from "prop-types";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { purple, red } from "../../helpers/utils_styles";
import { useForm } from "../../utils/useForm";
import {
	updateCustomFacilityModel,
	updateFacilityInfo,
	updateFacilityModel,
} from "../../helpers/utils_facility";
import { fetchAndProcessNotifications } from "../../helpers/utils_notifications";
import { sendPageTracking } from "../../helpers/utils_tracking";
import {
	enableFeatureViaWhiteList,
	featureFlags,
} from "../../helpers/utils_permissions";
// components
import EditFacilityInfo from "./EditFacilityInfo";
import FacilityNotificationsPanel from "./FacilityNotificationsPanel";
import Divider from "../forms/Divider";
import CopyText from "../forms/CopyText";
import AccordionContainer from "../shared/AccordionContainer";
import AccordionSection from "../shared/AccordionSection";

const customCSS = {
	loaded: {
		color: purple[600],
	},
	notLoaded: {
		color: red[600],
	},
	divider: {
		marginBottom: "6rem",
	},
	subtitle: {
		fontSize: "1.4rem",
		fontWeight: "600",
		marginBottom: "0rem",
	},
};

const getParentID = (currentFacility = {}) => {
	if (isEmptyVal(currentFacility?.facilityID)) return "No facility loaded.";
	if (isEmptyVal(currentFacility?.parentID)) return "No parent.";
	return currentFacility?.parentID?.toUpperCase();
};
const getFacilityID = (currentFacility = {}) => {
	if (isEmptyVal(currentFacility?.facilityID)) return "No facility loaded.";
	return currentFacility?.facilityID?.toUpperCase();
};

const Heading = ({ currentFacility }) => {
	return (
		<b
			style={
				!isEmptyObj(currentFacility) ? customCSS.loaded : customCSS.notLoaded
			}
		>
			{currentFacility?.communityName ?? `Please select a facility.`}
		</b>
	);
};

const FacilityInfo = ({
	currentFacility = {},
	currentUser = {},
	globalState = {},
	dispatchToState,
	dispatchAlert,
}) => {
	const [notificationLists, setNotificationLists] = useState({
		incidents: {},
		assessments: {},
		lockouts: {},
	});
	const { formState, setFormState, handleChange, handleCheckbox, handleReset } =
		useForm({
			communityName: currentFacility?.communityName,
			street: currentFacility?.address?.street ?? "",
			// ##TODOS:
			// - Change field to read from to: 'currentFacility?.address?.suiteNumber'
			// - Wait for jose to fix the 'GetCommunitiesByUserEmail' API.
			suiteNumber: currentFacility?.suiteNumber ?? "",
			city: currentFacility?.address?.city ?? "",
			state: currentFacility?.address?.state ?? "",
			zip: currentFacility?.address?.zip ?? "",
			phone: currentFacility?.phone ?? "",
			email: currentFacility?.email ?? "",
			fax: currentFacility?.fax ?? "",
			execDirector: currentFacility?.execDirector ?? "",
			alaDirector: currentFacility?.alaDirector ?? "",
			licenseNumber: currentFacility?.licenseNumber ?? "",
			yardiNumber: currentFacility?.yardiNumber ?? "",
		});
	const { values, touched } = formState;

	const handleCustomFields = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
			touched: {
				...touched,
				[name]: true,
			},
		});
	};

	const updateFacility = async () => {
		const { token } = currentUser;
		// ##TODOS:
		// - Include entirety of 'FACILITY' record ✓
		// - Need to carry over 'bitALAAssessment' & other fields as well ✓
		// - Add validation for 'shared' dataset(s)
		// const updatedRecord = updateFacilityModel({
		// 	...values,
		// 	facilityID: currentFacility?.facilityID,
		// 	parentID: currentFacility?.parentID,
		// 	timeZone: currentFacility?.timeZone,
		// });
		// - Added support for 'YardiFacilityNumber' field to UI & model
		// NEW MODEL - updated 7/29/2021
		const updatedRecord = updateCustomFacilityModel({
			...values,
			facilityID: currentFacility?.facilityID,
		});

		console.log("updatedRecord", updatedRecord);

		// const wasUpdated = false;
		const wasUpdated = await updateFacilityInfo(token, updatedRecord);

		if (wasUpdated) {
			dispatchToState({
				type: "UPDATE_FACILITY_INFO",
				facilityInfo: { ...values },
			});
			return dispatchAlert("SUCCESS", {
				heading: `Your changes were saved!`,
			});
		} else {
			return dispatchAlert("ERROR", {
				heading: `Error!`,
				subheading: `Changes were NOT saved. Try again!`,
			});
		}
	};
	const cancelUpdates = (e) => {
		handleReset(e);
	};

	// syncs changes from parent, to prevent updates not being reflected upon re-render
	const syncNotificationLists = (listType, updatedRecord = {}) => {
		const lowerList = listType?.toLowerCase();
		return setNotificationLists({
			...notificationLists,
			[lowerList]: { ...updatedRecord },
		});
	};

	const getNotificationLists = useCallback(async () => {
		const { token } = currentUser;
		const { facilityID } = currentFacility;

		const allRecentRecords = await fetchAndProcessNotifications(token, {
			facilityID: facilityID,
			index: 0,
			rows: 5,
		});

		return setNotificationLists({
			incidents: allRecentRecords?.incidents ?? {},
			assessments: allRecentRecords?.assessments ?? {},
			lockouts: allRecentRecords?.lockouts ?? {},
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// fetch all notifications' lists upon loading a facility
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		if (!isEmptyVal(currentFacility?.facilityID)) {
			getNotificationLists();
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// page tracker
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		sendPageTracking(currentUser?.username, {
			pagename: `AdminPanel-FacilityInfo`,
			path: `/portal/access?activeTab=facility-info`,
		});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.FacilityInfo}>
			<header className={styles.FacilityInfo_header}>
				<h2 className={styles.FacilityInfo_header_title}>
					Facility Details: <Heading currentFacility={currentFacility} />
				</h2>
				{/* FACILITY ID */}
				<div className={styles.FacilityInfo_header_subtitle}>
					Facility ID:{" "}
					<b>
						<CopyText
							text={getFacilityID(currentFacility)}
							customStyles={customCSS.subtitle}
						/>
					</b>
				</div>
				<div className={styles.FacilityInfo_header_subtitle}>
					Parent ID:{" "}
					<b>
						<CopyText
							text={getParentID(currentFacility)}
							customStyles={customCSS.subtitle}
						/>
					</b>
				</div>
			</header>
			<div className={styles.FacilityInfo_main}>
				<EditFacilityInfo
					key={currentFacility?.facilityID}
					vals={values}
					formState={formState}
					handleCustomFields={handleCustomFields}
					handleChange={handleChange}
					handleCheckbox={handleCheckbox}
					handleReset={handleReset}
					currentUser={currentUser}
					currentFacility={currentFacility}
					saveChanges={updateFacility}
					cancelChanges={cancelUpdates}
					dispatchToState={dispatchToState}
				/>
				<Divider customStyles={customCSS.divider} />

				{!isEmptyVal(currentFacility?.facilityID) &&
					featureFlags?.facility?.enableNotifications && (
						<>
							<div className={styles.FacilityInfo_main_title}>
								Facility Notifications
							</div>
							<AccordionContainer>
								<AccordionSection label="Incident Notifications List">
									<FacilityNotificationsPanel
										key={`INCIDENT_LIST`}
										listType="INCIDENTS"
										title="Incident Notifications List"
										notificationsList={notificationLists?.incidents ?? {}}
										currentUser={currentUser}
										currentFacility={currentFacility}
										dispatchAlert={dispatchAlert}
										syncChangesHandler={syncNotificationLists}
									/>
								</AccordionSection>
								<AccordionSection label="Assessment Change Notifications List">
									<FacilityNotificationsPanel
										key={`ASSESSMENT_CHANGE_LIST`}
										listType="ASSESSMENTS"
										title="Assessment Change Notifications List"
										notificationsList={notificationLists?.assessments ?? {}}
										currentUser={currentUser}
										currentFacility={currentFacility}
										dispatchAlert={dispatchAlert}
										syncChangesHandler={syncNotificationLists}
									/>
								</AccordionSection>
								<AccordionSection label="Administrator Lockout Notifications List">
									<FacilityNotificationsPanel
										key={`ADMIN_LOCKOUT_LIST`}
										listType="LOCKOUTS"
										title="Administrator Lockout Notifications List"
										notificationsList={notificationLists?.lockouts ?? {}}
										currentUser={currentUser}
										currentFacility={currentFacility}
										dispatchAlert={dispatchAlert}
										syncChangesHandler={syncNotificationLists}
									/>
								</AccordionSection>
							</AccordionContainer>
						</>
					)}
			</div>
		</div>
	);
};

export default FacilityInfo;

FacilityInfo.defaultProps = {};

FacilityInfo.propTypes = {
	currentFacility: PropTypes.object,
	currentUser: PropTypes.object,
	globalState: PropTypes.object,
	dispatchAlert: PropTypes.func,
	dispatchToState: PropTypes.func,
};
