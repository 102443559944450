import React, { useState } from "react";
import styles from "../../css/facility/CustomFacilitySearch.module.scss";
import sprite from "../../assets/icons/modals-complete.svg";
import { PropTypes } from "prop-types";
import { useForm } from "../../utils/useForm";
import {
	formatAndSortUserFacilities,
	matchFacilityByName,
	matchUserFacilityByName,
	searchForFacilityBy,
} from "../../helpers/utils_facility";
import { isEmptyVal } from "../../helpers/utils_types";
import { blueGrey, red } from "../../helpers/utils_styles";
// components
import FormSection from "../forms/FormSection";
import CustomCheckbox from "../shared/CustomCheckbox";
import TextInput from "../shared/TextInput";
import ButtonSM from "../shared/ButtonSM";
import FacilitySearchResults from "./FacilitySearchResults";
import ModalLG from "../shared/ModalLG";
import FacilitySearchDetails from "./FacilitySearchDetails";

const customCSS = {
	checkbox: {
		marginBottom: ".6rem",
	},
	searchBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: blueGrey[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
	},
	cancelBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: "transparent",
		color: red[600],
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "1rem",
	},
	clearBtn: {
		padding: ".7rem 1.4rem",
		borderRadius: "5rem",
		backgroundColor: red[600],
		color: "#ffffff",
		fontSize: "1.4rem",
		fontWeight: "500",
		marginRight: "auto",
	},
	dropdown: {
		width: "35rem",
	},
	searchInput: {
		backgroundColor: "#ffffff",
	},
	userIDSearch: {
		backgroundColor: "#ffffff",
	},
};

// checks if required selections have been made
const noTypeSelected = (vals = {}) => {
	const {
		// bools
		byEmail,
		byParentID,
		byFacilityID,
		bySuspendedStatus,
		byCommunityName,
		byParentChildren,
		// facility field searches
		emailSearch,
		parentIDSearch,
		facilityIDSearch,
		communityNameSearch,
		suspendedStatusSearch,
		parentChildrenSearch,
	} = vals;

	// no search type or search value supplied
	const hasNoType =
		!byEmail &&
		!byParentID &&
		!byFacilityID &&
		!bySuspendedStatus &&
		!byCommunityName &&
		!byParentChildren;
	// user types
	const hasNoSearch =
		isEmptyVal(emailSearch) &&
		isEmptyVal(parentIDSearch) &&
		isEmptyVal(facilityIDSearch) &&
		isEmptyVal(communityNameSearch) &&
		isEmptyVal(suspendedStatusSearch) &&
		isEmptyVal(parentChildrenSearch);

	return hasNoSearch && hasNoType;
};

const CustomSearch = ({
	vals,
	handleChange,
	handleCheckbox,
	handleSettings,
	allFacilities = [],
}) => {
	// handles which to render
	const renderSearch = (vals) => {
		switch (true) {
			case vals?.byEmail: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_EMAIL`}
							label="Search By Email Address"
							name="emailSearch"
							id="emailSearch"
							placeholder="Enter an email..."
							val={vals.emailSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byCommunityName: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_COMMUNITY_NAME`}
							label="Search By Community Name"
							name="communityNameSearch"
							id="communityNameSearch"
							placeholder="Enter User's Community Name..."
							val={vals.communityNameSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}
			case vals?.byFacilityID: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_FACILITY_ID`}
							label="Search By Facility ID"
							name="facilityIDSearch"
							id="facilityIDSearch"
							placeholder="Enter Facility ID..."
							val={vals.facilityIDSearch}
							handleChange={handleChange}
							customStyles={customCSS.searchInput}
						/>
					</div>
				);
			}

			case vals?.byParentID: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_PARENT_ID`}
							label="Search By Parent ID"
							name="parentIDSearch"
							id="parentIDSearch"
							placeholder="Enter Parent ID..."
							val={vals.parentIDSearch}
							handleChange={handleChange}
							customStyles={customCSS.userIDSearch}
						/>
					</div>
				);
			}
			case vals?.byParentChildren: {
				return (
					<div className={styles.CustomSearch}>
						<TextInput
							key={`SEARCH_BY_PARENT_ID`}
							label="Search By Parent ID"
							name="parentChildrenSearch"
							id="parentChildrenSearch"
							placeholder="Enter Parent ID..."
							val={vals.parentChildrenSearch}
							handleChange={handleChange}
							customStyles={customCSS.userIDSearch}
						/>
					</div>
				);
			}
			case vals?.bySuspendedStatus: {
				return (
					<div className={styles.CustomSearch}>
						<CustomCheckbox
							label="Is Suspended"
							name="suspendedStatusSearch"
							id="suspendedStatusSearch"
							val={vals?.suspendedStatusSearch}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
					</div>
				);
			}
			default:
				return null;
		}
	};

	return <>{renderSearch(vals)}</>;
};

const CustomFacilitySearch = ({ currentUser = {}, facilities = [] }) => {
	const { formState, setFormState, handleCheckbox, handleChange, handleReset } =
		useForm({
			// by user field
			byEmail: false,
			byCommunityName: false,
			byFacilityID: false,
			byParentID: false,
			bySuspendedStatus: false,
			byParentChildren: false, // returns all children for a parent
			// not implemented yet
			byAddress: false,
			byState: false,
			byCity: false,
			byPhoneNumber: false,
			byExecDirector: false,
			byALADirector: false,
			// searches
			facilityIDSearch: "",
			parentIDSearch: "",
			communityNameSearch: "",
			emailSearch: "",
			suspendedStatusSearch: false,
			parentChildrenSearch: "",
			// not enabled/active yet
			addressSearch: "",
			citySearch: "",
			stateSearch: "",
			phoneNumberSearch: "",
			execDirectorSearch: "",
			alaDirectorSearch: "",
		});
	const { values } = formState;
	const [searchResults, setSearchResults] = useState([]);
	const [isLoadingResults, setIsLoadingResults] = useState(false);
	const [showFacilityDetails, setShowFacilityDetails] = useState(false);
	const [selectedFacility, setSelectedFacility] = useState(null);

	const handleSettings = (name, val) => {
		setFormState({
			...formState,
			values: {
				...values,
				[name]: val,
			},
		});
	};

	// fires off request w/ search values
	const runSearch = async (e) => {
		const { token } = currentUser;
		setIsLoadingResults(true);
		const results = await searchForFacilityBy(token, values);
		console.log("results", results);

		if (results) {
			setIsLoadingResults(false);
			return setSearchResults(results);
		} else {
			return setIsLoadingResults(false);
		}
	};

	const selectFacility = (facility) => {
		setShowFacilityDetails(true);
		setSelectedFacility(facility);
	};

	// clear form & close search window
	const cancelSearch = (e) => {
		handleReset(e);
	};
	// clear our form fields
	const clearSearch = (e) => {
		handleReset(e);
		setSearchResults([]);
	};

	return (
		<>
			<div className={styles.CustomFacilitySearch}>
				<section className={styles.CustomFacilitySearch_main}>
					{/* SEARCH BY: EMAIL, NAME, FACILITY */}
					<FormSection
						title="Search By:"
						icon="checkbox"
						hoverText="Search by a user-related value."
					>
						<CustomCheckbox
							isDisabled={
								values?.byCommunityName ||
								values?.byParentID ||
								values?.byFacility ||
								values?.byFacilityID ||
								values?.byParentChildren
							}
							label="By Email"
							name="byEmail"
							id="byEmail"
							val={values?.byEmail}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byEmail ||
								values?.byParentID ||
								values?.bySuspendedStatus ||
								values?.byFacilityID ||
								values?.byParentChildren
							}
							label="By Community Name"
							name="byCommunityName"
							id="byCommunityName"
							val={values?.byCommunityName}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byCommunityName ||
								values?.byEmail ||
								values?.bySuspendedStatus ||
								values?.byFacilityID ||
								values?.byParentChildren
							}
							label="By Parent ID"
							name="byParentID"
							id="byParentID"
							val={values?.byParentID}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byCommunityName ||
								values?.byEmail ||
								values?.bySuspendedStatus ||
								values?.byParentID ||
								values?.byParentChildren
							}
							label="By Facility ID"
							name="byFacilityID"
							id="byFacilityID"
							val={values?.byFacilityID}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byEmail ||
								values?.byParentID ||
								values?.byCommunityName ||
								values?.byFacilityID ||
								values?.byParentChildren
							}
							label="By Suspended Status"
							name="bySuspendedStatus"
							id="bySuspendedStatus"
							val={values?.bySuspendedStatus}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						<CustomCheckbox
							isDisabled={
								values?.byEmail ||
								values?.byParentID ||
								values?.byCommunityName ||
								values?.byFacilityID
							}
							label="Show Parent's Children"
							name="byParentChildren"
							id="byParentChildren"
							val={values?.byParentChildren}
							handleCheckbox={handleCheckbox}
							customStyles={customCSS.checkbox}
						/>
						{/* CUSTOM SEARCH INPUT RENDERER */}
						<CustomSearch
							vals={values}
							handleChange={handleChange}
							handleSettings={handleSettings}
							allFacilities={[
								...formatAndSortUserFacilities(currentUser?.facilities),
							]}
						/>
					</FormSection>

					{/* ACTIONS */}
					<div className={styles.CustomFacilitySearch_main_actions}>
						<ButtonSM
							customStyles={customCSS.clearBtn}
							handleClick={clearSearch}
						>
							<span>Clear Search</span>
						</ButtonSM>
						<ButtonSM
							customStyles={customCSS.cancelBtn}
							handleClick={cancelSearch}
						>
							<span>Cancel Search</span>
						</ButtonSM>
						<ButtonSM
							isDisabled={noTypeSelected(values)}
							customStyles={customCSS.searchBtn}
							handleClick={runSearch}
						>
							<svg className={styles.CustomFacilitySearch_main_actions_icon}>
								<use xlinkHref={`${sprite}#icon-search2`}></use>
							</svg>
							<span>Run Search</span>
						</ButtonSM>
					</div>
				</section>
				<section className={styles.CustomFacilitySearch_main_results}>
					<FacilitySearchResults
						key={`FACILITY-SEARCH:${searchResults?.length}`}
						isLoading={isLoadingResults}
						searchResults={searchResults}
						setSelectedFacility={selectFacility}
					/>
				</section>
			</div>

			{showFacilityDetails && (
				<ModalLG
					title={`Facility Search: ${selectedFacility?.CommunityName}`}
					closeModal={() => setShowFacilityDetails(false)}
				>
					<FacilitySearchDetails facility={selectedFacility} />
				</ModalLG>
			)}
		</>
	);
};

export default CustomFacilitySearch;

CustomFacilitySearch.defaultProps = {};

CustomFacilitySearch.propTypes = {};
