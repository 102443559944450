import React, { useCallback, useEffect, useState } from "react";
import styles from "../../css/views/ManageUserAccessView.module.scss";
import sprite from "../../assets/icons/buttons.svg";
import { PropTypes } from "prop-types";
import { useAlerts, initialSettings } from "../../utils/useAlerts";
import { isEmptyObj, isEmptyVal } from "../../helpers/utils_types";
import { purple } from "../../helpers/utils_styles";
import { getProcessedFacililtyUserInfo } from "../../helpers/utils_security";
import { getUserFacilityID } from "../../helpers/utils_facility";
import { sendPageTracking } from "../../helpers/utils_tracking";
import { enableFeatureViaWhiteList } from "../../helpers/utils_permissions";
// components
import ModalLG from "../../components/shared/ModalLG";
import ButtonSM from "../../components/shared/ButtonSM";
import UserAccessTable from "../../components/admin/UserAccessTable";
import Placeholder from "../../components/shared/Placeholder";
import ProtectedFeature from "../../components/permissions/ProtectedFeature";
import CreateUserForm from "../../components/user/CreateUserForm";

import BadgesLegend from "../../components/admin/BadgesLegend";

const customCSS = {
	newUser: {
		width: "12rem",
		padding: ".6rem 1.3rem",
		borderRadius: "5rem",
		backgroundColor: purple[500],
		fontWeight: "600",
		fontSize: "1.4rem",
	},
	spinner: {
		display: "block",
		margin: "1rem auto",
	},
};

const getAppKey = (appName) => {
	const keys = {
		AdvantageTracker: "trackerAccess",
		AdminPortal: "portalAccess",
		SeniorCareVB: "legacyAccess",
		SeniorCareEHR: "legacyAccess",
	};

	return keys[appName];
};

const ManageUserAccessView = ({
	globalState = {},
	selectedFacility,
	dispatchToState,
	currentFacility,
}) => {
	const { currentUser, facilities } = globalState;
	const { AlertsHandler, dispatchAlert } = useAlerts({ ...initialSettings });
	const [showNewUserModal, setShowNewUserModal] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	// const [usersList, setUsersList] = useState(currentFacility?.users);
	const [usersList, setUsersList] = useState([]);
	const [accessLists, setAccessLists] = useState({
		trackerAccess: [],
		legacyAccess: [],
		portalAccess: [], // added 10/11/2021 at 10:29 AM
		emarAccess: [],
		ePayAccess: [],
	});
	// app access state(s)
	const { trackerAccess, legacyAccess, portalAccess, emarAccess, ePayAccess } =
		accessLists;
	// legacy only users
	const [isLegacyOnly, setIsLegacyOnly] = useState(false);

	// fetches, processes/formats & syncs facility users to local state
	const fetchUserData = useCallback(async () => {
		setIsLoading(true);
		const { token } = currentUser;
		const facilityID = getUserFacilityID(
			selectedFacility,
			currentUser?.facilities
		);
		// fire off request for users & access records
		const data = await getProcessedFacililtyUserInfo(token, facilityID);
		const { usersList, appAccess, isLegacyOnly = false } = data;

		console.log("appAccess", appAccess);

		if (!isEmptyObj(data)) {
			setIsLoading(false);
			setUsersList([...usersList]);
			setIsLegacyOnly(isLegacyOnly);
			return setAccessLists({
				trackerAccess: appAccess?.trackerAccess?.userIDs ?? [],
				legacyAccess: appAccess?.legacyAccess?.userIDs ?? [],
				// added 10/11/2021 at 10:29 AM
				portalAccess: appAccess?.portalAccess?.userIDs ?? [],
				emarAccess: appAccess?.emarAccess?.userIDs ?? [],
				ePayAccess: appAccess?.ePayAccess?.userIDs ?? [],
			});
		} else {
			setIsLoading(false);
			setUsersList([]);
			return setAccessLists({
				trackerAccess: [],
				legacyAccess: [],
				portalAccess: [],
				emarAccess: [],
				ePayAccess: [],
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initNewUser = () => {
		setShowNewUserModal(true);
	};

	// new handler for reflecting app access changes to the user-table
	const updateAppAccess = (appName, accessChanges = {}) => {
		const { isEnabled, userID } = accessChanges;
		// unedited list
		const appKey = getAppKey(appName);
		const list = [...accessLists[appKey]];
		// remove existing user from list
		if (isEnabled) {
			return setAccessLists({
				...accessLists,
				[appKey]: [...list, userID],
			});
		} else {
			// remove userID from
			return setAccessLists({
				...accessLists,
				[appKey]: [...list.filter((x) => x !== userID)],
			});
		}
	};

	const syncDeletedUser = (deletedUserID) => {
		const newList = usersList.filter((x) => x.userID !== deletedUserID);
		setUsersList(newList);
	};

	// fetches users on mount
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}
		if (!isEmptyVal(selectedFacility)) {
			fetchUserData();
		}

		return () => {
			isMounted = false;
		};
	}, [fetchUserData, selectedFacility]);

	// send page tracking info
	useEffect(() => {
		let isMounted = true;
		if (!isMounted) {
			return;
		}

		sendPageTracking(currentUser?.username, {
			pagename: `AdminPanel-Users`,
			path: `/portal/access?activeTab=users`,
		});

		return () => {
			isMounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isEmptyVal(selectedFacility)) {
		return (
			<div className={styles.ManageUserAccessView_warning}>
				<Placeholder msg="Select A Facility (above)" size="MD" />
			</div>
		);
	}
	return (
		<>
			<div className={styles.ManageUserAccessView}>
				<section className={styles.ManageUserAccessView_main}>
					<div className={styles.ManageUserAccessView_main_row}>
						<BadgesLegend />
						<ProtectedFeature
							isEnabled={enableFeatureViaWhiteList(currentUser?.userID)}
						>
							<div className={styles.ManageUserAccessView_main_newUser}>
								<ButtonSM
									handleClick={initNewUser}
									customStyles={customCSS.newUser}
								>
									<svg
										className={styles.ManageUserAccessView_main_newUser_icon}
									>
										<use xlinkHref={`${sprite}#icon-plus21`}></use>
									</svg>
									New User
								</ButtonSM>
							</div>
						</ProtectedFeature>
					</div>

					<UserAccessTable
						key={`${trackerAccess?.length}_${legacyAccess?.length}_${portalAccess?.length}_${usersList?.length}-${emarAccess?.length}`}
						isLegacyOnly={isLegacyOnly}
						isLoading={isLoading}
						currentUser={currentUser}
						currentFacility={currentFacility}
						usersList={usersList}
						trackerAccessList={accessLists.trackerAccess}
						legacyAccessList={accessLists.legacyAccess}
						portalAccessList={accessLists.portalAccess}
						emarAccessList={accessLists.emarAccess}
						ePayAccessList={accessLists.ePayAccess}
						updateAccessList={updateAppAccess}
						dispatchAlert={dispatchAlert}
						dispatchToState={dispatchToState}
						allFacilities={facilities}
						syncDeletedUser={syncDeletedUser}
					/>
				</section>
			</div>

			{showNewUserModal && (
				<ModalLG title="New User" closeModal={() => setShowNewUserModal(false)}>
					<CreateUserForm
						currentUser={currentUser}
						dispatchToState={dispatchToState}
						dispatchAlert={dispatchAlert}
						userTypes={globalState?.userTypes}
						userTitles={globalState?.userTitles}
						allApps={globalState?.apps}
						closeModal={() => setShowNewUserModal(false)}
					/>
				</ModalLG>
			)}

			{/* ALERTS UI */}
			{AlertsHandler}
		</>
	);
};

export default ManageUserAccessView;

ManageUserAccessView.defaultProps = {};

ManageUserAccessView.propTypes = {
	globalState: PropTypes.object,
	dispatchToState: PropTypes.func,
};
