import { isEmptyVal } from "./utils_types";
import { appIDs } from "./utils_apps";
import { matchQuestionIDFromStr } from "./utils_security";
import { addHours, addMinutes } from "date-fns";
import {
	matchTitleFromStr,
	matchUserTitleFromStr,
	matchUserTypeFromStr,
} from "./utils_user";

// FACILITY-RELATED MODELS //

/**
 * 'FacilityInfo' model: represents the 'FACILITY' table data-structure
 * 	- Contains data about that facility, such as ID, director, address, license etc.
 */
class FacilityInfo {
	constructor(facilityData = {}) {
		console.log("facilityData", facilityData);

		this._facilityID = facilityData?.facilityID;
		this._parentID = facilityData?.parentID ?? "";
		this._communityName = facilityData?.communityName;
		// contacts
		this._email = facilityData?.email ?? "";
		this._phone = facilityData?.phone ?? "";
		this._fax = facilityData?.fax ?? "";
		// employees
		this._execDirector = facilityData?.execDirector ?? "";
		this._alaDirector = facilityData?.alaDirector;
		// location
		this._address = facilityData?.street ?? "";
		this._suiteNumber = facilityData?.suiteNumber ?? "";
		this._city = facilityData?.city ?? "";
		this._state = facilityData?.state ?? "";
		this._zip = facilityData?.zip ?? "";
		// timezone
		this._timeZoneID = facilityData?.timeZone ?? "";
		// ids
		this._licenseNumber = facilityData?.licenseNumber ?? "";
		this._yardiNumber = facilityData?.yardiNumber ?? "";

		this._model = {
			guidFacility: this._facilityID, // str, required
			CommunityName: this._communityName, // str optional
			ExecDirector: this._execDirector, // str optional
			ALDirector: this._alaDirector, // str optional
			Address1: this._address, // str optional
			Address2: this._suiteNumber, // str optional
			City: this._city, // str optional
			State: this._state, // str optional
			ZIP: this._zip, // str optional
			Phone: this._phone, // str optional
			Fax: this._fax, // str optional
			Email: this._email, // str optional
			LicenseNumber: this._licenseNumber, // str optional
			guidParentFacility: this._parentID, // str optional
			APName: "", // str optional
			APAddress1: "", // str optional
			APAddress2: "", // str optional
			APCity: "", // str optional
			APState: "", // str optional
			APZIP: "", // str optional
			APEmail: "", // str optional
			APCorpName: "", // str optional
			APLastINVno: 0, // int optional (prev. 0)
			APLastQTY: 0, // int optional (prev. 0)
			APRate: 0, // int optional (prev. 0)
			LocalTimeZoneID: this._timeZoneID, // str optional
			bitALAAssessment: 0, // int optional
			bitMedications: true, // bool optional
			bitContacts: true, // bool optional
			bitAssessmentALA: true, // bool optional
			QMLink: true, // bool optional
			QMAuthID: "", // bool optional
			MedicaidProviderNum: "", // str optional
			CreatedDateTime: "", // datestring optional
			bitSuspendSubscription: false, // bool optional
		};
	}
	getProp(prop) {
		return this._model?.[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'DefaultExceptionModel'
 * - This is the base exception model, not specific to a facility, resident or user.
 */
class DefaultExceptionModel {
	constructor(name = "", desc = "") {
		this._name = name;
		this._desc = desc;

		this._model = {
			AssessmentExceptionId: 0,
			Name: this._name,
			Description: this._desc,
			// IsActive: true,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setName(name) {
		return (this._model = {
			...this._model,
			Name: name,
		});
	}
	setDescription(desc) {
		return (this._model = {
			...this._model,
			Description: desc,
		});
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'FacilityExceptionModel'
 * - This is the facility exception model, that associates with a base entry but is specific to a facility.
 */
class FacilityExceptionModel {
	constructor(facilityID = "", desc = "") {
		this._facilityID = facilityID;
		this._desc = desc;

		this._model = {
			FacilityId: this._facilityID, // string/uid, not null
			Description: this._desc,
			AssessmentFacilityExceptionId: 0, // int, not null
			AssessmentExceptionId: 0, // int, not null
			// IsActive: true,
			// CreatedDate: new Date().toUTCString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toUTCString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	_hasDesc() {
		const { Description } = this._model;
		return !isEmptyVal(Description);
	}

	setDescription(desc) {
		return (this._model = {
			...this._model,
			Description: desc,
		});
	}
	getModel() {
		if (!this._hasDesc()) return null;
		return this._model;
	}
}

// CANCELLATION TYPES //
/**
 * Default/base 'Cancellation Types' model
 * - Represents the 'AssessmentCancellation' model
 */
class CancellationTypeModel {
	constructor() {
		this._model = {
			AssessmentCancellationId: 0,
			Name: null,
			Description: null,
			// IsActive: true,
			// CreatedDate: new Date().toUTCString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toUTCString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setType(name) {
		return (this._model = {
			...this._model,
			Name: name,
		});
	}
	setProperty(prop, val) {
		return (this._model[prop] = val);
	}
	getProperty(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * Facility-specific 'Cancellation Types' model.
 * - Represents the 'AssessmentFacilityCancellation' model
 */
class FacilityCancellationTypeModel {
	constructor() {
		this._model = {
			AssessmentFacilityCancellationId: null,
			FacilityId: null,
			AssessmentCancellationId: null,
			Description: "",
			// IsActive: true,
			// CreatedDate: new Date().toUTCString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toUTCString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setType(name) {
		return (this._model = {
			...this._model,
			Name: name,
		});
	}
	setProperty(prop, val) {
		return (this._model[prop] = val);
	}
	getProperty(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

// CUSTOM TAGS //
/**
 * Custom 'category identifier' for a facility-level task.
 * - This is the default/base data structure
 * - Represents the 'AssessmentTag' model
 */
class TagModel {
	constructor() {
		this._model = {
			AssessmentTagId: 0,
			Name: null,
			Description: null,
			IsActive: true,
			// CreatedDate: new Date().toUTCString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toUTCString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProperty(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	setTagDesc(desc) {
		return (this._model = {
			...this._model,
			Description: desc,
		});
	}
	setFacilityID(facilityID) {
		return (this._model = {
			...this._model,
			FacilityId: facilityID,
		});
	}
	getModel() {
		return this._model;
	}
}

/**
 * Custom 'category identifier' for a facility-level task.
 * - This is the facility-specific data structure
 * - Represents the 'AssessmentFacilityTag' model
 */
class FacilityTagModel {
	constructor() {
		this._model = {
			AssessmentTagId: 0,
			AssessmentFacilityTagId: 0,
			FacilityId: null,
			Description: null,
			IsActive: true,
			IsDefault: false,
			// CreatedDate: new Date().toUTCString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toUTCString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProperty(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	setTagDesc(desc) {
		return (this._model = {
			...this._model,
			Description: desc,
		});
	}
	setFacilityID(facilityID) {
		return (this._model = {
			...this._model,
			FacilityId: facilityID,
		});
	}
	getModel() {
		return this._model;
	}
}

/**
 * Application Settings models:
 * - The following models enable custom settings at various 'entity' types such as:
 * 		- Application settings
 * 		- Facility settings, by app or globally
 */

class AppSettingsModel {
	constructor() {
		this._model = {
			SettingApplicationID: 0, // int, not null
			SettingTypeID: 0, // int, not null
			ApplicationID: 0, // int, not null
			Name: "string", // string, not null
			Content: "string", // string, null
			IsActive: true, // bool, not null
			// CreatedDate: null, // date, null
			// CreatedBy: null, // string, null
			// CreatedLoginBy: null, // string, null
			// CreatedStation: null, // string, null
			// ModifiedDate: null, // datetime, null
			// ModifiedBy: null, // uid, null
			// ModifiedLoginBy: null, // null,
			// ModifiedStation: null, // null
			// RestrictionValue: null, // string, null
			// RestrictionUnit: null, // string, null
		};
	}
	setRestrictionTerm(term) {
		return (this._model = {
			...this._model,
			RestrictionValue: term,
		});
	}
	setRestrictionType(unitType) {
		return (this._model = {
			...this._model,
			RestrictionUnit: unitType,
		});
	}
	setProp(name, val) {
		return (this._model = {
			...this._model,
			[name]: val,
		});
	}
	getModel() {
		return this._model;
	}
	getProp(prop) {
		return this._modell[prop];
	}
}

/**
 * 'AppSettingsTypeModel'
 * - This model enables creating a new 'AppSettingsType' in the ALADVSystem db.
 */
class AppSettingsTypeModel {
	constructor() {
		this._model = {
			SettingTypeID: 0, // int, not null,
			Name: "", // string, not null
			Description: "", // string, not null
			IsActive: true, // bool, not null
			// CreatedDate: null, // date, null
			// CreatedBy: null, // string, null
			// CreatedLoginBy: null, // string, null
			// CreatedStation: null, // string, null
			// ModifiedDate: null, // datetime, null
			// ModifiedBy: null, // uid, null
			// ModifiedLoginBy: null, // null,
			// ModifiedStation: null, // null
		};
	}
	setName(name) {
		return (this._model = {
			...this._model,
			Name: name,
		});
	}
	setDesc(desc) {
		return (this._model = {
			...this._model,
			Description: desc,
		});
	}
	setProp(name, val) {
		return (this._model = {
			...this._model,
			[name]: val,
		});
	}
	getModel() {
		return this._model;
	}
	getProp(prop) {
		return this._modell[prop];
	}
}

// 'Application-Specific', Facility level settings
class AppFacilitySettingsModel {
	constructor() {
		this._model = {
			SettingFacilityID: 0, // int, not null
			SettingTypeID: 0, // int, not null
			FacilityID: "", // string, not null
			Name: "", // string, not null
			Content: "", // string, null,
			IsActive: true, // bool, not null
			// CreatedDate: null, // date, null
			// CreatedBy: null, // string, null
			// CreatedLoginBy: null, // string, null
			// CreatedStation: null, // string, null
			// ModifiedDate: null, // datetime, null
			// ModifiedBy: null, // uid, null
			// ModifiedLoginBy: null, // null,
			// ModifiedStation: null, // null
		};
	}
	setName(name) {
		return (this._model = {
			...this._model,
			Name: name,
		});
	}
	setDesc(desc) {
		return (this._model = {
			...this._model,
			Description: desc,
		});
	}
	setProp(name, val) {
		return (this._model = {
			...this._model,
			[name]: val,
		});
	}
	getModel() {
		return this._model;
	}
	getProp(prop) {
		return this._modell[prop];
	}
}

class AppUserSettingsModel {
	constructor(userSettings = {}) {
		this._settingsTypeID = userSettings?.settingsTypeID ?? 0;
		this._userID = userSettings?.userID ?? null;
		this._settingsName = userSettings?.settingsName ?? "";
		this._settingsContent = userSettings?.settingsContent ?? "";
		this._restrictionVal = userSettings?.restrictionVal ?? null;
		this._restrictionUnit = userSettings?.restrictionUnit ?? null;

		this._model = {
			SettingUserID: 0, // int, not null - required
			SettingTypeID: this._settingsTypeID, // int, not null - required
			UserID: this._userID, // uid, null - required
			Name: this._settingsName, // str, not null - required
			Content: this._settingsContent, // str, null
			RestrictionValue: this._restrictionVal, // str, null
			RestrictionUnit: this._restrictionUnit, // str, null
			// IsActive: true, // bool, not null
			// CreatedDate: null, // date, null
			// CreatedBy: null, // string, null
			// CreatedLoginBy: null, // string, null
			// CreatedStation: null, // string, null
			// ModifiedDate: null, // datetime, null
			// ModifiedBy: null, // uid, null
			// ModifiedLoginBy: null, // null,
			// ModifiedStation: null, // null
		};
	}
}

/**
 * Create User Model
 * - For current use w/:
 * - 'ADVUSER' table
 * - Enables backwards-compatible Legacy Support
 */

/**
 * Default 'ADVUSER' model:
 * - Translates processed user object back into ADVUSER-compatible user.
 */
class ADVUSERModel {
	// ##TODOS:
	// - Double-check the data-types that do/do-not accept 'null' as a value
	// - Check w/ Jose & Brian to confirm fields that are embedded w/in legacy that cause errors if null
	constructor(user = {}) {
		this._email = user?.email ?? "";
		this._password = user?.password ?? user?.currentPassword ?? "";
		this._tempPassword = user?.tempPassword ?? "";
		this._tempPasswordExpiry = user?.tempPasswordExpiry ?? "";
		this._encryptedPassword = user?.encryptedPassword ?? "";
		// user details
		this._firstName = user?.firstName ?? "";
		this._lastName = user?.lastName ?? "";
		this._jobTitle = user?.jobTitle ?? "";
		// facility & user IDs
		this._userID = user?.userID ?? "";
		this._facilityID = user?.facilityID ?? "";
		// permissions/roles
		this._isMedTechRestricted = user?.isMedTechRestricted ?? false;
		this._isRegionalAdmin = user?.isRegionalAdmin ?? user?.isAdmin ?? false;
		this._isFacilityAdmin = user?.isFacilityAdmin ?? user?.isAdmin ?? false;
		this._isSuperUser = user?.isSuperUser ?? false;
		this._isFormerEmployee = user?.isFormerEmployee ?? false;

		this._model = {
			guidUser: this?._userID ?? "",
			strFirstName: this?._firstName ?? "",
			strLastName: this?._lastName ?? "",
			bitFacilityAdministrator: this?._isFacilityAdmin ?? false, // MIGHT NEED TO BE A BIT (ie 0 or 1)???
			guidFacility: this?._facilityID ?? "",
			strEmail: this?._email ?? "",
			strPassword: this?._password ?? "",
			intSalt: 0,
			TempPassword: this?._tempPassword ?? "",
			TempPasswordExpires: this?._tempPasswordExpiry ?? "",
			MedTechRestrictedAccess: this?._isMedTechRestricted ?? false, // MIGHT NEED TO BE A BIT (ie 0 or 1)???
			EncryptedPassword: this?._encryptedPassword ?? "",
			PWLastChanged: "", // MIGHT NEED A RANDOM DATETIME HERE????
			BadLogins: 0,
			strTitle: this?._jobTitle ?? "",
			alaAdmin: this?._isRegionalAdmin ?? false, // MIGHT NEED TO BE A BIT (ie 0 or 1)???
			superUser: this._isSuperUser ?? "", // MIGHT NEED TO BE A BIT (ie 0 or 1)???
			NoLongerAnEmployee: this?._isFormerEmployee ?? false,
			LockUserNames: false, // MIGHT NEED TO BE A BIT (ie 0 or 1)???
		};
	}
	setEmail(email) {
		this._email = email;
		return (this._model = {
			...this._model,
			strEmail: email,
		});
	}
	setPassword(password) {
		this._password = password;
		return (this._model = {
			...this._model,
			strPassword: password,
		});
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

class CustomUserModel {
	constructor(vals = {}) {
		this._jobTitle = vals?.jobTitle ?? "";
		this._phoneNumber = vals?.phoneNumber ?? "";
		this._phoneExt = vals?.phoneExt ?? "";
	}
}

// ##TODOS:
// - Migrate & merge "ADVUSERModel", "AdvUserCommunityModel" w/ "NewUserModel"
// - The legacy's 'CreateUser' API now supports passing an array of AdvUserCommunityModel(s)

/**
 * "NewUserModel": the 'ADVUSER' model for creating users in the legacy's system.
 * - Encodes the password & temp password in preparation of an HTTP request.
 * - NOTE: PLEASE USE THE NEW USER INFRA FOR CREATING USERS, AS A PRIMARY ACTION
 */
class NewUserModel {
	constructor(userVals = {}, userFacilities = []) {
		this._email = userVals?.newUsername;
		this._password = userVals?.newPassword;
		this._tempPassword = userVals?.tempPassword ?? null;
		this._firstName = userVals?.newFirstName;
		this._lastName = userVals?.newLastName;
		this._jobTitle = userVals?.jobTitle;
		// facility
		this._facilityID = userVals?.facilityID;
		// permissions/roles
		this._isRegionalAdmin = userVals?.isRegionalAdmin ?? false;
		this._isFacilityAdmin = userVals?.isFacilityAdmin ?? false;
		this._isSuperUser = userVals?.isSuperUser ?? false;
		this._isMedTechRestricted = userVals?.isMedTechRestricted ?? false;
		this._isFormerEmployee = userVals?.isFormerEmployee ?? false;

		this._advUserModel = {
			userAdv: {
				guidUser: 0,
				strFirstName: this?._firstName ?? "",
				strLastName: this?._lastName ?? "",
				guidFacility: this?._facilityID ?? "",
				strEmail: this?._email ?? "",
				strPassword: this?._password ?? "",
				strTitle: this?._jobTitle ?? "",
				intSalt: 0,
				TempPassword: this?._tempPassword ?? "",
				TempPasswordExpires: "",
				EncryptedPassword: "",
				PWLastChanged: "",
				BadLogins: 0,
				// user types
				superUser: this?._isSuperUser ?? false,
				alaAdmin: this?._isRegionalAdmin ?? false,
				bitFacilityAdministrator: this?._isFacilityAdmin ?? false,
				MedTechRestrictedAccess: this?._isMedTechRestricted ?? false,
				NoLongerAnEmployee: this?._isFormerEmployee ?? false,
				LockUserNames: false,
			},
			userCommunities: [],
		};
		this._advUserCommunityModel = [];
	}
	setEmail(email) {
		this._email = email;
		return (this._model = {
			...this._model,
			strEmail: email,
		});
	}
	setPassword(password) {
		this._password = password;
		return (this._model = {
			...this._model,
			strPassword: password,
		});
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'ADVUSERCOMMUNITIES' Model for granting access to one or more facilities.
 * - User MUST already exist in database for this to work successfully.
 */
class AdvUserCommunityModel {
	constructor(accessVals = {}) {
		this._userID = accessVals?.userID;
		this._targetFacilityID = accessVals?.facilityID;
		this._grantedByUserID = accessVals?.grantedBy;
		this._grantedDate = accessVals?.grantedDate ?? new Date().toISOString();

		this._model = {
			ID: 0,
			UserID: this._userID,
			FacilityID: this._targetFacilityID,
			GrantedBy: this._grantedByUserID,
			GrantedDate: this._grantedDate,
		};
	}
	setUserID(userID) {
		this._userID = userID;
		return (this._model = {
			...this._model,
			UserID: userID,
		});
	}
	setTargetFacility(facilityID) {
		this._targetFacilityID = facilityID;
		return (this._model = {
			...this._model,
			FacilityID: facilityID,
		});
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'UserFacilityAccessModel':
 * - Custom data structure for the client-only
 * - Used for rendering out <FacilityAccessList/>
 */
class UserFacilityAccessModel {
	constructor(accessVals = {}) {
		this._userID = accessVals?.userID;
		this._facilityID = accessVals?.facilityID;
		this._facilityName = accessVals?.communityName;
		this._location = accessVals?.location;
		this._city = accessVals?.city;
		this._state = accessVals?.state;
		this._grantedDate = accessVals?.grantedDate;
		// custom model
		this._model = {
			userID: this._userID,
			facilityID: this._facilityID,
			facilityName: this._facilityName,
			city: this._city,
			state: this._state,
			grantedDate: this._grantedDate,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * Access Models
 * - The following models enable managing various levels of access:
 * 		- Facility-level access
 * 		- Application-level access
 * 		- User-level access
 */

/**
 * AppByUserAccessModel:
 * - This model enables toggling a user's access to a given application
 * - It requires: 'ApplicationID', 'UserID', 'IsAccessible'
 */
class AppByUserAccessModel {
	constructor(accessVals = {}) {
		this._appID = accessVals?.appID ?? appIDs["AdvantageTracker"]; // defaults to the Tracker
		this._userID = accessVals?.userID ?? null;
		this._isAccessible = accessVals?.isAccessible ?? false;
		this._modifiedBy = accessVals?.modifiedBy ?? null;
		// complete ApplicationByUser model
		this._model = {
			ApplicationByUserID: 0, // int, not null
			ApplicationID: this._appID, // int, not null
			UserID: this._userID, // uid, null
			IsAccessible: this._isAccessible, // bool, not null
			// IsActive: true, // bool, not null
			// CreatedDate: new Date().toISOString(), // datetime, null
			// CreatedBy: null, // uid, null
			// CreatedLoginBy: null, // str, null
			// CreatedStation: null, // str, null
			// ModifiedDate: new Date().toISOString(), // datetime, null
			// ModifiedBy: this._modifiedBy, // uid, null
			// ModifiedLoginBy: null, // str, null
			// ModifiedStation: null, // str, null
		};
	}
	setAppID(appID) {
		return (this._model = {
			...this._model,
			ApplicationID: appID,
		});
	}
	setUserID(userID) {
		this._model = {
			...this._model,
			UserID: userID,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'AppByFacilityAccessModel'
 * - This model enables toggling access for a given facility to a specific ALA application.
 * - It requires: 'ApplicationID', 'FacilityID' and 'IsAccessible'
 */
class AppByFacilityAccessModel {
	constructor(accessVals = {}) {
		this._appID = accessVals?.appID ?? appIDs["AdvantageTracker"]; // defaults to the Tracker as the app
		this._facilityID = accessVals?.facilityID ?? null;
		this._isAccessible = accessVals?.isAccessible ?? false;

		this._model = {
			ApplicationByFacilityID: 0, // int, not null - required
			ApplicationID: this._appID, // int, not null - required
			FacilityID: this._facilityID, // uid, null - required
			IsAccessible: this._isAccessible, // bool, not null
			// IsActive: true, // bool, not null
			// CreatedDate: new Date().toISOString(), // datetime, null
			// CreatedBy: null, // uid, null
			// CreatedLoginBy: null, // str, null
			// CreatedStation: null, // str, null
			// ModifiedDate: new Date().toISOString(), // datetime, null
			// ModifiedBy: null, // uid, null
			// ModifiedLoginBy: null, // str, null
			// ModifiedStation: null, // str, null
		};
	}
	setAppID(appID) {
		return (this._model = {
			...this._model,
			ApplicationID: appID,
		});
	}
	setFacilityID(facilityID) {
		this._model = {
			...this._model,
			FacilityID: facilityID,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

// SECURITY QUESTIONS & ANSWERS MODELS //
class SecurityQuestionModel {
	constructor(questionVals = {}) {
		this._question = questionVals?.securityQuestion;
		// model
		this._model = {
			SecurityQuestionId: 0,
			Question: "",
			IsDefault: true,
			IsActive: true,
			CreatedDate: new Date().toISOString(),
			CreatedBy: null, // uid, null
			CreatedLoginBy: null, // str, null
			CreatedStation: null, // str, null
			ModifiedDate: new Date().toISOString(), // datetime, null
			ModifiedBy: null, // uid, null
			ModifiedLoginBy: null, // str, null
			ModifiedStation: null, // str, null
		};
	}
	setQuestion(question) {
		return (this._model = {
			...this._model,
			Question: question,
		});
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}
class SecurityAnswerModel {
	constructor() {
		this._model = {
			UserSecurityQuestionId: 0,
			UserLoginId: null,
			SecurityQuestionId: 0,
			Answer: null,
			IsActive: true,
			CreatedDate: new Date().toISOString(),
			CreatedBy: null, // uid, null
			CreatedLoginBy: null, // str, null
			CreatedStation: null, // str, null
			ModifiedDate: new Date().toISOString(), // datetime, null
			ModifiedBy: null, // uid, null
			ModifiedLoginBy: null, // str, null
			ModifiedStation: null, // str, null
		};
	}
	setAnswer(answer) {
		return (this._model = {
			...this._model,
			Answer: answer,
		});
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'SecurityAndAnswer' model that sets a security question's ID.
 */
class SecurityQuestionAndAnswerModel {
	constructor(allQuestions = [], vals = {}) {
		this._allQuestions = [...allQuestions];
		this._question = vals?.securityQuestion;
		this._answer = vals?.securityAnswer;

		this._model = {
			Question: this._question,
			QuestionId: 0,
			Answer: this._answer,
		};
	}
	// internal util
	_getQuestionID() {
		const id = matchQuestionIDFromStr(this._question, this._allQuestions);
		this._model.QuestionId = id;
		return this._model;
	}
	// external util
	getQuestionID(question, allQuestions = []) {
		const id = matchQuestionIDFromStr(question, allQuestions);
		return (this._model = {
			...this._model,
			QuestionID: id,
		});
	}
	// sets 'id', then returns model
	getModel() {
		this._getQuestionID();
		return this._model;
	}
}

//////////////////////////////////////////////////////////////////
//////////////////// USER TYPE & TITLE MODELS ////////////////////
//////////////////////////////////////////////////////////////////

/**
 * 'UserTypeModel':
 * - Custom data structure used to define 'user-types' such as: 'Super User', 'Facility Admin' etc.
 * - NOTE: this is the 'new-infra' equivalent of the Legacy's user type definitions.
 */
class UserTypeModel {
	constructor(userTypeVals = {}) {
		this._typeID = userTypeVals?.typeID ?? 0;
		this._userTypeName = userTypeVals?.userTypeName ?? "";
		this._userTypeDesc = userTypeVals?.userTypeDesc ?? "";
		this._isActive = userTypeVals?.isUserTypeActive ?? true;

		this._model = {
			UserTypeID: this._typeID,
			Name: this._userTypeName,
			Description: this._userTypeDesc,
			IsActive: this._isActive,
			// CreatedDate: "2021-10-21T14:43:21.720Z",
			// CreatedBy: "00000000-0000-0000-0000-000000000000",
			// CreatedLoginBy: "string",
			// CreatedStation: "string",
			// ModifiedDate: "2021-10-21T14:43:21.720Z",
			// ModifiedBy: "00000000-0000-0000-0000-000000000000",
			// ModifiedLoginBy: "string",
			// ModifiedStation: "string",
		};
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
	setProp(prop, val) {
		return (this._model[prop] = val);
	}
}

/**
 * "UserTitleModel": defines a custom user job title (eg. 'Director of Care' etc)
 */
class UserTitleModel {
	constructor(userVals = {}) {
		this._userTitle = userVals?.jobTitle;
		this._userTitleDesc = userVals?.jobTitleDesc ?? userVals?.jobTitle;

		this._model = {
			UserTitleID: 0,
			Name: this._userTitle,
			Description: this._userTitleDesc,
			IsActive: true,
			CreatedDate: new Date().toISOString(),
			CreatedBy: null,
			CreatedLoginBy: null,
			CreatedStation: null,
			ModifiedDate: new Date().toISOString(),
			ModifiedBy: null,
			ModifiedLoginBy: null,
			ModifiedStation: null,
		};
	}
	setTitleID(titleStr, allTitles = []) {
		const match = matchTitleFromStr(titleStr, allTitles);
		const id = match.UserTitleID;
		this._model = {
			...this._model,
			UserTitleID: id,
		};
		return this._model;
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}

// ALERTS & NOTIFICATIONS MODELS //
class AlertTypeModel {
	constructor(alertVals = {}) {
		this._alertMsg = alertVals?.alertMsg ?? "";
		this._hasReminders = alertVals?.hasReminders ?? false;
		this._isEnabled = alertVals?.isEnabled ?? false;
		this._isDismissable = alertVals?.isDismissable ?? false;

		this._model = {
			AlertTypeId: 0,
			HasReminders: this._hasReminders,
			IsEnable: this._isEnabled,
			IsDismissable: this._isDismissable,
			Message: this._alertMsg,
			IsActive: true,
			CreatedDate: new Date().toISOString(),
			CreatedBy: null, // uid, null
			CreatedLoginBy: null, // str, null
			CreatedStation: null, // str, null
			ModifiedDate: new Date().toISOString(), // datetime, null
			ModifiedBy: null, // uid, null
			ModifiedLoginBy: null, // str, null
			ModifiedStation: null, // str, null
		};
	}
	setHasReminders(hasReminders) {
		return (this._model = {
			...this._model,
			HasReminders: hasReminders,
		});
	}
	setMsg(msg) {
		this._alertMsg = msg;
		return (this._model = {
			...this._model,
			Message: msg,
		});
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}
class UserAlertModel {
	constructor() {
		this._model = {
			UserAlertId: 0,
			UserProfileId: 0,
			AlertTypeId: 0,
			IsActive: true,
			CreatedDate: new Date().toISOString(),
			CreatedBy: null, // uid, null
			CreatedLoginBy: null, // str, null
			CreatedStation: null, // str, null
			ModifiedDate: new Date().toISOString(), // datetime, null
			ModifiedBy: null, // uid, null
			ModifiedLoginBy: null, // str, null
			ModifiedStation: null, // str, null
		};
	}
	setUserAlertID(id, alerts) {}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

// USER LOGIN & PROFILE MODELS & RECORDS //

////////////////////////////////////////////////////////////////////////
//////////// 👇 USER PROFILE (NEW INFRA) RELATED MODELS 👇 ////////////
////////////////////////////////////////////////////////////////////////

// BELOW ARE THE VARIOUS PIECES OF THE ABOVE 'PROFILE' MODELS //
////////////////////////////////////////////////////////////////////////
//////// 👇 USER PROFILE & LOGIN (NEW INFRA) RELATED MODELS 👇 ////////
////////////////////////////////////////////////////////////////////////

// PRIMARY MODELS ~ LOGIN(1st) & PROFILE(2nd) //

/**
 * 'UserProfileModel': this model is a base data structure in the new user infrastructure.
 * - This will get created *after* creating a 'UserLogin' record.
 */
class UserProfileModel {
	constructor(profileRecords = {}) {
		const { profile, alerts, avatars, emails, phoneNumbers } = profileRecords;

		this._model = {
			userProfile: profile,
			userAlerts: alerts,
			userAvatars: avatars,
			userEmails: emails,
			userPhones: phoneNumbers,
		};
	}
	getProfile() {
		return this._model.userProfile;
	}
	getAlerts() {
		return this._model.userAlerts;
	}
	getAvatars() {
		return this._model.userAvatars;
	}
	getEmails() {
		return this._model.userEmails;
	}
	getPhones() {
		return this._model.userPhones;
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserLogin' model record.
 * - This MUST be the first record created for a given user when created w/ the new User Infra.
 */
class UserLoginModel {
	constructor(loginRecords = {}) {
		const {
			userLogin,
			user2FA,
			userApps,
			userRoles,
			userRoleGroups,
			userSecurityRoles,
			userSecurityRoleGroups,
			userSecurityAnswers,
			userFacilities,
		} = loginRecords;
		this._userLogin = userLogin;
		this._user2FA = user2FA;
		this._userApps = userApps;
		this._userRoles = userRoles;
		this._userRoleGroups = userRoleGroups;
		this._securityRoles = userSecurityRoles;
		this._securityRoleGroups = userSecurityRoleGroups;
		this._securityAnswers = userSecurityAnswers;
		this._userFacilities = userFacilities;

		// entire model
		this._model = {
			userLogin: { ...this._userLogin },
			user2FA: { ...this._user2FA },
			userApps: [...this._userApps],
			userRoles: this._userRoles,
			userRoleGroups: this._userRoleGroups,
			userSecurityRoles: this._securityRoles,
			userSecurityRoleGroups: this._securityRoleGroups,
			userSecurityAnswers: [...this._securityAnswers],
			userFacilities: this._userFacilities,
		};
	}
	// setters
	setProfileID(profileID) {
		this._userLogin.UserProfileID = profileID;
	}
	setLoginID(loginID) {
		this._userLogin.UserLoginID = loginID;
		// update 'userApps'
		// update 'userRoles'
		// update 'userRoleGroups'
		// update 'userSecurityAnswers'
		// update 'userFacilities'
	}
	setLogin(login) {
		this._userLogin = login;
		this._model.userLogin = login;
		return this._model;
	}
	set2FA(user2FA) {
		this._user2FA = user2FA;
		this._model.user2FA = user2FA;
		return this._model;
	}
	setApps(apps) {
		this._userApps = apps;
		this._model.userApps = apps;
		return this._model;
	}
	setUserRoles(userRoles) {
		this._userRoles = userRoles;
		this._model.userRoles = userRoles;
		return this._model;
	}
	setUserRoleGroups(userRoleGroups) {
		this._userRoleGroups = userRoleGroups;
		this._model.userRoleGroups = userRoleGroups;
		return this._model;
	}
	setSecurityRoles(securityRoles) {
		this._securityRoles = securityRoles;
		this._model.securityRoles = securityRoles;
		return this._model;
	}
	setSecurityRoleGroups(securityRoleGroups) {
		this._securityRoleGroups = securityRoleGroups;
		this._model.securityRoleGroups = securityRoleGroups;
		return this._model;
	}
	setSecurityAnswers(answers) {
		this._securityAnswers = answers;
		this._model.securityAnswer = answers;
		return this._model;
	}
	// getters
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

////////////////////////////////////////////////////////////////////////
//////// ☝️ USER PROFILE & LOGIN (NEW INFRA) RELATED MODELS ☝️ ////////
////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////
//////////// 👇 USER PROFILE (NEW INFRA) RELATED MODELS 👇 ////////////
////////////////////////////////////////////////////////////////////////

// BELOW ARE THE VARIOUS PIECES OF THE ABOVE 'PROFILE' MODELS //

////////////////////////////////////////////////////////////////////////
//////////// 👇 USER PROFILE (NEW INFRA) RELATED MODELS 👇 ////////////
////////////////////////////////////////////////////////////////////////

// BELOW ARE THE VARIOUS PIECES OF THE ABOVE 'PROFILE' MODELS //

/**
 * 'UserProfileModel': primary user details.
 * - This is created AFTER a 'UserLogin' entry is created/added
 */
class UserProfileRecord {
	constructor(userVals = {}) {
		this._userProfileID = userVals?.profileID ?? 0;
		this._facilityID = userVals?.facilityID;
		this._firstName = userVals?.firstName;
		this._middleName = userVals?.middleName ?? "";
		this._lastName = userVals?.lastName;
		// user details
		this._displayName = userVals?.username ?? userVals?.email;
		this._jobTitle = userVals?.jobTitle ?? null;
		this._userTitleID = userVals?.userTitleID ?? 0;
		this._userTypeID = userVals?.userTypeID ?? 0;
		// user state(s)
		this._isLockedOut = userVals?.isLockedOut ?? false;
		this._isEmployee = userVals?.isEmployee ?? true;
		this._isActive = userVals?.isActive ?? true;
		// created by
		this._createdByID = userVals?.createdByID ?? null;

		this._model = {
			UserProfileID: this._userProfileID,
			FacilityId: this._facilityID,
			UserTypeID: this._userTypeID,
			UserTitleID: this._userTitleID,
			FirstName: this._firstName,
			LastName: this._lastName,
			MiddleName: this._middleName,
			DisplayName: this._displayName,
			IsEmployee: this._isEmployee,
			IsLockOut: this._isLockedOut,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdByID,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdByID,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	// finds 'user-title' id and sets to model
	setTitleID(titleStr, allTitles = []) {
		const userTitle = matchUserTitleFromStr(titleStr, allTitles);
		this._jobTitle = titleStr;
		this._userTitleID = userTitle.UserTitleID;
		this._model = {
			...this._model,
			UserTitleID: this._userTitleID,
		};
		return this._model;
	}
	// finds 'user-type' id and sets to model
	setTypeID(titleStr, allTitles = []) {
		const userType = matchUserTypeFromStr(titleStr, allTitles);
		this._userTypeID = userType.UserTypeID;
		this._model = {
			...this._model,
			UserTypeID: this._userTypeID,
		};
		return this._model;
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserAlertRecord': a single user alert entry.
 */
class UserAlertRecord {
	constructor(alerts) {
		this._alertTypeID = alerts?.alertTypeID ?? 0;
		this._isActive = alerts?.isActive ?? false;
		// created by
		this._createdByID = alerts?.createdByID;

		this._model = {
			UserAlertID: 0,
			UserProfileID: 0,
			AlertTypeID: this._alertTypeID,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdByID,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdByID,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserAvatarRecord' model: a single entry in the 'userAvatars' used in the 'UserProfileModel'
 */
class UserAvatarRecord {
	constructor(avatarVals = {}) {
		this._fileID = avatarVals?.fileID ?? 0;
		this._colorCode = avatarVals?.avatarColor;
		// created by
		this._createdByID = avatarVals?.createdByID;

		this._model = {
			UserAvatarID: 0,
			UserProfileID: 0,
			FileRegistryID: this._fileID,
			ColorCode: this._colorCode,
			// IsActive: true,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdByID,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdByID,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserEmailRecord' model: a single entry in the 'userEmails' for 'UserProfileModel'
 */
class UserEmailRecord {
	constructor(userVals = {}) {
		this._email = userVals?.email;
		this._isPrimary =
			userVals?.isPrimaryEmail || !isEmptyVal(userVals?.email) ? true : false;
		this._isValidEmail = userVals?.isValidEmail ?? false;
		this._isEmailVerified = userVals?.isValidEmail ?? false;
		this._isActive = isEmptyVal(userVals?.email) ? false : true;
		// created by
		this._createdByID = userVals?.createdByID;
		// ids
		this._profileID = userVals?.userProfileID ?? 0;
		this._emailID = userVals?.userEmailID ?? 0;

		this._model = {
			UserEmailID: this._emailID, // updated 10/19/2021 at 12:31 PM
			UserProfileID: this._profileID, // updated 10/19/2021 at 12:31 PM
			Email: this._email,
			IsPrimary: this._isPrimary || this._isValidEmail,
			IsValidEmail: this._isValidEmail, // updated 10/19/2021 at 12:31 PM
			IsEmailVerified: this._isEmailVerified, // updated 10/19/2021 at 12:31 PM
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdByID,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdByID,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserPhoneRecord' model: a single entry in the 'userPhones' for 'UserProfileModel'
 */
class UserPhoneRecord {
	constructor(userVals = {}) {
		this._phone = userVals?.phoneNumber;
		this._phoneExt = userVals?.phoneExt ?? null;
		this._isPrimary = userVals?.isPrimaryPhone ?? false;
		this._phoneID = userVals?.phoneID ?? 0;
		// created by
		this._profileID = userVals?.profileID ?? 0;
		this._createdByID = userVals?.createdByID;

		this._model = {
			UserPhoneID: this._phoneID,
			UserProfileID: this._profileID,
			Phone: this._phone,
			Extension: this._phoneExt,
			IsPrimary: this._isPrimary,
			// IsActive: true,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdByID,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdByID,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

////////////////////////////////////////////////////////////////////////
//////////// ☝️ USER PROFILE (NEW INFRA) RELATED MODELS ☝️ ////////////
////////////////////////////////////////////////////////////////////////

// USER LOGIN Records
// BELOW ARE THE VARIOUS PIECES OF THE ABOVE 'LOGIN' MODELS //

/**
 * 'UserLoginRecord' model: a single entry in the 'userLogin' of "UserLoginModel"
 * - Changed to populate 'tempPassword' for new users instead of 'Password'
 */
class UserLoginRecord {
	constructor(userVals = {}) {
		this._userLoginID =
			userVals?.userID ?? "00000000-0000-0000-0000-000000000000";
		// login details (ie username & password)
		this._loginName = userVals?.username;
		this._loginNameByEmail = userVals?.email ?? null;
		this._password = userVals?.password ?? null;
		// temp password is good for 4 hours
		this._tempPassword = userVals?.tempPassword ?? null;
		this._tempPasswordExpiry =
			userVals?.tempPasswordExpiry ?? addHours(new Date(), 4).toISOString();
		// facility & start/end dates
		this._startDate = userVals?.effectiveStartDate ?? new Date().toISOString();
		this._endDate = userVals?.effectiveEndDate ?? null;
		this._facilityID = userVals?.facilityID;
		this._appID = userVals?.appID ?? 2;
		// password reset options
		this._isPwdResetByEmail = userVals?.isPwdResetByEmail ?? false;
		this._isPwdResetByQuestions = userVals?.isPwdResetByQuestions ?? true;
		this._isPwdResetByAdmin = userVals?.isPwdResetByAdmin ?? true;

		this._isSuspended = userVals?.isSuspended ?? false;
		this._suspendStartDate = userVals?.suspendStartDate ?? null;
		this._is2FAEnabled = userVals?.is2FAEnabled ?? false;
		this._isActive = userVals?.isActiveUser ?? true;

		// created by
		this._createdByID = userVals?.createdByID ?? null;

		// When creating a NEW user, pass an empty guid string (eg. '000000...')
		// When creating a login for an EXISTING user, pass their userID from the ADVUSER table
		this._model = {
			UserLoginID: this._userLoginID,
			UserProfileID: 0,
			ApplicationID: this._appID,
			UserAvatarID: null,
			FacilityId: this._facilityID,
			LoginName: this._loginName, // username, if NOT email, <= 100 chars
			LoginNameByEmail: this._loginNameByEmail, // email, <= 100 chars
			StartDate: this._startDate,
			EndDate: this._endDate,
			Password: this._password,
			EncryptedPassword: null,
			TempPassword: this._tempPassword,
			TempPasswordExpireDate: this._tempPasswordExpiry,
			PasswordLastChangedDate: null,
			PasswordExpireDate: null,
			SuspendedDate: this._suspendStartDate,
			IsPwdResetByEmail: this._isPwdResetByEmail,
			IsPwdResetByQuestions: this._isPwdResetByQuestions,
			IsPwdResetByAdmin: this._isPwdResetByAdmin,
			IsSuspended: this._isSuspended,
			Is2FAEnable: this._is2FAEnabled,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdByID,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}

/**
 * 'User2FARecord' model: a single entry in the 'user2FA' of "UserLoginModel"
 */
class User2FARecord {
	constructor(userVals = {}) {
		this._userLoginID =
			userVals?.userID ?? "00000000-0000-0000-0000-000000000000";

		this._startDate = userVals?.startDate2FA ?? null;
		this._endDate = userVals?.endDate2FA ?? null;

		this._contactEmail = userVals?.contactEmail ?? false;
		this._contactPhone = userVals?.contactPhone ?? false;

		this._isContactByEmail = userVals?.isContactByEmail ?? false;
		this._isContactByPhone = userVals?.isContactByPhone ?? false;

		this._model = {
			UserLogin2FAID: 0,
			UserLoginID: this._userLoginID,
			StartDate: new Date().toISOString(),
			EndDate: new Date().toISOString(),
			ContactEmail: this._contactEmail,
			ContactPhone: this._contactPhone,
			ContactTexting: null,
			IsContactByEmail: this._isContactByEmail,
			IsContactByPhone: this._isContactByPhone,
			IsContactByTexting: false,
			IsTrusted: false,
			// IsActive: false,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: null,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserAppsRecord' model: a single entry in the 'userApps' of "UserLoginModel"
 */
class UserAppsRecord {
	constructor(userVals = {}) {
		this._targetUserID = userVals?.targetUserID; // target user
		this._appID = userVals?.appID;
		this._isAccessible = userVals?.isAccessible ?? true;
		this._isActive = userVals?.isActive ?? true;
		// created by fields
		this._createdBy = userVals?.createdByID; // user that created this for the 'target'

		this._model = {
			ApplicationByUserID: 0,
			ApplicationID: this._appID,
			UserID: this._targetUserID,
			IsAccessible: this._isAccessible,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdBy,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdBy,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserRolesRecord' model: a single entry in the 'userRoles' of "UserLoginModel"
 */
class UserRolesRecord {
	constructor(userVals = {}) {
		this._targetUserID = userVals?.targetUserID;
		this._roleID = userVals?.targetUserRoleID ?? 0;
		this._roleTypeID = userVals?.targetUserRoleTypeID ?? 0;
		this._isActive = userVals?.isActive ?? true;
		// created by user vals
		this._createdBy = userVals?.createdByID;

		this._model = {
			RoleID: this._roleID,
			RoleTypeID: this._roleTypeID,
			UserID: this._targetUserID,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdBy,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdBy,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserRoleGroupsRecord' mode: a single entry in the 'userRoleGroups' of "UserLoginModel".
 */
class UserRoleGroupsRecord {
	constructor(userVals = {}) {
		this._targetUserID = userVals?.targetUserID;
		this._roleGroupID = userVals?.targetUserRoleGroupID ?? 0;
		this._roleGroupTypeID = userVals?.targetUserRoleGroupTypeID ?? 0;
		this._isActive = userVals?.isActive ?? true;
		// created by user vals
		this._createdBy = userVals?.createdByID;

		this._model = {
			RoleGroupID: this._roleGroupID,
			RoleGroupTypeID: this._roleGroupTypeID,
			UserID: this._targetUserID,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdBy,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdBy,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}
/**
 * 'UserSecurityAnswerRecord' mode: a single entry in the 'userSecurityAnswers' of "UserLoginModel".
 */
class UserSecurityAnswerRecord {
	constructor(userVals = {}) {
		this._answer = userVals?.securityAnswer;
		this._questionID = userVals?.securityQuestionID;
		this._userLoginID =
			userVals?.userLoginID ?? "00000000-0000-0000-0000-000000000000";
		// created by user vals
		this._createdBy = userVals?.createdByID;

		this._model = {
			UserLoginSecurityAnswerID: 0,
			UserLoginID: this._userLoginID,
			SecurityQuestionID: this._questionID,
			Answer: this._answer,
			// IsActive: true,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: this._createdBy,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: this._createdBy,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	setProp(prop, val) {
		this._model[prop] = val;
		return this._model;
	}
	getModel() {
		return this._model;
	}
}
/**
 * "UserLoginFacilities" model:
 * - Enables settings which facility(s) a user has access to when creating their 'UserLogin'
 * - Part of the 'UserLoginModel'
 */
class UserLoginFacilityRecord {
	constructor(userFacilityVals = {}) {
		const { userID, facilityID, grantedBy, grantedDate, hasAccess } =
			userFacilityVals;
		// used for new users that do NOT have a userID yet
		const newID = "00000000-0000-0000-0000-000000000000";
		// private fields
		this._userLoginID = isEmptyVal(userID) ? newID : userID;
		this._facilityID = facilityID;
		this._grantedBy = grantedBy;
		this._isActive = hasAccess;
		this._grantedDate = isEmptyVal(grantedDate)
			? new Date().toISOString()
			: grantedDate;

		this._model = {
			UserLoginFacilityID: 0,
			UserLoginID: this._userLoginID,
			FacilityId: this._facilityID,
			GrantedBy: this._grantedBy,
			GrantedDate: this._grantedDate,
			// IsActive: true,
			// CreatedDate: this._grantedDate,
			// CreatedBy: this._grantedBy,
			// CreatedLoginBy: null,
			// CreatedStation: null,
			// ModifiedDate: this._grantedDate,
			// ModifiedBy: this._grantedBy,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	getModel() {
		return this._model;
	}
}

class UserResetRules {
	constructor(resetRules) {
		this._loginName = resetRules?.username ?? resetRules?.email;
		this._loginNameByEmail = resetRules?.email ?? "";
		// login/profile status'
		this._isProfileActive = resetRules?.isProfileActive ?? true;
		this._isProfileLockout = resetRules?.isProfileLockout ?? false;
		this._isLoginActive = resetRules?.isLoginActive ?? true;
		this._isLoginLockout = resetRules?.isLoginLockout ?? false;
		this._isSuspended = resetRules?.isSuspended ?? false;
		// reset rules
		this._isPwdResetByAdmin = resetRules?.isPwdResetByAdmin;
		this._isPwdResetByEmail = resetRules?.isPwdResetByEmail;
		this._isPwdResetByQuestions = resetRules?.isPwdResetByQuestions;
		this._preferredMethodID = resetRules?.preferredMethodID ?? 0;

		this._model = {
			LoginName: "string",
			LoginNameByEmail: "string",
			IsProfileActive: true,
			IsProfileLockout: true,
			IsLoginActive: true,
			IsLoginLockout: true,
			LoginStartDate: "2021-10-28T13:57:22.861Z",
			LoginEndDate: "2021-10-28T13:57:22.861Z",
			PasswordExpireDate: "2021-10-28T13:57:22.861Z",
			IsSuspended: true,
			IsPwdResetByEmail: true,
			IsPwdResetByQuestions: true,
			IsPwdResetByAdmin: true,
			IsEnable2FA: true,
			StartDate2FA: "2021-10-28T13:57:22.861Z",
			EndDate2FA: "2021-10-28T13:57:22.861Z",
			IsTrusted2FA: true,
			PreferredResetMethodID: 0,
		};
	}
	getModel() {
		return this._model;
	}
}

class PasswordResetRules {
	constructor(resetConfig = {}) {
		this._isPwdResetByAdmin = resetConfig?.isPwdResetByAdmin ?? false;
		this._isPwdResetByEmail = resetConfig?.isPwdResetByEmail ?? false;
		this._isPwdResetByQuestions = resetConfig?.isPwdResetByQuestions ?? false;

		this._model = {
			IsPwdResetByEmail: this._isPwdResetByEmail,
			IsPwdResetByQuestions: this._isPwdResetByQuestions,
			IsPwdResetByAdmin: this._isPwdResetByAdmin,
		};
	}
	getModel() {
		return this._model;
	}
}

/**
 * @class PasswordResetConfig
 * @classdesc - Custom settings for which methods a user can use to reset their password, if needed.
 * @param {Object} resetConfig - Custom settings object w/ user-specific settings.
 */
class PasswordResetConfig {
	constructor(resetConfig = {}) {
		this._password = resetConfig?.password;
		this._tempPassword = resetConfig?.tempPassword;
		this._tempPasswordExpiry =
			resetConfig?.tempPasswordExpiry ?? addMinutes(new Date(), 60);
		this._passwordExpiry = resetConfig?.passwordExpiry ?? null;
		this._isPwdResetByAdmin = resetConfig?.isPwdResetByAdmin ?? true;
		this._isPwdResetByEmail = resetConfig?.isPwdResetByEmail ?? false;
		this._isPwdResetByQuestions = resetConfig?.isPwdResetByQuestions ?? true;
		// model
		this._model = {
			Password: this._password,
			TempPassword: this._tempPassword,
			TempPasswordExpireDate: this._tempPasswordExpiry,
			PasswordExpireDate: this._passwordExpiry,
			IsPwdResetByEmail: this._isPwdResetByEmail,
			IsPwdResetByQuestions: this._isPwdResetByQuestions,
			IsPwdResetByAdmin: this._isPwdResetByAdmin,
		};
	}
	setProp(prop, val) {
		return (this._model = {
			...this._model,
			[prop]: val,
		});
	}
	getProp(prop) {
		return this._model[prop];
	}
	getModel() {
		return this._model;
	}
}

class PasswordResetType {
	constructor(resetType) {
		this._resetTypeID = resetType?.resetTypeID ?? 0;
		this._resetName = resetType?.resetName ?? "";
		this._resetDesc = resetType?.resetDesc ?? "";
		this._isActive = resetType?.isActive ?? true;

		this._model = {
			ResetMethodTypeID: this._resetTypeID,
			Name: this._resetName,
			Description: this._resetDesc,
			// IsActive: this._isActive,
			// extraneous
			// CreatedDate: "2021-10-27T19:14:28.073Z",
			// CreatedBy: null,
			// CreatedLoginBy: "AdvantageAdmin",
			// CreatedStation: "DEVCHARITY",
			// ModifiedDate: "2021-10-27T19:14:28.073Z",
			// ModifiedBy: null,
			// ModifiedLoginBy: "AdvantageAdmin",
			// ModifiedStation: "DEVCHARITY",
		};
	}
	getModel() {
		return this._model;
	}
}

////////////////////////////////////////////////////////////////////////////////////////
//////////// USER ROLE(S), ROLE GROUP(S), ROLE GROUP TYPE(S), ROLE TYPE(S) ////////////
////////////////////////////////////////////////////////////////////////////////////////

class UserRole {
	constructor(roleOpts = {}) {
		// declare defaults (ie. fallbacks)
		const {
			roleID = 0,
			roleTypeID = 0,
			userID = "00000000-0000-0000-0000-000000000000",
			isActive = true,
			createdByID = "00000000-0000-0000-0000-000000000000",
		} = roleOpts;

		this._roleID = roleID;
		this._roleTypeID = roleTypeID;
		this._userID = userID;
		this._isActive = isActive;
		// who created this role
		this._createdByID = createdByID;

		this._model = {
			RoleID: this._roleID,
			RoleTypeID: this._roleTypeID,
			UserID: this._userID,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: null,
			// CreatedLoginBy: this._createdByID,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	getModel() {
		return this._model;
	}
}

class UserRoleGroup {
	constructor(groupOpts = {}) {
		// declare defaults (ie. fallbacks)
		const {
			roleGroupID = 0,
			roleGroupTypeID = 0,
			userID = "00000000-0000-0000-0000-000000000000",
			isActive = true,
			createdByID = "00000000-0000-0000-0000-000000000000",
		} = groupOpts;

		this._roleGroupID = roleGroupID;
		this._roleGroupTypeID = roleGroupTypeID;
		this._userID = userID;
		this._isActive = isActive;
		// who created this role
		this._createdByID = createdByID;

		this._model = {
			RoleGroupID: this._roleGroupID,
			RoleGroupTypeID: this._roleGroupTypeID,
			UserID: this._userID,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: null,
			// CreatedLoginBy: this._createdByID,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
}

class UserRoleGroupType {
	constructor(typeOpts = {}) {
		// declare defaults (ie. fallbacks)
		const {
			roleGroupTypeID = 0,
			roleName = "",
			roleDesc = "",
			userID = "00000000-0000-0000-0000-000000000000",
			isActive = true,
			createdByID = "00000000-0000-0000-0000-000000000000",
		} = typeOpts;

		this._roleGroupTypeID = roleGroupTypeID;
		this._roleName = roleName;
		this._roleDesc = roleDesc;
		this._userID = userID;
		this._isActive = isActive;
		// who created this role
		this._createdByID = createdByID;

		this._model = {
			RoleGroupTypeID: this._roleGroupTypeID,
			Name: this._roleName,
			Description: this._roleDesc,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: null,
			// CreatedLoginBy: this._createdByID,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	getModel() {
		return this._model;
	}
}

class UserRoleType {
	constructor(roleOpts = {}) {
		// declare defaults (ie. fallbacks)
		const {
			roleTypeID = 0,
			roleTypeName = "",
			roleTypeDesc = "",
			userID = "00000000-0000-0000-0000-000000000000",
			isActive = true,
			createdByID = "00000000-0000-0000-0000-000000000000",
		} = roleOpts;

		this._roleTypeID = roleTypeID;
		this._roleTypeName = roleTypeName;
		this._roleTypeDesc = roleTypeDesc;
		this._userID = userID;
		this._isActive = isActive;
		// who created this role
		this._createdByID = createdByID;

		this._model = {
			RoleTypeID: this._roleTypeID,
			Name: this._roleTypeName,
			Description: this._roleTypeDesc,
			// IsActive: this._isActive,
			// CreatedDate: new Date().toISOString(),
			// CreatedBy: null,
			// CreatedLoginBy: this._createdByID,
			// CreatedStation: null,
			// ModifiedDate: new Date().toISOString(),
			// ModifiedBy: null,
			// ModifiedLoginBy: null,
			// ModifiedStation: null,
		};
	}
	getModel() {
		return this._model;
	}
}

////////////////////////////////////////////////////////////////////////
/////////////////  USER WHITELIST & PERMISSIONS MODELS /////////////////
////////////////////////////////////////////////////////////////////////

/**
 * "WhiteListedUserModel":
 * - Whitelisted user login record
 * - A whitelisted user has special access to various apps, features & functions.
 */
class WhiteListedUserModel {
	constructor(vals) {
		this._whiteListID = vals?.userWhiteListID ?? 0;
		this._userID = vals?.userID ?? "00000000-0000-0000-0000-000000000000";
		this._isWhiteListed = vals?.isWhiteListed ?? false;

		this._model = {
			UserLoginWhiteListID: this._whiteListID,
			UserLoginID: this._userID,
			IsActive: this._isWhiteListed,
			// CreatedDate: "2021-10-21T14:43:18.016Z",
			// CreatedBy: "00000000-0000-0000-0000-000000000000",
			// CreatedLoginBy: "string",
			// CreatedStation: "string",
			// ModifiedDate: "2021-10-21T14:43:18.016Z",
			// ModifiedBy: "00000000-0000-0000-0000-000000000000",
			// ModifiedLoginBy: "string",
			// ModifiedStation: "string",
		};
	}
	getModel() {
		return this._model;
	}
}

///////////////////////////////////////////////////////////////
/////////////// PERMISSIONS BY MODULE MODEL(S) ///////////////
///////////////////////////////////////////////////////////////

class UserSecurityAccessModel {
	constructor(securityVals) {
		// app & user-related IDs
		this._appID = securityVals?.targetAppID;
		this._facilityID = securityVals?.facilityID;
		this._userID = securityVals?.userID;
		// security-related IDs
		this._accessID = securityVals?.accessID ?? 0;
		this._actionGroup = securityVals?.actionGroup ?? "";
		this._action = securityVals?.action ?? "";
		this._isGranted = securityVals?.isGranted ?? true;
		// geo-locking fields
		this._accessIP = securityVals?.accessIP ?? "";
		this._accessStartDate = securityVals?.startDate ?? new Date().toISOString();
		this._accessEndDate = securityVals?.endDate ?? new Date().toISOString();
		this._gpsLongitude = securityVals?.gpsLongitude ?? 0;
		this._gpsLatitude = securityVals?.gpsLatitude ?? 0;
		this._gpsRadius = securityVals?.gpsRadius ?? 0;

		this._model = {
			SecurityAccessID: this._accessID,
			ApplicationID: this._appID,
			FacilityID: this._facilityID,
			UserID: this._userID,
			ActionGroup: this._actionGroup,
			Action: this._action,
			IsGranted: this._isGranted,
			AccessIP: this._accessIP,
			AccessStartDate: this._accessStartDate,
			AccessEndDate: this._accessEndDate,
			AccessGpsLongitude: this._gpsLongitude,
			AccessGpsLatitude: this._gpsLatitude,
			AccessGpsRadius: this._gpsRadius,
			// extraneous fields
			IsActive: true,
			// CreatedDate: "2021-10-27T13:53:15.686Z",
			// CreatedBy: "00000000-0000-0000-0000-000000000000",
			// CreatedLoginBy: "string",
			// CreatedStation: "string",
			// ModifiedDate: "2021-10-27T13:53:15.686Z",
			// ModifiedBy: "00000000-0000-0000-0000-000000000000",
			// ModifiedLoginBy: "string",
			// ModifiedStation: "string",
		};
	}
	getModel() {
		return this._model;
	}
}

/**
 * "UserSecurityRoleModel":
 * - Defines a feature permission set for a user
 * 		- Joins a feature and role via user
 */
class UserSecurityRoleModel {
	constructor(userRoleVals) {
		// permission identifiers (eg. IDs)
		this._securityRoleID = userRoleVals?.securityRoleID ?? 0;
		this._roleID = userRoleVals?.roleID ?? 0;
		this._appID = userRoleVals?.appID ?? 0;
		this._moduleID = userRoleVals?.moduleID ?? 0;
		this._featureID = userRoleVals?.featureID ?? 0;
		// permission settings
		this._canAccess = userRoleVals?.canAccess ?? false;
		this._canAdd = userRoleVals?.canAdd ?? false;
		this._canCreate = userRoleVals?.canCreate ?? false;
		this._canDelete = userRoleVals?.canDelete ?? false;
		this._canDoAll = userRoleVals?.canDoAll ?? false;
		this._canExecute = userRoleVals?.canExecute ?? false;
		this._canUpdate = userRoleVals?.canUpdate ?? false;
		this._canView = userRoleVals?.canView ?? false;

		this._model = {
			SecurityByRoleID: this._securityRoleID,
			RoleID: this._roleID,
			ApplicationID: this._appID,
			ModuleID: this._moduleID,
			FeatureID: this._featureID,
			CanAccess: this._canAccess,
			CanAdd: this._canAdd,
			CanCreate: this._canCreate,
			CanDelete: this._canDelete,
			CanDoAll: this._canDoAll,
			CanExecute: this._canExecute,
			CanUpdate: this._canUpdate,
			CanView: this._canView,
			// extraneous fields
			IsActive: true,
			// CreatedDate: "2021-10-27T13:53:15.686Z",
			// CreatedBy: "00000000-0000-0000-0000-000000000000",
			// CreatedLoginBy: "string",
			// CreatedStation: "string",
			// ModifiedDate: "2021-10-27T13:53:15.686Z",
			// ModifiedBy: "00000000-0000-0000-0000-000000000000",
			// ModifiedLoginBy: "string",
			// ModifiedStation: "string",
		};
	}
	getModel() {
		return this._model;
	}
}

class UserSecurityRoleGroups {
	constructor(roleGroupVals) {
		// permission identifiers (eg. IDs)
		this._securityRoleGroupID = roleGroupVals?.securityRoleGroupID ?? 0;
		this._roleGroupID = roleGroupVals?.roleGroupID ?? 0;
		this._appID = roleGroupVals?.appID ?? 0;
		this._moduleID = roleGroupVals?.moduleID ?? 0;
		this._featureID = roleGroupVals?.featureID ?? 0;
		// permission settings
		this._canAccess = roleGroupVals?.canAccess ?? false;
		this._canAdd = roleGroupVals?.canAdd ?? false;
		this._canCreate = roleGroupVals?.canCreate ?? false;
		this._canDelete = roleGroupVals?.canDelete ?? false;
		this._canDoAll = roleGroupVals?.canDoAll ?? false;
		this._canExecute = roleGroupVals?.canExecute ?? false;
		this._canUpdate = roleGroupVals?.canUpdate ?? false;
		this._canView = roleGroupVals?.canView ?? false;

		this._model = {
			SecurityByRoleGroupID: this._securityRoleGroupID,
			RoleGroupID: this._roleGroupID,
			ApplicationID: this._appID,
			ModuleID: this._moduleID,
			FeatureID: this._featureID,
			CanAccess: this._canAccess,
			CanAdd: this._canAdd,
			CanCreate: this._canCreate,
			CanDelete: this._canDelete,
			CanDoAll: this._canDoAll,
			CanExecute: this._canExecute,
			CanUpdate: this._canUpdate,
			CanView: this._canView,
			IsActive: true,
			CreatedDate: "2021-10-27T13:53:15.686Z",
			CreatedBy: "00000000-0000-0000-0000-000000000000",
			CreatedLoginBy: "string",
			CreatedStation: "string",
			ModifiedDate: "2021-10-27T13:53:15.686Z",
			ModifiedBy: "00000000-0000-0000-0000-000000000000",
			ModifiedLoginBy: "string",
			ModifiedStation: "string",
		};
	}
	getModel() {
		return this._model;
	}
}

class UserSecurityUser {
	constructor(securityUser) {
		this._securityByUserID = securityUser?.securityUserID;
		this._userID = securityUser?.userID;
		this._appID = securityUser?.appID ?? 0;
		this._moduleID = securityUser?.moduleID ?? 0;
		this._featureID = securityUser?.featureID ?? 0;
		this._canAccess = securityUser?.canAccess ?? false;
		this._canAdd = securityUser?.canAdd ?? false;
		this._canCreate = securityUser?.canCreate ?? false;
		this._canDelete = securityUser?.canDelete ?? false;
		this._canDoAll = securityUser?.canDoAll ?? false;
		this._canExecute = securityUser?.canExecute ?? false;
		this._canUpdate = securityUser?.canUpdate ?? false;
		this._canView = securityUser?.canView ?? false;

		this._model = {
			SecurityByUserID: this._securityByUserID,
			UserID: this._userID,
			ApplicationID: this._appID,
			ModuleID: this._moduleID,
			FeatureID: this._featureID,
			CanAccess: this._canAccess,
			CanAdd: this._canAdd,
			CanCreate: this._canCreate,
			CanDelete: this._canDelete,
			CanDoAll: this._canDoAll,
			CanExecute: this._canExecute,
			CanUpdate: this._canUpdate,
			CanView: this._canView,
			// extraneous fields
			IsActive: true,
			// CreatedDate: "2021-10-27T13:53:15.686Z",
			// CreatedBy: "00000000-0000-0000-0000-000000000000",
			// CreatedLoginBy: "string",
			// CreatedStation: "string",
			// ModifiedDate: "2021-10-27T13:53:15.686Z",
			// ModifiedBy: "00000000-0000-0000-0000-000000000000",
			// ModifiedLoginBy: "string",
			// ModifiedStation: "string",
		};
	}
	getModel() {
		return this._model;
	}
}

/**
 * "UserPermissionsModel":
 * - Allows settings a user's security permissions:
 * 		- Assign user to 'Role Group' w/ default permission settings
 * 		- Assign user ot 'Role' w/ specific permission settings
 * 		- Assign user to 'Access' w/ GeoLocking & feature settings
 */
class UserPermissionsModel {
	constructor(userVals) {
		this._securityAccess = userVals?.securityAccess;
		this._securityRoles = userVals?.securityRoles;
		this._securityRoleGroups = userVals?.securityRoleGroups;
		this._securityUsers = userVals?.securityUsers;

		this._model = {
			UserSecurityAccesses: [
				{
					SecurityAccessID: 0,
					ApplicationID: 0,
					FacilityID: "00000000-0000-0000-0000-000000000000",
					UserID: "00000000-0000-0000-0000-000000000000",
					ActionGroup: "string",
					Action: "string",
					IsGranted: true,
					IsActive: true,
					CreatedDate: "2021-10-27T13:53:15.686Z",
					CreatedBy: "00000000-0000-0000-0000-000000000000",
					CreatedLoginBy: "string",
					CreatedStation: "string",
					ModifiedDate: "2021-10-27T13:53:15.686Z",
					ModifiedBy: "00000000-0000-0000-0000-000000000000",
					ModifiedLoginBy: "string",
					ModifiedStation: "string",
					AccessIP: "string",
					AccessStartDate: "2021-10-27T13:53:15.686Z",
					AccessEndDate: "2021-10-27T13:53:15.686Z",
					AccessGpsLongitude: 0,
					AccessGpsLatitude: 0,
					AccessGpsRadius: 0,
				},
			],
			UserSecurityRoles: [
				{
					SecurityByRoleID: 0,
					RoleID: 0,
					ApplicationID: 0,
					ModuleID: 0,
					FeatureID: 0,
					CanAccess: true,
					CanAdd: true,
					CanCreate: true,
					CanDelete: true,
					CanDoAll: true,
					CanExecute: true,
					CanUpdate: true,
					CanView: true,
					IsActive: true,
					CreatedDate: "2021-10-27T13:53:15.686Z",
					CreatedBy: "00000000-0000-0000-0000-000000000000",
					CreatedLoginBy: "string",
					CreatedStation: "string",
					ModifiedDate: "2021-10-27T13:53:15.686Z",
					ModifiedBy: "00000000-0000-0000-0000-000000000000",
					ModifiedLoginBy: "string",
					ModifiedStation: "string",
				},
			],
			UserSecurityRoleGroups: [
				{
					SecurityByRoleGroupID: 0,
					RoleGroupID: 0,
					ApplicationID: 0,
					ModuleID: 0,
					FeatureID: 0,
					CanAccess: true,
					CanAdd: true,
					CanCreate: true,
					CanDelete: true,
					CanDoAll: true,
					CanExecute: true,
					CanUpdate: true,
					CanView: true,
					IsActive: true,
					CreatedDate: "2021-10-27T13:53:15.686Z",
					CreatedBy: "00000000-0000-0000-0000-000000000000",
					CreatedLoginBy: "string",
					CreatedStation: "string",
					ModifiedDate: "2021-10-27T13:53:15.686Z",
					ModifiedBy: "00000000-0000-0000-0000-000000000000",
					ModifiedLoginBy: "string",
					ModifiedStation: "string",
				},
			],
			UserSecurityUsers: [
				{
					SecurityByUserID: 0,
					UserID: "00000000-0000-0000-0000-000000000000",
					ApplicationID: 0,
					ModuleID: 0,
					FeatureID: 0,
					CanAccess: true,
					CanAdd: true,
					CanCreate: true,
					CanDelete: true,
					CanDoAll: true,
					CanExecute: true,
					CanUpdate: true,
					CanView: true,
					IsActive: true,
					CreatedDate: "2021-10-27T13:53:15.686Z",
					CreatedBy: "00000000-0000-0000-0000-000000000000",
					CreatedLoginBy: "string",
					CreatedStation: "string",
					ModifiedDate: "2021-10-27T13:53:15.686Z",
					ModifiedBy: "00000000-0000-0000-0000-000000000000",
					ModifiedLoginBy: "string",
					ModifiedStation: "string",
				},
			],
		};
	}
}

/**
 * 'Levels of Care' related models
 */

/**
 * A single 'Level of Care' record
 */
class LevelOfCareModel {
	constructor(levelVals) {
		this._id = levelVals?.levelID ?? 0;
		this._desc = levelVals?.levelName ?? "";
		this._minPoints = levelVals?.minPoints ?? 0;
		this._maxPoints = levelVals?.maxPoints ?? 0;

		this._model = {
			ID: this._id,
			LevelDescription: this._desc,
			PointsMin: this._minPoints,
			PointsMax: this._maxPoints,
		};
	}
	getModel() {
		return this._model;
	}
}

class CustomLevelsOfCareModel {
	constructor(level = {}) {
		this._ID = level?.ID;
		this._LOCType = level?.LOCType;
		this._FloorUnit = level?.FloorUnit;
		this._LevelNumber = level?.LevelNumber;
		this._LevelDescription = level?.LevelDescription;
		this._FacilityID = level?.FacilityID;
		this._PointsMin = level?.PointsMin;
		this._PointsMax = level?.PointsMax;

		this._model = {
			ID: this._ID,
			LOCType: this._LOCType,
			FloorUnit: this._FloorUnit,
			LevelNumber: this._LevelNumber,
			LevelDescription: this._LevelDescription,
			FacilityID: this._FacilityID,
			PointsMin: this._PointsMin,
			PointsMax: this._PointsMax,
		};
	}
	getModel() {
		return this._model;
	}
}

// LEVELS OF CARE TEMPLATES //

// APP MESSAGE MODEL(S) //

class AppMessageModel {
	constructor(vals) {
		this._messageID = vals?.messageID ?? 0;
		this._appID = vals?.appID ?? 3;
		this._moduleName = vals?.messageName ?? "LoginPage";
		// global enabler (should always be true)
		this._enableMessages = vals?.enableMessages ?? true;
		// msg 1
		this._msg1 = vals?.msg1 ?? "";
		this._msg1Priority = vals?.msg1Priority ?? "";
		this._msg1Expiry = vals?.msg1Expiry ?? null;
		this._msg1Enable = vals?.enableMsg1 ?? false;
		// msg 2
		this._msg2 = vals?.msg2 ?? "";
		this._msg2Priority = vals?.msg2Priority ?? "";
		this._msg2Expiry = vals?.msg2Expiry ?? null;
		this._msg2Enable = vals?.enableMsg2 ?? false;
		// msg 3
		this._msg3 = vals?.msg3 ?? "";
		this._msg3Priority = vals?.msg3Priority ?? "";
		this._msg3Expiry = vals?.msg3Expiry ?? null;
		this._msg3Enable = vals?.enableMsg3 ?? false;
		// msg 4
		this._msg4 = vals?.msg4 ?? "";
		this._msg4Priority = vals?.msg4Priority ?? "";
		this._msg4Expiry = vals?.msg4Expiry ?? null;
		this._msg4Enable = vals?.enableMsg4 ?? false;
		// msg 5
		this._msg5 = vals?.msg5 ?? "";
		this._msg5Priority = vals?.msg5Priority ?? "";
		this._msg5Expiry = vals?.msg5Expiry ?? null;
		this._msg5Enable = vals?.enableMsg5 ?? false;

		this._model = {
			ApplicationMessageID: this._messageID,
			ApplicationID: this._appID,
			MessageName: this._moduleName,
			IsEnable: this._enableMessages,
			Msg1: this._msg1,
			Msg1Priority: this._msg1Priority,
			Msg1IsActive: this._msg1Enable,
			Msg1ExpireDate: this._msg1Expiry,
			Msg2: this._msg2,
			Msg2Priority: this._msg2Priority,
			Msg2IsActive: this._msg2Enable,
			Msg2ExpireDate: this._msg2Expiry,
			Msg3: this._msg3,
			Msg3Priority: this._msg3Priority,
			Msg3IsActive: this._msg3Enable,
			Msg3ExpireDate: this._msg3Expiry,
			Msg4: this._msg4,
			Msg4Priority: this._msg4Priority,
			Msg4IsActive: this._msg4Enable,
			Msg4ExpireDate: this._msg4Expiry,
			Msg5: this._msg5,
			Msg5Priority: this._msg5Priority,
			Msg5IsActive: this._msg5Enable,
			Msg5ExpireDate: this._msg5Expiry,
			IsActive: true,
			// CreatedDate: "2022-01-04T18:19:13.622Z",
			// CreatedBy: "00000000-0000-0000-0000-000000000000",
			// CreatedLoginBy: "string",
			// CreatedStation: "string",
			// ModifiedDate: "2022-01-04T18:19:13.622Z",
			// ModifiedBy: "00000000-0000-0000-0000-000000000000",
			// ModifiedLoginBy: "string",
			// ModifiedStation: "string",
		};
	}
	getModel() {
		return this._model;
	}
}

// USER LOGIN Records
// BELOW ARE THE VARIOUS PIECES OF THE ABOVE 'LOGIN' MODELS //

// Facility-related Model(s)
export { FacilityInfo };
// Exceptions
export { DefaultExceptionModel, FacilityExceptionModel };
// Cancellations
export { CancellationTypeModel, FacilityCancellationTypeModel };
// Tags
export { TagModel, FacilityTagModel };
// App-Settings
export {
	AppFacilitySettingsModel,
	AppUserSettingsModel,
	AppSettingsModel,
	AppSettingsTypeModel,
};
// User-Related Model(s)
export { NewUserModel, ADVUSERModel, AdvUserCommunityModel };

// Access-Related models
export { AppByUserAccessModel, AppByFacilityAccessModel };

// User Security Questions/Answers models
export {
	SecurityAnswerModel,
	SecurityQuestionModel,
	SecurityQuestionAndAnswerModel,
	PasswordResetConfig,
	// new model
	PasswordResetType, // reset method type
	PasswordResetRules,
	// unfinished model!
	UserResetRules,
};

// App Alerts & Notifications models
export { AlertTypeModel, UserAlertModel };

// client-only user access model
export { UserFacilityAccessModel };

// 'UserTitle' & 'UserType' models
export { UserTitleModel, UserTypeModel };

// 'UserProfile' related models & records
export {
	// primary model
	UserProfileModel,
	// sub-model records
	UserProfileRecord,
	UserAlertRecord,
	UserAvatarRecord,
	UserEmailRecord,
	UserPhoneRecord,
};

// 'UserLogin' related models & records
export {
	// primary model
	UserLoginModel,
	// sub-model records
	UserLoginRecord,
	User2FARecord,
	UserAppsRecord,
	UserRolesRecord,
	UserRoleGroupsRecord,
	UserSecurityAnswerRecord,
	UserLoginFacilityRecord,
};

// USER 'ROLE', 'ROLE-GROUP', 'ROLE-TYPE', 'ROLE-GROUP-TYPE' MODELS //
export {
	UserRole,
	UserRoleGroup,
	UserRoleGroupType,
	UserRoleType,
	// whitelisted user's model
	WhiteListedUserModel,
};

// USER PERMISSIONS WRAPPER & RELATED MODEL(S)
export {
	UserSecurityAccessModel,
	UserSecurityRoleModel,
	UserSecurityRoleGroups,
	UserSecurityUser,
	// main wrapper around above models
	UserPermissionsModel,
};

// Levels of Care models
export { LevelOfCareModel, CustomLevelsOfCareModel };

// App Messages (eg. login page)
export { AppMessageModel };
